import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState, useEffect } from 'react';
import '../../../../styles/dicegame.css'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Decimal } from 'decimal.js';
import { t } from 'i18next';
import Contexts from '../DiceGameContext';
import Lottie from "lottie-react";
import lottieLoading from './loadingV2.json'
import { Button } from '@mui/material';
import { requestbetresults,playRollingAudio } from './DiceComponetGamePart';
// import { walletGameToBalance } from '../../../../features/walletSlice';
import { useDispatch } from 'react-redux';
import AudioWin from '../../../../assets/audios/dicewin.mp3';
import AudioBet from '../../../../assets/audios/dicebet.mp3';


const { MaxBetContext, AnimationActive,Multiplier,RollOverOrUnder,RollOver,Requesting,
  RollResult,DiceVolume,BetHistory,IsAutoBetting,AutoBetStart,AutoBetStop } = Contexts;


const DiceComponetMenuTabs = ({httpManager,totalBalance,layoutDirection}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);
    // 下注金额
    const [betAmount, setBetAmount] = useState('0.0000');
    // 投注次数
    const [betTimes, setBetTimes] = useState('0');
    // 获得利润
    const [profit, setProfit] = useState('0.0000');
    // 下注金额步长
    const [betAmountStep, setBetAmountStep] = useState(1);
    //下注金额不能大于余额提示
    const [showBetAmountTooltip, setShowBetAmountTooltip] = useState(false);
    //是否自动投注状态中
    const {isAutoBetActive,setIsAutoBetActive} = useContext(IsAutoBetting);
    //乘数
    const {multiplier} = useContext(Multiplier);
    //大于还是小于
    const {rollOverOrUnder} = useContext(RollOverOrUnder);
    //下注的数值
    const {rollOver} = useContext(RollOver);
    //是否请求中
    const {isRequesting,setIsRequesting} = useContext(Requesting);
    //是否显示动画
    const {isAnimationActive} = useContext(AnimationActive);
    //下注后的结果
    const {rollResult,setRollResult} = useContext(RollResult);
    //投注按钮是否可用
    const [isBetButtonDisabled, setIsBetButtonDisabled] = useState(true);
    //游戏音量
    const {globalVolume} = useContext(DiceVolume);
    //下注历史
    const {historyBetsArr,setHistoryBetsArr} = useContext(BetHistory);
    //请求是否处理完成
    const [isRequestFinished, setIsRequestFinished] = useState(false);
    //自动下注开始
    const {autoBetStart,setAutoBetStart} = useContext(AutoBetStart);
    //自动下注结束
    const {autoBetStop,setAutoBetStop} = useContext(AutoBetStop);

    useEffect(() => {
      if(isRequestFinished){
        setIsRequestFinished(false);
        autoBetAction();
      }
    }, [isRequestFinished]);

    const checkBetTooltip = () => {
      try {
        const dec_betAmount = new Decimal(betAmount)
        const dec_balance = new Decimal(totalBalance)
        if (dec_betAmount.greaterThan(dec_balance)) {
          setShowBetAmountTooltip(true);
        } else {
          setShowBetAmountTooltip(false);
        }
      }catch(e){
      }
    };

    //下注金额变化--联动投注按钮
    useEffect(() => {
      let betIsOk = true;
      try {
        const dec_betAmount = new Decimal(betAmount)
        const dec_balance = new Decimal(totalBalance)
        if (dec_betAmount.greaterThan(dec_balance)) {
          setShowBetAmountTooltip(true);
          betIsOk = false;
        } else {
          setShowBetAmountTooltip(false);
        }
        if (dec_betAmount.lessThanOrEqualTo(0)) {
          betIsOk = false;
        }
        setIsBetButtonDisabled(betIsOk);
      }catch(e){
        console.log('e:',e);
      }
    }, [betAmount]);
    //乘数变化---联动获胜利润
    useEffect(() => {
      let dec_betAmount = new Decimal(betAmount);
      let dec_multiplier = new Decimal(multiplier.length === 0 ? 0 : multiplier);
      let dec_profit = dec_betAmount.times(dec_multiplier)
      setProfit(dec_profit.toString());
    }, [multiplier]);

    // 下注金额输入框---焦点消失事件
    const betInputBlurEvent = (event) => {
      const newValue = event.target.value;
      console.log('newValue:',newValue);
      let dec_accountBalance = new Decimal(totalBalance);
      let dec_newValue = new Decimal(0);
      try {
        dec_newValue = new Decimal(newValue);
      }catch(e){
      }
      if (dec_newValue.lt(0)) {
        setBetAmount('0.0000');
        return;
      }
      if (dec_newValue.greaterThan(dec_accountBalance)) {
        // setBetAmount(dec_accountBalance.toFixed(4));
        return;
      }
      setBetAmount(dec_newValue.toFixed(4));
      let dec_multiplier = new Decimal(multiplier);
      let dec_profit = dec_newValue.times(dec_multiplier)
      setProfit(dec_profit.toString());
    };
    // 下注金额输入框事件
    const betInputEvent = (event) => {
      const newValue = event.target.value;
      console.log('newValue:',newValue);
      //键盘输入
      if (event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType) {
        setBetAmount(newValue);
      } else {
        let dec_accountBalance = new Decimal(totalBalance);
        let dec_newValue = new Decimal(newValue);
        if (dec_newValue.lt(0)) {
          setBetAmount('0.0000');
          return;
        }
        if (dec_newValue.greaterThan(dec_accountBalance)) {
          setBetAmount(dec_accountBalance.toFixed(4));
          return;
        }
        setBetAmount(dec_newValue.toFixed(4));
        let dec_multiplier = new Decimal(multiplier);
        let dec_profit = dec_newValue.times(dec_multiplier)
        setProfit(dec_profit.toString());
      }
    };

    // 投注次数输入框事件
    const betTimesInputEvent = (event) => {
      const newValue = event.target.value;
      console.log('newValue:',newValue);
      if (newValue < 0) {
        setBetTimes(0);
        return;
      }
      setBetTimes(newValue);
    }
    //1/2,2x,最大值
    const betShortInputEvent = (param) => {
      console.log('param:',param);
      let dec_betAmount = null;
      if (param === 'half') {
        //使用decimal计算accountBalance 的一半
        const halfBet = new Decimal(betAmount).dividedBy(2);
        setBetAmount(halfBet.toFixed(4));
        dec_betAmount = new Decimal(halfBet);
      } else if (param === '2x') {
        const Bet2x = new Decimal(betAmount).mul(2);
        setBetAmount(Bet2x.toFixed(4));
        dec_betAmount = new Decimal(Bet2x);
      } else if (param === 'all') {
        setBetAmount(totalBalance);
        dec_betAmount = new Decimal(totalBalance);
      }
      let dec_multiplier = new Decimal(multiplier);
      let dec_profit = dec_betAmount.times(dec_multiplier)
      setProfit(dec_profit.toString());
    };


    //点击按钮文字缩小再恢复
    const [isClicked1, setIsClicked1] = useState(false);
    const [isClicked2, setIsClicked2] = useState(false);
    //选中哪个tab
    const [menuTabIndex, setMenuTableIndex] = useState(0);
    const handleClick = (props)=>{
      setMenuTableIndex(props);
    };

    //手动，自动，高级 鼠标是否移入
    const [hoverDiv1, setHoverDiv1] = useState(false);
    const [hoverDiv2, setHoverDiv2] = useState(false);
    const [hoverDiv3, setHoverDiv3] = useState(false);


    //若赢--选中哪个tab
    const [winTabIndex, setWinTableIndex] = useState(0);
    //若赢-重置 鼠标是否移入
    const [hoverDiv4, setHoverDiv4] = useState(false);
    //若赢-增加 鼠标是否移入
    const [hoverDiv5, setHoverDiv5] = useState(false);
    // 若赢--百分比
    const [winPercent, setWinPercent] = useState('0');
    // 若赢--输入变化
    const whenWinInputEvent = (event) => {
      const newValue = event.target.value;
      console.log('whenWinInputEvent newValue:',newValue)
      setWinPercent(newValue);
    }

    //若输--选中哪个tab
    const [lossTabIndex, setLossTableIndex] = useState(0);
    //若输-重置 鼠标是否移入
    const [hoverDiv6, setHoverDiv6] = useState(false);
    //若输-增加 鼠标是否移入
    const [hoverDiv7, setHoverDiv7] = useState(false);
    //若输--百分比
    const [lossPercent, setLossPercent] = useState('0');
    //若输--输入变化
    const whenLossInputEvent = (event) => {
      const newValue = event.target.value;
      console.log('whenLossInputEvent newValue:',newValue)
      setLossPercent(newValue);
    }

    //止赢
    const [stopWin, setStopWin] = useState('0.0000');
    //止赢--输入焦点消失
    const stopWinBlurEvent = (event) => {
      const newValue = event.target.value;
      console.log('stopWinBlurEvent newValue:',newValue)
      try{
        let dec_newValue = new Decimal(newValue);
        if(dec_newValue.lessThan(0)){
          setStopWin('0.0000');
        }else{
          setStopWin(dec_newValue.toFixed(4));
        }
      }catch{
        setStopWin('0.0000');
      }
    }
    //止赢--输入变化
    const stopWinInputEvent = (event) => {
      const newValue = event.target.value;
      console.log('stopWinInputEvent newValue:',newValue)
      if (event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType) {
        setStopWin(newValue);
      } else {
        if (newValue < 0) {
          setStopWin('0.0000');
          return;
        }
        try{
          let dec_newValue = new Decimal(newValue);
          setStopWin(dec_newValue.toFixed(4));
        }catch{
          setStopWin(newValue);
        }
      }
    }
    //止损
    const [stopLoss, setStopLoss] = useState('0.0000');
    const stopLossBlurEvent = (event) => {
      const newValue = event.target.value;
      console.log('stopLossBlurEvent newValue:',newValue)
      try{
        let dec_newValue = new Decimal(newValue);
        if(dec_newValue.lessThan(0)){
          setStopLoss('0.0000');
        }else{
          setStopLoss(dec_newValue.toFixed(4));
        }
      }catch{
        setStopLoss('0.0000');
      }
    }
    //止损--输入变化
    const stopLossInputEvent = (event) => {
      const newValue = event.target.value;
      console.log('stopLossInputEvent newValue:',newValue)
      if (event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType) {
        setStopLoss(newValue);
      } else {
        if (newValue < 0) {
          setStopLoss('0.0000');
          return;
        }
        try{
          let dec_newValue = new Decimal(newValue);
          setStopLoss(dec_newValue.toFixed(4));
        }catch{
          setStopLoss(newValue);
        }
      }
    }

    //点击投注按钮
    const betButtonEvent = () => {
      if(!isBetButtonDisabled){
        return;
      }
      playBetAudio()
      console.log('betButtonEvent');
      setIsRequesting(true);
      sendBetRequest();
    }

    //btc图标
    const BTCSvgIcon = () => (
      <svg width="16" height="16" viewBox="0 0 96 96" >
         <title></title> <path d="M95.895 48.105C95.895 74.557 74.453 96 48.002 96 21.549 96 .105 74.556.105 48.105.105 21.653 21.55.21 48.001.21s47.895 21.443 47.895 47.895Z" fill="#F7931A"></path><path d="M69.525 42.18c.93-6.27-3.84-9.645-10.38-11.895l2.115-8.505-5.16-1.29-2.1 8.28c-1.365-.345-2.76-.66-4.14-.975l2.1-8.295-5.175-1.29-2.115 8.49c-1.125-.255-2.235-.51-3.3-.78l-7.14-1.785-1.365 5.52s3.84.885 3.75.93a2.763 2.763 0 0 1 2.414 3.011l.001-.01-2.415 9.69c.213.049.394.106.568.174l-.028-.01-.54-.135-3.39 13.5a1.879 1.879 0 0 1-2.383 1.226l.013.004-3.765-.93L24.525 63l6.735 1.665 3.69.96-2.145 8.595 5.175 1.29 2.115-8.505c1.41.375 2.775.735 4.125 1.065l-2.115 8.475 5.175 1.29 2.13-8.58c8.835 1.665 15.465.99 18.255-6.99 2.25-6.42-.105-10.125-4.755-12.54 3.39-.72 5.925-2.955 6.615-7.545ZM57.69 58.755c-1.59 6.435-12.405 3-15.915 2.085L44.61 49.5c3.51.825 14.76 2.565 13.08 9.255Zm1.605-16.665c-1.5 5.85-10.5 2.865-13.38 2.145l2.58-10.32c2.91.72 12.315 2.085 10.8 8.175Z" fill="#fff"></path>
      </svg>

    );

    //投注次数icon
    const BetTimesSvgIcon = () => (
      <svg fill='#B0BAD3' width="16" height="16" viewBox="0 0 64 64" style={{
        width:betTimes<=0?'16px':'0px',
      }}>
        <title></title><path d="M47.746 15.695c-9.287 0-13.851 7.073-17.908 13.345-4.377 6.752-7.473 10.836-13.531 10.836a7.846 7.846 0 0 1-7.847-7.847 7.845 7.845 0 0 1 7.847-7.846 9.29 9.29 0 0 1 6.915 2.669l1.014-1.549c1.094-1.708 2.349-3.602 3.79-5.524a17.365 17.365 0 0 0-11.743-4.084h.024C7.302 15.695 0 22.998 0 32.002 0 41.007 7.302 48.31 16.307 48.31c11.103 0 16.387-8.006 20.63-14.705 3.79-5.872 6.352-9.448 10.81-9.448a7.846 7.846 0 0 1 7.846 7.846 7.846 7.846 0 0 1-7.847 7.847 9.27 9.27 0 0 1-5.791-1.78l.027.018a65.172 65.172 0 0 1-5.047 6.937l.082-.104a17.38 17.38 0 0 0 10.358 3.392c.113 0 .225 0 .334-.003h-.016C56.698 48.31 64 41.007 64 32.002c0-9.004-7.302-16.307-16.307-16.307h.053Z"></path>
      </svg>
    );

    //百分符号svg
    const PercentSvgIcon = () => (
      <svg fill="#B0BAD3" width="16" height="16" viewBox="0 0 64 64" >
        <title></title> <path d="M18.38 29.904c6.364 0 11.524-5.16 11.524-11.524 0-6.364-5.16-11.524-11.524-11.524-6.364 0-11.525 5.16-11.525 11.524 0 6.364 5.16 11.524 11.524 11.524Zm0-14.666a3.142 3.142 0 1 1-.001 6.285 3.142 3.142 0 0 1 0-6.285Zm27.24 18.858c-6.364 0-11.524 5.16-11.524 11.524 0 6.364 5.16 11.524 11.524 11.524 6.364 0 11.524-5.16 11.524-11.524 0-6.364-5.16-11.524-11.524-11.524Zm0 14.666a3.142 3.142 0 1 1 0-6.285 3.142 3.142 0 0 1 0 6.285Zm.585-41.904L6.857 57.144h10.644L56.85 6.858H46.205Z"></path>
      </svg>
    );
    //usdt图标
    const USDTSvgIcon = () => (
      <svg fill="none" width="16" height="16" viewBox="0 0 96 96">
        <title></title> <path d="M96 48c0 26.51-21.49 48-48 48S0 74.51 0 48 21.49 0 48 0s48 21.49 48 48Z" fill="#26A17B"></path><path d="M53.766 52.149v-.006c-.33.024-2.03.126-5.826.126-3.03 0-5.163-.09-5.913-.126v.009c-11.664-.513-20.37-2.544-20.37-4.974 0-2.427 8.706-4.458 20.37-4.98v7.932c.762.054 2.946.183 5.964.183 3.621 0 5.436-.15 5.775-.18v-7.929c11.64.519 20.325 2.55 20.325 4.974 0 2.43-8.685 4.455-20.325 4.971Zm0-10.77v-7.098h16.242V23.457H25.785v10.824h16.242v7.095c-13.2.606-23.127 3.222-23.127 6.354s9.927 5.745 23.127 6.354V76.83h11.74V54.078c13.178-.606 23.081-3.219 23.081-6.348s-9.903-5.742-23.082-6.351Z" fill="#fff"></path>
      </svg>
    );
    
    //是否显示最大值按钮
    // const [showMax, setShowMax] = useState(true);
    const { isMaxBetActive } = useContext(MaxBetContext);
    //1/2,2x,最大值 鼠标是否移入
    const [hoverHalf, setHoverHalf] = useState(false);
    const [hover2x, setHover2x] = useState(false);
    const [hoverMax, setHoverMax] = useState(false);

    const tabSelectedColor = '#1184FA'
    const borderColor = '#55657E';
    const textColor = '#8EA3C3';
    const hoverSecondaryColor = '#1184FA';
    const lineColor = '#1A2C38';
    
    //下注失败提示信息
    const [betFailMessage, setBetFailMessage] = useState('');

    //下注请求
    const sendBetRequest = () => {
      let dec_betAmount = new Decimal(betAmount);
      let condition = rollOverOrUnder?'bebig':'below';
      let target = rollOver;
      httpManager.post(
        '/dice/playerDice/',
        {
          amount: dec_betAmount.toFixed(4),
          condition:condition,
          currency: 'USDT',
          target: target,
        })
        .then(response => {
          // 处理响应
          console.log('response data:',response.data);
          if (response.data.code === 200) {
            setRollResult(response.data.data.diceRoll.state.result);
            requestbetresults(isAnimationActive)
            playRollingAudio(globalVolume)
            //历史记录增加一条数据
            let newData = {
              'isWin':response.data.data.diceRoll.isWin,
              'result':String(response.data.data.diceRoll.state.result),
              'roundId':String(response.data.data.diceRoll.roundId),
              'balance':String(response.data.data.diceRoll.balance),
              'moveLeft':false,
              'disappear':false}
            if(historyBetsArr.length >= 5) {
              historyBetsArr[historyBetsArr.length - 1]['disappear'] = true;
            }
            let newArr = [newData,...historyBetsArr]
            newArr.map((item,index) => {
              item['moveLeft'] = true;
              return item;
            });
            setHistoryBetsArr(newArr);

            setTimeout(() => {
              if(response.data.data.diceRoll.isWin){
                playWinAudio()
              }
              console.log('response.data.data.diceRoll.balance:',response.data.data.diceRoll.balance);
              // dispatch(walletGameToBalance({
              //   totalBalance:response.data.data.diceRoll.balance,
              //   won:response.data.data.diceRoll.winAmount,
              // }));
              setIsRequesting(false);
            }, 500);
            setTimeout(() => {
              setIsRequestFinished(true);
            }, 500);
          }else{
            setBetFailMessage(response.data.msg);
            handleClickOpen();
            setIsRequesting(false);
          }
        })
        .catch(error => {
          // 处理错误
          console.log('error:',error);
          setBetFailMessage(error.toString());
          handleClickOpen();
          setIsRequesting(false);
        });
    };

    //自动下注操作
    const autoBetAction =() =>
    {
      //自动下注check
      // betTimes --- 0无限操作 次数 
      // winTabIndex
      // winPercent
      // lossTabIndex
      // lossPercent
      // stopWin
      // stopLoss
      if(isAutoBetActive){
        let lastBetHistory = historyBetsArr[0]
        console.log('lastBetHistory:',lastBetHistory);
        let isContinue = true;
        if(betTimes > 0){
          let dec_betTimes = new Decimal(betTimes);
          dec_betTimes = dec_betTimes.minus(1);
          setBetTimes(dec_betTimes.toString());
          if(dec_betTimes.lessThanOrEqualTo(0)){
            //次数到了结束
            setIsAutoBetActive(false);
            isContinue = false;
          }
        }
        //winPercent 如果包含'--'则转成 '-'
        let winPercentStr = winPercent;
        if(winPercentStr.includes('--')){
          winPercentStr = winPercentStr.replace('--','-');
        }
        //若赢--增加
        let dec_winPercent = new Decimal(winPercentStr);
        if(winTabIndex === 1 && !dec_winPercent.equals(0) && lastBetHistory.isWin){
          let dec_betAmount = new Decimal(betAmount);
          let dec_changeAmount = dec_betAmount.times(dec_winPercent).dividedBy(100);
          let dec_resultAmount = dec_betAmount.plus(dec_changeAmount);
          console.log('若赢--增加 betAmount 到:',dec_resultAmount.toFixed(4));
          if(dec_resultAmount.lessThan(0)){
            dec_resultAmount = new Decimal(0);
            setIsAutoBetActive(false);
            isContinue = false;
          }
          setBetAmount(dec_resultAmount.toFixed(4));
        }

        //winPercent 如果包含'--'则转成 '-'
        let lossPercentStr = lossPercent;
        if(lossPercentStr.includes('--')){
          lossPercentStr = lossPercentStr.replace('--','-');
        }
        //若输
        let dec_lossPercent = new Decimal(lossPercentStr);
        if(lossTabIndex === 1 && !dec_lossPercent.equals(0) && !lastBetHistory.isWin){
          let dec_betAmount = new Decimal(betAmount);
          let dec_changeAmount = dec_betAmount.times(dec_lossPercent).dividedBy(100);
          let dec_resultAmount = dec_betAmount.plus(dec_changeAmount);
          console.log('若输--增加 betAmount 到:',dec_resultAmount.toFixed(4));
          if(dec_resultAmount.lessThan(0)){
            dec_resultAmount = new Decimal(0);
            setIsAutoBetActive(false);
            isContinue = false;
          }
          setBetAmount(dec_resultAmount.toFixed(4));
        }

        //止盈
        let dec_stopWin = new Decimal(stopWin);
        if(dec_stopWin.greaterThan(0)){
          let dec_lastBalance = new Decimal(lastBetHistory.balance);
          if(dec_lastBalance.greaterThanOrEqualTo(dec_stopWin)){
            setIsAutoBetActive(false);
            isContinue = false;
          }
        }

        //止损
        let dec_stopLoss = new Decimal(stopLoss);
        if(dec_stopLoss.greaterThan(0)){
          let dec_lastBalance = new Decimal(lastBetHistory.balance);
          if(dec_lastBalance.lessThanOrEqualTo(dec_stopLoss)){
            setIsAutoBetActive(false);
            isContinue = false;
          }
        }

        if(isContinue){
          sendBetRequest();
        }
      }
    }


    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };


    //游戏音效---胜利
    const [diceAudioWin] = useState(new Audio(AudioWin));
    const playWinAudio = () => {
      // 如果音频正在播放，先停止并重置播放位置
      if (!diceAudioWin.paused) {
        diceAudioWin.pause();
        diceAudioWin.currentTime = 0; // 重置音频文件到开始位置
      }
      diceAudioWin.volume = globalVolume / 100;
      diceAudioWin.play().catch(error => {
        console.log("Playback was prevented. Error: ", error)
      });
    }

    //游戏音效---下注
    const [diceAudioBet] = useState(new Audio(AudioBet));
    const playBetAudio = () => {
      // 如果音频正在播放，先停止并重置播放位置
      if (!diceAudioBet.paused) {
        diceAudioBet.pause();
        diceAudioBet.currentTime = 0; // 重置音频文件到开始位置
      }
      diceAudioBet.volume = globalVolume / 100;
      diceAudioBet.play().catch(error => {
        console.log("Playback was prevented. Error: ", error)
      });
    }

    const children = [
      /* tab部分----tab部分----tab部分----tab部分----tab部分----tab部分----tab部分----tab部分----tab部分----tab部分----tab部分---- */
      <div key="tab" style={{
        display:'flex',
        width:'calc(100% - 10px)',
        height:'34px',
        padding:'5px 5px 5px 5px',
        backgroundColor:'#0C131D',
        borderRadius:'18px',
        marginTop: layoutDirection === 'ver'?'10px':'0px',
        opacity: isRequesting||isAutoBetActive ? 0.5 : 1,
      }}>
        <button style={{
          backgroundColor: hoverDiv1 ? tabSelectedColor:'#0C131D',
          border:'none',
          borderRadius:'16px',
          color:'#FFFFFF',
          fontWeight:'bold',
          outline:'none',
          padding:'8px 8px 8px 8px',
          flex: '2',
        }}
        onMouseEnter={() =>{
          if (!isRequesting && !isAutoBetActive){
            setHoverDiv1(true)
          }
        }}
        onMouseLeave={() =>{
          if (!isRequesting && !isAutoBetActive){
            setHoverDiv1(false)
          }
        }}
        onClick={() =>{
          if (!isRequesting && !isAutoBetActive){
            setIsAutoBetActive(false)
            handleClick(0)
          }
        }}
        className={menuTabIndex===0 ? 'dice-tab-active' : ''} 
        onMouseDown={()=>
          {
            if (!isRequesting && !isAutoBetActive){
              setIsClicked1(true)
            }
          }}
        onMouseUp={()=>{
          if (!isRequesting && !isAutoBetActive){
            setIsClicked1(false)
          }
        }}
        ><span style={{
          fontSize: isClicked1 ? 0.7 * 0.92 + 'rem' : '0.7rem',
          cursor:'pointer',
          color:menuTabIndex===0?'#ffffff':'#8EA3C3'
        }}>
          手动投注
        </span>
        </button>

        <button style={{
          backgroundColor: hoverDiv2 ? tabSelectedColor:'#0C131D',
          border:'none',
          borderRadius:'16px',
          color:'#FFFFFF',
          fontWeight:'bold',
          outline:'none',
          padding:'8px 8px 8px 8px',
          flex: '2',
          marginLeft:'4px',
        }}
        onMouseEnter={() => {
          if (!isRequesting && !isAutoBetActive){
            setHoverDiv2(true)
          }
        }}
        onMouseLeave={() =>{
          if (!isRequesting && !isAutoBetActive){
            setHoverDiv2(false)
          }
        }}
        onClick={() => {
          if (!isRequesting && !isAutoBetActive){
            handleClick(1)
          }
        }}
        className={menuTabIndex===1 ? 'dice-tab-active' : ''}
        onMouseDown={()=>{
          if (!isRequesting && !isAutoBetActive){
            setIsClicked2(true)
          }
        }}
        onMouseUp={()=>{
          if (!isRequesting && !isAutoBetActive){
            setIsClicked2(false)
          }
        }}
        ><span style={{
          fontSize: isClicked2 ? 0.7 * 0.92 + 'rem' : '0.7rem',
          cursor:'pointer',
          color:menuTabIndex===1?'#ffffff':'#8EA3C3'
        }}>
          自动投注
        </span>
        </button>
        <button style={{
          backgroundColor: hoverDiv3 ? tabSelectedColor:'#0C131D',
          border:'none',
          borderRadius:'16px',
          color:'#FFFFFF',
          fontWeight:'bold',
          outline:'none',
          padding:'8px 8px 8px 8px',
          flex: '1',
          marginLeft:'4px',
          pointerEvents: 'none',
          disabled: true,
        }}
        // onMouseEnter={() => setHoverDiv3(true)}
        // onMouseLeave={() => setHoverDiv3(false)}
        // onClick={() => handleClick(2)}
        className={menuTabIndex===2 ? 'dice-tab-active' : ''}
        ><span style={{
          fontSize:'0.7rem',
          cursor:'pointer',
        }}>
          <svg fill="#ACB6CF" viewBox="0 0 64 64" style={{
            width:'13px',
            height:'13px',
            marginLeft:'2px',
            marginTop:'2px',
          }}> <title></title> <path d="M23.34 16.13h29.17L46.63 22l4.24 4.24L64 13.13 50.87 0l-4.24 4.24 5.88 5.89H23.34c-7.732 0-14 6.268-14 14h6a8 8 0 0 1 8-8Zm17.32 31.74H11.49L17.37 42l-4.24-4.24L0 50.87 13.13 64l4.24-4.24-5.88-5.89h29.17c7.732 0 14-6.268 14-14h-6a8 8 0 0 1-8 8Zm-5.23-12.44V44h-6.86v-8.57H20v-6.86h8.57V20h6.86v8.57H44v6.86h-8.57Z"></path></svg>
        </span>
        </button>
      </div>,
      /* 提示超过余额---提示超过余额---提示超过余额---提示超过余额---提示超过余额---提示超过余额---提示超过余额--- */
      <div key="alert" className="relativeContainer">
        <div className="tooltip" style={{
          display:showBetAmountTooltip?'block':'none',
        }}>
            您的投注金额不能大于余额！
        </div>
      </div>,
      /* 投注额度块----投注额度块----投注额度块----投注额度块----投注额度块----投注额度块----投注额度块----投注额度块----投注额度块---- */
      <div key="betAmount" style={{
        marginTop:'6px',
        display:'flex',
        flexDirection:'column',
        width:'calc(100%)',
      }}>
        <div style={{ 
          width:'100%', 
          display:'flex',
          flexDirection:'row',
          }}>
          <span style={{ fontSize:"13px", fontWeight:'bold', color:textColor}}>投注额</span>
          <span style={{ fontSize:"11px", marginLeft:'auto', fontWeight:'', color:textColor,textAlign:'end'}}>us${betAmount}</span>
        </div>
        
        <div style={{
          width:'100%',
          backgroundColor:'#55657E',
          marginTop:"2px",
          display:'flex',
          flexDirection:'row',
          borderRadius:'4px',
          overflow:'hidden',
          alignItems:'center',
          opacity: isRequesting || isAutoBetActive ? 0.5 : 1,
          }}>
          <TextField
            id="outlined-number"
            label=""
            type="number"
            placeholder='0.00000'
            value={betAmount}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              border:'2px solid',
              borderColor:'#55657E',
              borderRadius:'4px',
              padding:'0px 0px 0px 0px',
              backgroundColor:'#000000',
              width: '106px',
              '& .MuiInputBase-input': {
                  height: '34px',
                  padding: '0px 0px 0px 10px',//上右下左
                  fontSize: '14px', // 调整字体大小
                  color: 'white', // 设置占位符颜色
              },
              '& .MuiInputBase-input::placeholder': { // 特别针对占位符的样式
                color: 'white', // 设置占位符颜色
                fontWeight: 'bold', // 设置占位符字体加粗
              },
              '&:hover': {
                borderColor: tabSelectedColor, // 鼠标悬停时的边框颜色
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: 'transparent', // 设置透明
                }
              },
              flex: '1 1 0%',
              pointerEvents: isRequesting || isAutoBetActive?'none':'default',
            }}
            InputProps={{
              endAdornment: (
                  <InputAdornment position="end">
                      <USDTSvgIcon /> 
                  </InputAdornment>
              ),
            }}
            onFocus={e => {
              e.target.select();
            }}
            onChange={betInputEvent}
            onBlur={betInputBlurEvent}
            inputProps={{ step: betAmountStep }}
            onMouseEnter={checkBetTooltip}
            onMouseLeave={checkBetTooltip}
          />
          <button style={{
            maxWidth:'32px',
            height:'34px',
            backgroundColor: hoverHalf?hoverSecondaryColor:'transparent',
            border: 'none',
            color:'white',
            fontWeight:'bold',
            flex: '0 0 100%',
          }}
          onMouseEnter={() =>{
            if (!isRequesting  && !isAutoBetActive){
              setHoverHalf(true)
            }
          }}
          onMouseLeave={() =>{
            if (!isRequesting  && !isAutoBetActive){
              setHoverHalf(false)
            }
          }}
          onClick={()=>{
            if (!isRequesting  && !isAutoBetActive){
              betShortInputEvent('half')
            }
          }}
          >
            ½
          </button>
          <div style={{
              width: '1.5px', // 线的宽度
              height: '24px', // 线的高度
              backgroundColor: lineColor, // 线的颜色
          }}></div>
          <button style={{
            maxWidth:'32px',
            height:'34px',
            backgroundColor: hover2x?hoverSecondaryColor:'transparent',
            border: 'none',
            color:'white',
            fontWeight:'bold',
            flex: '0 0 100%',
          }}
          onMouseEnter={() =>{
            if (!isRequesting && !isAutoBetActive){
              setHover2x(true)
            }
          }}
          onMouseLeave={() =>{
            if (!isRequesting && !isAutoBetActive){
              setHover2x(false)
            }
          }}
          onClick={()=>{
            if (!isRequesting && !isAutoBetActive){
              betShortInputEvent('2x')
            }
          }}
          >
            2x
          </button>
          <div style={{
              width: '1.5px', // 线的宽度
              height: '24px', // 线的高度
              backgroundColor: lineColor, // 线的颜色
              display: isMaxBetActive?'block':'none',
          }}></div>
          <button style={{
            backgroundColor: hoverMax?hoverSecondaryColor:'transparent',
            border: 'none',
            color:'white',
            fontWeight:'bold',
            lineHeight:'34px',
            fontSize:'12px',
            display: isMaxBetActive?'block':'none',
          }}
          onMouseEnter={() => {
            if (!isRequesting && !isAutoBetActive){
              setHoverMax(true)
            }
          }}
          onMouseLeave={() =>{
            if (!isRequesting && !isAutoBetActive){
              setHoverMax(false)
            }
          }}
          onClick={()=>{
            if (!isRequesting && !isAutoBetActive){
              betShortInputEvent('all')
            }
          }}
          >
            最大值
          </button>
        </div>
      </div>,
      /* 投注次数----投注次数----投注次数----投注次数----投注次数----投注次数----投注次数----投注次数----投注次数----投注次数----投注次数---- */
      <div key="betTimes" style={{
        marginTop:'6px',
        flexDirection:'column',
        width:'calc(100%)',
        display: menuTabIndex==1?'flex':'none',
      }}>
        <div style={{ 
          width:'100%', 
          display:'flex',
          flexDirection:'row',
        }}>
          <span style={{ fontSize:"13px", fontWeight:'bold', color:textColor}}>投注次数</span>
        </div>
        <TextField
          id="outlined-number"
          label=""
          type="number"
          placeholder='0'
          value={betTimes}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            border:'2px solid',
            borderColor:borderColor,
            borderRadius:'4px',
            padding:'0px 0px 0px 0px',
            backgroundColor:'#000000',
            width: 'calc(100%-4px)',
            '& .MuiInputBase-input': {
                height: '34px',
                padding: '0px 0px 0px 10px',//上右下左
                fontSize: '14px', // 调整字体大小
                color: 'white', // 设置占位符颜色
            },
            '& .MuiInputBase-input::placeholder': { // 特别针对占位符的样式
              color: 'white', // 设置占位符颜色
              fontWeight: 'bold', // 设置占位符字体加粗
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                  borderColor: 'transparent', // 设置透明
              }
            },
            '&:hover': {
              borderColor: hoverSecondaryColor, // 鼠标悬停时的边框颜色
            },
            flex: '1 1 0%',
            pointerEvents: isRequesting || isAutoBetActive?'none':'default',
            opacity: isRequesting || isAutoBetActive ? 0.5 : 1,
          }}
          InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <BetTimesSvgIcon /> 
                </InputAdornment>
            ),
          }}
          onFocus={e => {
            e.target.select();
          }}
          onChange={betTimesInputEvent}
          inputProps={{ step: 1 }}
          onMouseEnter={checkBetTooltip}
          onMouseLeave={checkBetTooltip}
        />
      </div>,
      /* 若赢---- 若赢---- 若赢---- 若赢---- 若赢---- 若赢---- 若赢---- 若赢---- 若赢---- 若赢---- 若赢---- 若赢---- 若赢---- 若赢---- 若赢---- */
      <div key="ifWin" style={{
        marginTop:'6px',
        flexDirection:'column',
        width:'calc(100%)',
        display: menuTabIndex==1?'flex':'none',
      }}>
        <div style={{ 
          width:'100%', 
          display:'flex',
          flexDirection:'row',
          }}>
          <span style={{ fontSize:"13px", fontWeight:'bold', color:textColor}}>若赢</span>
        </div>
        <div style={{
          display:'flex',
          flexDirection:'row',
          alignItems:'center',
          width:'100%',
          height:'38px',
          backgroundColor:borderColor,
          marginTop:"2px",
          borderRadius:'4px',
          opacity: isRequesting || isAutoBetActive ? 0.5 : 1,
        }}>
          <button style={{
            backgroundColor: hoverDiv4? hoverSecondaryColor: winTabIndex===0? tabSelectedColor:'transparent',
            border:'none',
            borderRadius:'4px',
            color:'#FFFFFF',
            fontWeight:'bold',
            outline:'none',
            padding:'8px 8px 8px 8px',
            marginRight:'4px',
            width:'25%',
            marginLeft:'2px',
          }}
          onMouseEnter={() =>{
            if(!isRequesting && !isAutoBetActive){
              if (winTabIndex !== 0) {
                setHoverDiv4(true)
              }
            }
          }}
          onMouseLeave={() =>{
            if(!isRequesting && !isAutoBetActive){
              setHoverDiv4(false)
            }
          }}
          onClick={() =>{
            if(!isRequesting && !isAutoBetActive){
              setHoverDiv4(false) 
              setWinTableIndex(0)
            }
          }}
          className={menuTabIndex===0 ? 'dice-wintab-active' : ''}
          >重置</button>
          <button style={{
            backgroundColor: hoverDiv5? hoverSecondaryColor: winTabIndex===1? tabSelectedColor:'transparent',
            border:'none',
            borderRadius:'4px',
            color:'#FFFFFF',
            fontWeight:'bold',
            outline:'none',
            padding:'8px 8px 8px 8px',
            marginRight:'4px',
            width:'25%',
          }}
          onMouseEnter={() =>{
            if(!isRequesting && !isAutoBetActive){
              if (winTabIndex !== 1) {
                setHoverDiv5(true)
              }
            }
          }}
          onMouseLeave={() =>{
            if(!isRequesting && !isAutoBetActive){
              setHoverDiv5(false)
            }
          }}
          onClick={() => {
            if(!isRequesting && !isAutoBetActive){
            setHoverDiv5(false)
            setWinTableIndex(1)}}}
          className={menuTabIndex===0 ? 'dice-wintab-active' : ''}
          >增加</button>
          <TextField
            id="outlined-number"
            label=""
            type="number"
            placeholder='0'
            value={winPercent}
            disabled={winTabIndex===0?true:false}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              border:'2px solid',
              borderColor:borderColor,
              borderRadius:'4px',
              padding:'0px 0px 0px 0px',
              backgroundColor:'#000000',
              width: '100%',
              '& .MuiInputBase-input': {
                  height: '34px',
                  padding: '0px 0px 0px 10px',//上右下左
                  fontSize: '14px', // 调整字体大小
                  color: 'white !important', // 设置占位符颜色
              },
              '& .MuiInputBase-input::placeholder': { // 特别针对占位符的样式
                color: 'white', // 设置占位符颜色
                fontWeight: 'bold', // 设置占位符字体加粗
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: 'transparent', // 设置透明
                }
              },
              '& .Mui-disabled': {
                color: 'white', //safari 用这个
                WebkitTextFillColor: 'white', //chrome 用这个
                cursor: 'not-allowed', // 鼠标变为禁止符号
                '& .MuiInputBase-input': {
                    color: 'white', 
                    WebkitTextFillColor: 'white', 
                }
              },
              '&:hover': {
                borderColor: hoverSecondaryColor, // 鼠标悬停时的边框颜色
              },
              flex: '1 1 0%',
              pointerEvents: isRequesting || isAutoBetActive?'none':'default',
            }}
            InputProps={{
              endAdornment: (
                  <InputAdornment position="end">
                      <PercentSvgIcon /> 
                  </InputAdornment>
              ),
            }}
            onFocus={e => {
              e.target.select();
            }}
            onChange={whenWinInputEvent}
            inputProps={{ step: 1 }}
          />
        </div>
      </div>,
      /* 若输---- 若输---- 若输---- 若输---- 若输---- 若输---- 若输---- 若输---- 若输---- 若输---- 若输---- 若输---- 若输---- 若输----  */
      <div key="ifLoss" style={{
        marginTop:'2px',
        flexDirection:'column',
        width:'calc(100%)',
        display: menuTabIndex==1?'flex':'none',
      }}>
        <div style={{ 
          width:'100%', 
          display:'flex',
          flexDirection:'row',
          }}>
          <span style={{ fontSize:"13px", fontWeight:'bold', color:textColor}}>若输</span>
        </div>
        <div style={{
          display:'flex',
          flexDirection:'row',
          alignItems:'center',
          width:'100%',
          height:'38px',
          backgroundColor:borderColor,
          marginTop:"2px",
          borderRadius:'4px',
          opacity: isRequesting || isAutoBetActive ? 0.5 : 1,
        }}>
          <button style={{
            backgroundColor: hoverDiv6? hoverSecondaryColor: lossTabIndex===0? tabSelectedColor:'transparent',
            border:'none',
            borderRadius:'4px',
            color:'#FFFFFF',
            fontWeight:'bold',
            outline:'none',
            padding:'8px 8px 8px 8px',
            marginRight:'4px',
            width:'25%',
            marginLeft:'2px',
          }}
          onMouseEnter={() =>{
            if(!isRequesting && !isAutoBetActive){
              if (lossTabIndex !== 0) {
                setHoverDiv6(true)
              }
            }
          }}
          onMouseLeave={() =>{
            if(!isRequesting && !isAutoBetActive){
              setHoverDiv6(false)
            }
          }}
          onClick={() =>{
            if(!isRequesting && !isAutoBetActive){
            setHoverDiv6(false) 
            setLossTableIndex(0)} }}
          className={menuTabIndex===0 ? 'dice-wintab-active' : ''}
          >重置</button>
          <button style={{
            backgroundColor: hoverDiv7? hoverSecondaryColor: lossTabIndex===1? tabSelectedColor:'transparent',
            border:'none',
            borderRadius:'4px',
            color:'#FFFFFF',
            fontWeight:'bold',
            outline:'none',
            padding:'8px 8px 8px 8px',
            marginRight:'4px',
            width:'25%',
          }}
          onMouseEnter={() =>{
            if(!isRequesting && !isAutoBetActive){
              if (lossTabIndex !== 1) {
                setHoverDiv7(true)
              }
            }
          }}
          onMouseLeave={() =>{
            if(!isRequesting && !isAutoBetActive){
              setHoverDiv7(false)
            }
          }}
          onClick={() => {
            if(!isRequesting && !isAutoBetActive){
            setHoverDiv7(false)
            setLossTableIndex(1)}}}
          className={menuTabIndex===0 ? 'dice-wintab-active' : ''}>
            增加</button>
          <TextField
            id="outlined-number"
            label=""
            type="number"
            placeholder='0'
            value={lossPercent}
            disabled={lossTabIndex===0?true:false}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              border:'2px solid',
              borderColor:borderColor,
              borderRadius:'4px',
              padding:'0px 0px 0px 0px',
              backgroundColor:'#000000',
              width: '100%',
              '& .MuiInputBase-input': {
                  height: '34px',
                  padding: '0px 0px 0px 10px',//上右下左
                  fontSize: '14px', // 调整字体大小
                  color: 'white !important', // 设置占位符颜色
              },
              '& .MuiInputBase-input::placeholder': { // 特别针对占位符的样式
                color: 'white', // 设置占位符颜色
                fontWeight: 'bold', // 设置占位符字体加粗
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: 'transparent', // 设置透明
                }
              },
              '& .Mui-disabled': {
                color: 'white', //safari 用这个
                WebkitTextFillColor: 'white', //chrome 用这个
                cursor: 'not-allowed', // 鼠标变为禁止符号
                '& .MuiInputBase-input': {
                    color: 'white', 
                    WebkitTextFillColor: 'white', 
                }
              },
              '&:hover': {
                borderColor: hoverSecondaryColor, // 鼠标悬停时的边框颜色
              },
              flex: '1 1 0%',
              pointerEvents: isRequesting || isAutoBetActive?'none':'default',
            }}
            InputProps={{
              endAdornment: (
                  <InputAdornment position="end">
                      <PercentSvgIcon /> 
                  </InputAdornment>
              ),
            }}
            onFocus={e => {
              e.target.select();
            }}
            onChange={whenLossInputEvent}
            inputProps={{ step: 1 }}
          />
        </div>
      </div>,
      /* 止盈---- 止盈---- 止赢---- 止赢---- 止赢---- 止赢---- 止赢---- 止赢---- 止赢---- 止赢---- 止赢---- 止赢---- 止赢---- 止赢----  */
      <div key="winTo" style={{
        marginTop:'2px',
        flexDirection:'column',
        width:'calc(100%)',
        display: menuTabIndex==1?'flex':'none',
      }}>
        <div style={{ 
          width:'100%', 
          display:'flex',
          flexDirection:'row',
          }}>
          <span style={{ fontSize:"13px", fontWeight:'bold', color:textColor}}>止盈</span>
        </div>
          <TextField
            id="outlined-number"
            label=""
            type="number"
            placeholder='0'
            value={stopWin}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              marginTop:'2px',
              border:'2px solid',
              borderColor:borderColor,
              borderRadius:'4px',
              padding:'0px 0px 0px 0px',
              backgroundColor:'#000000',
              width: 'calc(100%-4px)',
              '& .MuiInputBase-input': {
                  height: '34px',
                  padding: '0px 0px 0px 10px',//上右下左
                  fontSize: '14px', // 调整字体大小
                  color: 'white !important', // 设置占位符颜色
              },
              '& .MuiInputBase-input::placeholder': { // 特别针对占位符的样式
                color: 'white', // 设置占位符颜色
                fontWeight: 'bold', // 设置占位符字体加粗
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: 'transparent', // 设置透明
                }
              },
              '& .Mui-disabled': {
                color: 'white', //safari 用这个
                WebkitTextFillColor: 'white', //chrome 用这个
                cursor: 'not-allowed', // 鼠标变为禁止符号
                '& .MuiInputBase-input': {
                    color: 'white', 
                    WebkitTextFillColor: 'white', 
                }
              },
              '&:hover': {
                borderColor: hoverSecondaryColor, // 鼠标悬停时的边框颜色
              },
              flex: '1 1 0%',
              pointerEvents: isRequesting || isAutoBetActive?'none':'default',
              opacity: isRequesting || isAutoBetActive ? 0.5 : 1,
            }}
            InputProps={{
              endAdornment: (
                  <InputAdornment position="end">
                      <USDTSvgIcon /> 
                  </InputAdornment>
              ),
            }}
            onFocus={e => {
              e.target.select();
            }}
            onBlur={stopWinBlurEvent}
            onChange={stopWinInputEvent}
            inputProps={{ step: 1 }}
          />
      </div>,
      /* 止损---- 止损---- 止损---- 止损---- 止损---- 止损---- 止损---- 止损---- 止损---- 止损---- 止损---- 止损---- 止损---- 止损----  */
      <div key="lossTo" style={{
        marginTop:'2px',
        flexDirection:'column',
        width:'calc(100%)',
        display: menuTabIndex==1?'flex':'none',
      }}>
        <div style={{ 
          width:'100%', 
          display:'flex',
          flexDirection:'row',
          }}>
          <span style={{ fontSize:"13px", fontWeight:'bold', color:textColor}}>止损</span>
        </div>
        <TextField
          id="outlined-number"
          label=""
          type="number"
          placeholder='0'
          value={stopLoss}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            marginTop:'2px',
            border:'2px solid',
            borderColor:borderColor,
            borderRadius:'4px',
            padding:'0px 0px 0px 0px',
            backgroundColor:'#000000',
            width: 'calc(100%-4px)',
            '& .MuiInputBase-input': {
                height: '34px',
                padding: '0px 0px 0px 10px',//上右下左
                fontSize: '14px', // 调整字体大小
                color: 'white !important', // 设置占位符颜色
            },
            '& .MuiInputBase-input::placeholder': { // 特别针对占位符的样式
              color: 'white', // 设置占位符颜色
              fontWeight: 'bold', // 设置占位符字体加粗
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                  borderColor: 'transparent', // 设置透明
              }
            },
            '& .Mui-disabled': {
              color: 'white', //safari 用这个
              WebkitTextFillColor: 'white', //chrome 用这个
              cursor: 'not-allowed', // 鼠标变为禁止符号
              '& .MuiInputBase-input': {
                  color: 'white', 
                  WebkitTextFillColor: 'white', 
              }
            },
            '&:hover': {
              borderColor: hoverSecondaryColor, // 鼠标悬停时的边框颜色
            },
            flex: '1 1 0%',
            pointerEvents: isRequesting || isAutoBetActive?'none':'default',
            opacity: isRequesting || isAutoBetActive ? 0.5 : 1,
          }}
          InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <USDTSvgIcon /> 
                </InputAdornment>
            ),
          }}
          onFocus={e => {
            e.target.select();
          }}
          onBlur={stopLossBlurEvent}
          onChange={stopLossInputEvent}
          inputProps={{ step: 1 }}
        />
      </div>,
      /* 获得利润块----获得利润块----获得利润块----获得利润块----获得利润块----获得利润块----获得利润块----获得利润块----获得利润块---- */
      <div key="profit" style={{
        marginTop:'10px',
        flexDirection:'column',
        width:'calc(100%)',
        display: menuTabIndex==0?'flex':'none',
      }}>
        <div style={{ 
          width:'100%', 
          display:'flex',
          flexDirection:'row',
          }}>
          <span style={{ fontSize:"13px", fontWeight:'bold', color:textColor}}>获胜利润</span>
          <span style={{ fontSize:"11px", marginLeft:'auto', fontWeight:'', color:textColor,textAlign:'end'}}>us${profit}</span>
        </div>
        <div style={{
          display:'flex',
          flexDirection:'row',
          alignItems:'center',
          width:'100%',
          height:'34px',
          backgroundColor:'#000000',
          marginTop:"2px",
          borderRadius:'4px',
        }}
          className='profitDiv'
        >
          <span style={{ 
            fontSize:"13px", 
            fontWeight:'bold', 
            color:textColor, 
            padding:'0px 0px 0px 10px',//上右下左
            }}>
              {
                profit
              }
            </span>
          <div style={{
            display:'flex',
            alignItems:'center',
            marginLeft:'auto',
            marginRight:'10px',
          }}><USDTSvgIcon/></div>
          
        </div>
      </div>,
      /* 投注按钮----投注按钮----投注按钮----投注按钮----投注按钮----投注按钮----投注按钮----投注按钮----投注按钮----投注按钮---- */
      <Button key="betBtt" className=
        {
          [
            isRequesting ? isAutoBetActive?'betButton':'disable-betButton' : isBetButtonDisabled?'betButton':'disable-betButton',
          ].join(' ')
        }
        style={{
          marginTop:'10px',
          width:'100%',
          height:'34px',
          borderRadius:'4px',
          border:'none',
          color: '#ffffff',
          fontWeight:'bold',
          outline:'none',
          padding:'8px 8px 8px 8px',
          fontSize:'14px',
          cursor:'pointer',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          cursor:isBetButtonDisabled?'default':'not-allowed',
        }}
        onClick={()=>{
          if(isAutoBetActive){
            setIsAutoBetActive(false)
            setAutoBetStop(true)
            return
          }
          if (!isRequesting){
            if (menuTabIndex === 1){
              setIsAutoBetActive(true)
              setAutoBetStart(true)
            }
            betButtonEvent()
          }
        }}
        disabled={false}>
          {
            menuTabIndex===1?isAutoBetActive?'停止自动投注':'':''
          }
          {
            isRequesting ? <Lottie animationData={lottieLoading} loop={true} style={{
              width:'24px',
              height:'24px',
            }} /> : menuTabIndex===0?'投注':isAutoBetActive?'':'开始自动投注'
          }
      </Button>
    ];

    let orderedChildren;
    if (layoutDirection === 'ver') {
      orderedChildren = [
        children[9],
        children[1], 
        children[2], 
        children[3], 
        children[4], 
        children[5], 
        children[6], 
        children[7], 
        children[8],
        children[0], 
      ];
    } else {
      // 默认顺序 
      orderedChildren = children;
    }
    
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"下注失败"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {betFailMessage}
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {orderedChildren}
        
      </React.Fragment>
    );
}

export default DiceComponetMenuTabs;