import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import peopleIcon from '../../assets/images/home/home-people.png'
import { getOnlineUserNumberService } from '../../network/service/home';
import { useDispatch, useSelector } from 'react-redux';
import { onlineUserRefresh } from '../../features/appSlice';

const FooterOnlinePeople = () => {
    const intervalId = useRef();
    const dispatch = useDispatch();

    const onlineUserNumber = useSelector((state) => state.app.onlineUserNumber);

    const handleGetOnlineUserNumber = async () => {
        try {
            const response = await getOnlineUserNumberService();
            dispatch(onlineUserRefresh({number:response.data.online_user_number}));
        } catch (error) {
            console.log('handleGetOnlineUserNumber error:'+error);
        }
    };

    // useEffect(() => {
    //     handleGetOnlineUserNumber();
    // }, []);

    useEffect(() => {
        intervalId.current = setInterval(() => {
            handleGetOnlineUserNumber();
        }, 10000);

        return () => {
            clearInterval(intervalId.current);
        };
    }, []);

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            mt:'-10px',
        }}>
            <Box sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
            }}>
                <Box sx={{
                    bgcolor:'#1CA45F',
                    width:'8px',
                    height:'8px',
                    borderRadius:'4px',
                }}/>
                <CardMedia
                    component="img"
                    image={peopleIcon}
                    sx={{
                        width:'14px',
                        height:'12px',
                        objectFit:'contain',
                        ml:'10px',
                    }}
                />
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                    ml:'2px',
                }}>
                    {onlineUserNumber}
                </Typography>
            </Box>
            <Typography sx={{
                color:'#6D728D',
                fontSize:'12px',
                fontStyle:'normal',
                fontWeight:400,
            }}>
                {'@2023YahooGrupo All Rights Reserved'}
            </Typography>
        </Grid>
    );
}

export default FooterOnlinePeople;