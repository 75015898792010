import React from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const WithdrawContentNoEditNetwork = (props) => {
    const { network,defaultValue } = props;
    const theme = useTheme();

    return (
        <Box sx={{
            bgcolor:'#15151B',
            borderRadius:'18px',
            padding:'19px 0px 50px 0px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'start',
            gap:'40px',
            width:'100%',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'calc(100% - 36px)',
                borderRadius:'0px',
                padding:'18px',
                gap:'8px',
            },
        }}>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'28px',
                fontStyle:'normal',
                fontWeight:500,
                ml:'70px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'15px',
                    ml:'0px',
                },
            }}>
                {network}
            </Typography>
            <Typography sx={{
                color:'#FFFFFF',
                fontSize:'32px',
                fontStyle:'normal',
                fontWeight:500,
                ml:'70px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'18px',
                    ml:'0px',
                },
            }}>
                {defaultValue}
            </Typography>
        </Box>
    );
}

export default WithdrawContentNoEditNetwork;