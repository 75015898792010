import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import { styled, alpha } from '@mui/material/styles';
import Menu, { menuClasses } from '@mui/material/Menu';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { popoverClasses } from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import arrowRightIcon from '../../assets/images/drawer/drawer-arrow_right.png';
import popularIcon from '../../assets/images/home/slots-popular.png';
import newIcon from '../../assets/images/home/slots-new.png';
import hotIcon from '../../assets/images/home/slots-hot.png';
import { useDispatch, useSelector } from 'react-redux';
import { casinoHotListRefreshCategory, casinoNewListRefreshCategory, casinoPopularListRefreshCategory } from '../../features/casinoListSlice';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    [`& .${paperClasses.root}`]: {
      padding:'0px 0px 18px 0px',
      marginTop: 10,
      bgcolor:'#1E212A',
      borderRadius: 18,
      minWidth:'136px',
      color: '#FFFFFF',
      [`& .${menuClasses.list}`]: {
        padding: '0px',
      },
      [`& .${menuItemClasses.root}`]: {
        '&:active': {
          backgroundColor: alpha(
            '#000000',
            0.8,
          ),
        },
      },
    },
    [`& .${popoverClasses.paper}`]: {
        backgroundColor: '#1E212A',
    }
}));


const CasinoListContentMainTitle = (props) => {
    const {
        category,menuOnClick,
    } = props;
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        popularSlots: t("popular_slots"),
        newSlots: t("new_slots"),
        hotSlots: t("hot_slots"),
        all: t("all"),
    };

    let chooseCategory = 0;
    const chooseCategorySelector = useSelector((state) => state.casinoList.currentChooseCategory);
    if(category === 1) {
        chooseCategory = chooseCategorySelector.popular;
    }else if(category === 2) {
        chooseCategory = chooseCategorySelector.new;
    }else if(category === 3) {
        chooseCategory = chooseCategorySelector.hot;
    }

    const menuTitleArr = [textData.all,'PragmaticPlay','PGSoft',];
    const [ currentCategory,setCurrentCategory ] = useState(menuTitleArr[chooseCategory]);

    useEffect(() => {
        setCurrentCategory(menuTitleArr[chooseCategory]);
    }, [category]);

    const handleCategoryClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleTitle = () => {
        if(category === 1) {
            return textData.popularSlots;
        }else if(category === 2) {
            return textData.newSlots;
        }else if(category === 3) {
            return textData.hotSlots;
        }
        return '';
    }

    // menu
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = (index) => {
        handleClose();
        setCurrentCategory(menuTitleArr[index]);
        if(category === 1) {
            dispatch(casinoPopularListRefreshCategory({casinoPopularListCategory:index}))
        }else if(category === 2) {
            dispatch(casinoNewListRefreshCategory({casinoNewListCategory:index}))
        }else if(category === 3) {
            dispatch(casinoHotListRefreshCategory({casinoHotListCategory:index}))
        }
        menuOnClick && menuOnClick(index === 0 ? '' : menuTitleArr[index]);
    }

    return (
        <React.Fragment>
            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                gap:'18px',
            }}>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                }}>
                    <CardMedia
                        component="img"
                        image={category === 1 ? popularIcon : (category === 2 ? newIcon : hotIcon)}
                        sx={{
                            width:'32px',
                            height:'32px',
                            objectFit:'contain',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'24px',
                                height:'24px',
                            },
                        }}
                    />
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'28px',
                        fontStyle:'normal',
                        fontWeight:500,
                        ml:'8px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'18px',
                        },
                    }}>
                        {handleTitle()}
                    </Typography>
                </Box>
                <Button variant='contained' sx={{
                    bgcolor:'#1E212A',
                    borderRadius:'18px',
                    height:'46px',
                    color:'#ACB3D7',
                    fontSize:'18px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textTransform:'none',
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    gap:'13px',
                    minWidth:'104px',
                    padding:'0px 18px',
                    '&:hover': {
                        bgcolor:'#1E212A',
                    }
                }} onClick={handleCategoryClick}>
                    {currentCategory}
                    <CardMedia
                        component="img"
                        image={arrowRightIcon}
                        sx={{
                            width:'8px',
                            height:'18px',
                            objectFit:'contain',
                        }}
                    />
                </Button>
            </Grid>

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {menuTitleArr.map((item,index) => (
                    <MenuItem onClick={() => handleMenuClick(index)} disableRipple key={index} sx={{
                        display:'flex',
                        justifyContent:'start',
                        alignItems:'center',
                        mt:'18px',
                        pl:'18px',
                        pr:'18px',
                        color:'#ACB3D7',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400
                    }}>
                        {item}
                    </MenuItem>
                ))}
            </StyledMenu>
        </React.Fragment>
    );
}

export default CasinoListContentMainTitle;