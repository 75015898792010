import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import HomeContentMainBanner from './HomeContentMainBanner';
import HomeContentMainInHouseGame from './HomeContentMainInHouseGame';
import HomeContentMainSlots from './HomeContentMainSlots';
import HomeContentMainGameProviders from './HomeContentMainGameProviders';
import HomeContentMainHistory from './HomeContentMainHistory';
import HomeContentMainTestimonials from './HomeContentMainTestimonials';
import HomeContentMainEffectBanner from './HomeContentMainEffectBanner';
import HomeContentMainLive from './HomeContentMainLive';
import HomeContentMainPopularSlots from './HomeContentMainPopularSlots';
import HomeContentMainNewSlots from './HomeContentMainNewSlots';
import HomeContentMainHotSlots from './HomeContentMainHotSlots';

const HomeContentMain = () => {
    const theme = useTheme();

    return (
        <Grid container sx={{
            padding:'36px 60px 145px 60px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'18px',
            },
        }}>
            <Container sx={{
                // bgcolor:'#bbbbbb',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <HomeContentMainBanner />
                <HomeContentMainEffectBanner />
                <HomeContentMainPopularSlots />
                <HomeContentMainNewSlots />
                <HomeContentMainHotSlots />
                {/* <HomeContentMainSlots /> */}
                {/* <HomeContentMainLive /> */}
                {/* <HomeContentMainInHouseGame /> */}
                <HomeContentMainGameProviders />
                <HomeContentMainHistory />
                {/* <HomeContentMainTestimonials /> */}
            </Container>
        </Grid>
    );
}

export default HomeContentMain;