import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import HomeContentMain from './HomeContentMain';
import Footer from '../footer/Footer';

const HomeContent = (props) => {
    const theme = useTheme();
    const params = new URLSearchParams(window.location.search);
    const inviteCode = params.get('SpreadCode');
    if(inviteCode !== undefined && inviteCode !== null && inviteCode.length > 0) {
        localStorage.setItem('TTInviteCode',inviteCode);
    }

    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);

    return (
        <Grid sx={{
            pl:'260px',
            pt:'64px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                pl:'0px',
            },
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between',
            }}>
                <HomeContentMain />
                <Footer />
            </Box>
        </Grid>
    );
}

export default HomeContent;