import React from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import SlotsContent from '../component/slots/SlotsContent';

const Slots = () => {
    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.slots} drawerIndex={3}/>
            <SlotsContent />
        </React.Fragment>
    )
}

export default Slots;