import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';

const GameContentMobile = (props) => {
    const {
        category,fromPage,gameIcon,gameName,gameUrl
    } = props;

    return (
        <CardMedia
            component='iframe'
            src={gameUrl}
            sx={{
                position:'absolute',
                top:'0px',
                left:'0px',
                width:'100%',
                height:'100%',
                border:'none',
            }}
        />
    );
}

export default GameContentMobile;