import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import referralBannerIcon from '../../assets/images/profile/profile-referral-banner.png';
import giftsIcon from '../../assets/images/profile/profile-referral-gifts.png';
import TTAlert from '../common/TTAlert';
import TTLoading from '../common/TTLoading';
import { bindInviteCodeService } from '../../network/service/user';
import { useDispatch, useSelector } from 'react-redux';
import { userRefreshUserInfo } from '../../features/userSlice';

const ProfileContentBottomReferralInput = styled(InputBase)(({ theme }) => ({
    [`& .${inputBaseClasses.input}`]: {
      position: 'relative',
      border: '0px solid',
      backgroundColor:'transparent',
      borderColor: 'transparent',
      fontSize: '12px',
      color:'#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      caretColor:'#1CA45F',
      '&::placeholder': {
        color: '#3A3E53',
        opacity: 1,
      },
      '&:-webkit-autofill': {
        backgroundColor: 'transparent !important',
        color:'#FFFFFF !important',
        fontSize: '12px !important',
        transition: theme.transitions.create(['background-color','color','font-size'], {
            duration: 99999,
            easing: 'ease-in-out',
            delay: 99999,
        }),
      },
    },
}));

const ProfileContentBottomReferral = (props) => {
    const { enterYourCode,youHaveCode,bind } = props;
    const theme = useTheme();
    const dispatch = useDispatch();

    const [ referralCode, setReferralCode ] = useState('');
    const [ openLoading,setOpenLoading ] = useState(false);

    const resultReferralCode = useSelector((state) => state.user.user.referral_code);

    const handleBindClick = () => {
        if(referralCode.trim() === '') {
            setAlertMessage(enterYourCode);
            setAlertType('error');
            setOpenAlert(true);
            return;
        }
        handleBindInviteCode(referralCode);
    };
    const handleReferralCodeInputChanged = (e) => {
        const filteredValue = e.target.value.replace(/[\u4E00-\u9FA5]/g, '');
        e.target.value = filteredValue;
        
        setReferralCode(e.target.value);
    };
    const handleBindInviteCode = async (inviteCode) => {
        setOpenLoading(true);
        try {
            let params = {
                invite_code:inviteCode,
            };
            await bindInviteCodeService(params);
            setOpenLoading(false);
            dispatch(userRefreshUserInfo({referral_code:inviteCode}));
        } catch (error) {
            setOpenLoading(false);
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setOpenAlert('error');
            setAlertMessage(errorMsg);
            setOpenAlert(true);
        }
    };

    // alert
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ alertType, setAlertType ] = useState('error');
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    return (
        <React.Fragment>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <TTAlert 
                type={alertType}
                isOpen={openAlert}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />
            <Box sx={{
                bgcolor:'#15151B',
                borderRadius:'18px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                gap:'28px',
                padding:'26px 18px',
                width:'333px',
                height:'calc(100% - 56px)',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    borderRadius:'0px',
                    gap:'18px',
                    padding:'18px',
                    width:'calc(100% - 36px)',
                    height:'auto',
                },
            }}>
                <CardMedia
                    component="img"
                    image={referralBannerIcon}
                    sx={{
                        width:'333px',
                        height:'101px',
                        objectFit:'contain',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'18px',
                            width:'100%',
                            height:'calc(100vw - 36px)*101/339',
                            objectFit:'cover',
                        },
                    }}
                />
                <Grid container sx={{
                    display:resultReferralCode.length > 0 ? 'none' : 'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                }}>
                    <Box sx={{
                        bgcolor:'#0D0F13',
                        borderRadius:'13px',
                        height:'26px',
                        display:'flex',
                        justifyContent:'start',
                        alignItems:'center',
                        gap:'8px',
                        padding:'0px 20px',
                        width:'calc(100% - 130px)',
                    }}>
                        <CardMedia
                            component="img"
                            image={giftsIcon}
                            sx={{
                                width:'12px',
                                height:'12px',
                                objectFit:'contain',
                            }}
                        />
                        <FormControl sx={{
                            width:'100%'
                        }}>
                            <ProfileContentBottomReferralInput type="text" 
                                placeholder={enterYourCode}
                                onChange={handleReferralCodeInputChanged}
                                autoComplete="off"
                                sx={{
                                    width:'100%',
                                    height:'100%',
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Button variant='text' sx={{
                        bgcolor:'#1CA45F',
                        borderRadius:'13px',
                        height:'26px',
                        color:'#FFFFFF',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:500,
                        textAlign:'center',
                        textTransform:'none',
                        padding:'0px 26px',
                        '&:hover': {
                            bgcolor:'#1CA45F',
                        }
                    }} onClick={handleBindClick}>
                        {bind}
                    </Button>
                </Grid>
                <Box sx={{
                    display:resultReferralCode.length > 0 ? 'flex' : 'none',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'8px',
                    width:'100%',
                }}>
                    <CardMedia
                        component="img"
                        image={giftsIcon}
                        sx={{
                            width:'12px',
                            height:'12px',
                            objectFit:'contain',
                        }}
                    />
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:500,
                    }}>
                        {`${youHaveCode}: \n${resultReferralCode}`}
                    </Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default ProfileContentBottomReferral;