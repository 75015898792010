import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import arrowRight from '../../assets/images/drawer/drawer-arrow_right.png'
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const HelpCenterContentMain = (props) => {
    const { index } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();
    const { t } = useTranslation();
    const textData = {
        helpCenter: t("help_center"),
        whatIsAmount: t("what_is___amount"),
        whatIsFee: t("what_is__fee"),
        termOfService: t("term_of_service"),
        privacy_policy: t("privacy_policy"),

        pageResponsibleGaming: t("page_responsible__gaming"),
        theWithdrawableBalance: t("the_withdrawable___balance"),
        whyIsAmount: t("why_is___amount"),
        inOrderSecure: t("in_order___secure"),
        howToAmount: t("how_to___amount"),
        youCanAmount: t("you_can___amount"),
        whyWillReduced: t("why_will___reduced"),
        whereMayDeducted: t("where_may___deducted"),

        whyDoesFee: t("why_does___fee"),
        whenWenMining: t("when_wen___mining"),
        theRoleFee: t("the_role___fee"),
        encourageMinersTransaction: t("encourage_miners___transaction"),
        howMuchFee: t("how_much___fee"),
        sinceThePlatform: t("since_the___platform"),
        
        responsibleGaming: t("responsible_gaming"),
        ttbetgameIsForm: t("ttbetgame_is___form"),
        theRestrictedAre: t("the_restricted___are"),
        australiaArubaPossessions: t("australia_aruba___possessions"),
        maintainingControl: t("maintaining_control"),
        gamblingShouldOrganisations: t("gambling_should___organisations"),
        selfExclusion: t("self_exclusion"),
        shouldYouConsidered: t("should_you___considered"),
        gamblingCounsellingOrganisations: t("gambling_counselling___organisations"),
        gamcareTheOrg: t("gamcare_the___org"),
        filteringSystems: t("filtering_systems"),
        filteringSolutionsCom: t("filtering_solutions___com"),

        introduction: t("introduction"),
        thisUserServices: t("this_user___services"),
        generalTerms: t("general_terms"),
        weReserveAgreement: t("we_reserve___agreement"),
        yourObligations: t("your_obligations"),
        duringYourServices: t("during_your___services"),
        restrictedUse: t("restricted_use"),
        youMayTtbet: t("you_may___ttbet"),
        registration: t("registration"),
        weReserveAccount: t("we_reserve___account"),
        yourAccount: t("your_account"),
        multipleCurrenciesEnglish: t("multiple_currencies___english"),
        inactiveAccounts: t("inactive_accounts"),
        ttbetWillPlatform: t("ttbet_will___platform"),
        fund_deposits: t("fund_deposits"),
        eachCurrencyDeposits: t("each_currency___deposits"),
        fundWithdrawals: t("fund_withdrawals"),
        dueToAppeal: t("due_to___appeal"),
        paymentTransactionsProcessors: t("payment_transactions___processors"),
        youAreAuthorities: t("you_are___authorities"),
        errors: t("errors"),
        ifThereFrame: t("if_there___frame"),
        sportsBettingCancellations: t("sports_betting___cancellations"),
        theWinnerRules: t("the_winner___rules"),
        communicationAndNotices: t("communication_and_notices"),
        youMustSettings: t("you_must___settings"),
        forceMajeure: t("force_majeure"),
        shouldWeLiability: t("should_we___liability"),
        liability: t("liability"),
        withinTheSoftware: t("within_the___software"),
        underageGambling: t("underage_gambling"),
        ifWeAgencies: t("if_we___agencies"),
        antiFraudPolicy: t("anti_fraud_policy"),
        ttbetProhibitsActions: t("ttbet_prohibits___actions"),
        intellectualProperty: t("intellectual_property"),
        unauthorizedUseProsecution: t("unauthorized_use___prosecution"),
        your_license: t("your_license"),
        subjectToProceedings: t("subject_to___proceedings"),
        yourConductSecurity: t("your_conduct___security"),
        forTheReceive: t("for_the___receive"),
        linksToWebsites: t("links_to___websites"),
        theServiceUse: t("the_service___use"),
        complaints: t("complaints"),
        ifYouProvider: t("if_you___provider"),
        assignment: t("assignment"),
        youMayService: t("you_may___service"),
        severability: t("severability"),
        ifAnyAffected: t("if_any___affected"),
        breachOfTerms: t("breach_of___terms"),
        without_LimitingService: t("without_limiting___service"),
        generalProvisions: t("general_provisions"),
        duration0fWritten: t("duration_of___written"),

        overview: t("overview"),
        this_privacy__etc: t("this_privacy__etc"),
        information_collection: t("information_collection"),
        you_can__preferences: t("you_can__preferences"),
        use_of_information: t("use_of_information"),
        we_may__consent: t("we_may__consent"),
        information_sharing: t("information_sharing"),
        we_weill__regulations: t("we_weill__regulations"),
        data_retention: t("data_retention"),
        we_only__law: t("we_only__law"),
        data_security: t("data_security"),
        we_take__account: t("we_take__account"),
        your_rights: t("your_rights"),
        you_have__requests: t("you_have__requests"),
        minors: t("minors"),
        if_you__time: t("if_you__time"),
        policy_updates: t("policy_updates"),
        we_may__alerts: t("we_may__alerts"),
        ten_contact_us: t("ten_contact_us"),
        if_you__on: t("if_you__on"),
    };

    const [ currentIndex, setCurrentIndex ] = useState(index > 0 ? index : 0);
    useEffect(() => {
        setCurrentIndex(index > 0 ? index : 0);
    }, [index]);

    const questionArr = [
        textData.whatIsAmount,textData.whatIsFee,textData.pageResponsibleGaming,textData.termOfService,textData.privacy_policy,
    ];

    const answerArr = [
        [
            {
                title:textData.whatIsAmount,
                desc:textData.theWithdrawableBalance,
            },
            {
                title:textData.whyIsAmount,
                desc:textData.inOrderSecure,
            },
            {
                title:textData.howToAmount,
                desc:textData.youCanAmount,
            },
            {
                title:textData.whyWillReduced,
                desc:textData.whereMayDeducted,
            },
        ],
        [
            {
                title:textData.whyDoesFee,
                desc:textData.whenWenMining,
            },
            {
                title:textData.theRoleFee,
                desc:textData.encourageMinersTransaction,
            },
            {
                title:textData.howMuchFee,
                desc:textData.sinceThePlatform,
            },
        ],
        [
            {
                title:textData.responsibleGaming,
                desc:textData.ttbetgameIsForm,
            },
            {
                title:textData.theRestrictedAre,
                desc:textData.australiaArubaPossessions,
            },
            {
                title:textData.maintainingControl,
                desc:textData.gamblingShouldOrganisations,
            },
            {
                title:textData.selfExclusion,
                desc:textData.shouldYouConsidered,
            },
            {
                title:textData.gamblingCounsellingOrganisations,
                desc:textData.gamcareTheOrg,
            },
            {
                title:textData.filteringSystems,
                desc:textData.filteringSolutionsCom,
            },
        ],
        [
            {
                title:textData.introduction,
                desc:textData.thisUserServices,
            },
            {
                title:textData.generalTerms,
                desc:textData.weReserveAgreement,
            },
            {
                title:textData.yourObligations,
                desc:textData.duringYourServices,
            },
            {
                title:textData.restrictedUse,
                desc:textData.youMayTtbet,
            },
            {
                title:textData.registration,
                desc:textData.weReserveAccount,
            },
            {
                title:textData.yourAccount,
                desc:textData.multipleCurrenciesEnglish,
            },
            {
                title:textData.inactiveAccounts,
                desc:textData.ttbetWillPlatform,
            },
            {   
                title:textData.fund_deposits,
                desc:textData.eachCurrencyDeposits,
            },
            {
                title:textData.fundWithdrawals,
                desc:textData.dueToAppeal,
            },
            {
                title:textData.paymentTransactionsProcessors,
                desc:textData.youAreAuthorities,
            },
            {
                title:textData.errors,
                desc:textData.ifThereFrame,
            },
            {
                title:textData.sportsBettingCancellations,
                desc:textData.theWinnerRules,
            },
            {
                title:textData.communicationAndNotices,
                desc:textData.youMustSettings,
            },
            {
                title:textData.forceMajeure,
                desc:textData.shouldWeLiability,
            },
            {
                title:textData.liability,
                desc:textData.withinTheSoftware,
            },
            {
                title:textData.underageGambling,
                desc:textData.ifWeAgencies,
            },
            {
                title:textData.antiFraudPolicy,
                desc:textData.ttbetProhibitsActions,
            },
            {
                title:textData.intellectualProperty,
                desc:textData.unauthorizedUseProsecution,
            },
            {
                title:textData.your_license,
                desc:textData.subjectToProceedings,
            },
            {
                title:textData.yourConductSecurity,
                desc:textData.forTheReceive,
            },
            {
                title:textData.linksToWebsites,
                desc:textData.theServiceUse,
            },
            {
                title:textData.complaints,
                desc:textData.ifYouProvider,
            },
            {
                title:textData.assignment,
                desc:textData.youMayService,
            },
            {
                title:textData.severability,
                desc:textData.ifAnyAffected,
            },
            {
                title:textData.breachOfTerms,
                desc:textData.without_LimitingService,
            },
            {
                title:textData.generalProvisions,
                desc:textData.duration0fWritten,
            },
        ],
        [
            {
                title:textData.overview,
                desc:textData.this_privacy__etc,
            },
            {
                title:textData.information_collection,
                desc:textData.you_can__preferences,
            },
            {
                title:textData.use_of_information,
                desc:textData.we_may__consent,
            },
            {
                title:textData.information_sharing,
                desc:textData.we_weill__regulations,
            },
            {
                title:textData.data_retention,
                desc:textData.we_only__law,
            },
            {
                title:textData.data_security,
                desc:textData.we_take__account,
            },
            {
                title:textData.your_rights,
                desc:textData.you_have__requests,
            },
            {
                title:textData.minors,
                desc:textData.if_you__time,
            },
            {
                title:textData.policy_updates,
                desc:textData.we_may__alerts,
            },
            {
                title:textData.ten_contact_us,
                desc:textData.if_you__on,
            },
        ],
    ];

    const handleQuestionClick = (index) => {
        if(index !== currentIndex) {
            setCurrentIndex(index);
        }
        if(screenMD) {
            navigate(RoutePath.helpCenterDesc, { state: { descArr:answerArr[index] }});
        }
    };

    return (
        <Grid container sx={{
            padding:'36px 60px 145px 60px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'18px 0px 0px 0px',
            },
        }}>
            <Container sx={{
                // bgcolor:'#bbbbbb',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                gap:'18px',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'24px',
                    fontStyle:'normal',
                    fontWeight:400,
                    width:'100%',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        display:'none',
                    },
                }}>
                    {textData.helpCenter}
                </Typography>
                <Grid sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    gap:'18px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'100%',
                    },
                }}>
                    <Box sx={{
                        bgcolor:'#15151B',
                        borderRadius:'18px',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        height:'100%',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'0px',
                            width:'100%',
                        },
                    }}>
                        {questionArr.map((item,index) => (
                            <Grid container sx={{
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:'center',
                                gap:'20px',
                                backgroundColor:(index === currentIndex && !screenMD) ? '#3A3E53' : 'transparent',
                                padding:index === 0 ? '20px 46px 12px 18px' : (index === (questionArr.length - 1) ? '12px 46px 20px 18px' :'12px 46px 12px 18px'),
                                color:(index === currentIndex && !screenMD) ? '#FFFFFF' : '#ACB3D7',
                                borderRadius:index === 0 ? '18px 18px 0px 0px' : '0px',
                                '&:hover': {
                                    // backgroundColor:'#3A3E53',
                                    color:'#FFFFFF',
                                },
                                transition: theme.transitions.create(['color','backgroundColor'], {
                                    duration: 300,
                                }),
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    borderRadius:'0px',
                                    padding:'15px 18px',
                                },
                            }}  onClick={() => handleQuestionClick(index)} key={index}>
                                <Typography sx={{
                                    
                                    fontSize:'15px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                    cursor:'pointer',
                                    width:'270px',
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        borderRadius:'0px',
                                        width:'calc(100vw - 100px)',
                                    },
                                }}>
                                    {item}
                                </Typography>
                                {(screenMD) ? 
                                    <CardMedia
                                        component="img"
                                        image={arrowRight}
                                        sx={{
                                            width:'7px',
                                            height:'15px',
                                            objectFit:'contain',
                                        }}
                                    />:''
                                }
                            </Grid>
                        ))}
                    </Box>
                    <Grid sx={{
                        bgcolor:'#15151B',
                        borderRadius:'18px',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        gap:'28px',
                        padding:'26px 18px',
                        maxWidth:'610px',
                        maxHeight:'650px',
                        overflow:'scroll',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            display:'none',
                            maxHeight:'auto',
                            overflow:'auto',
                        },
                    }}>
                        {answerArr[currentIndex].map((item,index) => (
                            <Box sx={{
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                                gap:'8px',
                            }} key={index}>
                                <Typography sx={{
                                    color:'#1CA45F',
                                    textDecoration:'underline',
                                    fontSize:'15px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                }}>
                                    {item.title}
                                </Typography>
                                <Typography sx={{
                                    color:'#FFFFFF',
                                    fontSize:'12px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                    whiteSpace:'pre-line',
                                }} style={{ whiteSpace: 'pre-line' }}>
                                    {item.desc}
                                </Typography>
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
}

export default HelpCenterContentMain;