import React from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import ReferralContent from '../component/referral/ReferralContent';

const Referral = () => {
    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.referral} drawerIndex={6} />
            <ReferralContent />
        </React.Fragment>
    )
}

export default Referral;