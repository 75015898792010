import React, { useEffect, useState } from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import HomeContent from '../component/home/HomeContent';
import { useLocation } from 'react-router-dom';
import HeaderWalletDepositHistory from '../component/header/HeaderWalletDepositHistory';
import { getTosPopupConfigService } from '../network/service/home';
import TTTosPopup from '../component/common/TTTosPopup';

const Home = () => {
    // 提现记录
    const [ openRecord, setOpenRecord ] = useState(false);
    const handleRecordAlertClose = () => {
        setOpenRecord(false);
    };

    const location = useLocation();
    const openDepositRecord = location.state && location.state.openDepositRecord;

    useEffect(() => {
        if(openDepositRecord) {
            setOpenRecord(true);
        }
    }, [openDepositRecord]);

    // 弹框
    const [ openTosPopup,setOpenTosPopup ] = useState(false);
    const [ tosPopupData,setTosPopupData ] = useState(null);
    const handleCloseTosPopup = () => {
        setOpenTosPopup(false);
    };
    // const handleFetchTosPopupConfig = async () => {
    //     try {
    //         const response = await getTosPopupConfigService();
    //         if(response.data.popup_config.length > 0) {
    //             setTosPopupData({
    //                 title:response.data.popup_config[0].name,
    //                 content:response.data.popup_config[0].content,
    //             })
    //             setOpenTosPopup(true);
    //         }
    //     } catch (error) {
    //         console.log('handleFetchTosPopupConfig error:'+error);
    //     }
    // }
    // useEffect(() => {
    //     handleFetchTosPopupConfig();
    // }, []);

    return (
        <React.Fragment>
            {openRecord ? 
                <HeaderWalletDepositHistory
                    isOpen={openRecord}
                    closeCallback={handleRecordAlertClose}
                /> : ''
            }
            {openTosPopup ? 
                <TTTosPopup 
                    isOpen={openTosPopup}
                    closeCallback={handleCloseTosPopup}
                    title={tosPopupData.title}
                    content={tosPopupData.content}
                /> : ''
            }

            <Customer />
            <Header fromPage={RoutePath.home} drawerIndex={0} />
            <HomeContent />
        </React.Fragment>
    )
}

export default Home;