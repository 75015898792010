import React, { useState,useMemo } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import verifyIcon from '../../assets/images/login-and-register/login-and-register-verify.png';
import verifyTipIcon from '../../assets/images/login-and-register/login-and-register-verify-tip.png';
import LoginAndRegisterContentInputTip from "./LoginAndRegisterContentInputTip";

const VerifyInput = styled(InputBase)(({ theme }) => ({
    // '& .MuiInputBase-input': {
      position: 'relative',
      border: '1px solid',
      borderColor: 'transparent',
      fontSize: '14px',
      width:'100%',
      height:'100%',
      color:'#FFFFFF',
      fullWidth:true,
      caretColor:'#1CA45F',
      backgroundColor: 'transparent',
      [`& .${inputBaseClasses.input}::placeholder`]: {
        color: '#6D728D',
        opacity: 1,
      },
      [theme.breakpoints.down(MobileBreakpoint.match)]: {
        fontSize:'12px',
      },
    // },
    [`& .${inputBaseClasses.input}`]: {
        caretColor:'#1CA45F',
    },
}));

const CustomInput = (props) => {
    const { placeholder,defaultText,isAutoFocus,isRegister,isInputTip,tipText,handleInputChanged } = props;
    const theme = useTheme();
    const { focused } = useFormControl() || {};

    const borderColor = React.useMemo(() => {
        return focused ? '#1CA45F' : (isInputTip ? '#C25156' : 'transparent');
    }, [focused,isInputTip]);
    const icon = React.useMemo(() => {
        return isInputTip ? (focused ? verifyIcon : verifyTipIcon) : verifyIcon;
    }, [focused,isInputTip]);
    const showInputTip = React.useMemo(() => {
        return isInputTip ? (focused ? false : true) : false;
    }, [focused,isInputTip]);

    const handleInputValueChanged = (e) => {
        const filteredValue = e.target.value.replace(/[\u4E00-\u9FA5]/g, '');
        e.target.value = filteredValue;

        handleInputChanged && handleInputChanged(e);
    }

    return (
        <Box sx={{
            display:'flex',
            justifyContent:'stretch',
            alignItems:'center',
            width:'100%',
            height:'50px',
            backgroundColor: '#0D0F13',
            border: '1px solid',
            borderColor: borderColor,
            borderRadius:'25px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'100%',
                backgroundColor:'#0C0D11',
                height:isRegister ? '50px' : '36px',
                borderRadius:isRegister ? '25px' : '18px',
            },
        }}>
            <CardMedia
                component='img'
                image={verifyIcon}
                sx={{
                    ml:'20px',
                    mr:'12px',
                    width:'17px',
                    height:'20px',
                }}
            />
            <VerifyInput 
                placeholder={placeholder} 
                value={defaultText}
                autoFocus={isAutoFocus}
                onChange={handleInputValueChanged} 
            />
            <Box sx={{
                position:'absolute',
                left:'5px',
                top:'23px',
                zIndex:1,
                pointerEvents: 'none',
            }}>
                <LoginAndRegisterContentInputTip 
                    open={showInputTip}
                    text={tipText}
                />
            </Box>
        </Box>
    );
}

const LoginAndRegisterContentVerifyCodeInput = (props) => {
    const { placeholder,defaultText,isAutoFocus,isRegister,isInputTip,tipText,handleInputChanged } = props;

    return (
        <FormControl sx={{
            width:'100%',
        }}>
             <CustomInput 
                placeholder={placeholder} 
                value={defaultText}
                autoFocus={isAutoFocus}
                isRegister={isRegister}
                isInputTip={isInputTip}
                tipText={tipText}
                handleInputChanged={handleInputChanged} 
            />
        </FormControl>
    )
}

export default LoginAndRegisterContentVerifyCodeInput;