import api from "../api";
import httpManager from "../httpManager";

// 邮箱登录
export const loginService = (param) => {
    return httpManager({
        url:api.authLogin,
        method:'post',
        data:param,
    });
}

// 邮箱注册
export const registerService = (param) => {
    return httpManager({
        url:api.authRegistrationConfirmCode,
        method:'post',
        data:param,
    })
}

// 邮箱注册发送验证码
export const registerEmailSendCodeService = (param) => {
    return httpManager({
        url:api.authRegistrationSendCode,
        method:'post',
        data:param,
    })
}

// 注册是否需要强势输入邀请码
export const registerForceInputInviteCodeService = () => {
    return httpManager({
        url:api.gameGetGameConfig,
        method:'post',
        data:{},
    })
}

// 重置邮箱登录密码
export const resetEmailPasswordService = (param) => {
    return httpManager({
        url:api.authRegistrationResetPasswordConfirmCode,
        method:'post',
        data:param,
    })
}

// 重置邮箱登录密码 发送验证码
export const resetEmailPasswordSendCodeService = (param) => {
    return httpManager({
        url:api.authRegistrationResetPasswordSendCode,
        method:'post',
        data:param,
    })
}

// 退出登录
export const logoutService = () => {
    return httpManager({
        url:api.authLogout,
        method:'post',
        data:{},
    })
}

// 获取用户信息
export const userInfoService = (userId) => {
    return httpManager({
        url:api.playeruser + `${userId}/`,
        method:'get',
        data:{},
    })
}

// Telegram登录
export const loginWithTelegramService = (param) => {
    return httpManager({
        url:api.authTelegramRegister,
        method:'post',
        data:param,
    });
}

// 更新用户信息
export const updateUserInfoService = (param) => {
    const { userId } = param;
    return httpManager({
        url:api.playeruser + `${userId}/`,
        method:'put',
        data:param,
    });
}

// 绑定邀请码
export const bindInviteCodeService = (param) => {
    return httpManager({
        url:api.authBindInviteCode,
        method:'post',
        data:param,
    });
}
