import React from 'react';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CardMedia from '@mui/material/CardMedia';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import TTSwitch from '../common/TTSwitch';

const ProfileContentPrivacySetting = (props) => {
    const {
        privacySetting,allowOtherTimes,allowOtherProfit,allowOtherWagered,
        allowOtherTimesValue,allowOtherProfitValue,allowOtherWageredValue,
        open,onCloseClick,
    } = props;

    const itemArr = [allowOtherTimes,allowOtherProfit,allowOtherWagered];

    return (
        <React.Fragment>
            <Drawer
                anchor='bottom'
                open={open}
                onClose={onCloseClick}
                sx={{
                    [`& .${drawerClasses.paper}`]: {
                        bgcolor:'#15151B',
                        borderRadius:'18px 18px 0px 0px',
                    }
                }}
            >
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                }}>
                    <Grid container sx={{
                        bgcolor:'#15151B',
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        padding:'18px 18px 0px 18px',
                    }}>
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'15px',
                            fontStyle:'normal',
                            fontWeight:500,
                        }}>
                            {privacySetting}
                        </Typography>
                        <IconButton onClick={onCloseClick}>
                            <CardMedia
                                component="img"
                                image={closeIcon}
                                sx={{
                                    width:'32px',
                                    height:'32px',
                                    objectFit:'contain',
                                }}
                            />
                        </IconButton>
                    </Grid>
                    <Box sx={{
                        bgcolor:'#1E212A',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        gap:'28px',
                        padding:'18px 18px 58px 18px',
                        mt:'18px',
                        width:'calc(100% - 36px)',
                    }}>
                        {itemArr.map((item,index) => (
                            <Grid container sx={{
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:'center',
                                gap:'20px',
                            }} key={index}>
                                <Typography sx={{
                                    color:'#ACB3D7',
                                    fontSize:'14px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                }}>
                                    {item}
                                </Typography>
                                <TTSwitch />
                            </Grid>
                        ))}
                    </Box>
                </Box>
            </Drawer>
        </React.Fragment>
    );
}

export default ProfileContentPrivacySetting;