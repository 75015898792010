import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import usdtIcon from '../../assets/images/header/header-usdt.png';
import HeaderWalletWithdrawBalance from './HeaderWalletWithdrawBalance';
import HeaderWalletWithdrawAddAccount from './HeaderWalletWithdrawAddAccount';
import { useSelector } from 'react-redux';

const HeaderWalletWithdrawAccount = (props) => {
    const {
        heightChanged,isChoose,chooseAccount,chooseAccountCallback,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        add: t("add"),
        confirm: t("confirm"),
        your_balance: t("your_balance"),
    }

    const totalCount = 4;
    let accountList = useSelector((state) => state.wallet.fiatWithdrawPreInfo.payInfoList);
    if(accountList === null) {
        accountList = [];
    }

    const [ openAddPIX,setOpenAddPIX ] = useState(false);
    const handleCloseAddPIXClick = () => {
        setOpenAddPIX(false);
    };

    const handleAccountClick = (index) => {
        if(isChoose) {
            chooseAccountCallback && chooseAccountCallback(accountList[index]);
        }
    };
    const handleAddAccountClick = () => {
        setOpenAddPIX(true);
    };

    // 动态获取高度
    const boxRef = useRef(null);
    useEffect(() => {
        const boxHeight = boxRef.current.getBoundingClientRect().height;
        heightChanged && heightChanged(boxHeight);
    }, []);

    return (
        <React.Fragment>
            {openAddPIX ? 
                <HeaderWalletWithdrawAddAccount 
                    isOpen={openAddPIX}
                    closeCallback={handleCloseAddPIXClick}
                /> : ''
            }

            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                width:screenMD ? '100%' : 'calc(100% - 198px)',
                height:'100%',
            }} ref={boxRef}>
                {screenMD ? 
                <Typography sx={{
                    color:'#8EA3C3',
                    fontSize:'18px',
                    fontWeight:700,
                }}>
                    {textData.your_balance}
                </Typography> : ''
            }
                <HeaderWalletWithdrawBalance />
                <Box sx={{
                    bgcolor:'#2C394E',
                    borderRadius:'8px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    padding:'20px',
                    width:'calc(100% - 40px)',
                    height:'calc(100% - 40px)',
                    mt:'12px',
                }}>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'18px',
                        fontWeight:600,
                        textAlign:'right',
                    }}>
                        {'PIX '}
                        <span style={{color:'#8EA3C3'}}>{`(${accountList.length}/${totalCount})`}</span>
                    </Typography>
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        gap:'18px',
                        mt:'18px',
                    }}>
                        {accountList.map((item,index) => (
                            <Button sx={{
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:'center',
                                padding:'0px 18px',
                                width:screenMD ? '100%' : 'calc((100% - 18px) / 2)',
                                height:'60px',
                                bgcolor:'#1E2736',
                                borderRadius:'8px',
                                textTransform:'none',
                                color:'#FFFFFF',
                                border:(isChoose && chooseAccount === item) ? '2px solid #43A0FF' : 'none',
                                '&:hover': {
                                    bgcolor:'#1E2736',
                                },
                            }} key={index} onClick={() => handleAccountClick(index)}>
                                <Box sx={{
                                    display:'flex',
                                    justifyContent:'flex-start',
                                    alignItems:'center',
                                }}>
                                    <CardMedia
                                        component="img"
                                        image={ usdtIcon }
                                        sx={{
                                            objectFit:'contain',
                                            width:'32px',
                                            height:'32px',
                                        }}
                                    />
                                    <Typography sx={{
                                        color:'#FFFFFF',
                                        fontSize:'18px',
                                        fontWeight:700,
                                        ml:'6px'
                                    }}>
                                        {`****${item.account_id.slice(-4)}`}
                                        {/* <span style={{color:'#8EA3C3'}}>{item.account_type}</span> */}
                                    </Typography>
                                </Box>
                                {isChoose ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none">
                                    <path d="M0.600001 11.6004L9 6.00039L0.6 0.400391" fill="#8EA3C3"/>
                                    </svg> : ''
                                }
                            </Button>
                        ))}
                        {accountList.length < totalCount ?
                            <Button sx={{
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:'center',
                                padding:'0px 18px',
                                width:screenMD ? '100%' : 'calc((100% - 18px) / 2)',
                                height:'60px',
                                bgcolor:'#1E2736',
                                borderRadius:'8px',
                                textTransform:'none',
                                color:'#FFFFFF',
                                '&:hover': {
                                    bgcolor:'#1E2736',
                                },
                            }} onClick={handleAddAccountClick}>
                                <Box sx={{
                                    display:'flex',
                                    justifyContent:'flex-start',
                                    alignItems:'center',
                                }}>
                                    <CardMedia
                                        component="img"
                                        image={ usdtIcon }
                                        sx={{
                                            objectFit:'contain',
                                            width:'32px',
                                            height:'32px',
                                        }}
                                    />
                                    <Typography sx={{
                                        color:'#FFFFFF',
                                        fontSize:'18px',
                                        fontWeight:700,
                                        ml:'6px'
                                    }}>
                                        {`${textData.add} PIX`}
                                    </Typography>
                                </Box>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none">
                                <path d="M0.600001 11.6004L9 6.00039L0.6 0.400391" fill="#8EA3C3"/>
                                </svg>
                            </Button> : ''
                        }
                    </Grid>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default HeaderWalletWithdrawAccount;