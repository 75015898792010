import { createAsyncThunk,  createSlice } from '@reduxjs/toolkit';
import { getCookieAccessToken, getCookieEmail, getCookieUserId, getCookieUsername } from '../tools/CommonTool';

// export const loginThunk = createAsyncThunk('user/login', async (param) => {
//     console.log('param:'+JSON.stringify(param));
//     try {
//         const response = await loginService(param);
//         return response.data;
//     } catch (error) {
//         return error.response;
//     }
// });
// export const registerThunk = createAsyncThunk('user/register', async (param) => {
//     try {
//         const response = await registerService(param);
//         return response.data;
//     } catch (error) {
//         console.log('loginThunk error:',error);
//     }
// });

// const initialState = {
//     loadingStatus:'idle',
//     header: {
//         errorMsg:'',
//         isSuccess:false,
//         code:0,
//     },
//     body: {
//         access_token:'',
//         refresh_token:'',
//         user: {
//             pk:0,
//             username:'',
//             email:'',
//             first_name:'',
//             last_name:'',
//         },
//     }
// }

const initialState = {
    access_token:getCookieAccessToken(),
    refresh_token:'',
    user: {
        pk:getCookieUserId(),
        username:getCookieUsername(),
        email:getCookieEmail(),
        first_name:'',
        last_name:'',
        desc:'',
        gender:'',
        profile_image_url:'',
        card_number:'',
        referral_code:'',
        joined_time:0,
        total_bet_count:0,
        total_bet_amount:0,
        total_win_amount:0,
        bet_symbol:'BRL',
        is_telegram_user:false,
    },
    logoutFlag:false,
    forceLogin:false,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userLogin(state,action) {
            if(action.payload !== undefined) {
                state.access_token = action.payload.access_token;
                state.refresh_token = action.payload.refresh_token;
                state.user.pk = action.payload.user.pk;
                state.user.username = action.payload.user.username;
                state.user.email = action.payload.user.email;
                state.user.first_name = action.payload.user.first_name;
                state.user.last_name = action.payload.user.last_name;
                state.user.referral_code = action.payload.referral_code;
                state.logoutFlag = false;
                state.forceLogin = false;
                // console.log('loginDispatch state:',JSON.stringify(state));
            }
        },
        userLogout(state,action) {
            state.access_token = '';
            state.refresh_token = '';
            state.user.pk = 0;
            state.user.username = ''
            state.user.email = '';
            state.user.first_name = '';
            state.user.last_name = '';
            state.user.referral_code = '';
            state.logoutFlag = true;
            state.forceLogin = false;
            // console.log('userLogout state:',JSON.stringify(state));
        },
        userForceLogin(state) {
            state.access_token = '';
            state.refresh_token = '';
            state.user.pk = 0;
            state.user.username = ''
            state.user.email = '';
            state.user.first_name = '';
            state.user.last_name = '';
            state.user.referral_code = '';
            state.logoutFlag = true;
            state.forceLogin = true;
            // console.log('userForceLogin state:',JSON.stringify(state));
        },
        userRefreshUserInfo(state,action) {
            if(action.payload.name !== undefined && action.payload.name.length > 0) {
                state.user.username = action.payload.name;
            }
            if(action.payload.name !== undefined && action.payload.name.length > 0) {
                state.user.desc = action.payload.desc;
            }
            if(action.payload.name !== undefined && action.payload.name.length > 0) {
                state.user.gender = action.payload.gender;
            }
            if(action.payload.name !== undefined && action.payload.name.length > 0) {
                state.user.profile_image_url = action.payload.profile_image_url;
            }
            if(action.payload.name !== undefined && action.payload.name.length > 0) {
                state.user.card_number = action.payload.card_number;
            }
            if(action.payload.referral_code !== undefined) {
                state.user.referral_code = action.payload.referral_code;
            }
            if(action.payload.joined_time !== undefined) {
                state.user.joined_time = action.payload.joined_time;
            }
            if(action.payload.total_bet_count !== undefined) {
                state.user.total_bet_count = action.payload.total_bet_count;
            }
            if(action.payload.total_bet_amount !== undefined) {
                state.user.total_bet_amount = action.payload.total_bet_amount;
            }
            if(action.payload.total_win_amount !== undefined) {
                state.user.total_win_amount = action.payload.total_win_amount;
            }
            if(action.payload.symbol !== undefined) {
                state.user.bet_symbol = action.payload.symbol;
            }
            if(action.payload.is_telegram_user !== undefined) {
                state.user.is_telegram_user = action.payload.is_telegram_user;
            }
            console.log('userRefreshUserInfo state:',JSON.stringify(state));
        }
    },
    // extraReducers: builder => {
    //     builder
    //       .addCase(loginThunk.pending, (state, action) => {
    //         state.loadingStatus = 'loading';
    //     })
    //       .addCase(loginThunk.fulfilled, (state, action) => {
    //         state.loadingStatus = 'idle';
    //         if(action.payload !== undefined) {
    //             if(action.payload.access_token !== undefined) {
    //                 state.body = action.payload;
    //                 // setCookieAccessToken(action.payload.access_token);
    //                 // setCookieUserId(action.payload.user.pk);
    //                 state.header = {
    //                     errorMsg: '',
    //                     isSuccess: true,
    //                     code: 200
    //                 }
    //             }else if(action.payload.data !== undefined) {
    //                 state.header = {
    //                     errorMsg: Object.values(action.payload.data).length > 0 ? 
    //                     Object.values(action.payload.data)[0] : 
    //                     JSON.stringify(action.payload.data),
    //                     isSuccess: false,
    //                     code: action.payload.status
    //                 }
    //             }
    //         }            
    //     })
    // }
});

export const { userLogin,userLogout,userForceLogin,userRefreshUserInfo } = userSlice.actions;

export default userSlice.reducer;