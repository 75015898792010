import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';

const TTTosPopup = (props) => {
    const { 
        isOpen,
        closeCallback,
        title,
        content,
    } = props;
    const theme = useTheme();
    
    return (
        <Dialog
            open={isOpen}
            // onClose={handleCloseClick}
            sx={{
                [`& .${dialogClasses.paper}`]: {
                    bgcolor:'transparent',
                    borderRadius:'18px',
                    maxWidth:'calc(100% - 36px)',
                    maxHeight:'calc(100% - 36px)',
                    margin:'0px',
                    padding:'0px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:'8px',
                        maxWidth:'calc(100% - 28px)',
                        maxHeight:'calc(100% - 28px)',
                        width:'calc(100% - 28px)',
                    },
                },
            }}
        >
            <DialogContent sx={{
                bgcolor:'transparent',
                borderRadius:'18px',
                // minWidth:'500px',
                maxWidth:'100%',
                m:'0px',
                p:'0px',
                overflow:'auto',
                scrollbarWidth: 'none', // Firefox
                msOverflowStyle: 'none', // Internet Explorer
                '&::-webkit-scrollbar': {
                    display: 'none', // Chrome, Safari, Edge
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'100%',
                    borderRadius:'8px',
                },
                transition: theme.transitions.create(['all'], {
                    duration: 300,
                    easing: 'ease-out',
                }),
            }}>
                <Box sx={{
                    bgcolor:'#1E2736',
                    borderRadius:'18px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'12px',
                    padding:'18px',
                    width:'calc(100% - 36px)',
                    overflow:'auto',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:'8px',
                    },
                }}>
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        width:'100%',
                    }}>
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'18px',
                            fontWeight:700,
                        }}>
                            {title}
                        </Typography>
                        <IconButton sx={{
                            mt:'-8px',
                            mr:'-8px',
                        }} onClick={closeCallback}>
                            <CardMedia
                                component='img'
                                image={closeIcon}
                                sx={{
                                    objectFit:'contain',
                                    width:'18px',
                                    height:'18px',
                                    '&:hover': {
                                        filter: 'brightness(0) invert(1)',
                                    },
                                }}
                            />
                        </IconButton>
                    </Grid>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        width:'100%',
                        color:'#FFFFFF'
                    }} dangerouslySetInnerHTML={{ __html: content }}>

                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default TTTosPopup;