import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../../tools/MobileBreakpoint';
import i18next from "i18next";
import RoutePath from "../../../tools/RoutePath";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import usdtNetworkIcon from '../../../assets/images/wallet/wallet-deposit-network-usdt.png';
import usdtIcon from '../../../assets/images/header/header-usdt.png';
import { walletDepositInfoService } from "../../../network/service/wallet";

const WalletContentMainLeftDepositNetwork = (props) => {
    const { network,next,theAmountThan } = props;
    const theme = useTheme();

    const [ chainArr, setChainArr ] = useState([]);
    const [ chain, setChain ] = useState(0);

    // const chainArr = [
    //     'USDT-ETH','USDT-TRON','USDT-BSC'
    // ];

    const handleChainClick = (index) => {
        setChain(index);
    };
    const handleChainInfos = async () => {
        try {
            const response = await walletDepositInfoService({type:2});
            for (let index = 0; index < response.data.data.length; index++) {
                const element = response.data.data[index];
                if(element.symbol.toUpperCase() === 'USDT') {
                    setChainArr(element.chains)
                    break;
                }
            }
        } catch (error) {
            console.log('handleChainInfos error:'+error);
        }
    };

    useEffect(() => {
        handleChainInfos();
    },[]);

    const params = new URLSearchParams();
    if(chainArr.length > 0) {        
        params.append('network', chainArr[chain].platform_name);
        params.append('token', 'USDT');
        params.append('address', chainArr[chain].address);
        params.append('language', i18next.language);
        params.append('depositLimit', chainArr[chain].withdraw_limit);
    }
    

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width:'100%',
            gap:'18px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'18px',
                padding:'18px',
                bgcolor:'#15151B',
                width:'calc(100% - 36px)',
            },
        }}>
            <Typography sx={{
                color:'#6D728D',
                fontSize:'20px',
                fontStyle:'normal',
                fontWeight:400,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'15px',
                },
            }}>
                {network}
            </Typography>
            <Grid container sx={{
                bgcolor:'#15151B',
                borderRadius:'18px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'flex-start',
                width:'100%',
                padding:'18px 38px',
                gap:'36px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'0px',
                },
            }}>
                <Grid container sx={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'start',
                    gap:'12px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        gap:'18px',
                    },
                }}>
                    {chainArr.map((item,index) => (
                        <Box sx={{
                            border: index === chain ? '4px solid rgba(178, 42, 40, 0.79)' : 'none',
                            borderRadius:'8px',
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center',
                            justifyContent:'center',
                            gap:'8px',
                            width:index === chain ? '175px' : '183px',
                            cursor:'pointer',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                borderRadius:'7px',
                                width:index === chain ? 'calc((100vw - 62px) / 2  - 8px)' : 'calc((100vw - 62px) / 2)',
                            },
                        }}  key={index} onClick={() => handleChainClick(index)}>
                            <Box sx={{
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                bgcolor:'#26A17B',
                                borderRadius:'8px',
                                width:'100%',
                                height:'58px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    borderRadius:'7px',
                                },
                            }}>
                                <CardMedia
                                    component="img"
                                    image={usdtNetworkIcon}
                                    sx={{
                                        width:'31px',
                                        height:'28px',
                                        objectFit:'contain',
                                    }}
                                />
                            </Box>
                            <Typography sx={{
                                color:'#FFFFFF',
                                fontSize:'15px',
                                fontStyle:'normal',
                                fontWeight:500,
                                textAlign:'center',
                            }}>
                                {`USDT-${item.platform_name}`}
                            </Typography>
                        </Box>
                    ))}
                </Grid>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'100%',
                }}>
                    <Typography sx={{
                        color:'#DB6060',
                        fontSize:'30px',
                        fontWeight:500,
                        width:'100%',
                        overflowWrap: 'break-word',
                        lineHeight:'35px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'20px',
                            lineHeight:'24px',
                        },
                    }}>
                        {theAmountThan}
                    </Typography>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:'6px',
                    }}>
                        <CardMedia
                            component="img"
                            image={usdtIcon}
                            sx={{
                                width:'28px',
                                height:'28px',
                                objectFit:'contain',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'18px',
                                    height:'18px',
                                },
                            }}
                        />
                        <Typography sx={{
                            color:'#FFD233',
                            fontSize:'32px',
                            fontWeight:500,
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'20px',
                            },
                        }}>
                            {'USDT'}
                        </Typography>
                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'32px',
                            fontWeight:500,
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'20px',
                            },
                        }}>
                            {(chainArr.length > 0 && chain < chainArr.length) ?
                                chainArr[chain].withdraw_limit : '30'
                            }
                        </Typography>
                    </Box>
                </Box>
                <a href={`${RoutePath.deposit}?${params.toString()}`} target='_blank' rel="noreferrer" style={{textDecoration:'none',width:'100%',}}>
                    <Button variant="text" sx={{
                        bgcolor:'#1CA45F',
                        color:'#FFFFFF',
                        fontSize:'24px',
                        fontStyle:'normal',
                        fontWeight:500,
                        textTransform:'none',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        height:'48px',
                        width:'100%',
                        borderRadius:'24px',
                        '&:hover': {
                            bgcolor:'#1CA45F',
                            color: '#FFFFFF',
                        },
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'18px',
                        },
                    }}>
                        {next}
                    </Button>
                </a>
            </Grid>
        </Box>
    );
}

export default WalletContentMainLeftDepositNetwork;