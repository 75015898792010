import * as React from 'react';
import i18next from "i18next";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { paperClasses } from '@mui/material/Paper';
import { menuClasses } from '@mui/material/Menu';
import { menuItemClasses } from '@mui/material/MenuItem';
import { popoverClasses } from '@mui/material/Popover';
import drawerArrowRight from '../../assets/images/drawer/drawer-arrow_right.png';
import languageEN from '../../assets/images/drawer/language-en.png';
import languageKO from '../../assets/images/drawer/language-ko.png';
import languageSG from '../../assets/images/drawer/language-sg.png';
import languageBR from '../../assets/images/drawer/language-br.png';
import languageES from '../../assets/images/drawer/language-es.png';
import menuHome from '../../assets/images/drawer/drawer-menu-home.png';
import menuHomeSelect from '../../assets/images/drawer/drawer-menu-home-selected.png';
import menuPopular from '../../assets/images/drawer/drawer-menu-popular.png';
import menuPopularSelect from '../../assets/images/drawer/drawer-menu-popular-selected.png';
import menuNew from '../../assets/images/drawer/drawer-menu-new.png';
import menuNewSelect from '../../assets/images/drawer/drawer-menu-new-selected.png';
import menuHot from '../../assets/images/drawer/drawer-menu-hot.png';
import menuHotSelect from '../../assets/images/drawer/drawer-menu-hot-selected.png';
import menuInHouseGame from '../../assets/images/drawer/drawer-menu-inHouseGame.png';
import menuInHouseGameSelect from '../../assets/images/drawer/drawer-menu-inHouseGame-selected.png';
import menuPoker from '../../assets/images/drawer/drawer-menu-poker.png';
import menuPokerSelect from '../../assets/images/drawer/drawer-menu-poker-selected.png';
import menuSlots from '../../assets/images/drawer/drawer-menu-slots.png';
import menuSlotsSelect from '../../assets/images/drawer/drawer-menu-slots-selected.png';
import menuLiveCasino from '../../assets/images/drawer/drawer-menu-liveCasino.png';
import menuLiveCasinoSelect from '../../assets/images/drawer/drawer-menu-liveCasino-selected.png';
import menuFavorites from '../../assets/images/drawer/drawer-menu-favorites.png';
import menuFavoritesSelect from '../../assets/images/drawer/drawer-menu-favorites-selected.png';
import menuRecent from '../../assets/images/drawer/drawer-menu-recent.png';
import menuRecentSelect from '../../assets/images/drawer/drawer-menu-recent-selected.png';
import menuReferral from '../../assets/images/drawer/drawer-menu-referral.png';
import menuReferralSelect from '../../assets/images/drawer/drawer-menu-referral-selected.png';
import closeBackIcon from '../../assets/images/drawer/drawer-close-back.png';
import ttLogoIcon from '../../assets/images/header/header-logo.png';
import DrawerMenuItem from './DrawerMenuItem';
import DrawerMenuHelpCenter from './DrawerMenuHelpCenter';
import { Fab } from '@mui/material';
import { useSelector } from 'react-redux';


const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    [`& .${paperClasses.root}`]: {
      borderRadius: 8,
      marginLeft: -5,
      marginTop: -10,
      color: '#FFFFFF',
      [`& .${menuClasses.list}`]: {
        padding: 'px 0',
        display:'flex',
        flexDirection:'column',
        justifyContent:'start',
        alignItems:'start',
        gap:'5px',
      },
      [`& .${menuItemClasses.root}`]: {
        '&:active': {
          backgroundColor: alpha(
            '#000000',
            0.8,
          ),
        },
      },
    },
    [`& .${popoverClasses.paper}`]: {
        backgroundColor: '#0C0D11',
    }
}));

const Drawer = (props) => {
    const [ language, setLanguage] = React.useState(i18next.language);
    const chooseLanguageImage = () => {
        if(language.toLowerCase().includes("ko")) {
            return languageKO;
        }else if(language.toLowerCase().includes("zh")) {
            return languageSG;
        }else if(language.toLowerCase().includes("pt")) {
            return languageBR;
        }else if(language.toLowerCase().includes("es")) {
            return languageES;
        }else {
            return languageEN;
        }
    }
    const chooseLanguageText = () => {
        if(language.toLowerCase().includes("ko")) {
            return '한국인';
        }else if(language.toLowerCase().includes("zh")) {
            return '繁體中文';
        }else if(language.toLowerCase().includes("pt")) {
            return 'Português';
        }else if(language.toLowerCase().includes("es")) {
            return 'Español';
        }else {
            return 'English';
        }
    }

    const { defaultSelectIndex,isSlider,drawerOnClose } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        home: t("home"),
        popularSlots: t("popular_slots"),
        newSlots: t("new_slots"),
        hotSlots: t("hot_slots"),
        inHouseGames: t("in_house_game"),
        poker: t("poker"),
        slots: t("slots"),
        liveCasino: t("live_casino"),
        favorites: t("favorites"),
        recent: t("recent"),
        referral: t("referral"),
        helpCenter: t("help_center"),
    };

    const userToken = useSelector((state) => state.user.access_token);
    const is_telegram_user = useSelector((state) => state.user.user.is_telegram_user);

    let btnTitleArr = [
        textData.home,
        textData.popularSlots,
        textData.newSlots,
        textData.hotSlots,
        // textData.inHouseGames,
        // textData.poker,
        textData.favorites,
        textData.recent,
        textData.referral,
    ];
    let btnIconArr = [
        menuHome,
        menuPopular,
        menuNew,
        menuHot,
        // menuInHouseGame,
        // menuPoker,
        menuFavorites,
        menuRecent,
        menuReferral,
    ];
    let btnIconSelectedArr = [
        menuHomeSelect,
        menuPopularSelect,
        menuNewSelect,
        menuHotSelect,
        // menuInHouseGameSelect,
        // menuPokerSelect,
        menuFavoritesSelect,
        menuRecentSelect,
        menuReferralSelect,
    ];

    if(userToken !== undefined && userToken !== null && userToken.length > 0 && is_telegram_user) {
        btnTitleArr = [
            textData.home,
            textData.popularSlots,
            textData.newSlots,
            textData.hotSlots,
            // textData.inHouseGames,
            // textData.poker,
            textData.favorites,
            textData.recent,
            textData.referral
        ];
        btnIconArr = [
            menuHome,
            menuPopular,
            menuNew,
            menuHot,
            // menuInHouseGame,
            // menuPoker,
            menuFavorites,
            menuRecent,
            menuReferral
        ];
        btnIconSelectedArr = [
            menuHomeSelect,
            menuPopularSelect,
            menuNewSelect,
            menuHotSelect,
            // menuInHouseGameSelect,
            // menuPokerSelect,
            menuFavoritesSelect,
            menuRecentSelect,
            menuReferralSelect
        ];
    }
    
    const navigate = useNavigate();
    const [ languageIcon, setLanguageIcon] = React.useState(chooseLanguageImage());
    const [ languageText, setLanguageText] = React.useState(chooseLanguageText());
    const [ menuIndex, setMenuIndex] = React.useState(defaultSelectIndex);

    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeLanguage = (lang) => {
        handleClose();
        if(!i18next.language.toLowerCase().includes(lang)) {
            i18next.changeLanguage(lang);
            setLanguage(lang);
            if(lang === 'ko') {
                setLanguageIcon(languageKO);
                setLanguageText('한국인');
            }else if(lang === 'zh') {
                setLanguageIcon(languageSG);
                setLanguageText('繁體中文');
            }else if(lang === 'pt') {
                setLanguageIcon(languageBR);
                setLanguageText('Português');
            }else if(lang === 'es') {
                setLanguageIcon(languageES);
                setLanguageText('Español');
            }else {
                setLanguageIcon(languageEN);
                setLanguageText('English');
            }
        }
    }
    
    const handleMenuClick = (index) => {
        setMenuIndex(index);
        if(index === 0) {
            navigate(RoutePath.home);
        }else if(index === 1) {
            navigate(RoutePath.popularSlots, { state: { category:1,fromPage:RoutePath.popularSlots } } );
        }else if(index === 2) {
            navigate(RoutePath.newSlots, { state: { category:2,fromPage:RoutePath.newSlots } } );
        }else if(index === 3) {
            navigate(RoutePath.hotSlots, { state: { category:3,fromPage:RoutePath.hotSlots } } );
        }
        // else if(index === 4) {
        //     navigate(RoutePath.inHouseGames);
        // }else if(index === 5) {
        //     navigate(RoutePath.poker);
        // }
        else if(index === 4) {
            navigate(RoutePath.favorite);
        }else if(index === 5) {
            navigate(RoutePath.recent);
        }else if(index === 6) {
            navigate(RoutePath.referral);
        }
    }
    const handleHelpCenterClick = () => {
        navigate(RoutePath.helpCenter, { state: { categoryIndex:0 }});
    }

    return (
        <React.Fragment>
            <Box sx={{
                bgcolor:'#15151B',
                display:isSlider ? 'block' : 'none',
                width:'249px',
                height:'calc(100% - 92px)',
                padding:'0px 0px 92px 0px',
                overflow:'scroll',
                scrollbarWidth: 'none', // Firefox
                msOverflowStyle: 'none', // Internet Explorer
                '&::-webkit-scrollbar': {
                    display: 'none', // Chrome, Safari, Edge
                },
                [theme.breakpoints.up(MobileBreakpoint.match)]: {
                    width:'260px',
                    float:'left',
                    position:'fixed',
                    height:'calc(100% - 108px)',
                    padding:'0px 0px 108px 0px',
                    zIndex:1,
                },
            }}>
                <Grid container sx={{
                    display:isSlider ? 'block' : 'none',
                    mt:'20px',
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    padding:'0px 18px',
                }}>
                    <CardMedia
                        component="img"
                        image={ttLogoIcon}
                        sx={{
                            width:'92px',
                            height:'28px',
                            objectFit:'contain',
                        }}
                    />
                    <IconButton onClick={drawerOnClose}>
                        <CardMedia
                            component="img"
                            image={closeBackIcon}
                            sx={{
                                width:'26px',
                                height:'26px',
                                objectFit:'contain',
                            }}
                        />
                    </IconButton>
                </Grid>

                <Grid container sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'100%',
                    mt:'0px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'23px',
                    },
                }}>
                    
                    <Grid container sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'start',
                        gap:'18px',
                        width:'100%',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'8px',
                        },
                    }}>
                        {btnTitleArr.map((item,index) => (
                            <DrawerMenuItem 
                                selected={menuIndex === index ? true : false}
                                btnIconImage={btnIconArr[index]}
                                btnIconImageSelected={btnIconSelectedArr[index]}
                                btnText={item}
                                btnClick={() => handleMenuClick(index)}
                                isInHouseGame={false}
                                inHouseGame={textData.inHouseGames}
                                key={item}
                            />
                        ))}
                        <DrawerMenuHelpCenter 
                            btnText={textData.helpCenter}
                            btnClick={handleHelpCenterClick}
                        />
                    </Grid>

                    <Box sx={{
                        bgcolor:'#15151B',
                        position:'fixed',
                        // left:'12px',
                        // bottom:'28px',
                        left:'0px',
                        bottom:'0px',
                        padding:'8px 8px 36px 8px',
                        margin:'0px',
                        width:'244px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'225px',
                            padding:'8px 8px 20px 8px',
                        },
                    }}>
                        <Button sx={{
                            boxShadow: 'none',
                            textTransform: 'none',
                            backgroundColor: '#0C0D11',
                            borderRadius:'8px',
                            display:'flex',
                            alignItems:'center',
                            height:'50px',
                            justifyContent:'space-between',
                            padding:'0px 18px',
                            width:'100%',
                            textDecoration:'none',
                            '&:hover': {
                                backgroundColor: '#0C0D11',
                            },
                        }} onClick={handleClick} disableRipple>
                            <Box sx={{
                                display:'flex',
                                justifyContent:'end',
                                alignItems:'center',
                                gap:'28px',
                            }}>
                                <CardMedia
                                    component="img"
                                    image={ languageIcon }
                                    sx={{
                                        width:'30px',
                                        height:'20px',
                                        objectFit:'contain',
                                    }}
                                />
                                <Typography sx={{
                                    color:'#ACB3D7',
                                    fontSize:'14px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                }}>
                                    {languageText}
                                </Typography>
                            </Box>
                            
                            <Box sx={{
                                display:'flex',
                                justifyContent:'end',
                                alignItems:'center',
                                gap:'5px',
                            }}>
                                <CardMedia
                                    component="img"
                                    image={ drawerArrowRight }
                                    sx={{
                                        width:'7px',
                                        height:'17px',
                                        objectFit:'contain',
                                    }}
                                />
                            </Box>
                        </Button>
                    </Box>
                </Grid>
            </Box>
            
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleChangeLanguage('pt')} disableRipple sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'20px',
                }}>
                    <CardMedia
                        component="img"
                        image={ languageBR }
                        sx={{
                            width:'35px',
                            height:'27px',
                            objectFit:'contain',
                        }}
                    />
                    Português
                </MenuItem>
                <MenuItem onClick={() => handleChangeLanguage('en')} disableRipple sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'20px',
                }}>
                    <CardMedia
                        component="img"
                        image={ languageEN }
                        sx={{
                            width:'35px',
                            height:'27px',
                            objectFit:'contain',
                        }}
                    />
                    English
                </MenuItem>
                <MenuItem onClick={() => handleChangeLanguage('ko')} disableRipple sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'20px',
                }}>
                    <CardMedia
                        component="img"
                        image={ languageKO }
                        sx={{
                            width:'35px',
                            height:'27px',
                            objectFit:'contain',
                        }}
                    />
                    한국인
                </MenuItem>
                <MenuItem onClick={() => handleChangeLanguage('zh')} disableRipple sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'20px',
                }}>
                    <CardMedia
                        component="img"
                        image={ languageSG }
                        sx={{
                            width:'35px',
                            height:'27px',
                            objectFit:'contain',
                        }}
                    />
                    繁體中文
                </MenuItem>
                <MenuItem onClick={() => handleChangeLanguage('es')} disableRipple sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'20px',
                }}>
                    <CardMedia
                        component="img"
                        image={ languageES }
                        sx={{
                            width:'35px',
                            height:'27px',
                            objectFit:'contain',
                        }}
                    />
                    Español
                </MenuItem>
            </StyledMenu>
        </React.Fragment>
    );
}

export default Drawer;