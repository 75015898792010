import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import usdtIcon from '../../assets/images/header/header-usdt.png';
import copyIcon from '../../assets/images/wallet/wallet-copy.png';
import logoIcon from '../../assets/images/header/header-logo.png';
import copy from 'clipboard-copy';
import TTAlert from '../common/TTAlert';
import TTLoading from '../common/TTLoading';
import { useDispatch, useSelector } from 'react-redux';
import { walletFiatDepositListService } from '../../network/service/wallet';
import { walletFiatDepositRecordListRefresh } from '../../features/walletSlice';
import { Skeleton } from '@mui/material';
import { isToday, timestampToDateTime } from '../../tools/CommonTool';

const HeaderWalletDepositHistory = (props) => {
    const { 
        isOpen,
        closeCallback,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        deposit_records: t("deposit_records"),
        total_deposit: t("total_deposit"),
        today: t("today"),
        waiting_for_payment: t("waiting_for_payment"),
        failed: t("failed"),
        success: t("success"),
        copy_successfully: t("copy_successfully"),
        copy_failed: t("copy_failed"),
        no_data: t("no_data"),
    }

    const recordData = useSelector((state) => state.wallet.fiatDepositRecord);

    const [ page,setPage ] = useState(1);
    const pageSize = 4;
    const [ totalPage,setTotalPage ] = useState(1);
    const handleCloseClick = () => {
        closeCallback && closeCallback();
    };
    const handleCopyBtnClick = (text) => {
        copy(text)
        .then(() => {
            setType('success');
            setOpen(true);
            setAlertMessage(textData.copy_successfully);
        })
        .catch((err) => {
            setType('error');
            setOpen(true);
            setAlertMessage(textData.copy_failed);
        });
    };
    const handleFetchDepositList = async (size) => {
        try {
            const param = {
                symbol:'BRL',
                page:page,
                pageSize:size,
            }
            const response = await walletFiatDepositListService(param);
            setOpenLoading(false);
            dispatch(walletFiatDepositRecordListRefresh({record:response.data.data}));
        } catch (error) {
            setOpenLoading(false);
            
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };
    const handleFetchPrePageClick = () => {
        if(page > 1) {
            setOpenLoading(true);
            setPage((prevPage) => prevPage - 1);
        }
    };
    const handleFetchNextPageClick = () => {
        if(page < totalPage) {
            setOpenLoading(true);
            setPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        handleFetchDepositList(pageSize);
    }, [page]);
    useEffect(() => {
        let totalPage = Math.floor(recordData.total_count / pageSize);
        if((recordData.total_count % pageSize) > 0) {
            totalPage = totalPage + 1;
        }
        setTotalPage(totalPage);
    }, [recordData]);

    // alert
    const [ openLoading,setOpenLoading ] = useState(false);
    const [ type, setType ] = useState('info');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <Dialog
            open={isOpen}
            // onClose={handleCloseClick}
            sx={{
                [`& .${dialogClasses.paper}`]: {
                    bgcolor:'transparent',
                    borderRadius:'18px',
                    maxWidth:'calc(100% - 36px)',
                    maxHeight:'calc(100% - 36px)',
                    margin:'0px',
                    padding:'0px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:'8px',
                        maxWidth:'calc(100% - 28px)',
                        maxHeight:'calc(100% - 28px)',
                        width:'100%',
                    },
                },
            }}
        >
            <DialogContent sx={{
                bgcolor:'transparent',
                borderRadius:'18px',
                width:'500px',
                maxWidth:'100%',
                m:'0px',
                p:'0px',
                overflow:'auto',
                scrollbarWidth: 'none', // Firefox
                msOverflowStyle: 'none', // Internet Explorer
                '&::-webkit-scrollbar': {
                    display: 'none', // Chrome, Safari, Edge
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'100%',
                    borderRadius:'8px',
                },
            }}>
                <TTLoading 
                    open={openLoading}
                    isForce={true}
                />
                <TTAlert 
                    type={type}
                    isOpen={open}
                    message={alertMessage}
                    closeCallback={handleAlertClose}
                />

                <Box sx={{
                    bgcolor:'#1E2736',
                    borderRadius:'18px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'12px',
                    padding:'18px',
                    width:'calc(100% - 36px)',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:'8px',
                    },
                }}>
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        width:'100%',
                    }}>
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'18px',
                            fontWeight:700,
                        }}>
                            {textData.deposit_records}
                        </Typography>
                        <IconButton sx={{
                            mt:'-8px',
                            mr:'-8px',
                        }} onClick={handleCloseClick}>
                            <CardMedia
                                component='img'
                                image={closeIcon}
                                sx={{
                                    objectFit:'contain',
                                    width:'18px',
                                    height:'18px',
                                    '&:hover': {
                                        filter: 'brightness(0) invert(1)',
                                    },
                                }}
                            />
                        </IconButton>
                    </Grid>
                    
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-end',
                        alignItems:'center',
                        width:'100%',
                        mt:'20px',
                    }}> 
                        <Typography sx={{
                            color:'#8EA3C3',
                            fontSize:'18px',
                            fontWeight:600,
                            textAlign:'right',
                        }}>
                            {textData.total_deposit}
                            <span style={{color:'#FFFFFF'}}>{` R$${recordData.total_deposit_amount}`}</span>
                        </Typography>
                    </Box>

                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        gap:'10px',
                        width:'100%',
                        mt:'20px',
                    }}>
                        {recordData.data === null ? 
                            Array(4).fill('').map((item,index) => (
                                <Skeleton 
                                    variant="rounded" 
                                    animation="wave"
                                    sx={{
                                        bgcolor:'#1E212A',
                                        borderRadius:'8px',
                                        width:'calc(100% - 20px)',
                                        height:'80px',
                                    }} 
                                    key={index}
                                />
                            )) : 
                            (recordData.data.length === 0 ? 
                                <Box sx={{
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    width:'100%',
                                    height:screenMD ? '200px' : '300px',
                                }}>
                                    <CardMedia
                                        component="img"
                                        image={ logoIcon }
                                        sx={{
                                            objectFit:'contain',
                                            width:'219px',
                                            height:'70px',
                                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                width:'128px',
                                                height:'40px',
                                            },
                                        }}
                                    />
                                    <Typography sx={{
                                        color:'#6D728D',
                                        fontSize:'18px',
                                        fontWeight:400,
                                        textAlign:'center',
                                        mt:'15',
                                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                            fontSize:'14px',
                                        },
                                    }}>
                                        {textData.no_data}
                                    </Typography>
                                </Box> : 
                                <React.Fragment>
                                    {recordData.data.map((item,index) => (
                                        <Box sx={{
                                            bgcolor:'#2C394E',
                                            borderRadius:'8px',
                                            display:'flex',
                                            flexDirection:'column',
                                            justifyContent:'flex-start',
                                            alignItems:'flex-start',
                                            gap:'10px',
                                            padding:'10px',
                                            width:'calc(100% - 20px)',
                                        }} key={index}>
                                            <Grid container sx={{
                                                display:'flex',
                                                justifyContent:'space-between',
                                                alignItems:'center',
                                                width:'100%',
                                            }}>
                                                <Box sx={{
                                                    display:'flex',
                                                    justifyContent:'flex-start',
                                                    alignItems:'center',
                                                }}>
                                                    <CardMedia
                                                        component="img"
                                                        image={ usdtIcon }
                                                        sx={{
                                                            objectFit:'contain',
                                                            width:'32px',
                                                            height:'32px',
                                                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                                width:'18px',
                                                                height:'18px',
                                                            },
                                                        }}
                                                    />
                                                    <Typography sx={{
                                                        color:'#8EA3C3',
                                                        fontSize:'18px',
                                                        fontWeight:700,
                                                        ml:'8px',
                                                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                            fontSize:'14px',
                                                        },
                                                    }}>
                                                        {'PIX(Quente)'}
                                                    </Typography>
                                                </Box>
                                                <Typography sx={{
                                                    color:'#FFFFFF',
                                                    fontSize:'18px',
                                                    fontWeight:700,
                                                    textAlign:'right',
                                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                        fontSize:'14px',
                                                    },
                                                }}>
                                                    {`R$${item.amount}`}
                                                </Typography>
                                            </Grid>
            
                                            <Box sx={{
                                                width:'100%',
                                                height:'2px',
                                                bgcolor:'#1E2736',
                                            }} />
            
                                            <Grid container sx={{
                                                display:'flex',
                                                justifyContent:'space-between',
                                                alignItems:'flex-start',
                                                width:'100%',
                                                rowGap:'8px',
                                            }}>
                                                <Box sx={{
                                                    display:'flex',
                                                    justifyContent:'flex-start',
                                                    alignItems:'center',
                                                }}>
                                                    <Typography sx={{
                                                        color:'#8EA3C3',
                                                        fontSize:'12px',
                                                        fontWeight:700,
                                                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                            fontSize:'10px',
                                                        },
                                                    }}>
                                                        {isToday(item.created_at) ? 
                                                            `${textData.today} ${timestampToDateTime(item.created_at,'HH:mm:ss')} ${item.sn}` :
                                                            `${timestampToDateTime(item.created_at,'yyyy-MM-dd HH:mm:ss')} ${item.sn}`
                                                        }
                                                    </Typography>
                                                    {screenMD ? '' :
                                                        <IconButton sx={{
                                                            padding:'0px',
                                                            ml:'10px'
                                                        }} onClick={() => handleCopyBtnClick(item.sn)}>
                                                            <CardMedia
                                                                component='img'
                                                                image={copyIcon}
                                                                sx={{
                                                                    objectFit:'contain',
                                                                    width:'18px',
                                                                    height:'18px',
                                                                    '&:hover': {
                                                                        filter: 'brightness(0) invert(1)',
                                                                    },
                                                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                                        width:'16px',
                                                                        height:'16px',
                                                                    },
                                                                }}
                                                            />
                                                        </IconButton>
                                                    }   
                                                </Box>
                                                {screenMD ? 
                                                    <IconButton sx={{
                                                        padding:'0px',
                                                        ml:'10px'
                                                    }} onClick={() => handleCopyBtnClick(item.sn)}>
                                                        <CardMedia
                                                            component='img'
                                                            image={copyIcon}
                                                            sx={{
                                                                objectFit:'contain',
                                                                width:'18px',
                                                                height:'18px',
                                                                '&:hover': {
                                                                    filter: 'brightness(0) invert(1)',
                                                                },
                                                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                                    width:'16px',
                                                                    height:'16px',
                                                                },
                                                            }}
                                                        />
                                                    </IconButton> : ''
                                                }
                                                {screenMD ? '' : 
                                                    <Typography sx={{
                                                        color:item.status === 0 ? '#D0A358' : (item.status === 1 ? '#1CA45F' : '#B22A28'),
                                                        fontSize:'14px',
                                                        fontWeight:700,
                                                        textAlign:'right',
                                                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                            fontSize:'12px',
                                                        },
                                                    }}>
                                                        {item.status === 0 ?
                                                            textData.waiting_for_payment : 
                                                            (item.status === 1 ? 
                                                                textData.success :
                                                                textData.failed
                                                            )
                                                        }
                                                    </Typography>
                                                }
                                            </Grid>
                                            {screenMD ? 
                                                <Typography sx={{
                                                    color:item.status === 0 ? '#D0A358' : (item.status === 1 ? '#1CA45F' : '#B22A28'),
                                                    fontSize:'14px',
                                                    fontWeight:700,
                                                    textAlign:'right',
                                                    mt:'-6px',
                                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                        fontSize:'12px',
                                                    },
                                                }}>
                                                    {item.status === 0 ?
                                                        textData.waiting_for_payment : 
                                                        (item.status === 1 ? 
                                                            textData.success :
                                                            textData.failed
                                                        )
                                                    }
                                                </Typography> : ''
                                            }
                                        </Box>
                                    ))}
                                    <Box sx={{
                                        width:'100%',
                                    }}>
                                        <Box sx={{
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center',
                                        }}>
                                            <IconButton onClick={handleFetchPrePageClick}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                                                <path d="M8.99961 12.0203L0.599609 6.42031L8.99961 0.820313" fill="#8EA3C3"/>
                                                </svg>
                                            </IconButton>
                                            <Typography sx={{
                                                color:'#8EA3C3',
                                                fontSize:'18px',
                                                fontWeight:500,
                                                padding:'0px 24px',
                                            }}>
                                                {`${page}/${totalPage}`}
                                            </Typography>
                                            <IconButton onClick={handleFetchNextPageClick}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                                                <path d="M-0.000585422 12.0203L8.39941 6.42031L-0.000585556 0.820313" fill="#8EA3C3"/>
                                                </svg>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            )
                        }
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default HeaderWalletDepositHistory;