import React, { useEffect, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Skeleton from '@mui/material/Skeleton';
import usdtIcon from '../../assets/images/header/header-usdt.png';
import depositBannerIcon from '../../assets/images/wallet/wallet-deposit-banner.png';
import depositBannerMobileIcon from '../../assets/images/wallet/wallet-deposit-banner-mobile.png';
import { useDispatch, useSelector } from 'react-redux';
import HeaderWalletDepositHistory from './HeaderWalletDepositHistory';
import { walletFiatDepositService, walletFiatPreDepositService } from '../../network/service/wallet';
import { walletFiatDepositPreOptionListRefresh } from '../../features/walletSlice';
import TTAlert from '../common/TTAlert';
import TTLoading from '../common/TTLoading';

const HeaderWalletDeposit = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        your_balance: t("your_balance"),
        transaction: t("transaction"),
        amount: t("amount"),
        deposit_now: t("deposit_now"),
    }

    const [ chooseAmountIndex,setChooseAmountIndex ] = useState(0);
    
    const totalBalance = useSelector((state) => state.wallet.totalBalance);
    const optionArr = useSelector((state) => state.wallet.fiatDepositPreOptionList);

    const handleTransactionBtnClick = () => {
        setOpenRecord(true);
    };
    const handleChooseAmountClick = (index) => {
        setChooseAmountIndex(index);
    };
    const handleDepositNowBtnClick = () => {
        setOpenLoading(true);
        handleDeposit();
    };

    // 获取充值金额
    const handleFetchFiatPreData = async () => {
        try {
            const param = {
                symbol:'BRL',
            }
            const response = await walletFiatPreDepositService(param);
            dispatch(walletFiatDepositPreOptionListRefresh({optionList:response.data.options}));
        } catch (error) {
            console.log('userWalletInfos error:'+error);
        }
    };
    // 充值
    const handleDeposit = async () => {
        try {
            const param = {
                symbol:'BRL',
                amount:optionArr[chooseAmountIndex],
            }
            const response = await walletFiatDepositService(param);
            setOpenLoading(false);

            const link = document.createElement('a');
            link.href = response.data.data.cashierUrl;
            link.target = '_blank';
            link.click();
        } catch (error) {
            setOpenLoading(false);
            
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setAlertType('error');
            setAlertMessage(errorMsg);
            setOpenAlert(true);
        }
    };

    useEffect(() => {
        handleFetchFiatPreData();
    }, []);

    // 提现记录
    const [ openRecord, setOpenRecord ] = useState(false);
    const handleRecordAlertClose = () => {
        setOpenRecord(false);
    };
    
    // alert
    const [ alertType,setAlertType ] = useState('info');
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const [ openLoading,setOpenLoading ] = useState(false);
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    return (
        <React.Fragment>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <TTAlert 
                type={alertType}
                isOpen={openAlert}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />

            {openRecord ? 
                <HeaderWalletDepositHistory
                    isOpen={openRecord}
                    closeCallback={handleRecordAlertClose}
                /> : ''
            }

            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                width:'100%',
                gap:'12px',
            }}>
                <CardMedia
                    component="img"
                    image={ screenMD ? depositBannerMobileIcon : depositBannerIcon }
                    sx={{
                        objectFit:'cover',
                        width:'100%',
                        height:'62px',
                        borderRadius:'8px',
                    }}
                />

                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'100%',
                }}>
                    <Typography sx={{
                        color:'#8EA3C3',
                        fontSize:'14px',
                        fontWeight:700,
                    }}>
                        {textData.your_balance}
                    </Typography>
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        backgroundColor:'#000000',
                        borderRadius:'8px',
                        border:'1px solid #55657E',
                        overflow:'hidden',
                        height:'40px',
                        width:'100%',
                        mt:'2px',
                    }}>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'center',
                            ml:'8px',
                        }}>
                            <Typography sx={{
                                color:'#FFFFFF',
                                fontSize:'14px',
                                fontWeight:700,
                            }}>
                                {totalBalance}
                            </Typography>
                            <CardMedia
                                component="img"
                                image={ usdtIcon }
                                sx={{
                                    objectFit:'contain',
                                    width:'14px',
                                    height:'14px',
                                    ml:'10px',
                                }}
                            />
                        </Box>
                        <Button sx={{
                            textTransform:'none',
                            textDecoration:'none',
                            bgcolor:'#55657E',
                            color:'#FFFFFF',
                            fontSize:'14px',
                            fontWeight:700,
                            width:'116px',
                            height:'100%',
                            borderRadius:'0px 8px 8px 0px',
                            '&:hover': {
                                bgcolor:'#55657E',
                            },
                        }} onClick={handleTransactionBtnClick}>
                            {textData.transaction}
                        </Button>
                    </Grid>
                </Box>

                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'100%',
                }}>
                    <Typography sx={{
                        color:'#8EA3C3',
                        fontSize:'14px',
                        fontWeight:700,
                    }}>
                        {textData.amount}
                    </Typography>
                    <Grid container columns={4} sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        alignSelf:'stretch',
                        rowGap:'10px',
                        columnGap:'2px',
                        mt:'10px',
                        width:'100%',
                    }}>
                        {optionArr === null ? 
                            Array(8).fill('').map((item,index) => (
                                <Skeleton 
                                    variant="rounded" 
                                    animation="wave"
                                    sx={{
                                        bgcolor:'#1E212A',
                                        height:'64px',
                                        width:'114px',
                                        borderRadius:'8px',
                                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                            height:'48px',
                                            width:'calc((100% - 6px) / 4)',
                                        },
                                    }} 
                                    key={index}
                                />
                            )) : 
                            optionArr.map((item,index) => (
                                <Box sx={{
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    borderRadius:'8px',
                                    border:index === chooseAmountIndex ? '4px solid #1184FA' : 'none',
                                    height:'58px',
                                    width:'106px',
                                    cursor:'pointer',
                                    padding:index === chooseAmountIndex ? '0' : '4px',
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        height:'48px',
                                        width:'calc((100% - 40px) / 4)',
                                    },
                                }} onClick={() => handleChooseAmountClick(index)} key={index}>
                                    <Box sx={{
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        gap:'2px',
                                        width:'100%',
                                        height:'100%',
                                        bgcolor:'#55657E',
                                        borderRadius:index === chooseAmountIndex ? '4px' : '8px',
                                    }}>
                                        <Typography sx={{
                                            color:'#FFFFFF',
                                            fontSize:'18px',
                                            fontWeight:700,
                                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                fontSize:'15px',
                                            },
                                        }}>
                                            {item}
                                        </Typography>
                                        <CardMedia
                                            component="img"
                                            image={ usdtIcon }
                                            sx={{
                                                objectFit:'contain',
                                                width:'18px',
                                                height:'18px',
                                            }}
                                        />
                                    </Box>
                                </Box>
                            ))
                        }
                    </Grid>
                </Box>

                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    bgcolor:'#2C394E',
                    borderRadius:'8px',
                    padding:'10px',
                    width:'calc(100% - 20px)',
                    height:'32px',
                }}>
                    <CardMedia
                        component="img"
                        image={ usdtIcon }
                        sx={{
                            objectFit:'contain',
                            width:'32px',
                            height:'32px',
                        }}
                    />
                    <Typography sx={{
                        color:'#8EA3C3',
                        fontSize:'18px',
                        fontWeight:700,
                        ml:'8px',
                    }}>
                        {'R$'}
                    </Typography>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'18px',
                        fontWeight:700,
                        ml:'18px',
                    }}>
                        {optionArr === null ? 
                            '0' :
                            optionArr[chooseAmountIndex]
                        }
                    </Typography>
                </Box>

                <Button disabled={optionArr == null ? true : false} sx={{
                    textTransform:'none',
                    textDecoration:'none',
                    bgcolor:'#1184FA',
                    color:'#FFFFFF',
                    fontSize:'18px',
                    fontWeight:700,
                    width:'100%',
                    height:'40px',
                    borderRadius:'8px',
                    margin:'28px 0px',
                    '&:hover': {
                        bgcolor:'#1184FA',
                    },
                    '&.Mui-disabled': {
                        bgcolor: alpha(
                            '#1184FA',
                            0.5,
                        ),
                    }
                }} onClick={handleDepositNowBtnClick}>
                    {textData.deposit_now}
                </Button>
            </Box>
        </React.Fragment>
    );
}

export default HeaderWalletDeposit;