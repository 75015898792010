import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import FavoriteContentItem from '../favorite/FavoriteContentItem';
import TTGameItemSkeleton from '../common/TTGameItemSkeleton';
import RoutePath from '../../tools/RoutePath';
import { getCasinoHotGamesService, getCasinoNewGamesService, getCasinoPopularGamesService, getSlotsListService } from '../../network/service/game';
import { useDispatch, useSelector } from 'react-redux';
import logoIcon from '../../assets/images/wallet/wallet-tt-logo.png';
import { casinoHotListDataLoadMore, casinoHotListDataRefresh, casinoNewListDataLoadMore, casinoNewListDataRefresh, casinoPopularListDataLoadMore, casinoPopularListDataRefresh } from '../../features/casinoListSlice';
import CasinoListContentMainTitle from './CasinoListContentMainTitle';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 16,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#3A3E53',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 8,
      backgroundColor: '#1CA45F',
    },
}));

const CasinoListContentMain = (props) => {
    const {
        category,fromPage,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const { t,i18n } = useTranslation();
    const textData = {
        popularSlots: t("popular_slots"),
        newSlots: t("new_slots"),
        hotSlots: t("hot_slots"),
        loadMore: t("load_more"),
        noMoreData: t("no_more_data"),
        comingSoon: t("coming_soon"),
    };

    const providerArr = ['','PragmaticPlay','PGSoft',];
    const chooseCategorySelector = useSelector((state) => state.casinoList.currentChooseCategory);
    const handleCategory = () => {
        if(category === 1) {
            return chooseCategorySelector.popular;
        }else if(category === 2) {
            return chooseCategorySelector.new;
        }else if(category === 3) {
            return chooseCategorySelector.hot;
        }
        return 0;
    };

    const [ isLoading,setIsLoading ] = useState(false);
    const [ gameProvider,setGameProvider ] = useState(providerArr[handleCategory()]);
    const [ pageStartIndex,setPageStartIndex ] = useState(0);
    const [ progress, setProgress ] = useState(0);

    const casinoList = useSelector((state) => state.casinoList.casinoList);
    const handleSlotsData = () => {
        if(category === 1) {
            return casinoList.popular;
        }else if(category === 2) {
            return casinoList.new;
        }else if(category === 3) {
            return casinoList.hot;
        }
        return null;
    };

    // 获取游戏列表
    const handleFetchCasinoList = async (pageSize) => {
        try {
            const gameType = gameProvider.toUpperCase().includes('LIVE') ? 'CasinoLive' : 'CasinoSlot';
            let param = {
                game_type:gameType,
                start:pageStartIndex,
                end:pageStartIndex + pageSize,
            };
            if(gameProvider.length > 0) {
                param = {
                    game_type:gameType,
                    game_provider:gameProvider,
                    start:pageStartIndex,
                    end:pageStartIndex + pageSize,
                };
            }
            let response = null;
            if(category === 1) { // popular
                response = await getCasinoPopularGamesService(param);
            }else if(category === 2) { // new
                response = await getCasinoNewGamesService(param);
            }else if(category === 3) { // hot
                response = await getCasinoHotGamesService(param);
            }
            setIsLoading(false);
            if(pageStartIndex > 0) {
                if(category === 1) { // popular
                    dispatch(casinoPopularListDataLoadMore({casinoPopularList:response.data}));
                }else if(category === 2) { // new
                    dispatch(casinoNewListDataLoadMore({casinoNewList:response.data}));
                }else if(category === 3) { // hot
                    dispatch(casinoHotListDataLoadMore({casinoHotList:response.data}));
                }
            }else {
                if(category === 1) { // popular
                    dispatch(casinoPopularListDataRefresh({casinoPopularList:response.data}));
                }else if(category === 2) { // new
                    dispatch(casinoNewListDataRefresh({casinoNewList:response.data}));
                }else if(category === 3) { // hot
                    dispatch(casinoHotListDataRefresh({casinoHotList:response.data}));
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    useEffect(() => {
        setGameProvider(providerArr[handleCategory()]);
    }, [category]);
    useEffect(() => {
        if(handleSlotsData() !== null && pageStartIndex === 0 && handleSlotsData().data.length > 20) {
            handleFetchCasinoList(handleSlotsData().data.length);
        }else {
            if(handleSlotsData() !== null && handleSlotsData().total_count - pageStartIndex < 20) {
                handleFetchCasinoList(handleSlotsData().total_count - pageStartIndex);
            }else {
                handleFetchCasinoList(20);
            }
        }
    }, [gameProvider,category,pageStartIndex,i18n.language]);
    useEffect(() => {
        if(handleSlotsData() !== null) {
            if(handleSlotsData().data.length < handleSlotsData().total_count) {
                const pg = handleSlotsData().data.length / handleSlotsData().total_count * 100;
                setProgress(Math.round(pg * 10) / 10);
            }else {
                setProgress(100);
            }
        }
    }, [handleSlotsData()]);

    const handleMenuOnClick = (provider) => {
        if(provider === gameProvider) {
            return;
        }
        if(category === 1) { // popular
            dispatch(casinoPopularListDataRefresh({casinoPopularList:null}));
        }else if(category === 2) { // new
            dispatch(casinoNewListDataRefresh({casinoNewList:null}));
        }else if(category === 3) { // hot
            dispatch(casinoHotListDataRefresh({casinoHotList:null}));
        }
        setPageStartIndex(0);
        setGameProvider(provider);
    }

    const handleLoadMoreClick = () => {
        if(handleSlotsData().data.length >= handleSlotsData().total_count || isLoading) {
            return;
        }
        setIsLoading(true);
        setPageStartIndex((prevPage) => {
            if(pageStartIndex + 20 < handleSlotsData().data.length) {
                return handleSlotsData().data.length;
            }
            return prevPage + 20;
        });
    }

    const handleTitle = () => {
        if(category === 1) {
            return textData.popularSlots;
        }else if(category === 2) {
            return textData.newSlots;
        }else if(category === 3) {
            return textData.hotSlots;
        }
        return '';
    }

    return (
        <Grid container sx={{
            padding:'36px 60px 145px 60px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'18px',
            },
        }}>
            <Container sx={{
                // bgcolor:'#bbbbbb',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                minHeight:'500px',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1000px',
                }
            }}>
                {/* <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'28px',
                    fontStyle:'normal',
                    fontWeight:500,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'18px',
                    },
                }}>
                    {textData.slots}
                </Typography> */}
                <CasinoListContentMainTitle
                    category={category} 
                    menuOnClick={handleMenuOnClick}
                />
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'start',
                    mt:'17px',
                    rowGap:'46px',
                    columnGap:'22px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        rowGap:'18px',
                        columnGap:'18px',
                        ml:'18px',
                        mr:'18px',
                    },
                }}>
                    {!handleSlotsData() ?
                    (screenMD ? Array(6).fill('') : Array(12).fill('')).map((_,index) => (
                        <TTGameItemSkeleton 
                            itemWidth={screenMD ? 'calc((100vw - 105px) / 2)' : '148px'}
                            key={index}
                        />
                    )) : 
                    <React.Fragment>
                        { handleSlotsData().data.length === 0 ?
                        <Box sx={{
                            borderRadius:'18px',
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'center',
                            alignItems:'center',
                            height:'578px',
                            gap:'30px',
                            width:'100%',
                            mt:'42px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                height:'303px',
                                gap:'18px',
                                borderRadius:'0px',
                                mt:'18px'
                            },
                        }}>
                            <CardMedia
                                component="img"
                                image={ logoIcon }
                                sx={{
                                    objectFit:'contain',
                                    width:'219px',
                                    height:'70px',
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        width:'185px',
                                        height:'58px',
                                    },
                                }}
                            />
                            <Typography sx={{
                                color:'#6D728D',
                                fontSize:'18px',
                                fontStyle:'normal',
                                fontWeight:400,
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    fontSize:'12px',
                                },
                            }}>
                                {textData.noData}
                            </Typography>
                        </Box> :
                        handleSlotsData().data.map((item,index) => (
                            <FavoriteContentItem 
                                image={item.game_image}
                                title={item.game_name}
                                gameUrl={''}
                                gameUid={item.game_uid}
                                isCloseFreePlayMode={true}
                                playerNum={888}
                                isFavorite={item.is_favorite}
                                favoriteNum={item.favorite_count}
                                category={handleTitle()}
                                comingSoon={textData.comingSoon}
                                fromPage={fromPage}
                                key={index}
                            />
                        ))
                        }
                    </React.Fragment>
                    }
                </Grid>
                {(!handleSlotsData() || handleSlotsData().data.length === 0) ? '' :
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        width:'100%',
                        gap:'54px',
                        mt:'48px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'20px',
                            mt:'20px',
                        },
                    }}>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            gap:'84px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                gap:'28px',
                            },
                        }}>
                            <Typography sx={{
                                color:'#FFFFFF',
                                fontSize:'18px',
                                fontStyle:'normal',
                                fontWeight:500,
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    fontSize:'16px',
                                },
                            }}>
                                {`${handleSlotsData().data.length > handleSlotsData().total_count ? handleSlotsData().total_count : handleSlotsData().data.length}/${handleSlotsData().total_count}`}
                            </Typography>
                            <BorderLinearProgress variant="determinate" value={progress} sx={{
                                width:'174px',
                                height:'16px',
                            }}/>
                            <Typography sx={{
                                color:'#FFFFFF',
                                fontSize:'18px',
                                fontStyle:'normal',
                                fontWeight:500,
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    fontSize:'16px',
                                },
                            }}>
                                {`${progress.toString().replace(/\.0$/, '')}%`}
                            </Typography>
                        </Box>
                        <Button variant='text' disableRipple={isLoading} onClick={handleLoadMoreClick} sx={{
                            bgcolor:'#3A3E53',
                            height:'48px',
                            borderRadius:'24px',
                            padding:'0px 30px',
                            justifyContent:"center",
                            alignItems:'center',
                            color:'#FFFFFF',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:500,
                            textTransform:'none',
                            minWidth:'210px',
                            '&:hover': {
                                background: '#3A3E53',
                            },
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'16px',
                                mb:'18px',
                            },
                        }}>
                            {
                                isLoading ? <CircularProgress sx={{ color:'#FFFFFF', }} size={30}/> :
                                <React.Fragment>
                                    {progress === 100 ? textData.noMoreData : textData.loadMore}
                                </React.Fragment>
                            }
                        </Button>
                    </Box>
                }
            </Container>
        </Grid>
    );
}

export default CasinoListContentMain;