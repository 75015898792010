import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const LaunchLoading = () => {
    return (
        // <Box sx={{
        //     width:'100vw',
        //     height:'100vh',
        //     display:'flex',
        //     justifyContent:'center',
        //     alignItems:'center',
        // }}>
        //     <Typography sx={{
        //         color:'#FFFFFF',
        //         fontSize:'20px',
        //         fontStyle:'normal',
        //         fontWeight:500,
        //     }}>
        //         Loading
        //     </Typography>
        // </Box>
        <Backdrop open={true} sx={{
            color:'#FFFFFF',
            zIndex: (theme) => theme.zIndex.modal + 1,
        }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default LaunchLoading;