import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled, alpha } from '@mui/material/styles';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import FormControl, { useFormControl } from '@mui/material/FormControl';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { menuClasses } from '@mui/material/Menu';
import { menuItemClasses } from '@mui/material/MenuItem';
import { popoverClasses } from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import TTLoading from '../common/TTLoading';
import TTAlert from '../common/TTAlert';
import HeaderWalletEnterPIN from './HeaderWalletEnterPIN';
import { walletAddBandCardService, walletFiatPreWithdrawService } from '../../network/service/wallet';
import { useDispatch, useSelector } from 'react-redux';
import { walletFiatWithdrawPreInfoRefresh } from '../../features/walletSlice';

const HeaderWalletWithdrawAddAccount = (props) => {
    const { 
        isOpen,
        closeCallback,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        add: t("add"),
        please_enter__name: t("please_enter__name"),
        please_enter__number: t("please_enter__number"),
        please_enter__account: t("please_enter__account"),
        confirm: t("confirm"),
    }

    const accountTypeArr = [
        'PIX-PHONE',
        'PIX-EMAIL',
        'PIX-CPF',
    ];

    const accountList = useSelector((state) => state.wallet.fiatWithdrawPreInfo.payInfoList);

    // 输入密码
    const [ openEnterPIN,setOpenEnterPIN ] = useState(false);
    const handleCloseEnterPINClick = () => {
        setOpenEnterPIN(false);
    };
    const handleConfirmPWDSuccessCallback = (password) => {
        setOpenEnterPIN(false);

        setOpenLoading(true);
        handleAddWithdrawAccount(password);
    };

    const handleCloseClick = () => {
        closeCallback && closeCallback();
    };

    // 名字
    const [ inputName,setInputName ] = useState('');
    const [ canEditName,setCanEditName ] = useState(true);
    const handleNameValueChanged = (e) => {
        setInputName(e.target.value);
    };
    // PIX账户
    const [ inputPIXAccount,setInputPIXAccount ] = useState('');
    const handlePIXAccountValueChanged = (e) => {
        setInputPIXAccount(e.target.value);
    };
    // 身份号
    const [ inputIDNumber,setInputIDNumber ] = useState('');
    const [ canEditIDNumber,setCanEditIDNumber ] = useState(true);
    const handleIDNumberValueChanged = (e) => {
        setInputIDNumber(e.target.value);
    };

    // 账户类型
    const [ isOpenAccountType,setIsOpenAccountType ] = useState(false);
    const [ chooseAccountType,setChooseAccountType ] = useState('');
    const handleOpenOrCloseAccountTypeClick = (event) => {
        setIsOpenAccountType(true);

        setAnchorMenuEl(event.currentTarget);
    };

    const handleIsDisableConfirm = () => {
        if(inputName.length <= 0 || inputIDNumber.length <= 0 || chooseAccountType.length <= 0) {
            return true;
        }
        if(chooseAccountType !== 'PIX-CPF' && inputPIXAccount.length <= 0) {
            return true;
        }
        return false;
    };
    const handleConfirmBtnClick = () => {
        setOpenEnterPIN(true);
    };

    // 选择账号类型
    const [ anchorMenuEl, setAnchorMenuEl ] = React.useState(null);
    const openMenu = Boolean(anchorMenuEl);
    const handleMenuClose = () => {
        setAnchorMenuEl(null);
        setIsOpenAccountType(false);
    };
    const handleMenuClick = (index) => {
        setChooseAccountType(accountTypeArr[index]);
        setAnchorMenuEl(null);
        setIsOpenAccountType(false);
    };

    const handleAddWithdrawAccount = async (newPassword) => {
        try {
            const param = {
                withdraw_password:newPassword,
                person_id:inputIDNumber,
                person_name:inputName,
                account_type:chooseAccountType.replace('PIX-', ''),
                account_id:inputPIXAccount,
            }
            await walletAddBandCardService(param);
            handleFetchWithdrawInfo();
        } catch (error) {
            setOpenLoading(false);
            
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };
    // 提现配置
    const handleFetchWithdrawInfo = async () => {
        try {
            const response = await walletFiatPreWithdrawService();
            setOpenLoading(false);
            dispatch(walletFiatWithdrawPreInfoRefresh(response.data));
            handleCloseClick();
        } catch (error) {
            setOpenLoading(false);
            
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };

    useEffect(() => {
        if(accountList !== null && accountList.length > 0) {
            setCanEditName(false);
            setCanEditIDNumber(false);
            setInputName(accountList[0].person_name);
            setInputIDNumber(accountList[0].person_id);
        }
    },[accountList]);

     //alert 
     const [ openLoading,setOpenLoading ] = useState(false);
     const [ type, setType ] = useState('info');
     const [ open, setOpen ] = useState(false);
     const [ alertMessage, setAlertMessage ] = useState('');
     const handleAlertClose = (event, reason) => {
         if (reason === 'clickaway') {
           return;
         }
         setOpen(false);
     };

    return (
        <React.Fragment>
            {openEnterPIN ? 
                <HeaderWalletEnterPIN 
                    isOpen={openEnterPIN}
                    closeCallback={handleCloseEnterPINClick}
                    confirmPWDSuccessCallback={handleConfirmPWDSuccessCallback}
                /> : ''
            }

            <Dialog
                open={isOpen}
                // onClose={handleCloseClick}
                sx={{
                    [`& .${dialogClasses.paper}`]: {
                        bgcolor:'transparent',
                        borderRadius:'18px',
                        maxWidth:'calc(100% - 36px)',
                        maxHeight:'calc(100% - 36px)',
                        margin:'0px',
                        padding:'0px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'8px',
                            maxWidth:'calc(100% - 28px)',
                            maxHeight:'calc(100% - 28px)',
                            width:'calc(100% - 28px)',
                        },
                    },
                }}
            >
                <DialogContent sx={{
                    bgcolor:'transparent',
                    borderRadius:'18px',
                    width:'500px',
                    maxWidth:'100%',
                    m:'0px',
                    p:'0px',
                    overflow:'auto',
                    scrollbarWidth: 'none', // Firefox
                    msOverflowStyle: 'none', // Internet Explorer
                    '&::-webkit-scrollbar': {
                        display: 'none', // Chrome, Safari, Edge
                    },
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'100%',
                        borderRadius:'8px',
                    },
                    transition: theme.transitions.create(['all'], {
                        duration: 300,
                        easing: 'ease-out',
                    }),
                }}>
                    <TTLoading 
                        open={openLoading}
                        isForce={true}
                    />
                    <TTAlert 
                        type={type}
                        isOpen={open}
                        message={alertMessage}
                        closeCallback={handleAlertClose}
                    />

                    <Box sx={{
                        bgcolor:'#1E2736',
                        borderRadius:'18px',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        padding:'18px',
                        width:'calc(100% - 36px)',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'8px',
                        },
                    }}>
                        <Grid container sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            width:'100%',
                        }}>
                            <Typography sx={{
                                color:'#ACB3D7',
                                fontSize:'18px',
                                fontWeight:700,
                            }}>
                                {`${textData.add} PIX`}
                            </Typography>
                            <IconButton sx={{
                                mt:'-8px',
                                mr:'-8px',
                            }} onClick={handleCloseClick}>
                                <CardMedia
                                    component='img'
                                    image={closeIcon}
                                    sx={{
                                        objectFit:'contain',
                                        width:'18px',
                                        height:'18px',
                                        '&:hover': {
                                            filter: 'brightness(0) invert(1)',
                                        },
                                    }}
                                />
                            </IconButton>
                        </Grid>
                        
                        <FormControl sx={{
                            width:'100%',
                            mt:'28px',
                        }}>
                            <HeaderWalletWithdrawAddAccountInput 
                                placeholder={textData.please_enter__name}
                                defaultText={inputName}
                                isAutoFocus={true}
                                isCanEdit={canEditName}
                                handleInputChanged={handleNameValueChanged}
                            />
                        </FormControl>
                        
                        <Button sx={{
                            bgcolor:'#000000',
                            borderRadius:'8px',
                            border:'1px solid',
                            borderColor:isOpenAccountType ? '#1184FA' : '#55657E',
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            height:'40px',
                            width:'100%',
                            padding:'0px 8px',
                            textTransform:'none',
                            color:'#55657E',
                            mt:'28px',
                            '&:hover': {
                                bgcolor:'#000000',
                            },
                        }} onClick={handleOpenOrCloseAccountTypeClick}>
                            <Box sx={{
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'center',
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                <path d="M12.836 11.6403C12.836 12.3678 12.6146 12.9435 12.1717 13.3674C11.7289 13.7912 11.1405 14 10.4004 14H2.43561C1.70176 14 1.11342 13.7912 0.664257 13.3674C0.221419 12.9435 0 12.3678 0 11.6403C0 11.3177 0.0126525 11.0014 0.0316313 10.6977C0.05061 10.3877 0.0948938 10.0587 0.158156 9.70447C0.221419 9.3502 0.30366 9.02124 0.398554 8.71758C0.493448 8.41392 0.626299 8.11658 0.790782 7.82558C0.955264 7.53457 1.14505 7.28784 1.35382 7.0854C1.56891 6.88296 1.82829 6.71848 2.13195 6.59828C2.44193 6.47808 2.77723 6.41482 3.15047 6.41482C3.20741 6.41482 3.33394 6.47808 3.53638 6.61094C3.73882 6.74379 3.96656 6.88929 4.21328 7.04745C4.46634 7.2056 4.7953 7.35743 5.20018 7.48396C5.60506 7.61681 6.00994 7.68007 6.41482 7.68007C6.8197 7.68007 7.22458 7.61681 7.62946 7.48396C8.03434 7.35111 8.36331 7.2056 8.61636 7.04745C8.86941 6.88929 9.09715 6.73746 9.29327 6.61094C9.49571 6.47808 9.62223 6.41482 9.67917 6.41482C10.0524 6.41482 10.3877 6.47808 10.6977 6.59828C11.0077 6.71848 11.2671 6.88296 11.4758 7.0854C11.6909 7.28784 11.8744 7.53457 12.0389 7.82558C12.2033 8.11658 12.3362 8.40759 12.4311 8.71758C12.526 9.02124 12.6082 9.3502 12.6715 9.70447C12.7347 10.0587 12.779 10.394 12.798 10.6977C12.817 11.0077 12.8296 11.3177 12.8296 11.6403H12.836ZM8.89471 1.02485C9.57795 1.70809 9.91957 2.53683 9.91957 3.49842C9.91957 4.46001 9.57795 5.28875 8.89471 5.97198C8.21148 6.65522 7.38274 6.99684 6.42115 6.99684C5.45956 6.99684 4.63082 6.65522 3.94758 5.97198C3.26435 5.28875 2.92273 4.46001 2.92273 3.49842C2.92273 2.53683 3.26435 1.70809 3.94758 1.02485C4.63082 0.341618 5.45956 0 6.42115 0C7.38274 0 8.21148 0.341618 8.89471 1.02485Z" fill="#8EA3C3"/>
                                </svg>
                                <Typography sx={{
                                    color:chooseAccountType.length === 0 ? '#55657E' : '#FFFFFF',
                                    fontSize:'14px',
                                    fontWeight:700,
                                    textTransform:'capitalize',
                                    ml:'10px',
                                }}>
                                    {chooseAccountType.length === 0 ? 
                                        'PIX CPF' : chooseAccountType
                                    }
                                </Typography>
                            </Box>
                            <Box sx={{
                                transform:isOpenAccountType ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition:'transform 0.3s ease-in-out',
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                <path d="M0.800001 0.800195L6.4 9.20019L12 0.800196" fill="#8EA3C3"/>
                                </svg>
                            </Box>
                        </Button>

                        {(chooseAccountType.length !== 0 && chooseAccountType !== 'PIX-CPF') ?
                            <FormControl sx={{
                                width:'100%',
                                mt:'28px',
                            }}>
                                <HeaderWalletWithdrawAddAccountInput 
                                    type={'pix'}
                                    placeholder={textData.please_enter__account}
                                    defaultText={inputPIXAccount}
                                    isAutoFocus={false}
                                    isCanEdit={true}
                                    handleInputChanged={handlePIXAccountValueChanged}
                                />
                            </FormControl> : ''
                        }
                        
                        <FormControl sx={{
                            width:'100%',
                            mt:'28px',
                        }}>
                            <HeaderWalletWithdrawAddAccountInput 
                                placeholder={textData.please_enter__number}
                                defaultText={inputIDNumber}
                                isAutoFocus={false}
                                isCanEdit={canEditIDNumber}
                                handleInputChanged={handleIDNumberValueChanged}
                            />
                        </FormControl>

                        <Box sx={{
                            bgcolor:'#2C394E',
                            width:'100%',
                            height:'2px',
                            mt:'28px',
                        }} />

                        <Button disabled={handleIsDisableConfirm()} sx={{
                            textTransform:'none',
                            textDecoration:'none',
                            bgcolor:'#1184FA',
                            color:'#FFFFFF',
                            fontSize:'18px',
                            fontWeight:700,
                            width:'100%',
                            height:'40px',
                            borderRadius:'8px',
                            margin:'28px 0px 0px 0px',
                            '&:hover': {
                                bgcolor:'#1184FA',
                            },
                            '&.Mui-disabled': {
                                bgcolor: alpha(
                                    '#1184FA',
                                    0.5,
                                ),
                            }
                        }} onClick={handleConfirmBtnClick}>
                            {textData.confirm}
                        </Button>
                    </Box>

                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorMenuEl}
                        open={openMenu}
                        onClose={handleMenuClose}
                    >
                        {accountTypeArr.map((item,index) => (
                            <MenuItem onClick={() => handleMenuClick(index)} disableRipple sx={{
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'center',
                                bgcolor:item === chooseAccountType ? '#1184FA' : 'transparent',
                                width:'100%',
                                height:'30px',
                                padding:'0px 8px',
                            }} key={index}>
                                {item}
                            </MenuItem>
                        ))}
                    </StyledMenu>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default HeaderWalletWithdrawAddAccount;

const CustomInput = styled(InputBase)(({ theme }) => ({
    position: 'relative',
    borderColor: 'transparent',
    width:'100%',
    height:'100%',
    fullWidth:true,
    backgroundColor: 'transparent',
    [`& .${inputBaseClasses.input}::placeholder`]: {
        color: '#55657E',
        opacity: 1,
    },
    [`& .${inputBaseClasses.input}`]: {
        caretColor:'#1CA45F',
        color:'#FFFFFF',
        fontSize:'14px',
        fontWeight:700,
    },
}));

const HeaderWalletWithdrawAddAccountInput = (props) => {
    const { type,placeholder,defaultText,isAutoFocus,isCanEdit,handleInputChanged } = props;
    const theme = useTheme();
    const { focused } = useFormControl() || {};

    const borderColor = React.useMemo(() => {
        return focused ? '#1184FA' : '#55657E';
    }, [focused]);

    return (
        <Box sx={{
            bgcolor:'#000000',
            borderRadius:'8px',
            border: '1px solid',
            borderColor:isCanEdit ? borderColor : '#55657E',
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center',
            width:'calc(100% - 16px)',
            height:'40px',
            padding:'0px 8px',
            gap:'8px',
        }}>
            {type === 'pix' ? 
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                    <path d="M11.8311 10.091C11.1886 10.1988 10.7231 9.8994 10.3 9.45547C9.71468 8.84117 9.09437 8.26044 8.51329 7.6419C8.10718 7.20963 7.35715 7.18347 6.92983 7.63766C6.29573 8.31099 5.62877 8.95321 4.977 9.61028C4.66243 9.92732 4.29661 10.062 3.8403 10.1086C3.10017 10.1843 2.55868 9.96126 2.10238 9.37488C1.75599 8.92917 1.2601 8.60965 0.953304 8.12154C0.411108 7.25876 0.533756 6.27227 1.20425 5.55653C1.71181 5.01434 2.24871 4.49971 2.77288 3.97342C2.81564 3.93065 2.85063 3.87552 2.92274 3.87516C3.72189 3.87057 4.50903 3.82108 5.11909 4.54177C5.64821 5.16632 6.28442 5.69862 6.84252 6.3002C7.31862 6.81341 8.07042 6.85264 8.5907 6.2903C9.19369 5.63818 9.84545 5.03166 10.4629 4.39297C10.7835 4.06143 11.1663 3.91722 11.6138 3.90556C11.8428 3.89955 12.0729 3.93666 12.3002 3.88753C12.3606 3.87446 12.397 3.89496 12.4377 3.93737C13.0071 4.53046 13.6302 5.07266 14.159 5.70357C14.8291 6.50308 14.7408 7.75147 14.0243 8.48913C13.5383 8.98962 13.0375 9.47526 12.5529 9.97681C12.3663 10.1698 12.1432 10.0627 11.9368 10.0913C11.9022 10.0963 11.8661 10.092 11.8308 10.092L11.8311 10.091Z" fill="#00D66F"/>
                    <path d="M11.7704 3.28313C10.9991 3.27005 10.418 3.54433 9.92357 4.06956C9.32835 4.70224 8.69709 5.30028 8.08031 5.91211C7.80215 6.1878 7.65158 6.1924 7.372 5.91706C6.82414 5.37663 6.2664 4.84468 5.74152 4.28234C5.13818 3.63552 4.46591 3.17639 3.53457 3.29267C3.51336 3.17674 3.59394 3.15023 3.63883 3.10534C4.43834 2.30477 5.24881 1.51446 6.03842 0.703989C6.93442 -0.2164 8.27471 -0.24503 9.19157 0.681368C10.0087 1.50739 10.8341 2.32563 11.6551 3.14776C11.6845 3.17745 11.7096 3.21138 11.7704 3.28313Z" fill="#00D66F"/>
                    <path d="M11.7651 10.7389C11.0801 11.4274 10.4488 12.0714 9.80587 12.704C9.42803 13.0755 9.10214 13.5085 8.62251 13.7655C7.90712 14.149 6.81531 14.0744 6.20419 13.4512C5.3195 12.5492 4.45177 11.6303 3.59677 10.7396C3.80213 10.7007 4.09655 10.7526 4.38603 10.6632C4.78084 10.5409 5.11556 10.3458 5.40327 10.0581C6.04443 9.41588 6.68382 8.77189 7.32428 8.12896C7.63108 7.8211 7.79614 7.82075 8.09587 8.12118C8.67093 8.69696 9.25837 9.26036 9.8193 9.84957C10.3368 10.3928 10.9224 10.7622 11.7654 10.7396L11.7651 10.7389Z" fill="#00D66F"/>
                </svg> : 
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                <path d="M17.2319 2.1546V11.8454C17.2319 12.4183 17.0066 12.9668 16.6002 13.3683C16.1987 13.7698 15.6502 14 15.0773 14H2.1546C1.58167 14 1.03323 13.7747 0.631689 13.3683C0.225254 12.9668 0 12.4183 0 11.8454V2.1546C0 0.964673 0.964673 0 2.1546 0H15.0773C15.6502 0 16.1987 0.225254 16.6002 0.631689C17.0066 1.03323 17.2319 1.58167 17.2319 2.1546ZM11.8454 3.77055C11.5467 3.77055 11.3068 4.01049 11.3068 4.3092C11.3068 4.6079 11.5467 4.84785 11.8454 4.84785H14.5387C14.8374 4.84785 15.0773 4.6079 15.0773 4.3092C15.0773 4.01049 14.8374 3.77055 14.5387 3.77055H11.8454ZM11.8454 6.4638C11.5467 6.4638 11.3068 6.70374 11.3068 7.00245C11.3068 7.30115 11.5467 7.5411 11.8454 7.5411H14.5387C14.8374 7.5411 15.0773 7.30115 15.0773 7.00245C15.0773 6.70374 14.8374 6.4638 14.5387 6.4638H11.8454ZM11.8454 9.15705C11.5467 9.15705 11.3068 9.39699 11.3068 9.6957C11.3068 9.9944 11.5467 10.2343 11.8454 10.2343H14.5387C14.8374 10.2343 15.0773 9.9944 15.0773 9.6957C15.0773 9.39699 14.8374 9.15705 14.5387 9.15705H11.8454ZM5.92515 3.2319C5.03393 3.2319 4.3092 3.95663 4.3092 4.84785V5.92515C4.3092 6.81637 5.03393 7.5411 5.92515 7.5411C6.81637 7.5411 7.5411 6.81637 7.5411 5.92515V4.84785C7.5411 3.95663 6.81637 3.2319 5.92515 3.2319ZM4.11333 7.91326C3.28576 8.27562 2.60021 8.87793 2.1595 9.62224V9.6957C2.1595 10.2882 2.63938 10.773 3.2368 10.773H8.6233C9.21581 10.773 9.70059 10.2931 9.70059 9.6957V9.62224C9.25988 8.87793 8.57433 8.27562 7.74676 7.91326C7.25219 8.36376 6.60581 8.6184 5.93494 8.6135C5.26408 8.6135 4.6177 8.36376 4.12312 7.90836L4.11333 7.91326Z" fill="#8EA3C3"/>
                </svg>
            }

            <CustomInput 
                readOnly={!isCanEdit}
                value={defaultText}
                placeholder={placeholder} 
                autoFocus={isAutoFocus}
                onChange={handleInputChanged}
            />
        </Box>
    );
};

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    [`& .${paperClasses.root}`]: {
      width:'461px',
      marginTop:'8px',
      padding:'8px 0px',
      color: '#FFFFFF',
      borderRadius: 8,
      backgroundColor:'#000000',
      border:'1px solid #55657E',
      [theme.breakpoints.down(MobileBreakpoint.match)]: {
        width:'calc(100vw - 28px - 36px)',
      },
      [`& .${menuClasses.list}`]: {
        padding: '0px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'start',
        alignItems:'start',
        gap:'5px',
      },
      [`& .${menuItemClasses.root}`]: {
        '&:active': {
          backgroundColor: alpha(
            '#1184FA',
            0.8,
          ),
        },
        '&:hover': {
            backgroundColor:'#1184FA',
        }
      },
    },
    [`& .${popoverClasses.paper}`]: {
        backgroundColor: '#000000',
    },
}));
