import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import PokerContentPoker from './PokerContentPoker';
import PokerContentFlagship from './PokerContentFlagship';
import PokerContentHallMode from './PokerContentHallMode';
import PokerContentClubMode from './PokerContentClubMode';
import PokerContentMTTMode from './PokerContentMTTMode';

const PokerContentMain = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        welcomeToOf: t("welcome_to___of"),
        weBringDedication: t("we_bring___dedication"),
        iphoneDownload: t("iphone_download"),
        androidDownload: t("android_download"),
        downloadTheMode: t("download_the___mode"),
        ourFlagshipPoker: t("our_flagship___poker"),
        theRevenueSource: t("the_revenue___source"),
        thereAreRake: t("there_are___rake"),
        theOddsInsurance: t("the_odds___insurance"),
        hallMode: t("hall_mode"),
        publicHallCasino: t("public_hall___casino"),
        inThisImmediately: t("in_this___immediately"),
        thisIsWant: t("this_is___want"),
        clubMode: t("club_mode"),
        clubModePlayers: t("club_mode___players"),
        inThisGames: t("in_this___games"),
        theInsuranceBeats: t("the_insurance___beats"),
        mttMode: t("mtt_mode"),
        inTournamentGame: t("in_tournament___game"),
        forExampleOrganizer: t("for_example___organizer"),
        theDistributionFinishers: t("the_distribution___finishers")
    };

    return (
        <Grid container sx={{
            padding:'82px 60px 145px 60px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'18px',
            },
        }}>
            <Container sx={{
                // bgcolor:'#bbbbbb',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <PokerContentPoker 
                    welcomeToOf={textData.welcomeToOf}
                    ttPoker={'YahooGrupo'}
                    weBringDedication={textData.weBringDedication}
                    iphoneDownload={textData.iphoneDownload}
                    androidDownload={textData.androidDownload}
                    downloadTheMode={textData.downloadTheMode}
                />
                <PokerContentFlagship 
                    ourFlagshipPoker={textData.ourFlagshipPoker}
                    theRevenueSource={textData.theRevenueSource}
                    thereAreRake={textData.thereAreRake}
                    theOddsInsurance={textData.theOddsInsurance}
                />
                <PokerContentHallMode 
                    hallMode={textData.hallMode}
                    publicHallCasino={textData.publicHallCasino}
                    inThisImmediately={textData.inThisImmediately}
                    thisIsWant={textData.thisIsWant}
                />
                <PokerContentClubMode 
                    clubMode={textData.clubMode}
                    clubModePlayers={textData.clubModePlayers}
                    inThisGames={textData.inThisGames}
                    theInsuranceBeats={textData.theInsuranceBeats}
                />
                <PokerContentMTTMode
                    mttMode={textData.mttMode}
                    inTournamentGame={textData.inTournamentGame}
                    forExampleOrganizer={textData.forExampleOrganizer}
                    theDistributionFinishers={textData.theDistributionFinishers}
                />
            </Container>
        </Grid>
    );
}

export default PokerContentMain;