import { createSlice } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';

const initialState = {
    symbol:'BRL', // 币种
    balance:'0.0000', // 现货余额
    funding_balance:'0.0000', // 资金余额
    totalBalance:'0.0000', // 总余额(现货+资金)
    recordList:[],

    // 法币
    fiatDepositPreOptionList:null,
    fiatDepositRecord: {
        total_count:0,
        data:null,
        total_deposit_amount:'0.0000',
    },
    fiatWithdrawPreInfo: {
        payInfoList:null,
        hasWithdrawPassword:null,
    },
    fiatWithdrawRecord: {
        total_count:0,
        data:null,
        total_withdraw_amount:'0.0000',
    }
}


const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        walletRefresh(state,action) {
            state.symbol = action.payload.symbol;
            state.balance = action.payload.balance;
            state.funding_balance = action.payload.funding_balance;
            state.totalBalance = BigNumber(action.payload.balance).plus(BigNumber(action.payload.funding_balance)).toFixed(4,1);
        },
        walletTransfer(state,action) {
            state.balance = BigNumber(state.balance).minus(BigNumber(action.payload.amount)).toFixed();
            state.funding_balance = BigNumber(state.funding_balance).plus(BigNumber(action.payload.amount)).toFixed();
            console.log('walletTransfer state:'+JSON.stringify(state));
        },
        walletWithdraw(state,action) {
            state.funding_balance = BigNumber(state.funding_balance).minus(BigNumber(action.payload.amount)).toFixed();
            console.log('walletWithdraw state:'+JSON.stringify(state));
        },
        walletLogout(state) {
            state.balance = '0.0000';
            state.funding_balance = '0.0000';
            state.totalBalance = '0.0000';
            console.log('walletLogout state:'+JSON.stringify(state));
        },
        walletRecordRefresh(state,action) {
            state.recordList.length = 0;
            state.recordList = [].concat(action.payload.recordList);
            console.log('walletRecordRefresh state:'+JSON.stringify(state));
        },
        walletGameBalanceChanged(state,action) {         
            // 判断输赢   
            if(BigNumber(action.payload.won).isGreaterThan(BigNumber('0'))) {
                state.balance = BigNumber(state.balance).plus(BigNumber(action.payload.won)).toFixed();
            }else {
                const lockedNum = BigNumber(state.balance).plus(BigNumber(action.payload.won)).toFixed();
                if(!BigNumber(lockedNum).isGreaterThan(BigNumber('0'))) {
                    state.balance = '0.0000';
                    const free = BigNumber(state.funding_balance).plus(BigNumber(lockedNum)).toFixed();
                    state.funding_balance = BigNumber(free).isGreaterThan(BigNumber('0')) ? free : '0.0000';
                }else {
                    state.balance = lockedNum.toString();
                }
            }
            state.totalBalance = BigNumber(state.balance).plus(BigNumber(state.funding_balance)).toFixed(4,1);
            console.log('walletGameBalanceChanged state:'+JSON.stringify(state));
        },

        // 法币
        walletFiatDepositPreOptionListRefresh(state,action) {
            state.fiatDepositPreOptionList = action.payload.optionList;
        },
        walletFiatDepositRecordListRefresh(state,action) {
            state.fiatDepositRecord = action.payload.record;
        },
        walletFiatWithdrawPreInfoRefresh(state,action) {
            state.fiatWithdrawPreInfo.payInfoList = action.payload.pay_info;
            state.fiatWithdrawPreInfo.hasWithdrawPassword = action.payload.has_withdraw_password;
        },
        walletFiatWithdrawHasPasswordRefresh(state,action) {
            state.fiatWithdrawPreInfo.hasWithdrawPassword = action.payload.hasWithdrawPassword;
        },
        walletFiatWithdrawRecordListRefresh(state,action) {
            state.fiatWithdrawRecord = action.payload.record;
        },
    }
});

export const { 
    walletRefresh,
    walletTransfer,
    walletWithdraw,
    walletLogout,
    walletRecordRefresh,
    walletGameBalanceChanged,
    walletFiatDepositPreOptionListRefresh,
    walletFiatDepositRecordListRefresh,
    walletFiatWithdrawPreInfoRefresh,
    walletFiatWithdrawHasPasswordRefresh,
    walletFiatWithdrawRecordListRefresh,
} = walletSlice.actions;

export default walletSlice.reducer;