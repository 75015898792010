import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CardMedia from '@mui/material/CardMedia';
import logoIcon from '../../assets/images/header/header-logo.png';

const DepositContentHeader = () => {
    
    return (
        <React.Fragment>
            <AppBar sx={{
                bgcolor:'#1E212A',
            }}>
                <Toolbar sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    height:'64px',
                }}>
                    <CardMedia
                        component="img"
                        image={logoIcon}
                        sx={{
                            width:'92px',
                            height:'28px',
                            objectFit:'contain',
                        }}
                    />
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

export default DepositContentHeader;