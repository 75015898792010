import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import homeSlotsFlagIcon from '../../assets/images/home/home-slots-flag.png';
import TTAlert from '../common/TTAlert';
import TTGameIcon from '../common/TTGameIcon';
import RoutePath from '../../tools/RoutePath';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const HomeContentMainSlotsItem = (props) => {
    const { slots,image,sourceImageName,title,gameUid,isCloseFreePlayMode,isFavorite,favoriteNum,comingSoon } = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // let resourceImage = null;
    // if(sourceImageName !== undefined && sourceImageName != null && sourceImageName.length > 0) {
    //     resourceImage = require(`../../assets/images/temp/slots/${sourceImageName}.png`);
    // }

    const handleItemClick = () => {
        // setAlertType('info');
        // setAlertMessage(comingSoon);
        // setOpenAlert(true);

        navigate(RoutePath.game,
            { 
                state: { 
                    category:slots,
                    fromPage:RoutePath.home,
                    gameIcon:image,
                    gameName:title,
                    gameUrl:'',
                    gameUid:gameUid,
                    isCloseFreePlayMode:isCloseFreePlayMode,
                    isFavorite:isFavorite,
                    favoriteNum:favoriteNum,
                }
            }
        );
    }

    // alert
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ alertType, setAlertType ] = useState('info');
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    return (
        <React.Fragment>
            <TTAlert 
                type={alertType}
                isOpen={openAlert}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />

            <Box sx={{
                display:'flex',
                flexDirection:'column',
                width:'148px',
                gap:'18px',
                cursor:'pointer',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'calc((100vw - 86px) / 4)',
                    gap:'5px',
                },
            }} onClick={handleItemClick}>
                <TTGameIcon
                    width='148px'
                    height='148px'
                    mobileSpace='86px'
                    column={4}
                    image={image}
                />
                <Box sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'flex-start',
                }}>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'16px',
                        fontStyle:'normal',
                        fontWeight:700,
                        // maxWidth:'130px',
                        width:'100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'8px',
                            // maxWidth:'70px',
                        },
                    }}>
                        {title}
                    </Typography>
                    {/* <CardMedia
                        component="img"
                        image={ homeSlotsFlagIcon }
                        sx={{
                            objectFit:'contain',
                            width:'16px',
                            height:'16px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'8px',
                                height:'8px',
                            },
                        }}
                    /> */}
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default HomeContentMainSlotsItem;