import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import FormControl from '@mui/material/FormControl';

const CustomInput = styled(InputBase)(({ theme }) => ({
    position: 'relative',
    borderColor: 'transparent',
    width:'100%',
    height:'100%',
    fullWidth:true,
    backgroundColor: 'transparent',
    [`& .${inputBaseClasses.input}::placeholder`]: {
        color: '#6D728D',
        opacity: 1,
    },
    [`& .${inputBaseClasses.input}:-webkit-autofill`]: {
        backgroundColor: 'transparent !important',
        color:'#FFFFFF !important',
        fontSize: '18px !important',
        transition: theme.transitions.create(['background-color','color','font-size'], {
            duration: 99999,
            easing: 'ease-in-out',
            delay: 99999,
        }),
    },
    [`& .${inputBaseClasses.input}`]: {
        caretColor:'#1CA45F',
        color:'#FFFFFF',
        textAlign:'center',
        fontSize:'18px',
        fontWeight:700,
    },
}));

const HeaderWalletSetWithdrawPINInput = (props) => {
    const {
        isSee,isNoAutoFocus,handleOnValueChanged,defaultValue,
    } = props;

    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const inputRef5 = useRef(null);
    const inputRef6 = useRef(null);

    const [ inputValue,setInputValue ] = useState(
        {
            first:'',
            second:'',
            third:'',
            four:'',
            five:'',
            six:'',
        }
    );

    const fullInputValue = () => {
        return `${inputValue.first}${inputValue.second}${inputValue.third}${inputValue.four}${inputValue.five}${inputValue.six}`;
    }

    useEffect(() => {
        if(defaultValue !== undefined && defaultValue !== null && defaultValue.length === 6) {
            setInputValue({
                first:defaultValue[0],
                second:defaultValue[1],
                third:defaultValue[2],
                four:defaultValue[3],
                five:defaultValue[4],
                six:defaultValue[5],
            });
        }
    }, [defaultValue]);
    useEffect(() => {
        if(!isNoAutoFocus) {
            inputRef1.current.focus();
        }
    }, []);
    useEffect(() => {
        // console.log(`first:${inputValue.first},second:${inputValue.second},third:${inputValue.third},four:${inputValue.four},five:${inputValue.five},six:${inputValue.six}`);
        handleOnValueChanged && handleOnValueChanged(fullInputValue());
    }, [inputValue]);
    useEffect(() => {
        if(isSee) {
            inputRef1.current.value = inputValue.first;
            inputRef2.current.value = inputValue.second;
            inputRef3.current.value = inputValue.third;
            inputRef4.current.value = inputValue.four;
            inputRef5.current.value = inputValue.five;
            inputRef6.current.value = inputValue.six;
        }else {
            inputRef1.current.value = inputValue.first.length > 0 ? '*' : '';
            inputRef2.current.value = inputValue.second.length > 0 ? '*' : '';
            inputRef3.current.value = inputValue.third.length > 0 ? '*' : '';
            inputRef4.current.value = inputValue.four.length > 0 ? '*' : '';
            inputRef5.current.value = inputValue.five.length > 0 ? '*' : '';
            inputRef6.current.value = inputValue.six.length > 0 ? '*' : '';
        }
    },[isSee,inputValue]);

    const handleKeyDown = (event,index) => {
        if (event.key.toLocaleLowerCase() === 'backspace') {
            if(index === 0 && inputRef1.current.value.length > 0) {
                setInputValue({
                    ...inputValue,
                    first: '',
                });
                return;
            }else if(index === 1 && inputRef2.current.value.length > 0) {
                setInputValue({
                    ...inputValue,
                    second: '',
                });
                return;
            }else if(index === 2 && inputRef3.current.value.length > 0) {
                setInputValue({
                    ...inputValue,
                    third: '',
                });
                return;
            }else if(index === 3 && inputRef4.current.value.length > 0) {
                setInputValue({
                    ...inputValue,
                    four: '',
                });
                return;
            }else if(index === 4 && inputRef5.current.value.length > 0) {
                setInputValue({
                    ...inputValue,
                    five: '',
                });
                return;
            }else if(index === 5 && inputRef6.current.value.length > 0) {
                setInputValue({
                    ...inputValue,
                    six: '',
                });
                return;
            }

            if(index === 1 && inputRef2.current.value.length === 0 && inputRef1.current.value.length > 0) {
                inputRef1.current.focus();
                inputRef1.current.value = '';
                setInputValue({
                    ...inputValue,
                    first: '',
                });
            }else if(index === 2 && inputRef3.current.value.length === 0) {
                if(inputRef2.current.value.length > 0) {
                    inputRef2.current.focus();
                    inputRef2.current.value = '';
                    setInputValue({
                        ...inputValue,
                        second: '',
                    });
                }else if(inputRef1.current.value.length > 0) {
                    inputRef1.current.focus();
                    inputRef1.current.value = '';
                    setInputValue({
                        ...inputValue,
                        first: '',
                    });
                }
            }else if(index === 3 && inputRef4.current.value.length === 0) {
                if(inputRef3.current.value.length > 0) {
                    inputRef3.current.focus();
                    inputRef3.current.value = '';
                    setInputValue({
                        ...inputValue,
                        third: '',
                    });
                }else if(inputRef2.current.value.length > 0) {
                    inputRef2.current.focus();
                    inputRef2.current.value = '';
                    setInputValue({
                        ...inputValue,
                        second: '',
                    });
                }else if(inputRef1.current.value.length > 0) {
                    inputRef1.current.focus();
                    inputRef1.current.value = '';
                    setInputValue({
                        ...inputValue,
                        first: '',
                    });
                }
            }else if(index === 4 && inputRef5.current.value.length === 0) {
                if(inputRef4.current.value.length > 0) {
                    inputRef4.current.focus();
                    inputRef4.current.value = '';
                    setInputValue({
                        ...inputValue,
                        four: '',
                    });
                }else if(inputRef3.current.value.length > 0) {
                    inputRef3.current.focus();
                    inputRef3.current.value = '';
                    setInputValue({
                        ...inputValue,
                        third: '',
                    });
                }else if(inputRef2.current.value.length > 0) {
                    inputRef2.current.focus();
                    inputRef2.current.value = '';
                    setInputValue({
                        ...inputValue,
                        second: '',
                    });
                }else if(inputRef1.current.value.length > 0) {
                    inputRef1.current.focus();
                    inputRef1.current.value = '';
                    setInputValue({
                        ...inputValue,
                        first: '',
                    });
                }
            }else if(index === 5 && inputRef6.current.value.length === 0) {
                if(inputRef5.current.value.length > 0) {
                    inputRef5.current.focus();
                    inputRef5.current.value = '';
                    setInputValue({
                        ...inputValue,
                        five: '',
                    });
                }else if(inputRef4.current.value.length > 0) {
                    inputRef4.current.focus();
                    inputRef4.current.value = '';
                    setInputValue({
                        ...inputValue,
                        four: '',
                    });
                }else if(inputRef3.current.value.length > 0) {
                    inputRef3.current.focus();
                    inputRef3.current.value = '';
                    setInputValue({
                        ...inputValue,
                        third: '',
                    });
                }else if(inputRef2.current.value.length > 0) {
                    inputRef2.current.focus();
                    inputRef2.current.value = '';
                    setInputValue({
                        ...inputValue,
                        second: '',
                    });
                }else if(inputRef1.current.value.length > 0) {
                    inputRef1.current.focus();
                    inputRef1.current.value = '';
                    setInputValue({
                        ...inputValue,
                        first: '',
                    });
                }
            }
        }else {
            const keyCode = event.which || event.keyCode;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
                event.preventDefault();
            }
        }
    };    
    const handleInputValueChanged = (e,index) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, '');
        if(filteredValue.length > 1) {
            filteredValue = filteredValue.slice(0, 1);
        }
        if(filteredValue.length > 0) {
            if(index === 0 && inputValue.second.length === 0) {
                inputRef2.current.focus();
            }else if(index === 1 && inputValue.third.length === 0) {
                inputRef3.current.focus();
            }else if(index === 2 && inputValue.four.length === 0) {
                inputRef4.current.focus();
            }else if(index === 3 && inputValue.five.length === 0) {
                inputRef5.current.focus();
            }else if(index === 4 && inputValue.six.length === 0) {
                inputRef6.current.focus();
            }
        }
        if(index === 0) {
            setInputValue({
                ...inputValue,
                first: filteredValue,
            });
        }else if(index === 1) {
            setInputValue({
                ...inputValue,
                second: filteredValue,
            });
        }else if(index === 2) {
            setInputValue({
                ...inputValue,
                third: filteredValue,
            });
        }else if(index === 3) {
            setInputValue({
                ...inputValue,
                four: filteredValue,
            });
        }else if(index === 4) {
            setInputValue({
                ...inputValue,
                five: filteredValue,
            });
        }else if(index === 5) {
            setInputValue({
                ...inputValue,
                six: filteredValue,
            });
        }
         
        e.target.value = isSee ? filteredValue : (filteredValue.length > 0 ? '*' : '');
    }

    return (
        <Box sx={{
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center',
            width:'100%',
            height:'100%',
            bgcolor:'#000000',
            borderRadius:'8px',
            border:'1px solid #55657E',
            overflow:'hidden',
        }}>
            <FormControl sx={{
                width:'calc((100% - 10px) / 6)',
                height:'100%',
                position:'relative',
            }}>
                <CustomInput 
                    inputRef={inputRef1} 
                    inputProps={{ 
                        maxLength: 1,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    onChange={(e) => handleInputValueChanged(e,0)} 
                    onKeyDown={(e) => handleKeyDown(e,0)}
                />
                {(!isSee && inputValue.first.length > 0) ? 
                    <Box sx={{
                        position:'absolute',
                        top:'calc((100% - 18px) / 2)',
                        left:'calc((100% - 18px) / 2)',
                        bgcolor:'#8EA3C3',
                        borderRadius:'18px',
                        width:'18px',
                        height:'18px',
                        pointerEvents: 'none',
                    }} /> : ''
                }
            </FormControl>
            <Box sx={{
                bgcolor:'#2C394E',
                width:'2px',
                height:'34px',
            }} />
            <FormControl sx={{
                width:'calc((100% - 10px) / 6)',
                height:'100%',
                position:'relative',
            }}>
                <CustomInput 
                    inputRef={inputRef2} 
                    inputProps={{ 
                        maxLength: 1,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    onChange={(e) => handleInputValueChanged(e,1)} 
                    onKeyDown={(e) => handleKeyDown(e,1)}
                />
                {(!isSee && inputValue.second.length > 0) ? 
                    <Box sx={{
                        position:'absolute',
                        top:'calc((100% - 18px) / 2)',
                        left:'calc((100% - 18px) / 2)',
                        bgcolor:'#8EA3C3',
                        borderRadius:'18px',
                        width:'18px',
                        height:'18px',
                        pointerEvents: 'none',
                    }} /> : ''
                }
            </FormControl>
            <Box sx={{
                bgcolor:'#2C394E',
                width:'2px',
                height:'34px',
            }} />
            <FormControl sx={{
                width:'calc((100% - 10px) / 6)',
                height:'100%',
                position:'relative',
            }}>
                <CustomInput 
                    inputRef={inputRef3} 
                    inputProps={{ 
                        maxLength: 1,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    onChange={(e) => handleInputValueChanged(e,2)} 
                    onKeyDown={(e) => handleKeyDown(e,2)}
                />
                {(!isSee && inputValue.third.length > 0) ? 
                    <Box sx={{
                        position:'absolute',
                        top:'calc((100% - 18px) / 2)',
                        left:'calc((100% - 18px) / 2)',
                        bgcolor:'#8EA3C3',
                        borderRadius:'18px',
                        width:'18px',
                        height:'18px',
                        pointerEvents: 'none',
                    }} /> : ''
                }
            </FormControl>
            <Box sx={{
                bgcolor:'#2C394E',
                width:'2px',
                height:'34px',
            }} />
            <FormControl sx={{
                width:'calc((100% - 10px) / 6)',
                height:'100%',
                position:'relative',
            }}>
                <CustomInput 
                    inputRef={inputRef4} 
                    inputProps={{ 
                        maxLength: 1,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    onChange={(e) => handleInputValueChanged(e,3)} 
                    onKeyDown={(e) => handleKeyDown(e,3)}
                />
                {(!isSee && inputValue.four.length > 0) ? 
                    <Box sx={{
                        position:'absolute',
                        top:'calc((100% - 18px) / 2)',
                        left:'calc((100% - 18px) / 2)',
                        bgcolor:'#8EA3C3',
                        borderRadius:'18px',
                        width:'18px',
                        height:'18px',
                        pointerEvents: 'none',
                    }} /> : ''
                }
            </FormControl>
            <Box sx={{
                bgcolor:'#2C394E',
                width:'2px',
                height:'34px',
            }} />
            <FormControl sx={{
                width:'calc((100% - 10px) / 6)',
                height:'100%',
                position:'relative',
            }}>
                <CustomInput 
                    inputRef={inputRef5} 
                    inputProps={{ 
                        maxLength: 1,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    onChange={(e) => handleInputValueChanged(e,4)} 
                    onKeyDown={(e) => handleKeyDown(e,4)}
                />
                {(!isSee && inputValue.five.length > 0) ? 
                    <Box sx={{
                        position:'absolute',
                        top:'calc((100% - 18px) / 2)',
                        left:'calc((100% - 18px) / 2)',
                        bgcolor:'#8EA3C3',
                        borderRadius:'18px',
                        width:'18px',
                        height:'18px',
                        pointerEvents: 'none',
                    }} /> : ''
                }
            </FormControl>
            <Box sx={{
                bgcolor:'#2C394E',
                width:'2px',
                height:'34px',
            }} />
            <FormControl sx={{
                width:'calc((100% - 10px) / 6)',
                height:'100%',
                position:'relative',
            }}>
                <CustomInput 
                    inputRef={inputRef6} 
                    inputProps={{ 
                        maxLength: 1,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    onChange={(e) => handleInputValueChanged(e,5)} 
                    onKeyDown={(e) => handleKeyDown(e,5)}
                />
                {(!isSee && inputValue.six.length > 0) ? 
                    <Box sx={{
                        position:'absolute',
                        top:'calc((100% - 18px) / 2)',
                        left:'calc((100% - 18px) / 2)',
                        bgcolor:'#8EA3C3',
                        borderRadius:'18px',
                        width:'18px',
                        height:'18px',
                        pointerEvents: 'none',
                    }} /> : ''
                }
            </FormControl>
        </Box>
    );
}

export default HeaderWalletSetWithdrawPINInput;
