import React from 'react';
import WithdrawContent from '../component/withdraw/WithdrawContent';

const Withdraw = () => {
    return (
        <React.Fragment>
            <WithdrawContent />
        </React.Fragment>
    )
}

export default Withdraw;