import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'react-router-dom';
import iphoneIcon from '../../assets/images/poker/poker-apple.webp';
import androidIcon from '../../assets/images/poker/poker-android.webp';
import pokerIcon from '../../assets/images/poker/poker-poker.webp';
import mobilePokerIcon from '../../assets/images/poker/poker-mobile-poker.webp';

const DownloadButton = (props) => {
    const { btnBgColor, btnIconImage, btnText, btnClick } = props;
    const theme = useTheme();

    const CustomButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        backgroundColor: btnBgColor,
        color: '#FFFFFF',
        borderRadius:'3px',
        display:'flex',
        alignItems:'center',
        height:'65px',
        minWidth:'210px',
        justifyContent:'center',
        padding:'0px 10px',
        gap:'10px',
        textDecoration:'none',
        '&:hover': {
          backgroundColor: btnBgColor,
        },
        [theme.breakpoints.down(MobileBreakpoint.match)]: {
            height:'48px',
            minWidth:'0px',
            width:'calc((100vw - 54px) / 2)',
            padding:'0px 10px',
            gap:'8px',
        },
    });

    return (
        <CustomButton variant='contained' onClick={btnClick}>
            <CardMedia
                component="img"
                image={btnIconImage}
                sx={{
                    width:'22px',
                    height:'24px',
                    objectFit:'contain',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'18px',
                        height:'19px',
                    },
                }}
            />
            <Typography sx={{
                fontSize:'14px',
                fontWeight:500,
                textDecoration:'none',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'11px',
                },
            }}>
                {btnText}
            </Typography>
        </CustomButton>
    );
}

const PokerContentPoker = (props) => {
    const {
        welcomeToOf,ttPoker,weBringDedication,iphoneDownload,androidDownload,downloadTheMode
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    const iosDownloadUrl = "https://app.tfdd.top/#/join/TTPoker";
    const androidDownloadUrl = "https://pks01.s3.sa-east-1.amazonaws.com/download/555784c1b9a74f37b9244c89b0bfe2a0.apk";

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            gap:'32px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                gap:'18px',
            },
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                width:'465px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'100%',
                },
            }}>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'40px',
                    fontStyle:'normal',
                    fontWeight:700,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'29px',
                    },
                }}>
                    {welcomeToOf}
                </Typography>
                <Typography sx={{
                    color:'#B22A28',
                    fontSize:'64px',
                    fontStyle:'normal',
                    fontWeight:700,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'47px',
                    },
                }}>
                    {ttPoker}
                </Typography>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'17px',
                    fontStyle:'normal',
                    fontWeight:500,
                    mt:'18px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                        mt:'13px',
                    },
                }}>
                    {weBringDedication}
                </Typography>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'16px',
                    mt:'22px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        gap:'18px',
                    },
                }}>
                    <Link to={iosDownloadUrl} target='_blank' style={{textDecoration:'none'}}>
                        <DownloadButton 
                            btnBgColor='#B22A28'
                            btnIconImage={iphoneIcon}
                            btnText={iphoneDownload}
                        />
                    </Link>
                    <Link to={androidDownloadUrl} style={{textDecoration:'none'}}>
                        <DownloadButton 
                            btnBgColor='#1CA45F'
                            btnIconImage={androidIcon}
                            btnText={androidDownload}
                        />
                    </Link>
                </Grid>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'14px',
                    fontStyle:'normal',
                    fontWeight:700,
                    mt:'22px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'10px',
                    },
                }}>
                    {downloadTheMode}
                </Typography>
            </Box>
            <CardMedia
                component="img"
                image={screenMD ? mobilePokerIcon : pokerIcon}
                sx={{
                    width:'500px',
                    height:'306px',
                    objectFit:'cover',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'100%',
                        height:'calc((100vw - 36px) * 225 / 335)',
                        borderRadius:'13px',
                    },
                }}
            />
        </Grid>
    );
}

export default PokerContentPoker;