import React, { useEffect, useRef, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styled from '@emotion/styled';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import BigNumber from 'bignumber.js';
import { filterDecimal } from '../../tools/CommonTool';
import seeIcon from '../../assets/images/login-and-register/login-and-register-password-see.png';
import hiddenIcon from '../../assets/images/login-and-register/login-and-register-password-hidden.png';
import HeaderWalletSetWithdrawPINInput from './HeaderWalletSetWithdrawPINInput';
import HeaderWalletWithdrawBalance from './HeaderWalletWithdrawBalance';
import TTLoading from '../common/TTLoading';
import TTAlert from '../common/TTAlert';
import { userWalletInfosService, walletFiatWithdrawService } from '../../network/service/wallet';
import { walletRefresh } from '../../features/walletSlice';
import { useDispatch } from 'react-redux';

const CustomInput = styled(InputBase)(({ theme }) => ({
    position: 'relative',
    borderColor: 'transparent',
    width:'100%',
    height:'100%',
    fullWidth:true,
    backgroundColor: 'transparent',
    [`& .${inputBaseClasses.input}::placeholder`]: {
        color: '#55657E',
        opacity: 1,
    },
    [`& .${inputBaseClasses.input}`]: {
        caretColor:'#1CA45F',
        color:'#FFFFFF',
        fontSize:'14px',
        fontWeight:700,
    },
}));

const HeaderWalletWithdrawWithdraw = (props) => {
    const {
        heightChanged,chooseAccountCallback,chooseAccount,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        the_remaining__is: t("the_remaining__is"),
        add_withdrawal_account: t("add_withdrawal_account"),
        amount: t("amount"),
        withdrawal_password: t("withdrawal_password"),
        confirm: t("confirm"),
        your_balance: t("your_balance"),
        withdraw_successfully: t("withdraw_successfully"), 
    }

    const [ currentAccount,setCurrentAccount ] = useState(chooseAccount);
    const [ amount,setAmount ] = useState('');
    const handleAddWithdrawAccountClick = () => {
        chooseAccountCallback && chooseAccountCallback();
    };
    const handleAmountValueChanged = (e) => {
        let number = 0;
        const decimalIndex = filterDecimal(e.target.value).indexOf('.');
        if (decimalIndex !== -1) {
            number = filterDecimal(e.target.value).length - decimalIndex - 1;
        }
        if(number > 4) {
            setAmount(BigNumber(filterDecimal(e.target.value)).toFixed(4,1));
        }else {
            setAmount(filterDecimal(e.target.value));
        }
    };

    // 输入密码
    const [ seePWD,setSeePWD ] = useState(false);
    const [ PINPWD,setPINPWD ] = useState('');
    const handlePWDValueChanged = (text) => {
        setPINPWD(text);
    };
    const handleSeeOrHiddenNewPWD = () => {
        setSeePWD(!seePWD);
    };

    const handleConfirmBtnClick = () => {
        setOpenLoading(true);
        handleWithdraw();
    };

    const handleWithdraw = async () => {
        try {
            const param = {
                withdraw_password:PINPWD,
                pay_id:currentAccount.pay_id,
                amount:BigNumber(amount).toNumber(),
                symbol:'BRL',
            }
            await walletFiatWithdrawService(param);
            setOpenLoading(false);

            setType('success');
            setAlertMessage(textData.withdraw_successfully);
            setOpen(true);

            handleUserWalletInfos();
        } catch (error) {
            setOpenLoading(false);
            
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };

    const handleUserWalletInfos = async () => {
        try {
            const response = await userWalletInfosService();
            if(response.data.data.length > 0) {
                for (let index = 0; index < response.data.data.length; index++) {
                    const element = response.data.data[index];
                    if(element.symbol.toUpperCase() === 'BRL') {
                        dispatch(walletRefresh(element))
                        break;
                    }
                }
            }
        } catch (error) {
            console.log('userWalletInfos error:'+error);
        }
    };

    useEffect(() => {
        setCurrentAccount(chooseAccount);
    }, [chooseAccount]);

    // 动态获取高度
    const boxRef = useRef(null);
    useEffect(() => {
        const boxHeight = boxRef.current.getBoundingClientRect().height;
        heightChanged && heightChanged(boxHeight);
    }, []);

    //alert 
    const [ openLoading,setOpenLoading ] = useState(false);
    const [ type, setType ] = useState('info');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <TTAlert 
                type={type}
                isOpen={open}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />

            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                width:screenMD ? '100%' : 'calc(100% - 198px)',
                height:'100%',
            }} ref={boxRef}>
                {screenMD ? 
                    <Typography sx={{
                        color:'#8EA3C3',
                        fontSize:'18px',
                        fontWeight:700,
                    }}>
                        {textData.your_balance}
                    </Typography> : ''
                }
                <HeaderWalletWithdrawBalance />
                <Box sx={{
                    bgcolor:'#2C394E',
                    borderRadius:'8px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    padding:'10px',
                    width:'calc(100% - 20px)',
                    height:'calc(100% - 20px)',
                    mt:'12px',
                }}>
                    {/* <Typography sx={{
                        color:'#8EA3C3',
                        fontSize:'14px',
                        fontWeight:700,
                        textTransform:'capitalize',
                    }}>
                        {`${textData.the_remaining__is} R$ ${totalBalance}`}
                    </Typography> */}
                    <Button sx={{
                        bgcolor:'#000000',
                        borderRadius:'8px',
                        border:'1px solid #55657E',
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        height:'40px',
                        width:'100%',
                        padding:'0px 18px',
                        textTransform:'none',
                        color:'#8EA3C3',
                        '&:hover': {
                            bgcolor:'#000000',
                        },
                    }} onClick={handleAddWithdrawAccountClick}>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'center',
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                            <path d="M15.3775 8.79518C17.9197 8.79518 19.9799 10.8554 19.9799 13.3976C19.9799 15.9398 17.9197 18 15.3775 18C12.8353 18 10.7751 15.9398 10.7751 13.3976C10.7751 10.8554 12.8353 8.79518 15.3775 8.79518ZM15.3775 10.6667C15.004 10.6667 14.6908 10.9398 14.6386 11.3092L14.6305 11.4096V12.6506H13.3896L13.2892 12.6586C12.9197 12.7108 12.6466 13.0241 12.6466 13.3976C12.6466 13.7711 12.9197 14.0843 13.2892 14.1365L13.3896 14.1446H14.6305V15.3855L14.6386 15.4859C14.6908 15.8554 15.004 16.1285 15.3775 16.1285C15.751 16.1285 16.0643 15.8554 16.1165 15.4859L16.1245 15.3855V14.1446H17.3655L17.4659 14.1365C17.8353 14.0843 18.1084 13.7711 18.1084 13.3976C18.1084 13.0241 17.8353 12.7108 17.4659 12.6586L17.3655 12.6506H16.1245V11.4096L16.1165 11.3092C16.0643 10.9398 15.751 10.6667 15.3775 10.6667ZM19.8434 6.94779V10.257C18.9116 8.44177 17.0482 7.29719 15.008 7.28916C12.9679 7.28112 11.0964 8.40964 10.1486 10.2169C9.20482 12.0241 9.34538 14.2088 10.5141 15.8755H2.9759C1.33333 15.8755 0 14.5422 0 12.8996V6.94779H19.8434ZM16.8675 0C18.51 0 19.8434 1.33333 19.8434 2.9759V4.95984H0V2.9759C0 1.33333 1.33333 0 2.9759 0H16.8675Z" fill="#8EA3C3"/>
                            </svg>
                            <Typography sx={{
                                color:(currentAccount !== undefined && currentAccount !== null) ? '#FFFFFF' : '#8EA3C3',
                                fontSize:'14px',
                                fontWeight:700,
                                textTransform:'capitalize',
                                ml:'8px',
                            }}>
                                {(currentAccount !== undefined && currentAccount !== null) ?
                                    <React.Fragment>
                                        {`PIX(****${currentAccount.account_id.slice(-4)})`}
                                        <span style={{color:'#8EA3C3',marginLeft:'8px'}}>{currentAccount.account_type}</span>
                                    </React.Fragment> : textData.add_withdrawal_account
                                }
                            </Typography>
                        </Box>
                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none">
                        <path d="M0.600001 11.6004L9 6.00039L0.6 0.400391" fill="#8EA3C3"/>
                        </svg>
                    </Button>
                    <Box sx={{
                        bgcolor:'#000000',
                        borderRadius:'8px',
                        border:'1px solid #55657E',
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        width:'calc(100% - 36px)',
                        height:'40px',
                        padding:'0px 18px',
                        mt:'18px',
                    }}>
                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'18px',
                            fontWeight:700,
                        }}>
                            {'R$'}
                        </Typography>
                        <FormControl sx={{
                            width:'100%',
                            height:'100%',
                            ml:'8px',
                        }}>
                            <CustomInput 
                                placeholder={textData.amount} 
                                value={amount}
                                onChange={handleAmountValueChanged}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{
                        bgcolor:'#1E2736',
                        width:'100%',
                        height:'2px',
                        mt:'18px',
                    }} />
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        mt:'8px',
                        width:'100%',
                    }}>
                        <Typography sx={{
                            color:'#8EA3C3',
                            fontSize:'14px',
                            fontWeight:700,
                        }}>
                            {textData.withdrawal_password}
                        </Typography>
                        <IconButton sx={{
                            padding:'0px',
                        }} onClick={handleSeeOrHiddenNewPWD}>
                            <CardMedia
                                component='img'
                                image={seePWD ? hiddenIcon : seeIcon}
                                sx={{
                                    objectFit:'contain',
                                    width:'30px',
                                    height:'30px',
                                    '&:hover': {
                                        filter: 'brightness(0) invert(1)',
                                    },
                                }}
                            />
                        </IconButton>
                    </Grid>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        width:'100%',
                        height:'58px',
                        mt:'2px'
                    }}>
                        <HeaderWalletSetWithdrawPINInput
                            isSee={seePWD}
                            isNoAutoFocus={true}
                            handleOnValueChanged={handlePWDValueChanged}
                        />
                    </Box>
                    <Button disabled={(currentAccount !== null && amount.length > 0 && PINPWD.length === 6) ? false : true} sx={{
                        textTransform:'none',
                        textDecoration:'none',
                        bgcolor:'#1184FA',
                        color:'#FFFFFF',
                        fontSize:'18px',
                        fontWeight:700,
                        width:'100%',
                        height:'40px',
                        borderRadius:'8px',
                        margin:'28px 0px',
                        '&:hover': {
                            bgcolor:'#1184FA',
                        },
                        '&.Mui-disabled': {
                            bgcolor: alpha(
                                '#1184FA',
                                0.5,
                            ),
                        }
                    }} onClick={handleConfirmBtnClick}>
                        {textData.confirm}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default HeaderWalletWithdrawWithdraw;