import React from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import usdtIcon from '../../assets/images/header/header-usdt.png';

const WithdrawContentToken = (props) => {
    const { token } = props;
    const theme = useTheme();

    return (
        <Box sx={{
            bgcolor:'#15151B',
            borderRadius:'18px',
            padding:'30px 0px',
            display:'flex',
            justifyContent:'start',
            alignItems:'center',
            gap:'25px',
            width:'100%',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'calc(100% - 36px)',
                borderRadius:'0px',
                padding:'8px 18px',
                gap:'18px',
            },
        }}>
            <CardMedia
                component="img"
                image={usdtIcon}
                sx={{
                    width:'36px',
                    height:'36px',
                    objectFit:'contain',
                    ml:'53px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'28px',
                        height:'28px',
                        ml:'0px',
                    },
                }}
            />
            <Typography sx={{
                color:'#FFFFFF',
                fontSize:'30px',
                fontStyle:'normal',
                fontWeight:500,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'18px',
                },
            }}>
                {token}
            </Typography>
        </Box>
    );
}

export default WithdrawContentToken;