import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import playIcon from '../../assets/images/game/game-play.png';
import favoriteIcon from '../../assets/images/game/game-favorite.png';
import questionIcon from '../../assets/images/game/game-question.png';
import fullIcon from '../../assets/images/game/game-full.png';
import RoutePath from '../../tools/RoutePath';
import { Link } from 'react-router-dom';
import TTSwitch from '../common/TTSwitch';
import { useSelector } from 'react-redux';
import AppConfig from '../../tools/AppConfig';

const GameContentGamePreviewPC = (props) => {
    const {
        category,gameIcon,gameName,isCloseFreePlayMode,resultGameUrl,isFavorite,favoriteNum,whilePlayingIn,profileSetting,freePlay,realPlay,funPlay,favorites,
        categoryOnClick,freePlayOnClick,realPlayOnclick,favoriteOnClick,questionOnClick,profileOnClick,showGame,
    } = props;

    let defaultChecked = showGame && resultGameUrl.includes("token") && (resultGameUrl.includes("is_try") && new URLSearchParams(resultGameUrl).get('is_try').toString() === '0');
    if(showGame && !resultGameUrl.includes(AppConfig.webSiteUrl)) {
        const searchParams = new URLSearchParams(resultGameUrl);
        const modeValue = searchParams.get('mode');
        if(modeValue === '1') {
            defaultChecked = true;
        }else {
            defaultChecked = false;
        }
    }
    const [ openRealPlay,setOpenRealPlay ] = useState(defaultChecked);
    if(defaultChecked !== openRealPlay) {
        setOpenRealPlay(defaultChecked);
    }

    const accessToken = useSelector((state) => state.user.access_token);

    const handleToggleFullscreen = () => {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          document.documentElement.requestFullscreen().catch( err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message}`);
            });
        }
    }
    const handleSwitchChanged = (event) => {
        setOpenRealPlay(event.target.checked);
        if(!event.target.checked) {
            freePlayOnClick && freePlayOnClick();
        }else {
            if(accessToken === undefined || accessToken.length <= 0) {
                setOpenRealPlay(false);
            }
            realPlayOnclick && realPlayOnclick();
        }
    };

    let gameIframeHeight = '880px';
    let gameIframeWidth = '500px';
    if(!resultGameUrl.includes(AppConfig.webSiteUrl)) {
        gameIframeHeight = '600px';
        gameIframeWidth = '100%';
    }

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            gap:'0px',
            width:'100%',
        }}>
            <Box sx={{
                display:'flex',
                justifyContent:'start',
                alignItems:'center',
            }}>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'24px',
                    fontStyle:'normal',
                    fontWeight:400,
                    cursor:'pointer',
                    '&:hover': {
                        color:'#6D728D',
                    }
                }} onClick={categoryOnClick}>
                    {`${category}>`}
                </Typography>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'24px',
                    fontStyle:'normal',
                    fontWeight:400,
                }}>
                    {gameName}
                </Typography>
            </Box>
            <Box sx={{
                width:'100%',
                height:showGame ? gameIframeHeight : '750px',
                overflow:'hidden',
                mt:'48px',
            }}>
                <Box sx={{
                    bgcolor:showGame ? '#000000' : 'transparent',
                    width:'100%',
                    height:'100%',
                    overflow:'hidden',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                }}>
                    <Box sx={{
                        display:showGame ? 'none' : 'block',
                        width:'100%',
                        height:'100%',
                    }}>
                        <CardMedia
                            component='img'
                            src={gameIcon}
                            sx={{
                                objectFit:'cover',
                                width:'100%',
                                height:'100%',
                                filter:'blur(20px)',
                            }}
                        />
                        <Box sx={{
                            bgcolor:'rgba(0,0,0,0.8)',
                            width:'100%',
                            height:'100%',
                            float:'left',
                            position:'relative',
                            // filter:'blur(8px)',
                            transform:'translateY(-750px)',
                        }}/>
                    </Box>
                    {showGame ? 
                        <CardMedia
                            component='iframe'
                            src={resultGameUrl}
                            sx={{
                                width:gameIframeWidth,
                                height:'100%',
                                border:'none',
                            }}
                        /> : ''
                    }
                </Box>

                <Box sx={{
                    width:'100%',
                    height:'100%',
                    float:'left',
                    position:'relative',
                    transform:'translateY(-750px)',
                    display:showGame ? 'none' : 'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'28px',
                }}>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'28px',
                        maxWidth:'496px',
                    }}>
                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'15px',
                            fontStyle:'normal',
                            fontWeight:500,
                            textAlign:'center',
                        }}>
                            {`${whilePlayingIn} `}
                            <span style={{color:'#1CA45F',textDecoration:'underline',cursor:'pointer'}} onClick={profileOnClick}>{profileSetting}</span>
                        </Typography>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            gap:'27px',
                        }}>
                            {(isCloseFreePlayMode !== undefined && isCloseFreePlayMode !== null && isCloseFreePlayMode === true) ? '' :
                                <Button sx={{
                                    bgcolor:'#3A3E53',
                                    borderRadius:'24px',
                                    textTransform:'none',
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    gap:'7px',
                                    padding:'0px 29px',
                                    color:'#FFFFFF',
                                    fontSize:'17px',
                                    fontStyle:'normal',
                                    fontWeight:500,
                                    height:'48px',
                                    '&:hover': {
                                        bgcolor:'#3A3E53',
                                    }
                                }} onClick={freePlayOnClick}>
                                    <CardMedia
                                        component='img'
                                        src={playIcon}
                                        sx={{
                                            objectFit:'contain',
                                            width:'24px',
                                            height:'24px',
                                        }}
                                    />
                                    {freePlay}
                                </Button>
                            }
                            <Button sx={{
                                bgcolor:'#1CA45F',
                                borderRadius:'24px',
                                textTransform:'none',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                gap:'7px',
                                padding:'0px 29px',
                                color:'#FFFFFF',
                                fontSize:'17px',
                                fontStyle:'normal',
                                fontWeight:500,
                                height:'48px',
                                '&:hover': {
                                    bgcolor:'#1CA45F',
                                }
                            }} onClick={realPlayOnclick}>
                                <CardMedia
                                    component='img'
                                    src={playIcon}
                                    sx={{
                                        objectFit:'contain',
                                        width:'24px',
                                        height:'24px',
                                    }}
                                />
                                {realPlay}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Grid container sx={{
                bgcolor:'#15151B',
                borderRadius:'0px 0px 18px 18px',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'flex-start',
                gap:'20px',
                padding:'18px 51px',
            }}>
                {resultGameUrl.includes(AppConfig.webSiteUrl) ? 
                    <React.Fragment>
                        {(isCloseFreePlayMode !== undefined && isCloseFreePlayMode !== null && isCloseFreePlayMode === true) ? 
                            <Box /> :
                            <Box sx={{
                                display:'flex',
                                justifyContent:'end',
                                alignItems:'center',
                                gap:'0px',
                            }}>
                                <Typography sx={{
                                    color:'#ACB3D7',
                                    fontSize:'20px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                    mr:'8px',
                                }}>
                                    {funPlay}
                                </Typography>
                                <TTSwitch 
                                    iOSStyle={true}
                                    value={openRealPlay}
                                    onChangeClick={handleSwitchChanged}
                                />
                                <Typography sx={{
                                    color:'#ACB3D7',
                                    fontSize:'20px',
                                    fontStyle:'normal',
                                    fontWeight:400,
                                    ml:'18px',
                                }}>
                                    {realPlay}
                                </Typography>
                                {/* <IconButton sx={{
                                    width:'38px',
                                    height:'38px',
                                }} onClick={questionOnClick}>
                                    <CardMedia
                                        component='img'
                                        src={questionIcon}
                                        sx={{
                                            objectFit:'contain',
                                            width:'22px',
                                            height:'22px',
                                        }}
                                    />
                                </IconButton> */}
                                
                            </Box>
                        }
                        <IconButton sx={{
                            width:'22px',
                            height:'22',
                            ml:'10px',
                        }} onClick={handleToggleFullscreen}>
                            <CardMedia
                                component='img'
                                src={fullIcon}
                                sx={{
                                    objectFit:'contain',
                                    width:'20px',
                                    height:'20px',
                                }}
                            />
                        </IconButton>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Button sx={{
                            bgcolor:'#1E212A',
                            borderRadius:'100px',
                            textTransform:'none',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            gap:'18px',
                            padding:'8px 18px',
                            color:'#ACB3D7',
                            fontSize:'20px',
                            fontStyle:'normal',
                            fontWeight:400,
                            '&:hover': {
                                bgcolor:'#1E212A',
                            }
                        }} onClick={favoriteOnClick}>
                            {isFavorite ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M19.8338 8.81219H13.9751C16.2454 0.429182 12.4133 0 12.4133 0C10.7879 0 11.1257 1.28501 11.0013 1.49833C11.0013 5.59714 6.64597 8.80965 6.64597 8.80965V20.4356C6.64597 21.5835 8.21032 21.9975 8.82235 21.9975H17.6244C18.4523 21.9975 19.1278 19.8262 19.1278 19.8262C21.3042 12.4234 21.3042 10.2191 21.3042 10.2191C21.3042 8.69029 19.8312 8.81219 19.8312 8.81219H19.8338ZM4.24356 8.81727H0.736465C0.0126977 8.81727 0 9.52834 0 9.52834L0.723768 21.2534C0.723768 22 1.47039 22 1.47039 22H4.50514C5.13748 22 5.1324 21.5073 5.1324 21.5073V9.70865C5.1324 8.80711 4.24103 8.81981 4.24103 8.81981L4.24356 8.81727Z" fill="url(#paint0_linear_7418_10308)"/>
                            <defs>
                            <linearGradient id="paint0_linear_7418_10308" x1="7.93642e-08" y1="11" x2="21.3042" y2="11" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#DE9948"/>
                            <stop offset="1" stopColor="#FDD971"/>
                            </linearGradient>
                            </defs>
                            </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M19.8338 8.81219H13.9751C16.2454 0.429182 12.4133 0 12.4133 0C10.7879 0 11.1257 1.28501 11.0013 1.49833C11.0013 5.59714 6.64597 8.80965 6.64597 8.80965V20.4356C6.64597 21.5835 8.21032 21.9975 8.82235 21.9975H17.6244C18.4523 21.9975 19.1278 19.8262 19.1278 19.8262C21.3042 12.4234 21.3042 10.2191 21.3042 10.2191C21.3042 8.69029 19.8312 8.81219 19.8312 8.81219H19.8338ZM4.24356 8.81727H0.736465C0.0126977 8.81727 0 9.52834 0 9.52834L0.723768 21.2534C0.723768 22 1.47039 22 1.47039 22H4.50514C5.13748 22 5.1324 21.5073 5.1324 21.5073V9.70865C5.1324 8.80711 4.24103 8.81981 4.24103 8.81981L4.24356 8.81727Z" fill={isFavorite ? "red" : "#6D728D"}/>
                            </svg>
                            }
                            {`${favoriteNum} ${favorites}`}
                        </Button>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'end',
                            alignItems:'center',
                            gap:'0px',
                        }}>
                            {!(isCloseFreePlayMode !== undefined && isCloseFreePlayMode !== null && isCloseFreePlayMode === true) ? 
                                <Box sx={{
                                    display:'flex',
                                    justifyContent:'flex-start',
                                    alignItems:'center',
                                }}>
                                    <Typography sx={{
                                        color:'#ACB3D7',
                                        fontSize:'20px',
                                        fontStyle:'normal',
                                        fontWeight:400,
                                        mr:'8px',
                                    }}>
                                        {funPlay}
                                    </Typography>
                                    <TTSwitch 
                                        iOSStyle={true}
                                        value={openRealPlay}
                                        onChangeClick={handleSwitchChanged}
                                    />
                                    <Typography sx={{
                                        color:'#ACB3D7',
                                        fontSize:'20px',
                                        fontStyle:'normal',
                                        fontWeight:400,
                                        ml:'18px',
                                    }}>
                                        {realPlay}
                                    </Typography>
                                </Box> : ''
                            }
                            {/* <IconButton sx={{
                                width:'38px',
                                height:'38px',
                            }} onClick={questionOnClick}>
                                <CardMedia
                                    component='img'
                                    src={questionIcon}
                                    sx={{
                                        objectFit:'contain',
                                        width:'22px',
                                        height:'22px',
                                    }}
                                />
                            </IconButton> */}
                            <IconButton sx={{
                                width:'22px',
                                height:'22',
                                ml:'10px',
                            }} onClick={handleToggleFullscreen}>
                                <CardMedia
                                    component='img'
                                    src={fullIcon}
                                    sx={{
                                        objectFit:'contain',
                                        width:'20px',
                                        height:'20px',
                                    }}
                                />
                            </IconButton>
                        </Box>
                    </React.Fragment>
                }
            </Grid>
        </Box>
    );
}

export default GameContentGamePreviewPC;