import Cookies from 'js-cookie';

// 将时间戳转为指定格式的时间(yyyy-MM-dd HH:mm:ss)
export const timestampToDateTime = (timestamp,format) => {
  let time = timestamp;
  if(timestamp > 10) {
    time = timestamp * 1000;
  }
  const date = new Date(time);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return format
    .replace(/yyyy/g, year)
    .replace(/MM/g, month)
    .replace(/dd/g, day)
    .replace(/HH/g, hours)
    .replace(/mm/g, minutes)
    .replace(/ss/g, seconds);
}
// 判断是否是今天
export const isToday = (timestamp) => {
  let time = timestamp;
  if(timestamp > 10) {
    time = timestamp * 1000;
  }

  const today = new Date();
  const date = new Date(time);

  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  );
}

export const filterDecimal = (inputString) => {
  const decimalPattern = /(\d*\.\d*|\d+)/;
  
  const decimalMatch = inputString.match(decimalPattern);
  
  if (decimalMatch) {
    return decimalMatch[0];
  } else {
    return '';
  }
}
// export const multiplyDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     const result = decimal1 * decimal2;
//     return result;
//   } else {
//     return 0; 
//   }
// }
// export const subtractionDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     const result = decimal1 - decimal2;
//     return result;
//   } else {
//     return 0; 
//   }
// }
// export const addDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     const result = decimal1 + decimal2;
//     return result;
//   } else {
//     return 0; 
//   }
// }
// export const compareDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     if (decimal1 < decimal2) {
//       return -1;
//     } else if (decimal1 > decimal2) {
//       return 1;
//     } else {
//       return 0
//     }
//   } else {
//     return -2;
//   }
// }
// export const isEqualDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     if (decimal1 = decimal2) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }
// export const isGreaterThanDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     if (decimal1 > decimal2) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }
// export const isLessThanDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     if (decimal1 < decimal2) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }

export const getCookieAccessToken = () => {
  return (Cookies.get('accessToken') !== undefined && Cookies.get('accessToken').length > 0) ? Cookies.get('accessToken') : '';
};
export const getCookieUserId = () => {
  return (Cookies.get('userId') !== undefined && Cookies.get('userId').length > 0) ? Cookies.get('userId') : ''; 
};
export const getCookieUsername = () => {
  return (Cookies.get('username') !== undefined && Cookies.get('username').length > 0) ? Cookies.get('username') : ''; 
};
export const getCookieEmail = () => {
  return (Cookies.get('email') !== undefined && Cookies.get('email').length > 0) ? Cookies.get('email') : ''; 
};
export const setCookieAccessToken = (token) => {
  Cookies.set('accessToken',token);
};
export const setCookieUserId = (userId) => {
  Cookies.set('userId',userId);
}
export const setCookieUsername = (username) => {
  Cookies.set('username',username);
}
export const setCookieEmail = (email) => {
  Cookies.set('email',email);
}
export const clearUserCookies = () => {
  Cookies.remove('accessToken');
  Cookies.remove('userId');
  Cookies.remove('username');
};