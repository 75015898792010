import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WalletContentMainLeftMenu from './WalletContentMainLeftMenu';
import WalletContentMainLeftDeposit from './deposit/WalletContentMainLeftDeposit';
import WalletContentMainLeftWithdraw from './withdraw/WalletContentMainLeftWithdraw';

const WalletContentMainLeft = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        myWallet: t("my_wallet"),
        deposit: t("deposit"),
        withdraw: t("withdraw"),
        yourBalance: t("your_balance"),
        transaction: t("transaction"),
        network: t("network"),
        next: t("next"),
        recordOf: t("record_of"),
        orderNo: t("order_no"),
        type: t("type"),
        amount: t("amount"),
        status: t("status"),
        time: t("time"),
        token: t("token"),
        noData: t("no_data"),
        depositAddress: t("deposit_address"),
        yourCanLeakage: t("your_can___leakage"),
        withdrawAddress: t("withdraw_address"),
        withdrawAmount: t("withdraw_amount"),
        withdrawableAmount: t("withdrawable_amount"),
        amountReceived: t("amount_received"),
        minimumAmount: t("minimum_amount"),
        fee: t("fee"),
        address: t("address"),
        paste: t("paste"),
        gasFeeRequires: t("gas_fee_requires"),
        withdrawalAmountsFrom: t("withdrawal_amounts_from"),
        theGasAmount: t("the_gas____amount"),
        estimatedArrival: t("estimated_arrival"),
        confirm: t("confirm"),
        insufficientBalance: t("insufficient_balance"),
        pleaseEnterAddress: t("please_enter___address"),
        prepare: t("prepare"),
        inProgress: t("in_progress"),
        success: t("success"),
        failed: t("failed"),
        theAmountThan: t("the_amount__than"),
        otherwiseTheReceived: t("otherwise_the__received"),
    };

    const [ menuIndex,setMenuIndex ] = React.useState(0);

    const handleMenuClick = (index) => {
        if(index === menuIndex) {
            return;
        }
        setMenuIndex(index);
    };

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            width:'649px',
        }}>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'24px',
                fontStyle:'normal',
                fontWeight:400,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    display:'none',
                },
            }}>
                {textData.myWallet}
            </Typography>
            <WalletContentMainLeftMenu 
                deposit={textData.deposit}
                withdraw={textData.withdraw}
                menuIndex={menuIndex}
                menuOnClick={handleMenuClick}
            />
            <WalletContentMainLeftDeposit 
                yourBalance={textData.yourBalance}
                transaction={textData.transaction}
                network={textData.network}
                next={textData.next}
                recordOf={textData.recordOf}
                orderNo={textData.orderNo}
                type={textData.type}
                amount={textData.amount}
                status={textData.status}
                time={textData.time}
                deposit={textData.deposit}
                prepare={textData.prepare}
                inProgress={textData.inProgress}
                success={textData.success}
                failed={`${textData.failed.charAt(0).toUpperCase()}${textData.failed.slice(1)}`}
                noData={textData.noData}
                theAmountThan={textData.theAmountThan}
                isShow={menuIndex === 0 ? true : false}
            />
            <WalletContentMainLeftWithdraw 
                yourBalance={textData.yourBalance}
                transaction={textData.transaction}
                network={textData.network}
                next={textData.next}
                recordOf={textData.recordOf}
                orderNo={textData.orderNo}
                type={textData.type}
                amount={textData.amount}
                status={textData.status}
                time={textData.time}
                withdraw={textData.withdraw}
                prepare={textData.prepare}
                inProgress={textData.inProgress}
                success={textData.success}
                failed={`${textData.failed.charAt(0).toUpperCase()}${textData.failed.slice(1)}`}
                noData={textData.noData}
                withdrawAddress={textData.withdrawAddress}
                withdrawAmount={textData.withdrawAmount}
                withdrawableAmount={textData.withdrawableAmount}
                amountReceived={textData.amountReceived}
                minimumAmount={textData.minimumAmount}
                fee={textData.fee}
                withdrawalAmountsFrom={textData.withdrawalAmountsFrom}
                insufficientBalance={textData.insufficientBalance}
                pleaseEnterAddress={textData.pleaseEnterAddress}
                isShow={menuIndex === 1 ? true : false}
            />
        </Box>
    );
}

export default WalletContentMainLeft;