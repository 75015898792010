import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileBreakpoint from '../tools/MobileBreakpoint';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import HelpCenterContent from '../component/help-center/HelpCenterContent';
import { useLocation } from 'react-router-dom';
import TTAppBar from '../component/common/TTAppBar';
import { useTranslation } from "react-i18next";

const HelpCenter = () => {
    const location = useLocation();
    const categoryIndex = location.state && location.state.categoryIndex;

    const { t } = useTranslation();
    const textData = {
        helpCenter: t("help_center"),
    };

    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
        });
    },[categoryIndex]);

    if(screenMD) {
        return (
            <React.Fragment>
                <Customer />
                <TTAppBar  
                    style={1} 
                    title={textData.helpCenter}
                />
                <HelpCenterContent screenMD={true} />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.helpCenter} drawerIndex={-1} />
            <HelpCenterContent index={categoryIndex} />
        </React.Fragment>
    )
}

export default HelpCenter;