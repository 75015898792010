import React from 'react';
import LoginAndRegisterContent from '../component/login-and-register/LoginAndRegisterContent';
import { useLocation } from 'react-router-dom';
import Customer from '../component/common/Customer';

const LoginAndRegister = () => {
    const location = useLocation();
    const type = location.state && location.state.type;
    const fromPage = location.state && location.state.fromPage;
    const isLogout = location.state && location.state.isLogout;

    return (
        <React.Fragment>
            <Customer />
            <LoginAndRegisterContent 
                type={type}
                fromPage={fromPage}
                isLogout={isLogout}
            />
        </React.Fragment>
    )
}

export default LoginAndRegister;