import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import usdtIcon from '../../assets/images/header/header-usdt.png';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { walletRefresh } from '../../features/walletSlice';
import { userWalletInfosService } from '../../network/service/wallet';

const HeaderWalletWithdrawBalance = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();

    const totalBalance = useSelector((state) => state.wallet.totalBalance);

    const [ isRefreshingBalance,setIsRefreshingBalance ] = useState(false);

    const handleRefreshBalanceClick = () => {
        if(!isRefreshingBalance) {
            handleUserWalletInfos();
        }
    };

    const handleUserWalletInfos = async () => {
        try {
            setIsRefreshingBalance(true);
            const response = await userWalletInfosService();
            setIsRefreshingBalance(false);
            if(response.data.data.length > 0) {
                for (let index = 0; index < response.data.data.length; index++) {
                    const element = response.data.data[index];
                    if(element.symbol.toUpperCase() === 'BRL') {
                        dispatch(walletRefresh(element))
                        break;
                    }
                }
            }
        } catch (error) {
            setIsRefreshingBalance(false);
            console.log('userWalletInfos error:'+error);
        }
    };

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:screenMD ? 'space-between' : 'flex-start',
            alignItems:'center',
        }}>
            <Box sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
            }}>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'18px',
                    fontWeight:700,
                }}>
                    {totalBalance}
                </Typography>
                <CardMedia
                    component="img"
                    image={ usdtIcon }
                    sx={{
                        objectFit:'contain',
                        width:'18px',
                        height:'18px',
                        ml:'6px',
                    }}
                />
            </Box>
            <IconButton sx={{
                padding:'0px',
                ml:screenMD ? '0px' : '10px',
                transform:isRefreshingBalance ? 'rotate(360deg)' : 'rotate(0deg)',
                transition:isRefreshingBalance ? 'transform 1s ease-in-out' : 'none',
            }} onClick={handleRefreshBalanceClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                <path d="M3.88321 19.4763L8.87056 16.7594L11.1538 19.2403C13.1946 19.2403 15.159 18.4623 16.6641 17.0847C19.432 14.5336 20.1336 10.4646 18.5519 7.24391C18.2904 6.72094 18.4371 6.08317 18.8708 5.68775C19.5277 5.08187 20.6055 5.24769 21.0009 6.0449C23.1119 10.3371 22.2191 15.7135 18.5264 19.1128C14.4319 22.882 8.0606 22.9139 3.88321 19.4827V19.4763ZM3.59621 2.88157C7.68431 -0.881272 14.0556 -0.91316 18.2394 2.51804L13.252 5.23493L10.9688 2.75401C8.92796 2.75401 6.96363 3.53209 5.4585 4.90967C2.69058 7.46075 1.98904 11.5297 3.5707 14.7504C3.81943 15.2862 3.69188 15.9176 3.25182 16.313C2.59492 16.9189 1.51709 16.753 1.12167 15.9558C-0.989346 11.6636 -0.0964692 6.28088 3.59621 2.88157Z" fill="#43A0FF"/>
                </svg>
            </IconButton>
        </Grid>
    );
}

export default HeaderWalletWithdrawBalance;