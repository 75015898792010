import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CardMedia from '@mui/material/CardMedia';
import arrowLeftIcon from '../../assets/images/header/header-arrow-left.png';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';

const TTAppBar = (props) => {
    // style: 1左侧返回  2:右侧按钮 3:左侧文本 右侧返回 4: 左侧文本 右侧关闭
    const { style,title,rightTitle,rightOnClick,rightBackTitle } = props;

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <AppBar sx={{
            bgcolor:'#15151B',
        }}>
            <Toolbar sx={{
                    display:'flex',
                    padding:'0px',
                    justifyContent:style === 1 ? 'flex-start' : 'space-between',
                    alignItems:'center',
                    height:'68px',
                }}>
                    <Button disableRipple sx={{
                        bgcolor:'transparent',
                        textTransform:'none',
                        color:'#ACB3D7',
                        fontSize:'15px',
                        fontStyle:'normal',
                        fontWeight:400,
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:'18px',
                        height:'100%',
                        pl:'18px',
                        '&:hover': {
                            bgcolor:'transparent',
                        },
                        '&:disabled': {
                            color:'#ACB3D7',
                        },
                    }} onClick={handleBackClick} disabled={(style === 3 || style === 4)  ? true : false}>
                        <CardMedia
                            component="img"
                            image={arrowLeftIcon}
                            sx={{
                                width:'8px',
                                height:'16px',
                                objectFit:'contain',
                                display:(style === 3 || style === 4) ? 'none' : 'block',
                            }}
                        />
                        {title}
                    </Button>
                    <Button variant='text' sx={{
                        bgcolor:'#3A3E53',
                        borderRadius:'14px',
                        height:'28px',
                        mr:'18px',
                        padding:'0px 18px',
                        color:'#ACB3D7',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400,
                        textTransform:'none',
                        display:style === 2 ? 'flex' : 'none',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        '&:hover': {
                            bgcolor:'#3A3E53',
                        }
                    }} onClick={rightOnClick}>
                        {rightTitle}
                    </Button>
                    <Button sx={{
                        bgcolor:'#3A3E53',
                        borderRadius:'14px',
                        height:'28px',
                        textTransform:'none',
                        color:'#ACB3D7',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400,
                        display:style === 3 ? 'flex' : 'none',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:'13px',
                        padding:'0px 18px',
                        mr:'18px',
                        '&:hover': {
                            bgcolor:'#3A3E53',
                        }
                    }} onClick={handleBackClick} disabled={style === 3 ? false : true}>
                        <CardMedia
                            component="img"
                            image={arrowLeftIcon}
                            sx={{
                                width:'5px',
                                height:'12px',
                                objectFit:'contain',
                            }}
                        />
                        {rightBackTitle}
                    </Button>
                    <IconButton onClick={handleBackClick} sx={{
                        width:'55px',
                        height:'50px',
                        display:style === 4 ? 'block' : 'none',
                    }}>
                        <CardMedia
                            component="img"
                            image={closeIcon}
                            sx={{
                                width:'28px',
                                height:'28px',
                                objectFit:'contain',
                            }}
                        />
                    </IconButton>
                </Toolbar>
        </AppBar>
    );
}

export default TTAppBar;