import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import copyIcon from '../../assets/images/wallet/wallet-copy.png';
import { useDispatch, useSelector } from 'react-redux';
import { userInfoService } from '../../network/service/user';
import { userRefreshUserInfo } from '../../features/userSlice';
import copy from 'clipboard-copy';
import TTAlert from '../common/TTAlert';
import AppConfig from '../../tools/AppConfig';

const ReferralContentMainContentCode = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        yourCode: t("your_code"),
        yourReferralLink: t("your_referral_link"),
        copyYourRebate: t("copy_your___rebate"),
        copySuccessfully: t("copy_successfully"), 
        copyFailed: t("copy_failed"), 
    };

    const referralCode = useSelector((state) => state.user.user.card_number);
    const linkUrl = AppConfig.webSiteUrl;
    // const referralCode = useSelector((state) => state.user.user.referral_code);
    // const linkUrl = 'https://t.me/BJJT_777bot';

    const handleCopyCodeClick = (text) => {
        copy(text)
        .then(() => {
            setType('success');
            setOpen(true);
            setAlertMessage(textData.copySuccessfully);
        })
        .catch((err) => {
            setType('error');
            setOpen(true);
            setAlertMessage(textData.copyFailed);
        });
    };

    const [ type, setType ] = useState('success');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            <TTAlert 
                type={type}
                isOpen={open}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />

            <Box sx={{
                bgcolor:'#15151B',
                borderRadius:'18px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                padding:'18px',
                // width:'calc(100% - 36px)',
                width:'460px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    borderRadius:'0px',
                    width:'calc(100% - 36px)',
                },
            }}>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'20px',
                    fontStyle:'normal',
                    fontWeight:500,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                    },
                }}>
                    {textData.yourCode}
                </Typography>
                <Grid container sx={{
                    bgcolor:'#0D0F13',
                    borderRadius:'24px',
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    mt:'8px',
                    padding:'8px 0px',
                }}>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'18px',
                        fontStyle:'normal',
                        fontWeight:500,
                        ml:'18px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'12px',
                        },
                    }}>
                        {referralCode}
                    </Typography>
                    <IconButton sx={{
                        width:'40px',
                        height:'100%',
                    }} onClick={() => handleCopyCodeClick(referralCode)}>
                        <CardMedia
                            component="img"
                            image={copyIcon}
                            sx={{
                                width:'18px',
                                height:'18px',
                                mr:'10px',
                                objectFit:'contain',
                            }}
                        />
                    </IconButton>
                </Grid>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'20px',
                    fontStyle:'normal',
                    fontWeight:500,
                    mt:'18px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                    },
                }}>
                    {textData.yourReferralLink}
                </Typography>
                <Grid container sx={{
                    bgcolor:'#0D0F13',
                    borderRadius:'24px',
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    mt:'8px',
                    padding:'8px 0px',
                }}>
                    <a target='_blank' href={linkUrl} style={{textDecoration:'none'}}>
                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:500,
                            ml:'18px',
                            maxWidth:'360px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'12px',
                                maxWidth:'340px',
                            },
                        }}>
                            {`${linkUrl}?SpreadCode=${referralCode}`}
                        </Typography>
                    </a>
                    <IconButton sx={{
                        width:'40px',
                        height:'100%',
                    }} onClick={() => handleCopyCodeClick(`${linkUrl}?SpreadCode=${referralCode}`)}>
                        <CardMedia
                            component="img"
                            image={copyIcon}
                            sx={{
                                width:'18px',
                                height:'18px',
                                mr:'10px',
                                objectFit:'contain',
                            }}
                        />
                    </IconButton>
                </Grid>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                    mt:'18px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                    },
                }}>
                    {textData.copyYourRebate}
                </Typography>
            </Box>
        </React.Fragment>
    );
}

export default ReferralContentMainContentCode;