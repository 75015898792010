import { configureStore } from '@reduxjs/toolkit';
import userSlice from '../features/userSlice';
import appSlice from '../features/appSlice';
import walletSlice from '../features/walletSlice';
import gameSlice from '../features/gameSlice';
import homeSlice from '../features/homeSlice';
import referralSlice from '../features/referralSlice';
import slotsSlice from '../features/slotsSlice';
import liveCasinoSlice from '../features/liveCasinoSlice';
import favoriteSlice from '../features/favoriteSlice';
import recentSlice from '../features/recentSlice';
import casinoListSlice from '../features/casinoListSlice';

const store = configureStore({
    reducer: {
        user: userSlice,
        wallet: walletSlice,
        game: gameSlice,
        app: appSlice,
        home: homeSlice,
        referral: referralSlice,
        slots: slotsSlice,
        liveCasino: liveCasinoSlice,
        favorite: favoriteSlice,
        recent: recentSlice,
        casinoList: casinoListSlice,
    }
})

export default store;