import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

const ProfileContentBottomItem = (props) => {
    const { item, btnOnClick } = props;
    const {
        type,title,image,rightImage,desc,buttonTitle,btnLeftTitle
    } = item;

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'start',
            gap:'18px',
            cursor:type === 5 ? 'pointer' : 'default',
        }} onClick={type === 5 ? btnOnClick : null}>
            <Box sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'8px',
            }}>
                <CardMedia
                    component="img"
                    image={image}
                    sx={{
                        width:'32px',
                        height:'32px',
                        objectFit:'contain',
                    }}
                />
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'14px',
                    fontStyle:'normal',
                    fontWeight:400,
                }}>
                    {title}
                </Typography>
            </Box>
            <Typography sx={{
                display:type === 1 ? 'block' : 'none',
                color:'#ACB3D7',
                fontSize:'14px',
                fontStyle:'normal',
            }}>
                {desc}
            </Typography>
            <Button variant='text' sx={{
                display:type === 2 ? 'flex' : 'none',
                justifyContent:'center',
                alignItems:'center',
                textAlign:'center',
                bgcolor:'#1E212A',
                borderRadius:'16px',
                height:'32px',
                textTransform:'none',
                color:'#ACB3D7',
                fontSize:'14px',
                fontStyle:'normal',
                fontWeight:400,
                textAlign:'center',
                '&:hover':{
                    bgcolor:'#1E212A',
                    color:'#FFFFFF',
                }
            }} onClick={btnOnClick}>
                {buttonTitle}
            </Button>
            <Box sx={{
                display:type === 3 ? 'flex' : 'none',
                justifyContent:'flex-end',
                alignItems:'center',
                gap:'18px',
            }}>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'14px',
                    fontStyle:'normal',
                }}>
                    {btnLeftTitle}
                </Typography>
                <Button variant='text' sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    textAlign:'center',
                    bgcolor:'#1E212A',
                    borderRadius:'16px',
                    height:'32px',
                    textTransform:'none',
                    color:'#ACB3D7',
                    fontSize:'14px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textAlign:'center',
                    '&:hover':{
                        bgcolor:'#1E212A',
                        color:'#FFFFFF',
                    }
                }} onClick={btnOnClick}>
                    {buttonTitle}
                </Button>
            </Box>
            <Box sx={{
                display:type === 4 ? 'flex' : 'none',
                justifyContent:'flex-end',
                alignItems:'center',
                gap:'18px',
            }}>
                <CardMedia
                    component="img"
                    image={rightImage}
                    sx={{
                        width:'24px',
                        height:'24px',
                        objectFit:'contain',
                    }}
                />
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'14px',
                    fontStyle:'normal',
                    fontWeight:400,
                }}>
                    {desc}
                </Typography>
            </Box>
            <IconButton onClick={btnOnClick} sx={{
                display:type === 5 ? 'flex' : 'none',
            }}>
                <CardMedia
                    component="img"
                    image={rightImage}
                    sx={{
                        width:'8px',
                        height:'16px',
                        objectFit:'contain',
                    }}
                />
            </IconButton>
        </Grid>
    );
}

export default ProfileContentBottomItem;