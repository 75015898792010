import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

const HeaderWalletWithdrawMenu = (props) => {
    const {
        defaultMenu,boxHeight,menuClick,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        your_balance: t("your_balance"),
        withdraw: t("withdraw"),
        withdraw_records: t("withdraw_records"),
        manage_account: t("manage_account"),
    }
    const menuTitleArr = [
        textData.withdraw,
        textData.withdraw_records,
        textData.manage_account,
    ];

    const [ currentMenu,setCurrentMenu ] = useState(defaultMenu);
    const [ childBoxHeight,setChildBoxHeight ] = useState(boxHeight);

    useEffect(() => {
        setChildBoxHeight(boxHeight);
    }, [boxHeight]);

    useEffect(() => {
        setCurrentMenu(defaultMenu);
    }, [defaultMenu]);

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            height:(childBoxHeight !== undefined && childBoxHeight !== null) ? childBoxHeight : '100%',
            transition: theme.transitions.create(['height'], {
                duration: 300,
                easing: 'ease-out',
            }),
        }}>
            <Typography sx={{
                color:'#8EA3C3',
                fontSize:'18px',
                fontWeight:700,
            }}>
                {textData.your_balance}
            </Typography>
            <Box sx={{
                bgcolor:'#16202C',
                borderRadius:'8px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                alignSelf:'stretch',
                mt:'12px',
                width:'180px',
                overflow:'hidden',
                height:'100%',
            }}>
                {menuTitleArr.map((item,index) => (
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        width:'100%',
                        height:'40px',
                        mt:index === 0 ? '16px' : '28px',
                        mb:(index === menuTitleArr.length - 1) ? '16px' : '0px',
                        cursor:'pointer',
                        bgcolor:currentMenu === index ? '#0C131D' : 'transparent',
                        borderLeft:currentMenu === index ? '4px solid #1184FA' : 'none',
                        '&:hover': {
                            bgcolor:'#0C131D',
                        },
                    }} onClick={() => menuClick(index)} key={index}>
                        <Typography sx={{
                            color:currentMenu === index ? '#FFFFFF' : '#8EA3C3',
                            fontSize:'14px',
                            fontWeight:400,
                            ml:currentMenu === index ? '14px' : '18px',
                        }}>
                            {item}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default HeaderWalletWithdrawMenu;