import React from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import FavoriteContent from '../component/favorite/FavoriteContent';

const Favorite = () => {
    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.favorite} drawerIndex={4}/>
            <FavoriteContent />
        </React.Fragment>
    )
}

export default Favorite;