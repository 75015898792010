import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileBreakpoint from '../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import ProfileContent from '../component/profile/ProfileContent';
import TTAppBar from '../component/common/TTAppBar';

const Profile = () => {
    const { t } = useTranslation();
    const textData = {
        profile: t("profile"),
    };
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    if(screenMD) {
        return (
            <React.Fragment>
                <Customer />
                <TTAppBar style={1} title={textData.profile} />
                <ProfileContent screenMD={true} />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.profile} drawerIndex={-1} />
            <ProfileContent screenMD={false} />
        </React.Fragment>
    )
}

export default Profile;