import React, { useState, useEffect,useContext,useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Decimal } from 'decimal.js';
import Contexts from '../LimboContext';
import { use } from 'i18next';
import AudioTick from '../../../../assets/audios/limbotick.mp3';

const { AnimationActive,Multiplier,Requesting,
  RollResult,DiceVolume,BetHistory,IsAutoBetting } = Contexts;

let endValue = 100;
let startTime = performance.now();
let end = 100;

const LimboComponetGamePart = (props) => {
  const theme = useTheme();
  React.useEffect(() => {
      window.scrollTo({ 
          left: 0,
          top: 0,
          behavior: 'auto'
    });
  },[]);

  const pastWinBttColor = '#ffffff';
  const pastWinBttNormalColor = '#0e71d4';
  const pastWinBttHoverColor = '#1184FA';

  const pastLossBttColor = '#a9a9a9';
  const pastLostBttNormalColor = '#273854';
  const pastLostBttHoverColor = '#557086';
  
  //是否可以继续点击
  const [canClick, setCanClick] = useState(true);
  //乘数
  const {multiplier, setMultiplier} =  useContext(Multiplier);
  //乘数显示使用
  const [multiplierShow, setMultiplierShow] = useState('0');
  //是否有动画
  const {isAnimationActive} = useContext(AnimationActive);
  //是否请求中
  const {isRequesting} = useContext(Requesting);
  //投注结果数字
  const {rollResult} = useContext(RollResult);
  //获胜机率
  const [winChance, setWinChance] = useState('49.50000000');
  //游戏音量
  const {globalVolume} = useContext(DiceVolume);
  //是否自动投注
  const {isAutoBetActive} = useContext(IsAutoBetting);
  //投注历史
  const {historyBetsArr,setHistoryBetsArr} = useContext(BetHistory);


  useEffect(() => {
    console.log('rollResult==='+rollResult);
    if (rollResult === undefined){
      return
    }
    const tResultStr = new Decimal(rollResult).times(100).toFixed(0);
    endValue = parseInt(tResultStr, 10);
    if(endValue > 100){
      playTickAudio();
      setTimeout(() => {
        end = endValue;
        startTime = performance.now();
        handleStartAnimation();
      },100)
    }else{
      setCurrentShowNum(100)
    }
  },[rollResult]);

  //删除一条数据
  const deleteOnePastData = (item) => {
    let newArr = historyBetsArr.map((item,index) => {
      item['moveLeft'] = false;
      return item;
    });
    //从newArr数组里删除item.roundId 这一条数据
    let deleteArr = newArr.filter(
      (tmpitem,index) => {
        return tmpitem.roundId !== item.roundId;
      }
    );
    console.log('afterdelete===='+deleteArr);
    setHistoryBetsArr(deleteArr);
  }

  //重置数据 moveLeft 到 false
  const resetPastData = () => {
    let newArr = historyBetsArr.map((item,index) => {
      item['moveLeft'] = false;
      return item;
    });
    setHistoryBetsArr(newArr);
  }

  const tabSelectedColor = '#304553';
  const textColor = '#8EA3C3';
  const primaryColor = '#000000';
  const hoverSecondaryColor = '#1184FA';
  //进度条文字颜色
  const progressTextColor = '#8EA3C3';

  //x图标
  const XSvgIcon = () => (
    <svg fill={textColor} viewBox="0 0 64 64" width="14px" height="14px"> <title></title> <path d="M55.92 7.227H43.493L32 23.307 20.507 7.227H8.08L25.787 32 8.08 56.773h12.453L32 40.694l11.467 16.08H55.92L38.213 32 55.92 7.227Z"></path>
    </svg>
  );
  //百分符号svg
  const PercentSvgIcon = () => (
    <svg fill="#B0BAD3" width="16" height="16" viewBox="0 0 64 64" >
       <path d="M18.38 29.904c6.364 0 11.524-5.16 11.524-11.524 0-6.364-5.16-11.524-11.524-11.524-6.364 0-11.525 5.16-11.525 11.524 0 6.364 5.16 11.524 11.524 11.524Zm0-14.666a3.142 3.142 0 1 1-.001 6.285 3.142 3.142 0 0 1 0-6.285Zm27.24 18.858c-6.364 0-11.524 5.16-11.524 11.524 0 6.364 5.16 11.524 11.524 11.524 6.364 0 11.524-5.16 11.524-11.524 0-6.364-5.16-11.524-11.524-11.524Zm0 14.666a3.142 3.142 0 1 1 0-6.285 3.142 3.142 0 0 1 0 6.285Zm.585-41.904L6.857 57.144h10.644L56.85 6.858H46.205Z"></path>
    </svg>
  );
  //显示结果svg <style>.cls-1{fill:#fff;}.cls-2{fill:#e9f0f5;}.cls-3{fill:#d3dee6;}</style>
  const ResultFuckSvgIcon = () => (
    <svg fill='#ffffff' width="46" height="52"><g id="games">
      <path fill='#ffffff' d="M43.28,11.13,25.05.61a4.56,4.56,0,0,0-4.55,0L2.28,11.13A4.55,4.55,0,0,0,0,15.07v21a4.55,4.55,0,0,0,2.28,3.94L20.5,50.58a4.56,4.56,0,0,0,4.55,0L43.28,40.06a4.56,4.56,0,0,0,2.27-3.94v-21A4.56,4.56,0,0,0,43.28,11.13Z"/>
      <path fill='#e9f0f5' d="M21.13,24.64,1.85,13.51A1.23,1.23,0,0,0,0,14.57v22a3.69,3.69,0,0,0,1.84,3.19l19.09,11a3.69,3.69,0,0,0,1.85.49,34.48,34.48,0,0,0,0-23.82A3.3,3.3,0,0,0,21.13,24.64Z"/>
      <path fill="#d3dee6" d="M43.7,13.51,24.42,24.64a3.3,3.3,0,0,0-1.64,2.86V51.32a3.68,3.68,0,0,0,1.84-.49l19.09-11a3.69,3.69,0,0,0,1.84-3.19v-22A1.23,1.23,0,0,0,43.7,13.51Z"/></g>
    </svg>
  );
  
  const progressDefaultColor = '#1184FA'; 
  const progressBetColor ='#E61043';
  const minMultiplier = 1.01; //乘数最小
  const maxMultiplier = 1000000; //乘数最大
  const minOddsWin = 0.00009900; //胜率最小
  const maxOddsWin = 98.01980198  //胜率最大
  const dec_99 = new Decimal(99)

  //获胜几率输入框--失去焦点事件
  const oddsWinCheckValue = () => {
    const dec_minOddsWin = new Decimal(String(minOddsWin))
    const dec_maxOddsWin = new Decimal(String(maxOddsWin))
    const dec_inputOddsWin = new Decimal(winChance.length?String(winChance):'0')
    let showValue = dec_inputOddsWin;
    if (dec_inputOddsWin.greaterThan(dec_maxOddsWin)) {
      // 大于最大值
      showValue=maxOddsWin;
    } else if(dec_inputOddsWin.lessThan(dec_minOddsWin)) {
      // 小于最小值
      showValue=minOddsWin;
    }else{
      showValue=dec_inputOddsWin;
    }
    //保留showValue小数点后4位
    let tmpValue = showValue.toFixed(8)
    setWinChance(tmpValue);
    //计算乘数
    let tmpMultiplier = dec_99.div(showValue).toFixed(2)
    console.log('tmpMultiplier===='+tmpMultiplier)
    setMultiplier(tmpMultiplier);
  }
  //获胜几率输入框--变化事件
  const oddsWinChangeEvent = (event) => {
    const newValue = event.target.value;
    console.log('oddsWinChangeEvent newValue:',newValue)
    //键盘输入
    if (event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType) {
      setWinChance(newValue);
    } else {
      // 没有key属性，上下箭头被按下
      const dec_minOddsWin = new Decimal(String(minOddsWin))
      const dec_maxOddsWin = new Decimal(String(maxOddsWin))
      const dec_inputOdds = new Decimal(String(newValue))
      let showValue = newValue;
      if (dec_inputOdds.greaterThan(dec_maxOddsWin)) {
        // 大于最大值
        showValue=maxOddsWin;
      } else if(dec_inputOdds.lessThan(dec_minOddsWin)) {
        // 小于最小值
        showValue=minOddsWin;
      }else{
        showValue=dec_inputOdds;
      }
      //保留showValue小数点后4位
      let tmpValue = showValue.toFixed(8)
      setWinChance(tmpValue);
      //计算乘数
      setMultiplier(dec_99.div(showValue).toFixed(2));
    }
  }

  //乘数输入框--失去焦点事件
  const multiplierCheckValue = () => {
    const dec_minMultiplier = new Decimal(String(minMultiplier))
    const dec_maxMultiplier = new Decimal(String(maxMultiplier))
    const dec_inputMultiplier = new Decimal(multiplier.length?String(multiplier):'0')
    let showValue = dec_inputMultiplier;
    if (dec_inputMultiplier.greaterThan(dec_maxMultiplier)) {
      // 大于最大值
      showValue=maxMultiplier;
    } else if(dec_inputMultiplier.lessThan(dec_minMultiplier)) {
      // 小于最小值
      showValue=minMultiplier;
    }
    //保留showValue小数点后4位
    let tmpValue = showValue.toFixed(2)
    setMultiplier(tmpValue);
    //计算获胜几率
    setWinChance(dec_99.div(showValue).toFixed(8))
  }
  //乘数输入框--变化事件
  const multiplierChangeEvent = (event) => {
    const newValue = event.target.value;
    console.log('whenMultiplierInputEvent newValue:',newValue)
    //键盘输入
    if (event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType) {
      setMultiplier(newValue);
    } else {
      // 没有key属性，上下箭头被按下
      const dec_minMultiplier = new Decimal(String(minMultiplier))
      const dec_maxMultiplier = new Decimal(String(maxMultiplier))
      const dec_inputMultiplier = new Decimal(String(newValue))
      let showValue = newValue;
      if (dec_inputMultiplier.greaterThan(dec_maxMultiplier)) {
        // 大于最大值
        showValue=maxMultiplier;
      } else if(dec_inputMultiplier.lessThan(dec_minMultiplier)) {
        // 小于最小值
        showValue=minMultiplier;
      }else{
        showValue=dec_inputMultiplier;
      }
      //保留showValue小数点后4位
      let tmpValue = showValue.toFixed(2)
      setMultiplier(tmpValue);
      //计算获胜几率
      setWinChance(dec_99.div(showValue).toFixed(8))
    }
  }


  //游戏音效---数字跳动时音效
  const limboAudioTick = useRef(new Audio(AudioTick));
  const playTickAudio = () => {
    const audio = limboAudioTick.current;
    // 如果音频正在播放，先停止并重置播放位置
    if (!audio.paused) {
      audio.pause()
      audio.currentTime = 0; // 重置音频文件到开始位置
    }
    audio.volume = globalVolume / 100;
    audio.play().catch(error => {
      console.log("Playback was prevented. Error: ", error)
    });
  }

  useEffect(() => {
    console.log('multiplier==='+multiplier)
    if(multiplier.length === 0){
      setMultiplierShow('0');
    }else{
      setMultiplierShow(multiplier);
    }
  },[multiplier]);

  useEffect(() => {
    const audio = limboAudioTick.current;
    // 定义音频播放开始时的回调函数
    const handleAudioPlay = () => {
      console.log("音频开始播放");
    };
    // 添加事件监听器
    audio.addEventListener('play', handleAudioPlay);
    // 清理函数中移除事件监听器
    return () => {
      audio.removeEventListener('play', handleAudioPlay);
    };
  }, []); // 空依赖数组确保事件监听器只被添加和移除一次

  //触发动画使用
  const [animateTriggle, setAnimateTriggle] = useState(false);
  const handleStartAnimation = () => {
    console.log("handleStartAnimation animateTriggle === "+animateTriggle)
    setAnimateTriggle(!animateTriggle);
  };
  const [currentShowNum, setCurrentShowNum] = useState(100);
 
  useEffect(() => {
    let frame;
    let start = 100
    let duration = 400
    const animate = () => {
      const currentTime = performance.now();
      const elapsedTime = currentTime - startTime;
      const progress = elapsedTime / duration;
      console.log('progress==='+progress)
      if (progress < 1) {
        setCurrentShowNum(start + (end - start) * progress);
        frame = requestAnimationFrame(animate);
      } else {
        setCurrentShowNum(end); // 确保动画结束时的数字是准确的
      }
    };
    
    frame = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(frame); // 清除动画帧
    };
  }, [animateTriggle]);
  

  return (
    <React.Fragment>
      <Box sx={{
          position:'relative',
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          alignItems:'center',
          backgroundColor:'transparent',
          height:'calc(100% - 1.5rem)',
          padding:'0.75rem',
      }}>
        <div style={{
          position:'absolute',
          right:'1rem',
          top:'1rem',
          width:'100%',
          display:'flex',
          flexDirection:'row-reverse',
          gap:'0.25rem',
        }}>
          {
            historyBetsArr.map((item,index) => {
              return (
                <Button className={
                  [
                    'limbo-pastbet',
                    item.disappear ? 'dice-pastbet-disappear' : item.moveLeft ? 'dice-pastbet-moveLeft' : '',
                  ].join(' ')
                } key={item.roundId}
                  sx={{
                    backgroundColor:item.isWin ? pastWinBttNormalColor : pastLostBttNormalColor,
                    color:item.isWin ? pastWinBttColor : pastLossBttColor,
                    '&:hover': {
                      backgroundColor:item.isWin ? pastWinBttHoverColor : pastLostBttHoverColor,
                    },
                    animationDuration: isAnimationActive?'0.3s':'0s',
                  }}
                  onAnimationEnd={(e) => {
                    if (historyBetsArr.length > 5){
                      if (item.disappear){
                        deleteOnePastData(item);
                        setCanClick(true)
                      }
                    }else{
                      if (index === 0) { // 只在最后一个动画结束时重置
                        resetPastData();
                        setCanClick(true)
                      }
                    }
                  }}
                >
                  {item.result+'×'}
                </Button>
              );
            })
          }
        </div>
        <div id='diceContainer' style={{
          width:'100%',
          marginTop:'2.25rem',
          paddingBottom:'1rem',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          flexGrow:1,
        }}>
          {/* <button onClick={
            ()=>{
              handleStartAnimation();
            }
            }>
            游戏内容
          </button> */}
          <Box className='limbo-game-container' sx={{
          }}>
            <Box sx={{
              flex: '1',
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              padding:'2rem 0px',
            }}>
              <span className='unselectable' style={{
                fontFamily:'Proxima-Nova-regular',
                fontWeight:'600',
                color: textColor,
                margin: '0px',
                border: '0',
                padding: '0',
                verticalAlign: 'baseline',
                fontVariantNumeric: 'tabular-nums',
                fontSize:'6.5rem',
                color: currentShowNum <= 100? '#ffffff':(new Decimal(end).div(100)).lessThanOrEqualTo((new Decimal(multiplierShow)))?progressBetColor:progressDefaultColor,
              }}>{String((currentShowNum/100).toFixed(2))+'×'}</span>
            </Box>
            
          </Box>
        </div>
        {/* <button onClick={requestbetresults}>测试按钮</button> */}
        <div className='limbo-game-bottom'>
          <div className='dice-game-label' style={{
          }}>
            <TextField
              id="outlined-number"
              label=""
              type="number"
              placeholder='0'
              value={multiplier}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                border:'1px solid',
                borderColor:'#55657E',
                borderRadius:'4px',
                padding:'0px 0px 0px 0px',
                backgroundColor:primaryColor,
                width: '100%',
                '& .MuiInputBase-input': {
                    height: '26px',
                    padding: '0px 0px 0px 10px',//上右下左
                    fontSize: '12px', // 调整字体大小
                    color: 'white', // 设置占位符颜色
                },
                '& .MuiInputBase-input::placeholder': { // 特别针对占位符的样式
                  color: 'white', // 设置占位符颜色
                  fontWeight: 'bold', // 设置占位符字体加粗
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                      borderColor: 'transparent', // 设置透明
                  }
                },
                '&:hover': {
                  borderColor: hoverSecondaryColor, // 鼠标悬停时的边框颜色
                },
                flex: '1 1 0%',
                opacity: isRequesting || isAutoBetActive? 0.5 : 1,
                pointerEvents: isRequesting || isAutoBetActive?'none':'default',
              }}
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <XSvgIcon /> 
                    </InputAdornment>
                ),
              }}
              onFocus={e => {
                e.target.select();
              }}
              onBlur={
                e => {
                  multiplierCheckValue();
                }
              }
              onChange={(event)=>{
                multiplierChangeEvent(event)
              }}
              inputProps={{ step: 1 }}
            />
            <span style={{
              fontSize:"12px", 
              fontWeight:'bold', 
              color: textColor, 
              marginBottom:'2px',
            }}>目标乘数</span>
          </div>
          <div className='dice-game-label' style={{
          }}>
            <TextField
              id="outlined-number"
              label=""
              type="number"
              placeholder='0'
              value={winChance}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                border:'1px solid',
                borderColor:'#55657E',
                borderRadius:'4px',
                padding:'0px 0px 0px 0px',
                backgroundColor:primaryColor,
                width: '100%',
                '& .MuiInputBase-input': {
                    height: '26px',
                    padding: '0px 0px 0px 10px',//上右下左
                    fontSize: '12px', // 调整字体大小
                    color: 'white', // 设置占位符颜色
                },
                '& .MuiInputBase-input::placeholder': { // 特别针对占位符的样式
                  color: 'white', // 设置占位符颜色
                  fontWeight: 'bold', // 设置占位符字体加粗
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                      borderColor: 'transparent', // 设置透明
                  }
                },
                '&:hover': {
                  borderColor: hoverSecondaryColor, // 鼠标悬停时的边框颜色
                },
                flex: '1 1 0%',
                opacity: isRequesting || isAutoBetActive ? 0.5 : 1,
                pointerEvents: isRequesting || isAutoBetActive?'none':'default',
              }}
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <PercentSvgIcon /> 
                    </InputAdornment>
                ),
              }}
              onFocus={e => {
                e.target.select();
              }}
              inputProps={{ step: 1 }}
              onBlur={
                e => {
                  oddsWinCheckValue();
                }
              }
              onChange={(event)=>{
                if(!isAutoBetActive){
                  oddsWinChangeEvent(event)
                }
              }}
            />
            <span style={{
              fontSize:"12px", 
              fontWeight:'bold', 
              color: textColor, 
              marginBottom:'2px',
            }}>获胜机率</span>
          </div>
        </div>
      </Box>
    </React.Fragment>
  );
}

export default LimboComponetGamePart;