import React, { useEffect, useState } from 'react';
import i18next from "i18next";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import GameContentGamePreviewPC from './GameContentGamePreviewPC';
import GameContentGamePreviewMobile from './GameContentGamePreviewMobile';
import TTAlert from '../common/TTAlert';
import { walletGameBalanceChanged } from '../../features/walletSlice';
import RoutePath from '../../tools/RoutePath';
import TTLoading from '../common/TTLoading';
import { favoriteGameService, getGameUrlService, unfavoriteGameService } from '../../network/service/game';
import { gameRefresh } from '../../features/gameSlice';
import BigNumber from 'bignumber.js';
import AppConfig from '../../tools/AppConfig';

const GameContentGamePreview = (props) => {
    const {
        category,fromPage,gameIcon,gameName,gameUrl,gameUid,isCloseFreePlayMode,isFavorite,favoriteNum,whilePlayingIn,profileSetting,
        freePlay,realPlay,funPlay,favorites,by,lookNewGameUrl,pleaseLoginAccount,insufficientBalanceUp
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // loading
    const [ openLoading,setOpenLoading ] = useState(false);
    const [ showGame,setShowGame ] = useState(false);
    const [ oldGameUrl,setOldGameUrl ] = useState(gameUrl);
    const [ resultGameUrl,setResultGameUrl ] = useState(gameUrl);
    if(oldGameUrl !== lookNewGameUrl) {
        setOldGameUrl(lookNewGameUrl);
        setShowGame(false);
        setResultGameUrl(gameUrl);
    }

    const accessToken = useSelector((state) => state.user.access_token);
    const totalBalance = useSelector((state) => state.wallet.totalBalance);

    const [ favoriteState,setFavoriteState ] = useState(isFavorite);
    const [ favoriteCount,setFavoriteCount ] = useState(favoriteNum);
    useEffect(() => {
        setFavoriteState(isFavorite);
    }, [isFavorite]);
    useEffect(() => {
        setFavoriteCount(favoriteNum);
    }, [favoriteNum]);

    useEffect(() => {
        const handleMessageEvent = (event) => {
            if(event.origin === AppConfig.webSiteUrl) {
                // 处理接收到的消息
                try {
                    const balanceData = JSON.parse(event.data);
                    if(balanceData && balanceData.toBalance) {
                        dispatch(walletGameBalanceChanged({
                            totalBalance:balanceData.toBalance,
                            won:balanceData.won,
                        }));
                    }
                } catch (error) {
                    
                }
            }
        };
        window.addEventListener('message', handleMessageEvent);
        return () => {
            window.removeEventListener('message', handleMessageEvent);
          };
    },[resultGameUrl]);

    const handleCategoryClick = () => {
        if(fromPage.toLowerCase().includes('inhousegames')) {
            navigate(fromPage);
        }else {
            navigate(-1);
        } 
    }
    const handleFreePlayClick = () => {
        // 接入游戏
        if(gameUid !== undefined && gameUid !== null && gameUid.length > 0) {
            handleFetchGameUrl('Demo');
            return;
        }

        // 判断是否登录
        if(accessToken === undefined || accessToken.length <= 0) {
            setAlertType('info');
            setAlertMessage(pleaseLoginAccount);
            setOpenAlert(true);
            return;
        }

        // 自研游戏
        if(screenMD) {
            // const link = document.createElement('a');
            // link.href = gameUrl;
            // link.target = '_blank';
            // link.click();
            navigate(RoutePath.gameMobile,
                { 
                    state: { 
                        category:category,
                        fromPage:fromPage,
                        gameIcon:gameIcon,
                        gameName:gameName,
                        gameUrl:gameUrl,
                        // gameUrl:accessToken.length > 0 ? `${gameUrl}?token=${accessToken}&is_try=${1}` : gameUrl,
                    }
                }
            );
        }else {
            if(accessToken.length > 0) {
                // setResultGameUrl(`${gameUrl}?token=${accessToken}&is_try=${1}`);
                setResultGameUrl(gameUrl);
            }
            setShowGame(true);
        }
    };
    const handleRealPlayClick = () => {
        // 判断是否登录、余额是否为0
        if(accessToken === undefined || accessToken.length <= 0) {
            setAlertType('info');
            setAlertMessage(pleaseLoginAccount);
            setOpenAlert(true);
            return;
        }
        if(BigNumber(totalBalance).isLessThanOrEqualTo(BigNumber('0'))) {
            setAlertType('info');
            setAlertMessage(insufficientBalanceUp);
            setOpenAlert(true);
            return;
        }

        // 接入游戏
        if(gameUid !== undefined && gameUid !== null && gameUid.length > 0) {
            handleFetchGameUrl('OnlineReal');
            return;
        }

        // 自研游戏
        if(screenMD) {
            // const link = document.createElement('a');
            // link.href = accessToken.length > 0 ? `${gameUrl}?token=${accessToken}` : gameUrl;
            // link.target = '_blank';
            // link.click();
            navigate(RoutePath.gameMobile,
                { 
                    state: { 
                        category:category,
                        fromPage:fromPage,
                        gameIcon:gameIcon,
                        gameName:gameName,
                        gameUrl:accessToken.length > 0 ? `${gameUrl}?token=${accessToken}` : gameUrl,
                        // gameUrl:accessToken.length > 0 ? `${gameUrl}?token=${accessToken}&is_try=${0}` : gameUrl,
                    }
                }
            );
        }else {
            if(accessToken.length > 0) {
                setResultGameUrl(`${gameUrl}?token=${accessToken}`);
                // setResultGameUrl(`${gameUrl}?token=${accessToken}&is_try=${0}`);
            }
            setShowGame(true);
        }
    }
    const handleFavoriteClick = () => {
        if(accessToken === undefined || accessToken.length <= 0) {
            setAlertType('info');
            setAlertMessage(pleaseLoginAccount);
            setOpenAlert(true);
            return;
        }
        handleFavoriteGame();
    }
    const handleQuestionClick = () => {
        
    }
    const handleProfileClick = () => {
        // 判断是否登录、余额是否为0
        if(accessToken === undefined || accessToken.length <= 0) {
            setAlertType('info');
            setAlertMessage(pleaseLoginAccount);
            setOpenAlert(true);
            return;
        }
        navigate(RoutePath.profile);
    }

    const handleFetchGameUrl = async (playMode) => {
        setOpenLoading(true);
        let language = 'EN';
        if(i18next.language.toLowerCase().includes('es')) {
            language = 'ES';
        }else if(i18next.language.toLowerCase().includes('pt')) {
            language = 'PT';
        }else if(i18next.language.toLowerCase().includes('ko')) {
            language = 'KO';
        }else if(i18next.language.toLowerCase().includes('zh')) {
            language = 'ZH';
        }
        try {
            const param = {
                game_uid:gameUid,
                play_mode:playMode,
                language:language,
                country:'BR',
            }
            const response = await getGameUrlService(param);
            if(!response.data.is_success) {
                setOpenLoading(false);
                setAlertType('error');
                setAlertMessage(response.data.error_msg);
                setOpenAlert(true);
                return;
            }
            const parsedData = JSON.parse(response.data.payload);
            const launchUrl = parsedData.game_launch_url;
            setOpenLoading(false);
            
            if(screenMD) {
                navigate(RoutePath.gameMobile,
                    { 
                        state: { 
                            category:category,
                            fromPage:fromPage,
                            gameIcon:gameIcon,
                            gameName:gameName,
                            gameUrl:launchUrl
                        }
                    }
                );
            }else {
                setResultGameUrl(launchUrl);
                setShowGame(true);
            }
            
            let cache = {
                category:category,
                fromPage:fromPage,
                gameIcon:gameIcon,
                gameName:gameName,
                gameUrl:launchUrl,
                gameUid:gameUid,
                isFavorite:isFavorite,
                favoriteNum:favoriteNum,
            };
            const cacheStr = JSON.stringify(cache);
            localStorage.setItem('TTLatestGame',cacheStr);

            dispatch(gameRefresh({currentGameUrl:launchUrl}));
        } catch (error) {
            setOpenLoading(false);
            console.log(error);
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setAlertType('error');
            setAlertMessage(errorMsg);
            setOpenAlert(true);
            return null;
        }
    }

    const handleFavoriteGame = async () => {
        setOpenLoading(true);
        try {
            const param =  {
                game_uid:gameUid,
            }
            if(favoriteState) {
                await unfavoriteGameService(param);
                setFavoriteCount((prevFavoriteCount) => prevFavoriteCount - 1);
            }else {
                await favoriteGameService(param);
                setFavoriteCount((prevFavoriteCount) => prevFavoriteCount + 1);
            }
            setOpenLoading(false);
            setFavoriteState(!favoriteState);
        } catch (error) {
            setOpenLoading(false);
            console.log(error);
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setAlertType('error');
            setAlertMessage(errorMsg);
            setOpenAlert(true);
        }
    };

    // alert
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ alertType, setAlertType ] = useState('info');
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    return (
        <React.Fragment>
            <TTLoading
                open={openLoading}
                isForce={true}
            />
            <TTAlert 
                type={alertType}
                isOpen={openAlert}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />
            {screenMD ? 
                <GameContentGamePreviewMobile 
                    category={category}
                    gameIcon={gameIcon}
                    gameName={gameName}
                    gameUid={gameUid}
                    isCloseFreePlayMode={isCloseFreePlayMode}
                    isFavorite={favoriteState}
                    favoriteNum={favoriteCount}
                    by={by}
                    freePlay={freePlay}
                    realPlay={realPlay}
                    favorites={favorites}
                    categoryOnClick={handleCategoryClick}
                    freePlayOnClick={handleFreePlayClick}
                    realPlayOnclick={handleRealPlayClick}
                    favoriteOnClick={handleFavoriteClick}
                    questionOnClick={handleQuestionClick}
                /> : 
                <GameContentGamePreviewPC 
                    category={category}
                    gameIcon={gameIcon}
                    gameName={gameName}
                    resultGameUrl={resultGameUrl}
                    gameUid={gameUid}
                    isCloseFreePlayMode={isCloseFreePlayMode}
                    isFavorite={favoriteState}
                    favoriteNum={favoriteCount}
                    whilePlayingIn={whilePlayingIn}
                    profileSetting={profileSetting}
                    freePlay={freePlay}
                    realPlay={realPlay}
                    funPlay={funPlay}
                    favorites={favorites}
                    categoryOnClick={handleCategoryClick}
                    freePlayOnClick={handleFreePlayClick}
                    realPlayOnclick={handleRealPlayClick}
                    favoriteOnClick={handleFavoriteClick}
                    questionOnClick={handleQuestionClick}
                    profileOnClick={handleProfileClick}
                    showGame={showGame}
                />
            }
        </React.Fragment>
    );
}

export default GameContentGamePreview;