import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import bannerBig1 from '../../assets/images/temp/home-banner-big1.png';
import bannerBig2 from '../../assets/images/temp/home-banner-big2.png';
import bannerBig3 from '../../assets/images/temp/home-banner-big3.png';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';
import { useSelector } from 'react-redux';
import { Slide, Typography } from '@mui/material';
import TTLoadImage from '../common/TTLoadImage';
import HeaderWallet from '../header/HeaderWallet';

const HomeContentMainBannerLargeImage = (props) => {
    const { image,imageOnClick } = props;

    const [ lastImage,setLastImage ] = useState(null);
    const [ direction,setDirection ] = useState('left');

    useEffect(() => {
        
    }, [image]);

    return (
        <Box sx={{
            position:'relative',
            width:'750px',
            height:'325px',
            borderRadius:'8px',
            cursor:'pointer',
            overflow:'hidden',
        }} onClick={imageOnClick}>
            <Slide direction={direction} in={true}>
                <CardMedia
                    component="img"
                    image={image}
                    sx={{
                        objectFit:'fill',
                        width:'100%',
                        height:'100%',
                        position:'absolute',
                        top:'0px',
                        left:'0px',
                    }}
                />
            </Slide>
        </Box>
    );
}

const HomeContentMainBanner = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();

    const imageArr = [
        bannerBig1,
        bannerBig2,
        bannerBig3,
        bannerBig1,
        bannerBig2,
        bannerBig3
    ];
    const imageMobileArr = [
        bannerBig1,
        bannerBig2,
        bannerBig3,
    ];
    if(!screenMD && imageArr.length < 6 && imageArr.length > 3) {
        imageArr.push(...imageArr);
    }

    const defaultLargeIndex = () => {
        if(imageArr.length === 1 || imageArr.length === 2) {
            return 0;
        }else {
            return 1;
        }
    }
    const swiperRef = useRef(null);
    const largeSwiperRef = useRef(null);
    const [ showLargeIndex, setShowLargeIndex ] = useState(defaultLargeIndex());
    const [ largeImage, setLargeImage ] = useState(imageArr[showLargeIndex]);
    const handleMinImageChanged= (swiper) => {
        handleLargeScroll(swiper.realIndex >= (imageArr.length - 1) ? 0 : (swiper.realIndex + 1));

        setShowLargeIndex(swiper.realIndex >= (imageArr.length - 1) ? 0 : (swiper.realIndex + 1));
    };
    const accessToken = useSelector((state) => state.user.access_token);

    const handleSlideClick = (index) => {
        if(index === 0 || index === 3) {
            // navigate(RoutePath.inHouseGames);
        }else if(index === 1 || index === 4) {
            // navigate(RoutePath.poker);
        }else if(index === 2 || index === 5) {
            if(accessToken === undefined || accessToken.length <= 0) {
                navigate(RoutePath.login,{state: {type:'login',fromPage:RoutePath.home}});
                return;
            }
            // navigate(RoutePath.wallet);
            setWalletOpen(true);
        }
    };
    
    const handleMiniSlideClick = (swiper,index) => {
        if(swiper.current.swiper === undefined || swiper.current.swiper === null) {
            return;
        }
        if(index === 0) {
            if(showLargeIndex === (imageArr.length - 1)) {
                swiper.current.swiper.slideNext();
            }else {
                swiper.current.swiper.slidePrev();
            }
        }else if(index === (imageArr.length - 1)) {
            if(showLargeIndex === 0) {
                swiper.current.swiper.slidePrev();
            }else {
                swiper.current.swiper.slideNext();
            }
        }else {
            if(index < showLargeIndex) {
                swiper.current.swiper.slidePrev();
            }else if(index > showLargeIndex) {
                swiper.current.swiper.slideNext();
            }
        }
    };
    const handleLargeScroll = (index) => {
        if(largeSwiperRef.current.swiper === undefined || largeSwiperRef.current.swiper === null) {
            return;
        }
        // largeSwiperRef.current.swiper.slideTo(index);
        if(index < showLargeIndex) {
            if(index === 0 && showLargeIndex === 5) {
                largeSwiperRef.current.swiper.slideNext();
            }else {
                largeSwiperRef.current.swiper.slidePrev();
            }
        }else if(index > showLargeIndex) {
            if(index === imageArr.length - 1 && showLargeIndex === 0) {
                largeSwiperRef.current.swiper.slidePrev();
            }else {
                largeSwiperRef.current.swiper.slideNext();
            }
        }
    };
    
    useEffect(() => {
        if(!screenMD) {
            largeSwiperRef.current.swiper.slideTo(1,0);
        }
    }, []);
    useEffect(() => {
        setLargeImage(imageArr[showLargeIndex]);
    }, [showLargeIndex]);

    // 钱包
    const [ walletOpen, setWalletOpen ] = useState(false);
    const handleWalletAlertClose = () => {
        setWalletOpen(false);
    };

    if(screenMD) {
        return (
            <React.Fragment>
                {walletOpen ? 
                    <HeaderWallet
                        isOpen={walletOpen}
                        category={0}
                        closeCallback={handleWalletAlertClose}
                    /> : ''
                }

                <Box sx={{
                    width:'100%',
                    height:'calc((100vw - 36px)*160/340)',
                }}>
                    <Swiper 
                        pagination={{ clickable: true }}
                        modules={[Autoplay,Pagination]} 
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        style={{
                            width:"100%",
                            height:'100%',
                            borderRadius:'18px',
                            '--swiper-pagination-color':'#fff',
                            '--swiper-pagination-bullet-size': '8px',
                            '--swiper-pagination-bottom': '18px',
                            '--swiper-pagination-bullet-inactive-color': '#000',
                            '--swiper-pagination-bullet-inactive-opacity': 0.5,
                            // '--swiper-pagination-bullet-inactive-color': '#fff'
                        }}
                    >
                        {imageMobileArr.map((item,index) => (
                            <SwiperSlide key={index} onClick={() => handleSlideClick(index)}>
                                {/* <CardMedia
                                    component="img"
                                    image={item}
                                    sx={{
                                        objectFit:'contain',
                                        width:"100%",
                                        height:'100%',
                                        borderRadius:'18px',
                                        cursor:'pointer',
                                    }}
                                /> */}
                                <TTLoadImage 
                                    image={item}
                                    objectFit={'contain'}
                                    width={'100%'}
                                    height={'100%'}
                                    borderRadius={'18px'}
                                    borderRadiusMobile={'18px'}
                                    skeletonBgColor={'#1E212A'}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {walletOpen ? 
                <HeaderWallet
                    isOpen={walletOpen}
                    category={0}
                    closeCallback={handleWalletAlertClose}
                /> : ''
            }

            <Box sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                height:'325px',
                overflow:'hidden',
            }} wrap='nowrap'>
                {/* <HomeContentMainBannerLargeImage 
                    image={largeImage}
                    imageOnClick={handleLargeImageClick}
                /> */}

                <Box sx={{
                    position:'relative',
                    width:'750px',
                    height:'325px',
                    borderRadius:'18px',
                    cursor:'pointer',
                    overflow:'hidden',
                }}>
                    <Swiper
                        ref={largeSwiperRef}
                        direction={'horizontal'}
                        slidesPerView={1}
                        spaceBetween={10}
                        loop={true}
                        allowTouchMove={false}
                        style={{
                            width:'100%',
                            height:'100%',
                            borderRadius:'18px',
                        }}
                    >
                        {imageArr.map((item,index) => (
                            <SwiperSlide key={index} onClick={() => handleSlideClick(index)}>
                                <Box sx={{
                                    position:'relative',
                                    width:'100%',
                                    height:'100%',
                                    borderRadius:'18px',
                                    cursor:'pointer',
                                    overflow:'hidden',
                                }}>
                                    {/* <CardMedia
                                        component="img"
                                        image={item}
                                        sx={{
                                            objectFit:'fill',
                                            width:'100%',
                                            height:'100%',
                                            position:'absolute',
                                            top:'0px',
                                            left:'0px',
                                        }}
                                    /> */}
                                    <TTLoadImage 
                                        image={item}
                                        objectFit={'fill'}
                                        width={'100%'}
                                        height={'100%'}
                                        borderRadius={'18px'}
                                        borderRadiusMobile={'18px'}
                                        skeletonBgColor={'#1E212A'}
                                    />
                                </Box>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
                            
                <Box sx={{
                    width:'226px',
                    height:'325px',
                    ml:'20px'
                }}>
                    <Swiper 
                        ref={swiperRef}
                        slidesPerView={3}
                        spaceBetween={40/3}
                        direction={'vertical'}
                        loop={true}
                        modules={[Autoplay]} 
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        allowTouchMove={false}
                        style={{
                            width:'100%',
                            height:'100%',
                            borderRadius:'8px',
                        }}
                        onSlideChange={handleMinImageChanged}
                    >
                        {imageArr.map((item,index) => (
                            <SwiperSlide key={index} onClick={() => handleMiniSlideClick(swiperRef,index)}>
                                <Box sx={{
                                    position:'relative',
                                    width:'100%',
                                    height:'98px',
                                    borderRadius:'8px',
                                    cursor:'pointer',
                                    overflow:'hidden',
                                }}>
                                    {/* <CardMedia
                                        component="img"
                                        image={item}
                                        sx={{
                                            objectFit:'fill',
                                            width:'100%',
                                            height:'100%',
                                            position:'absolute',
                                            top:'0px',
                                            left:'0px',
                                        }}
                                    /> */}
                                    <TTLoadImage 
                                        image={item}
                                        objectFit={'fill'}
                                        width={'100%'}
                                        height={'100%'}
                                        borderRadius={'8px'}
                                        borderRadiusMobile={'8px'}
                                        skeletonBgColor={'#1E212A'}
                                    />
                                    <Box sx={{
                                        bgcolor:showLargeIndex === index ? 'transparent' : 'rgba(1,1,1,0.7)',
                                        width:'100%',
                                        height:'100%',
                                        position:'absolute',
                                        top:'0px',
                                        left:'0px',
                                    }} />
                                </Box>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>

                {/* <Grid container sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'space-between',
                    alignItems:'start',
                    alignContent:'space-between',
                    width:'286px',
                    height:'298px',
                }}>
                    {imageArr.map((item,index) => (
                        <CardMedia
                            component="img"
                            image={ item }
                            sx={{
                                objectFit:'cover',
                                width:'100%',
                                height:'88px',
                                borderRadius:'8px',
                                cursor:'pointer',
                            }}
                            key={index}
                            onClick={() => handleMinImageClick(index)}
                        />
                    ))}
                </Grid> */}
            </Box>
        </React.Fragment>
    );
}

export default HomeContentMainBanner;