import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Footer from '../../footer/Footer';
import LimboGameContent from './LimboGameContent';
import { useLocation } from 'react-router-dom';
import '../../../styles/dicegame.css'
import '../../../styles/limbogame.css'
import '../../../assets/fonts/fonts.css';
const LimboLandingPage = (props) => {
    const location = useLocation();
    const state = location.state;
    const fromPage = state?.fromPage;
    const gameIcon = state?.gameIcon;
    const gameName = state?.gameName;
    const gameUrl = state?.gameUrl;
    const theme = useTheme();
    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);
    console.log('LimboLandingPage')
    return (
        <Grid
        sx={{
            pl:'260px',
            pt:'64px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                pl:'0px',
            },
        }}>
            <Box className="dice-scroll-div"
            sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between',
                height:'calc(100vh - 64px)',
                overflow: 'auto',
                overflowX: 'hidden',
            }}
            >
                <LimboGameContent />
                <Box sx={{ mt: 'auto' }}>
                    <Footer />
                </Box>
            </Box>
        </Grid>
    );
}

export default LimboLandingPage;