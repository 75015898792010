import React, { useEffect, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import seeIcon from '../../assets/images/login-and-register/login-and-register-password-see.png';
import hiddenIcon from '../../assets/images/login-and-register/login-and-register-password-hidden.png';
import TTAlert from '../common/TTAlert';
import HeaderWalletSetWithdrawPINInput from './HeaderWalletSetWithdrawPINInput';
import { useDispatch } from 'react-redux';
import TTLoading from '../common/TTLoading';
import { walletAddWithdrawPasswordService } from '../../network/service/wallet';
import { walletFiatWithdrawHasPasswordRefresh } from '../../features/walletSlice';
import CryptoJS from 'crypto-js';

const HeaderWalletSetWithdrawPIN = (props) => {
    const { 
        isOpen,
        closeCallback,
        confirmCallback,
    } = props;
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        withdrawal_password: t("withdrawal_password"),
        it_your__first: t("it_your__first"),
        set_up__password: t("set_up__password"),
        new_withdrawal_password: t("new_withdrawal_password"),
        confirm_new__password: t("confirm_new__password"),
        note_your__loss: t("note_your__loss"),
        confirm: t("confirm"),
        thePasswordInconsistent: t("the_password___inconsistent"),
    }

    const [ seeNewPWD,setSeeNewPWD ] = useState(false);
    const [ seeConfirmNewPWD,setSeeConfirmNewPWD ] = useState(false);
    const [ newPassword,setNewPassword ] = useState('');
    const [ confirmPassword,setConfirmPassword ] = useState('');

    const handleCloseClick = () => {
        closeCallback && closeCallback();
    };
    const handleSeeOrHiddenNewPWD = () => {
        setSeeNewPWD(!seeNewPWD);
    };
    const handleSeeOrHiddenConfirmNewPWD = () => {
        setSeeConfirmNewPWD(!seeConfirmNewPWD);
    };
    const handleNewPWDValueChanged = (text) => {
        setNewPassword(text);
    };
    const handleConfirmNewPWDValueChanged = (text) => {
        setConfirmPassword(text);
    };
    const handleConfirmBtnClick = () => {
        if(newPassword !== confirmPassword) {
            setOpen(true);
            setAlertMessage(textData.thePasswordInconsistent);
            return;
        }

        setOpenLoading(true);
        handleSetWithdrawPassword();
    };
    const handleSetWithdrawPassword = async () => {
        try {
            // const saltPWD = CryptoJS.MD5(newPassword + '{PWD_Salt_CC_Poker}').toString();
            const param = {
                password:newPassword,
            }
            await walletAddWithdrawPasswordService(param);
            setOpenLoading(false);
            dispatch(walletFiatWithdrawHasPasswordRefresh({hasWithdrawPassword:true}));
            confirmCallback && confirmCallback();
        } catch (error) {
            setOpenLoading(false);
            
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };

    // alert
    const [ openLoading,setOpenLoading ] = useState(false);
    const [ type, setType ] = useState('info');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog
                open={isOpen}
                // onClose={handleCloseClick}
                sx={{
                    [`& .${dialogClasses.paper}`]: {
                        bgcolor:'transparent',
                        borderRadius:'18px',
                        maxWidth:'calc(100% - 36px)',
                        maxHeight:'calc(100% - 36px)',
                        margin:'0px',
                        padding:'0px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'8px',
                            maxWidth:'calc(100% - 28px)',
                            maxHeight:'calc(100% - 28px)',
                            width:'calc(100% - 28px)',
                        },
                    },
                }}
            >
                <DialogContent sx={{
                    bgcolor:'transparent',
                    borderRadius:'18px',
                    width:'500px',
                    maxWidth:'100%',
                    m:'0px',
                    p:'0px',
                    overflow:'auto',
                    scrollbarWidth: 'none', // Firefox
                    msOverflowStyle: 'none', // Internet Explorer
                    '&::-webkit-scrollbar': {
                        display: 'none', // Chrome, Safari, Edge
                    },
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'100%',
                        borderRadius:'8px',
                    },
                }}>
                    <TTLoading 
                        open={openLoading}
                        isForce={true}
                    />
                    <TTAlert 
                        type={type}
                        isOpen={open}
                        message={alertMessage}
                        closeCallback={handleAlertClose}
                    />

                    <Box sx={{
                        bgcolor:'#1E2736',
                        borderRadius:'18px',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'12px',
                        padding:'18px',
                        width:'calc(100% - 36px)',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'8px',
                        },
                    }}>
                        <Grid container sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            width:'100%',
                        }}>
                            <Typography sx={{
                                color:'#ACB3D7',
                                fontSize:'18px',
                                fontWeight:700,
                            }}>
                                {textData.withdrawal_password}
                            </Typography>
                            <IconButton sx={{
                                mt:'-8px',
                                mr:'-8px',
                            }} onClick={handleCloseClick}>
                                <CardMedia
                                    component='img'
                                    image={closeIcon}
                                    sx={{
                                        objectFit:'contain',
                                        width:'18px',
                                        height:'18px',
                                        '&:hover': {
                                            filter: 'brightness(0) invert(1)',
                                        },
                                    }}
                                />
                            </IconButton>
                        </Grid>

                        <Box sx={{
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'center',
                            alignItems:'center',
                            padding:'10px',
                            width:'calc(100% - 20px)',
                        }}>
                            <Typography sx={{
                                color:'#00D66F',
                                fontSize:'12px',
                                fontWeight:700,
                                width:'100%',
                            }}>
                                {textData.it_your__first}
                            </Typography>
                            <Typography sx={{
                                color:'#FFFFFF',
                                fontSize:'18px',
                                fontWeight:700,
                                width:'100%',
                                mt:'18px'
                            }}>
                                {textData.set_up__password}
                            </Typography>
                            <Box sx={{
                                bgcolor:'#2C394E',
                                width:'100%',
                                height:'2px',
                                mt:'18px',
                            }} />
                            <Grid container sx={{
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:'center',
                                mt:'10px',
                                width:'100%',
                            }}>
                                <Typography sx={{
                                    color:'#8EA3C3',
                                    fontSize:'14px',
                                    fontWeight:700,
                                }}>
                                    {textData.new_withdrawal_password}
                                </Typography>
                                <IconButton sx={{
                                    padding:'0px',
                                }} onClick={handleSeeOrHiddenNewPWD}>
                                    <CardMedia
                                        component='img'
                                        image={seeNewPWD ? hiddenIcon : seeIcon}
                                        sx={{
                                            objectFit:'contain',
                                            width:'30px',
                                            height:'30px',
                                            '&:hover': {
                                                filter: 'brightness(0) invert(1)',
                                            },
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                            <Box sx={{
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                                width:'100%',
                                height:'58px',
                                mt:'2px'
                            }}>
                                <HeaderWalletSetWithdrawPINInput
                                    isSee={seeNewPWD}
                                    handleOnValueChanged={handleNewPWDValueChanged}
                                />
                            </Box>
                            <Grid container sx={{
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:'center',
                                mt:'18px',
                                width:'100%',
                            }}>
                                <Typography sx={{
                                    color:'#8EA3C3',
                                    fontSize:'14px',
                                    fontWeight:700,
                                }}>
                                    {textData.confirm_new__password}
                                </Typography>
                                <IconButton sx={{
                                    padding:'0px',
                                }} onClick={handleSeeOrHiddenConfirmNewPWD}>
                                    <CardMedia
                                        component='img'
                                        image={seeConfirmNewPWD ? hiddenIcon : seeIcon}
                                        sx={{
                                            objectFit:'contain',
                                            width:'30px',
                                            height:'30px',
                                            '&:hover': {
                                                filter: 'brightness(0) invert(1)',
                                            },
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                            <Box sx={{
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                                width:'100%',
                                height:'58px',
                                mt:'2px'
                            }}>
                                <HeaderWalletSetWithdrawPINInput
                                    isSee={seeConfirmNewPWD}
                                    isNoAutoFocus={true}
                                    handleOnValueChanged={handleConfirmNewPWDValueChanged}
                                />
                            </Box>
                            <Typography sx={{
                                color:'#D0A358',
                                fontSize:'14px',
                                fontWeight:600,
                                mt:'18px',
                            }}>
                                {textData.note_your__loss}
                            </Typography>
                            <Button disabled={(newPassword.length === 6 && confirmPassword.length === 6) ? false : true} sx={{
                                textTransform:'none',
                                textDecoration:'none',
                                bgcolor:'#1184FA',
                                color:'#FFFFFF',
                                fontSize:'18px',
                                fontWeight:700,
                                width:'100%',
                                height:'40px',
                                borderRadius:'8px',
                                margin:'50px 0px 28px 0px',
                                '&:hover': {
                                    bgcolor:'#1184FA',
                                },
                                '&.Mui-disabled': {
                                    bgcolor: alpha(
                                        '#1184FA',
                                        0.5,
                                    ),
                                }
                            }} onClick={handleConfirmBtnClick}>
                                {textData.confirm}
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default HeaderWalletSetWithdrawPIN;