import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import logoIcon from '../../assets/images/header/header-logo.png';
import HeaderWalletWithdrawBalance from './HeaderWalletWithdrawBalance';
import { walletFiatWithdrawRecordListRefresh } from '../../features/walletSlice';
import { walletFiatWithdrawListService } from '../../network/service/wallet';
import TTLoading from '../common/TTLoading';
import TTAlert from '../common/TTAlert';
import { timestampToDateTime } from '../../tools/CommonTool';

const HeaderWalletWithdrawRecordItem = (props) => {
    const { time,amount,method,status } = props;
    const theme = useTheme();
    const arr = [
        time,amount,method,status,
    ];

    return (
        <Box sx={{
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center',
            width:'100%',
        }}>
            {arr.map((item,index) => (
                <Typography sx={{
                    color:'#8EA3C3',
                    fontSize:'15px',
                    fontWeight:400,
                    textAlign:'center',
                    width:`calc(100% / ${arr.length})`,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'10px',
                    },
                }} key={index}>
                    {item}
                </Typography>
            ))}
        </Box>
    );
};

const HeaderWalletWithdrawRecord = (props) => {
    const {
        heightChanged,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        total_withdraw: t("total_withdraw"),
        time: t("time"),
        amount: t("amount"),
        method: t("method"),
        status: t("status"),
        action: t("action"),
        no_data: t("no_data"),
        confirm: t("confirm"),
        your_balance: t("your_balance"),
        inProgress: t("in_progress"),
        success: t("success"),
        failed: t("failed"),
    }

    const recordData = useSelector((state) => state.wallet.fiatWithdrawRecord);
    const [ page,setPage ] = useState(1);
    const pageSize = 4;
    const [ totalPage,setTotalPage ] = useState(1);

    const withdrawTitleArr = [
        textData.time,
        textData.amount,
        textData.method,
        textData.status,
    ];

    const handleFetchWithdrawRecord = async (size) => {
        try {
            const param = {
                symbol:'BRL',
                page:page,
                pageSize:size,
            }
            const response = await walletFiatWithdrawListService(param);
            setOpenLoading(false);
            dispatch(walletFiatWithdrawRecordListRefresh({record:response.data.data}));
        } catch (error) {
            setOpenLoading(false);
            
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };

    const handleFetchPrePageClick = () => {
        if(page > 1) {
            setOpenLoading(true);
            setPage((prevPage) => prevPage - 1);
        }
    };
    const handleFetchNextPageClick = () => {
        if(page < totalPage) {
            setOpenLoading(true);
            setPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        handleFetchWithdrawRecord(pageSize);
    }, [page]);
    useEffect(() => {
        let totalPage = Math.floor(recordData.total_count / pageSize);
        if((recordData.total_count % pageSize) > 0) {
            totalPage = totalPage + 1;
        }
        setTotalPage(totalPage);
    }, [recordData]);

    // 动态获取高度
    const boxRef = useRef(null);
    useEffect(() => {
        const boxHeight = boxRef.current.getBoundingClientRect().height;
        heightChanged && heightChanged(boxHeight);
    }, [recordData]);

    //alert 
    const [ openLoading,setOpenLoading ] = useState(false);
    const [ type, setType ] = useState('info');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <TTAlert 
                type={type}
                isOpen={open}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />

            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                width:screenMD ? '100%' : 'calc(100% - 198px)',
                height:'100%',
            }} ref={boxRef}>
                {screenMD ? 
                    <Typography sx={{
                        color:'#8EA3C3',
                        fontSize:'18px',
                        fontWeight:700,
                    }}>
                        {textData.your_balance}
                    </Typography> : ''
                }
                <HeaderWalletWithdrawBalance />
                <Box sx={{
                    bgcolor:'#2C394E',
                    borderRadius:'8px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    padding:'10px',
                    width:'calc(100% - 20px)',
                    height:'calc(100% - 20px)',
                    mt:'12px',
                }}>
                    <Box sx={{
                        display:'flex',
                        justifyContent:screenMD ? 'flex-start' : 'flex-end',
                        alignItems:'center',
                        width:'100%',
                        height:screenMD ? '22px' : '40px',
                    }}>
                        <Typography sx={{
                            color:'#8EA3C3',
                            fontSize:'18px',
                            fontWeight:600,
                            textAlign:'right',
                        }}>
                            {`${textData.total_withdraw} `}
                            <span style={{color:'#FFFFFF'}}>{`R$${recordData.total_withdraw_amount}`}</span>
                        </Typography>
                    </Box>
                    <Box sx={{
                        bgcolor:'#1E2736',
                        borderRadius:'8px',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        width:'100%',
                        mt:'10px',
                        gap:'18px',
                        pb:'18px',
                    }}>
                        <Box sx={{
                            bgcolor:'#16202C',
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'center',
                            width:'100%',
                            height:'40px',
                        }}>
                            {withdrawTitleArr.map((item,index) => (
                                <Typography sx={{
                                    color:'#8EA3C3',
                                    fontSize:'15px',
                                    fontWeight:400,
                                    textAlign:'center',
                                    width:`calc(100% / ${withdrawTitleArr.length})`,
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        fontSize:'10px',
                                    },
                                }} key={index}>
                                    {item}
                                </Typography>
                            ))}
                        </Box>
                        {recordData.data === null ? 
                            Array(10).fill('').map((item,index) => (
                                <Skeleton 
                                    variant="rounded" 
                                    animation="wave"
                                    sx={{
                                        bgcolor:'#1E212A',
                                        borderRadius:'8px',
                                        width:'100%',
                                        height:'20px',
                                    }} 
                                    key={index}
                                />
                            )) : 
                            (recordData.data.length === 0 ? 
                                <Box sx={{
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    width:'100%',
                                    height:screenMD ? '230px' : '400px',
                                }}>
                                    <CardMedia
                                        component="img"
                                        image={ logoIcon }
                                        sx={{
                                            objectFit:'contain',
                                            width:'219px',
                                            height:'70px',
                                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                width:'128px',
                                                height:'40px',
                                            },
                                        }}
                                    />
                                    <Typography sx={{
                                        color:'#6D728D',
                                        fontSize:'18px',
                                        fontWeight:400,
                                        textAlign:'center',
                                        mt:'15',
                                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                            fontSize:'14px',
                                        },
                                    }}>
                                        {textData.no_data}
                                    </Typography>
                                </Box> : 
                                <React.Fragment>
                                    {recordData.data.map((item,index) => (
                                        <HeaderWalletWithdrawRecordItem 
                                            time={timestampToDateTime(item.created_at,'yyyy-MM-dd HH:mm:ss')}
                                            amount={item.amount}
                                            method={'PIX'}
                                            status={item.status === 1 ? textData.success : ((item.status === 2 || item.status === 3) ? textData.inProgress : textData.failed)}
                                            key={index}
                                        />
                                ))}
                                </React.Fragment>
                            )
                        }
                    </Box>
                    {recordData.data !== null && recordData.data.length > 0 ? 
                        <Box sx={{
                            width:'100%',
                            mt:'18px',
                        }}>
                            <Box sx={{
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                            }}>
                                <IconButton onClick={handleFetchPrePageClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                                    <path d="M8.99961 12.0203L0.599609 6.42031L8.99961 0.820313" fill="#8EA3C3"/>
                                    </svg>
                                </IconButton>
                                <Typography sx={{
                                    color:'#8EA3C3',
                                    fontSize:'18px',
                                    fontWeight:500,
                                    padding:'0px 24px',
                                }}>
                                    {`${page}/${totalPage}`}
                                </Typography>
                                <IconButton onClick={handleFetchNextPageClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                                    <path d="M-0.000585422 12.0203L8.39941 6.42031L-0.000585556 0.820313" fill="#8EA3C3"/>
                                    </svg>
                                </IconButton>
                            </Box>
                        </Box> : ''
                    }
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default HeaderWalletWithdrawRecord;