import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import playIcon from '../../assets/images/home/home-play.png';

const TTGameIcon = (props) => {
    const {
        width,height,mobileSpace,column,image,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    const [ isHover,setIsHover ] = useState(false);
    const handleMouseEnter = () => {
        if(!screenMD) {
            setIsHover(true);
        }
    };
    const handleMouseLeave = () => {
        if(!screenMD) {
            setIsHover(false);
        }
    };

    const [imageLoaded, setImageLoaded] = useState(false);
    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <Box sx={{
            position: 'relative',
            overflow:'hidden',
            width:width,
            height:height,
            borderRadius:'24px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:`calc((100vw - ${mobileSpace}) / ${column})`,
                height:`calc((100vw - ${mobileSpace}) / ${column})`,
                borderRadius:'16px',
            },
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
            <CardMedia
                component="img"
                image={ image }
                onLoad={handleImageLoad}
                sx={{
                    objectFit:'cover',
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'100%',
                    transform:isHover ? 'scale(1.1)' : 'scale(1.0)',
                    transition: theme.transitions.create(['transform'], {
                        duration: 300,
                        easing: 'ease-in-out',
                    }),
                }}
            /> 
            {!imageLoaded ?
                <Skeleton 
                variant="rounded" 
                animation="wave"
                sx={{
                    bgcolor:'#1E212A',
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'100%',
                    borderRadius:'24px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:'16px',
                    },
                }} />
                 :
                <Box sx={{
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'100%',
                    height:'100%',
                    backgroundColor:!isHover ? 'transparent' : 'rgba(0, 0, 0, 0.60)',
                    opacity:isHover ? 1 : 0,
                    transition: theme.transitions.create(['backgroundColor','opacity'], {
                        duration: 300,
                        easing: 'ease-in-out',
                    }),
                }}>
                    <CardMedia
                        component="img"
                        image={ playIcon }
                        sx={{
                            objectFit:'cover',
                            width:'48px',
                            height:'48px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'30px',
                                height:'30px',
                            },
                            transform:isHover ? 'scale(1.0)' : 'scale(1.3)',
                            transition: theme.transitions.create(['transform'], {
                                duration: 400,
                                easing: 'ease-in-out',
                            }),
                        }}
                    />
                </Box>
            }
        </Box>
    );
}

export default TTGameIcon;