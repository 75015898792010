import React from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import CardMedia from "@mui/material/CardMedia";
import forgetIcon from '../../assets/images/login-and-register/login-and-register-forget.png';
import { Typography } from "@mui/material";

const LoginAndRegisterContentRightForget = (props) => {
    const { forgetPassword,isHidden,forgetOnClick } = props;
    const theme = useTheme();

    return (
        <Box sx={{
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center',
            width:'100%',
            display:!isHidden ? 'block' : 'none',
        }}>
            <Box sx={{
                bgcolor:'transparent',
                borderRadius:'0px',
                cursor:'pointer',
                textTransform: 'none',
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'7px',
                mt:'18px',
                '&:hover': {
                    filter: 'brightness(0) invert(1)',
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'12px',
                },
            }} onClick={forgetOnClick}>
                <CardMedia
                    component='img'
                    image={forgetIcon}
                    sx={{
                        width:'32px',
                        height:'32px',
                    }}
                />
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'15px',
                    fontStyle:'normal',
                    fontWeight:400,
                }}>
                    {forgetPassword}
                </Typography>
            </Box>
        </Box>
    );
}

export default LoginAndRegisterContentRightForget;