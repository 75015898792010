import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DepositContentMain from './DepositContentMain';
import DepositContentHeader from './DepositContentHeader';
import Footer from '../footer/Footer';

const DepositContent = (props) => {
    const params = new URLSearchParams(window.location.search);
    const network = params.get('network');
    const token = params.get('token');
    const address = params.get('address');
    const language = params.get('language');
    const depositLimit = params.get('depositLimit');
    return (
        <Grid>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between',
            }}>
                <DepositContentHeader />
                <DepositContentMain 
                    network={network}
                    token={token}
                    address={address}
                    language={language}
                    depositLimit={depositLimit}
                />
                <Footer />
            </Box>
        </Grid>
    );
}

export default DepositContent;