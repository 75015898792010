import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab, { tabClasses } from "@mui/material/Tab";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import List from "@mui/material/List";
import Grow from '@mui/material/Grow';
import usdtIcon from '../../assets/images/header/header-usdt.png';

const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className={`${tabsClasses.indicatorSpan}`} /> }}
    />
  ))(
    ({ theme }) => ({        
        backgroundColor:'#1E212A',
        borderRadius:'19px',
        padding:'0px 18px',
        width:'calc(100% - 36px)',
        maxWidth:'calc(100% - 36px)',
        [theme.breakpoints.down(MobileBreakpoint.match)]: {
            backgroundColor:'transparent',
            marginLeft:'-18px',
            borderRadius:'0px',
            minHeight:'30px',
            height:'30px',
        },
        [`& .${tabsClasses.indicator}`]: {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          [theme.breakpoints.down(MobileBreakpoint.match)]: {
            marginBottom:2,
          },
        },
        [`& .${tabsClasses.indicatorSpan}`]: {
          maxWidth: 21,
          height: 0,
          width: '100%',
          backgroundColor: 'transparent',
          borderRadius: 2,
          [theme.breakpoints.down(MobileBreakpoint.match)]: {
            height: 4,
            backgroundColor: '#D9D9D9',
          },
        },
    })
);
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      fontWeight: '400',
      fontSize: '24px',
      fontStyle: 'normal',
      color: '#6D728D',
      margin: '0px',
      padding: '0px',
      marginRight: '88px',
      [theme.breakpoints.down(MobileBreakpoint.match)]: {
        minHeight:'20px',
        minWidth:'20px',
        height:'20px',
        fontSize: '12px',
        marginRight: '48px',
      },
      '&.Mui-selected': {
        color: '#FFFFFF',
        [theme.breakpoints.down(MobileBreakpoint.match)]: {
            fontSize: '15px',
        },
      },
      [`& .${tabClasses.labelIcon}`]: {
        padding:'0px',
        margin:'0px',
      }
    }),
);

const ReferralContentMainContentReferralDataItem = (props) => {
    const {
        item,maxAmountLength,handleAddResultMaxAmountLength,
    } = props;
    const { symbol,sum_amount } = item;
    const theme = useTheme();

    if(sum_amount.toString().length < maxAmountLength) {
        // 位数不足以 添加0 需要将最大长度+1
        if(!sum_amount.toString().includes('.') && 
            (sum_amount.toString().length + 2) > maxAmountLength) {
            handleAddResultMaxAmountLength && handleAddResultMaxAmountLength(
                1
            );
        }
    }

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
        }}>
            <Box sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'18px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    gap:'8px',
                },
            }}>
                <CardMedia
                    component="img"
                    image={usdtIcon}
                    sx={{
                        width:'28px',
                        height:'28px',
                        objectFit:'contain',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'18px',
                            height:'18px',
                        },
                    }}
                />
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'28px',
                    fontStyle:'normal',
                    fontWeight:500,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                        fontWeight:400,
                    },
                }}>
                    {symbol}
                </Typography>
            </Box>
            <Typography sx={{
                color:'#FFFFFF',
                fontSize:'28px',
                fontStyle:'normal',
                fontWeight:500,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'15px',
                    fontWeight:400,
                },
            }}>
                {sum_amount}
                <span style={{color:'#6D728D'}}>{(sum_amount.toString().includes('.') ? '' : '.').padEnd(maxAmountLength - sum_amount.toString().length, '0')}</span>
            </Typography>
        </Grid>
    );
}

const ReferralContentMainContentReferralData = (props) => {
    const {
        yesterdayArr,sevenDayArr,thirtyDayArr,
    } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        referralData: t("referral_data"),
        yesterday: t("yesterday"),
        sevenDay: t("seven_day"),
        thirtyDay: t("thirty_day"),
    };

    const [ referralDataArr, setReferralDataArr ] = useState(yesterdayArr);
    const [ currentCategory, setCurrentCategory ] = useState(0);
    const handleMenuClick = (event, newValue) => {
        if(newValue !== currentCategory) {
            setCurrentCategory(newValue);
        }
    };

    const maxAmountLength = referralDataArr.reduce((maxLen, item) => {
        const amountLength = item.sum_amount.toString().length;
        return amountLength > maxLen ? amountLength : maxLen;
    }, 0);
    const [resultMaxAmountLength, setResultMaxAmountLength] = useState(maxAmountLength);
    const handleAddResultMaxAmountLength = (addNum) => {
        setResultMaxAmountLength(maxAmountLength + addNum);
    };

    useEffect(() => {
        if(currentCategory === 0) {
            setReferralDataArr(yesterdayArr);
        }else if(currentCategory === 1) {
            setReferralDataArr(sevenDayArr);
        }else if(currentCategory === 2) {
            setReferralDataArr(thirtyDayArr);
        }
    }, [currentCategory,yesterdayArr]);

    return (
        <Box sx={{
            bgcolor:'#15151B',
            borderRadius:'18px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            padding:'26px 18px',
            gap:'18px',
            width:'calc(100% - 36px)',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                borderRadius:'0px',
                padding:'18px 18px 8px 18px',
                gap:'18px',
            },
        }}>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'24px',
                fontStyle:'normal',
                fontWeight:500,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'15px',
                },
            }}>
                {textData.referralData}
            </Typography>
            <StyledTabs
                value={currentCategory}
                onChange={handleMenuClick}
                aria-label="styled tabs example"
            >
                <StyledTab label={textData.yesterday} />
                <StyledTab label={textData.sevenDay} />
                <StyledTab label={textData.thirtyDay} />
            </StyledTabs>
            <List sx={{
                width:'calc(100% - 36px)',
                ml:'18px',
                mr:'18px',
            }}>
                {referralDataArr.map((item,index) => (
                    <Grow in={true} key={
                        `${item.symbol}${item.amount}`
                    } 
                    style={{ 
                        transformOrigin: '0 0 0',
                        transitionDelay: `${index * 80}ms`
                    }}
                    timeout={{enter:(referralDataArr.length - 1) * 180,exit:0,}}
                    >
                        <Box sx={{
                            mt:index === 0 ? '0px' : '20px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                mt:index === 0 ? '-10px' : '20px',
                            },
                        }}>
                            <ReferralContentMainContentReferralDataItem 
                                item={item}
                                maxAmountLength={resultMaxAmountLength}
                                handleAddResultMaxAmountLength={handleAddResultMaxAmountLength}
                            />
                        </Box>
                    </Grow>
                ))}
            </List>
        </Box>
    );
}

export default ReferralContentMainContentReferralData;