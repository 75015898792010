import React from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import HouseGamesContent from '../component/inHouseGames/HouseGamesContent';

const HouseGames = () => {
    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.inHouseGames} drawerIndex={4}/>
            <HouseGamesContent />
        </React.Fragment>
    )
}

export default HouseGames;