import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WithdrawContentToken from '../WithdrawContentToken';
import WithdrawContentNoEditNetwork from './WithdrawContentNoEditNetwork';
import WithdrawContentNoEditAddress from './WithdrawContentNoEditAddress';

const WithdrawContentNoEditMain = (props) => {
    const {
        network,networkType,token,address,amount,gas,balance,fundingBalance,language
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    if(!i18next.language.toLowerCase().includes(language.toLowerCase())) {
        i18next.changeLanguage(language);
    }
    const { t } = useTranslation();
    const textData = {
        withdraw: t("Withdraw"), 
        beforeWithdrawingCorrect: t("before_withdrawing___correct"), 
        network: t("network"), 
        address: t("address"), 
        paste: t("paste"), 
        amount: t("amount"), 
        max: t("max"), 
        balance: t("balance"), 
        gasFee: t("gas_fee"), 
        gasFeeRequires: t("gas_fee_requires"), 
        withdrawalAmountsFrom: t("withdrawal_amounts_from"), 
        theGasAmount: t("the_gas____amount"), 
        estimatedArrival: t("estimated_arrival"), 
        confirm: t("confirm"), 
        theWithdrawalBetween: t("the_withdrawal___between"), 
        failed: t("failed"), 
        withdrawSuccessfully: t("withdraw_successfully"), 
    };

    return (
        <Grid container sx={{
            mt:'100px',
            padding:'0px 60px 100px 60px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'70px',
                padding:'0px',
            },
        }}>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                gap:'25px',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1000px',
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    gap:'8px',
                },
            }}>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'start',
                    alignItems:'start',
                    width:'100%',
                }}>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'32px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            ml:'18px',
                            fontSize:'15px',
                        },
                    }}>
                        {textData.withdraw}
                    </Typography>
                    <Typography sx={{
                        color:'#F15A5A',
                        fontSize:'24px',
                        fontStyle:'normal',
                        fontWeight:400,
                        width:'100%',
                        mt:'18px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'calc(100% - 36px)',
                            margin:'8px 18px 0px 18px',
                            fontSize:'12px',
                        },
                    }}>
                        {textData.beforeWithdrawingCorrect}
                    </Typography>
                </Box>
                <WithdrawContentToken 
                    token={token}
                />
                <WithdrawContentNoEditNetwork 
                    network={textData.network}
                    defaultValue={network}
                />
                <WithdrawContentNoEditAddress 
                    address={textData.address}
                    paste={textData.paste}
                    amount={textData.amount}
                    max={textData.max}
                    balance={textData.balance}
                    gasFee={textData.gasFee}
                    gasFeeRequires={textData.gasFeeRequires}
                    failed={textData.failed}
                    withdrawSuccessfully={textData.withdrawSuccessfully}
                    withdrawalAmountsFrom={textData.withdrawalAmountsFrom}
                    theGasAmount={textData.theGasAmount}
                    estimatedArrival={textData.estimatedArrival}
                    confirm={textData.confirm}
                    theWithdrawalBetween={textData.theWithdrawalBetween}
                    defaultAddress={address}
                    defaultAmount={amount}
                    defaultBalance={balance}
                    defaultFundingBalance={fundingBalance}
                    defaultGasFee={gas}
                    token={token}
                    chain={network}
                    chainType={networkType}
                />
                {screenMD ? <Box /> : ''}
            </Container>
        </Grid>
    );
}

export default WithdrawContentNoEditMain;