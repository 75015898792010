import React from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import PokerContent from '../component/poker/PokerContent';

const Poker = () => {
    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.poker} drawerIndex={5} />
            <PokerContent />
        </React.Fragment>
    )
}

export default Poker;