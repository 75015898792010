import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';
import '../../../styles/dicegame.css'
import LimboComponetMenuTabs from './limboComponet/LimboComponetMenuTabs';
import LimboComponetFooter from './limboComponet/LimboComponetFooter';
import Contexts from './LimboContext';
import LimboComponetGamePart from './limboComponet/LimboComponetGamePart';

const { MaxBetContext, AnimationActive,Multiplier,Requesting,
    RollResult,DiceVolume,BetHistory,IsAutoBetting,AutoBetStart,AutoBetStop } = Contexts;

const LimboHorGame = ({ isMaxBetActive, setIsMaxBetActive,isAnimationActive,setIsAnimationActive,
    multiplier,
    setMultiplier,
    httpManager,
    isRequesting,
    setIsRequesting,
    rollResult,
    setRollResult,
    totalBalance,
    globalVolume,
    setGlobalVolume,
    historyBetsArr,
    setHistoryBetsArr,
    isAutoBetActive,
    setIsAutoBetActive,
    layoutDirection,
    autoBetStart,
    setAutoBetStart,
    autoBetStop,
    setAutoBetStop,
 }) => {
    //底部菜单底色
    const footerBGColor = '#1E2736';
    //游戏区域底色
    const gameBGColor = '#16202C';
    //菜单区域底色
    const menuBGColor = '#242d3d';
    const theme = useTheme();

    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);
    return (
      <React.Fragment>
        {/*宽度撑满的游戏区域div */}
        <Box sx={{
            display:'flex',
            backgroundColor:'#0C131D',
            justifyContent:'center',
            alignItems:'center',
            width:'calc(100% - 10px)',
            // height:'549px',
            padding:'5px 5px 5px 5px',
            marginTop:'27px',
            }}>
            {/*游戏区域完整div */}
             <Box sx={{ 
                display:'flex',
                flexDirection: 'column',
                justifyContent:'center',
                alignItems:'center',
                width:'816px',
                // height:'549px',
                flexShrink: 1,
                padding:'5px 5px 5px 5px',
            }}>
                <Box sx={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'center',
                    backgroundColor:menuBGColor,
                    maxWidth:'816px',
                    width:'100%',
                    flexShrink: 1,
                    // height:'513px',
                    borderTopLeftRadius:'5px',
                    borderTopRightRadius:'5px',
                    overflow:'hidden',
                }}>
                    {/* 侧边菜单区域 */}
                    <Box sx={{
                        display:'flex',
                        flexDirection: 'column',
                        backgroundColor: menuBGColor,
                        width:'240px',
                        flexShrink: 0,
                        // height:'484px',
                        padding:'8px 8px 8px 8px',
                    }}>
                        <AutoBetStop.Provider value={{ autoBetStop, setAutoBetStop }}>
                        <AutoBetStart.Provider value={{ autoBetStart, setAutoBetStart }}>
                        <IsAutoBetting.Provider value={{ isAutoBetActive, setIsAutoBetActive }}>
                        <BetHistory.Provider value={{ historyBetsArr, setHistoryBetsArr }}>
                        <DiceVolume.Provider value={{ globalVolume, setGlobalVolume }}>
                        <RollResult.Provider value={{ rollResult, setRollResult }}>
                        <Requesting.Provider value={{ isRequesting, setIsRequesting }}>
                        <Multiplier.Provider value={{ multiplier, setMultiplier }}>
                        <AnimationActive.Provider value={{ isAnimationActive, setIsAnimationActive }}>
                        <MaxBetContext.Provider value={{ isMaxBetActive, setIsMaxBetActive }}>
                            <LimboComponetMenuTabs 
                                httpManager={httpManager}
                                totalBalance={totalBalance}
                                layoutDirection={layoutDirection}
                            />
                        </MaxBetContext.Provider>
                        </AnimationActive.Provider>
                        </Multiplier.Provider>
                        </Requesting.Provider>
                        </RollResult.Provider>
                        </DiceVolume.Provider>
                        </BetHistory.Provider>
                        </IsAutoBetting.Provider>
                        </AutoBetStart.Provider>
                        </AutoBetStop.Provider>
                    </Box>
                    {/* 游戏内容区域 */}
                    <Box sx={{
                        backgroundColor: gameBGColor,
                        maxWidth:'568px',
                        flex: '1 1 auto',
                        marginBottom:'2px',
                        minHeight:'482px',
                    }}>
                        <IsAutoBetting.Provider value={{ isAutoBetActive, setIsAutoBetActive }}>
                        <BetHistory.Provider value={{ historyBetsArr, setHistoryBetsArr }}>
                        <DiceVolume.Provider value={{ globalVolume, setGlobalVolume }}>
                        <RollResult.Provider value={{ rollResult, setRollResult }}>
                        <Requesting.Provider value={{ isRequesting, setIsRequesting }}>
                        <Multiplier.Provider value={{ multiplier, setMultiplier }}>
                        <AnimationActive.Provider value={{ isAnimationActive, setIsAnimationActive }}>
                            <LimboComponetGamePart/>
                        </AnimationActive.Provider>
                        </Multiplier.Provider>
                        </Requesting.Provider>
                        </RollResult.Provider>
                        </DiceVolume.Provider>
                        </BetHistory.Provider>
                        </IsAutoBetting.Provider>
                    </Box>
                </Box>
                {/* 底部菜单区域 */}
                <Box sx={{
                    backgroundColor: footerBGColor,
                    maxWidth:'806px',
                    width:'calc(100% - 10px)',
                    flexShrink: 1,
                    height:'42px',
                    padding:'5px 5px 5px 5px',
                    borderBottomLeftRadius:'5px',
                    borderBottomRightRadius:'5px',
                }}>
                    <DiceVolume.Provider value={{ globalVolume, setGlobalVolume }}>
                    <AnimationActive.Provider value={{ isAnimationActive, setIsAnimationActive }}>
                    <MaxBetContext.Provider value={{ isMaxBetActive, setIsMaxBetActive }}>
                        <LimboComponetFooter/>
                    </MaxBetContext.Provider>
                    </AnimationActive.Provider>
                    </DiceVolume.Provider>
                </Box> 
            </Box>
        </Box>
            
      </React.Fragment>
    );
}

export default LimboHorGame;