import React from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import DiceLandingPage from '../component/HtmlGames/diceAbout/DiceLandingPage';

const SDGameDice = () => {
    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.diceGame} drawerIndex={1}/>
            <DiceLandingPage />
        </React.Fragment>
    )
}

export default SDGameDice;