import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from '@mui/material/Grid';
import HeaderWalletWithdrawMenu from './HeaderWalletWithdrawMenu';
import HeaderWalletWithdrawWithdraw from './HeaderWalletWithdrawWithdraw';
import HeaderWalletWithdrawRecord from './HeaderWalletWithdrawRecord';
import HeaderWalletWithdrawAccount from './HeaderWalletWithdrawAccount';

const HeaderWalletWithdraw = (props) => {
    const {
        withdrawCategory,withdrawCategoryChangedCallback,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    const [ isChooseAccount,setIsChooseAccount ] = useState(false);
    const [ chooseAccount,setChooseAccount ] = useState(null);
    const [ currentMenu,setCurrentMenu ] = useState(() => {
        if(screenMD) {
            return withdrawCategory;
        }else {
            return 0;
        }
    });
    const handleMenuClick = (index) => {
        if(index === currentMenu) {
            return;
        }

        if(index === 2) {
            setIsChooseAccount(false);
        }
        setCurrentMenu(index);
    };

    const handleAddAccountCallback = () => {
        setIsChooseAccount(true);
        setCurrentMenu(2);

        withdrawCategoryChangedCallback && withdrawCategoryChangedCallback(2);
    };
    const handleChooseAccountCallback = (PIX) => {
        setChooseAccount(PIX);
        setCurrentMenu(0);

        withdrawCategoryChangedCallback && withdrawCategoryChangedCallback(0);
    };

    useEffect(() => {
        if(screenMD) {
            if(withdrawCategory !== 2) {
                setIsChooseAccount(false);
            }
            setCurrentMenu(withdrawCategory);
        }
    }, [withdrawCategory]);

    // 动态设置左侧菜单高度
    const [ parentBoxHeight, setParentBoxHeight ] = useState(208);
    const handleRightBoxHeightChanged = (height) => {
        if(height > 208) {
            setParentBoxHeight(height);
        }else {
            setParentBoxHeight(208);
        }
    };
    
    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-start',
            width:'100%',
        }}>
            {screenMD ? '' : 
                <HeaderWalletWithdrawMenu 
                    defaultMenu={currentMenu}
                    boxHeight={parentBoxHeight}
                    menuClick={handleMenuClick}
                />
            }
            {currentMenu === 0 ?
                <HeaderWalletWithdrawWithdraw 
                    heightChanged={handleRightBoxHeightChanged}
                    chooseAccountCallback={handleAddAccountCallback}
                    chooseAccount={chooseAccount}
                /> :
                (currentMenu === 1 ?
                    <HeaderWalletWithdrawRecord 
                        heightChanged={handleRightBoxHeightChanged}
                    /> :
                    <HeaderWalletWithdrawAccount
                        heightChanged={handleRightBoxHeightChanged}
                        isChoose={isChooseAccount}
                        chooseAccount={chooseAccount}
                        chooseAccountCallback={handleChooseAccountCallback}
                    />
                )
            }
        </Grid>
    );
}

export default HeaderWalletWithdraw;