import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import usdtIcon from '../../assets/images/header/header-usdt.png';
import defaultAvatarIcon from '../../assets/images/header/header-default-avatar.png';

const HomeContentMainHistoryBlockItemIconImage = (props) => {
    const { isToday,icon,text } = props;
    const theme = useTheme();

    if(isToday) {
        return (
            <Box sx={{
                display:'flex',
                justifyContent:'start',
                alignItems:'center',
                gap:'8px',
                width:'130px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:isToday ? '88px' : '120px',
                },
            }}>
                <CardMedia
                    component="img"
                    image={ icon }
                    sx={{
                        width:'18px',
                        height:'18px',
                        borderRadius:'9px',
                        display:'none',
                    }}
                />
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                }}>
                    {text}
                </Typography>
            </Box>
        );
    }
    return (
        <Box sx={{
            display:'flex',
            justifyContent:'start',
            alignItems:'center',
            gap:'8px',
            width:'130px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:isToday ? '88px' : '120px',
            },
        }}>
            <CardMedia
                component="img"
                image={ icon }
                sx={{
                    width:'18px',
                    height:'18px',
                    borderRadius:'9px',
                }}
            />
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'12px',
                fontStyle:'normal',
                fontWeight:400,
            }}>
                {text}
            </Typography>
        </Box>
    );
}


const HomeContentMainHistoryBlockItem = (props) => {
    const { item,index,isToday,maxAmountLength,handleAddResultMaxAmountLength } = props;
    const { game,icon,amount } = item;
    const { rank,avatar,user,profit } = item;

    const resultAmount = isToday ? profit : amount;
    if(resultAmount.toString().length < maxAmountLength) {
        // 位数不足以 添加0 需要将最大长度+1
        if(!resultAmount.toString().includes('.') && 
        (resultAmount.toString().includes('-') ? resultAmount.toString().length + 3 : resultAmount.toString().length + 2) > maxAmountLength) {
            handleAddResultMaxAmountLength && handleAddResultMaxAmountLength(
                resultAmount.toString().includes('-') ? 2 : 1
            );
        }
    }

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
        }}>
            <Box sx={{
                display:'flex',
                justifyContent:'start',
                alignItems:'center',
            }}>
                <HomeContentMainHistoryBlockItemIconImage 
                    isToday={isToday}
                    icon={isToday ? null : require(`../../assets/images/temp/${icon.slice(8)}.png`)}
                    text={isToday ? rank.toString().padStart(2, '0') : game}
                />
                <Box sx={{
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'center',
                    gap:'8px',
                }}>
                    <CardMedia
                        component="img"
                        image={(avatar !== undefined && avatar.length > 0 && avatar.startsWith('avatars://')) ? require(`../../assets/images/profile/avatar/${avatar.slice(10)}.png`) : defaultAvatarIcon}
                        sx={{
                            width:'18px',
                            height:'18px',
                            borderRadius:'9px',
                        }}
                    />
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400,
                    }}>
                        {user}
                    </Typography>
                </Box>
            </Box>

            
            <Box sx={{
                display:'flex',
                justifyContent:'start',
                alignItems:'center',
                gap:'8px',
            }}>
                <CardMedia
                    component="img"
                    image={ usdtIcon }
                    sx={{
                        width:'18px',
                        height:'18px',
                        borderRadius:'9px',
                    }}
                />
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textAlign:'right',
                }}>
                    {resultAmount}                    
                    <span style={{color:'#6D728D'}}>{(resultAmount.toString().includes('.') ? '' : '.').padEnd(maxAmountLength - resultAmount.toString().length, '0')}</span>
                </Typography>
            </Box>
        </Grid>
    );
}

export default HomeContentMainHistoryBlockItem;