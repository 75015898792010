import React, { useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { useFormControl } from '@mui/material/FormControl';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import emailIcon from '../../assets/images/login-and-register/login-and-register-email.png';
import emailTipIcon from '../../assets/images/login-and-register/login-and-register-email-tip.png';
import LoginAndRegisterContentInputTip from "./LoginAndRegisterContentInputTip";

const EmailInput = styled(InputBase)(({ theme }) => ({
    [`& .${inputBaseClasses.input}`]: {
      position: 'relative',
      border: '1px solid',
      borderColor: 'transparent',
      fontSize: '14px',
      width:'350px',
      height:'36px',
      color:'#FFFFFF',
      fullWidth:true,
      caretColor:'#1CA45F',
      backgroundColor: 'transparent',
      '&::placeholder': {
        color: '#6D728D',
        opacity: 1,
      },
      '&:-webkit-autofill': {
        backgroundColor: 'transparent !important',
        color:'#FFFFFF !important',
        fontSize: '14px !important',
        transition: theme.transitions.create(['background-color','color','font-size'], {
            duration: 99999,
            easing: 'ease-in-out',
            delay: 99999,
        }),
        [theme.breakpoints.down(MobileBreakpoint.match)]: {
            fontSize:'12px !important',
        },
      },
      [theme.breakpoints.down(MobileBreakpoint.match)]: {
        width:'calc(100vw - 93px)',
        fontSize:'12px',
      },
    },
}));

const LoginAndRegisterContentEmailInput = (props) => {
    const { placeholder,defaultText,isAutoFocus,isInputTip,tipText,handleInputChanged } = props;
    const theme = useTheme();
    const { focused } = useFormControl() || {};

    const borderColor = React.useMemo(() => {
        return focused ? '#1CA45F' : (isInputTip ? '#C25156' : 'transparent');
    }, [focused,isInputTip]);
    const icon = React.useMemo(() => {
        return isInputTip ? (focused ? emailIcon : emailTipIcon) : emailIcon;
    }, [focused,isInputTip]);
    const showInputTip = React.useMemo(() => {
        return isInputTip ? (focused ? false : true) : false;
    }, [focused,isInputTip]);

    const handleInputValueChanged = (e) => {
        const filteredValue = e.target.value.replace(/[\u4E00-\u9FA5]/g, '');
        e.target.value = filteredValue;

        handleInputChanged && handleInputChanged(e);
    }

    return (
        <Box sx={{
            display:'flex',
            justifyContent:'stretch',
            alignItems:'center',
            width:'429px',
            height:'50px',
            backgroundColor: '#0D0F13',
            border: '1px solid',
            borderColor: borderColor,
            borderRadius:'25px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'100%',
                backgroundColor:'#0C0D11',
            },
        }}>
            <CardMedia
                component='img'
                image={icon}
                sx={{
                    ml:'20px',
                    mr:'12px',
                    width:'20px',
                    height:'17px',
                }}
            />
            <EmailInput type="text" 
                placeholder={placeholder} 
                value={defaultText} 
                autoFocus={isAutoFocus}
                onChange={handleInputValueChanged} 
            />
            <Box sx={{
                position:'absolute',
                left:'5px',
                top:'23px',
                zIndex:1,
                pointerEvents: 'none',
            }}>
                <LoginAndRegisterContentInputTip 
                    open={showInputTip}
                    text={tipText}
                />
            </Box>
        </Box>
    );
}

export default LoginAndRegisterContentEmailInput;