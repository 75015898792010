import React, { useEffect, useRef, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styled from '@emotion/styled';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import seeIcon from '../../assets/images/login-and-register/login-and-register-password-see.png';
import hiddenIcon from '../../assets/images/login-and-register/login-and-register-password-hidden.png';
import HeaderWalletSetWithdrawPINInput from './HeaderWalletSetWithdrawPINInput';
import TTLoading from '../common/TTLoading';
import TTAlert from '../common/TTAlert';
import { walletResetWithdrawPasswordService, walletSendWithdrawPasswordService } from '../../network/service/wallet';

const CustomInput = styled(InputBase)(({ theme }) => ({
    position: 'relative',
    borderColor: 'transparent',
    width:'100%',
    height:'100%',
    fullWidth:true,
    backgroundColor: 'transparent',
    [`& .${inputBaseClasses.input}::placeholder`]: {
        color: '#55657E',
        opacity: 1,
    },
    [`& .${inputBaseClasses.input}`]: {
        caretColor:'#1CA45F',
        color:'#FFFFFF',
        fontSize:'14px',
        fontWeight:700,
    },
}));

const HeaderWalletResetPIN = (props) => {
    const { 
        isOpen,
        closeCallback,
        resetPasswordSuccessCallback,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        reset_password: t("reset_password"),
        password: t("password"),
        confirm_password: t("confirm_password"),
        verification: t("verification"),
        send_code: t("send_code"),
        confirm: t("confirm"),
        verification_code___later: t("verification_code___later"),
        sent_successfully: t("sent_successfully"),
    }
    
    const [ seeNewPWD,setSeeNewPWD ] = useState(false);
    const [ seeConfirmNewPWD,setSeeConfirmNewPWD ] = useState(false);
    const [ newPassword,setNewPassword ] = useState('');
    const [ confirmPassword,setConfirmPassword ] = useState('');

    const handleCloseClick = () => {
        closeCallback && closeCallback();
    };
    const handleSeeOrHiddenNewPWD = () => {
        setSeeNewPWD(!seeNewPWD);
    };
    const handleSeeOrHiddenConfirmNewPWD = () => {
        setSeeConfirmNewPWD(!seeConfirmNewPWD);
    };
    const handleNewPWDValueChanged = (text) => {
        setNewPassword(text);
    };
    const handleConfirmNewPWDValueChanged = (text) => {
       setConfirmPassword(text);
    };

    // 验证码
    const [ inputVerifyCode,setInputVerifyCode ] = useState('');
    const handleVerifyValueChanged = (e) => {
        const filteredValue = e.target.value.replace(/[^0-9]/g, '');

        setInputVerifyCode(filteredValue);

        e.target.value = filteredValue;
    };
    const timeValue = 60;
    const [ countdownTime,setCountdownTime ] = useState(timeValue);
    const intervalId = useRef();
    const handleSendCodeClick = () => {
        if(countdownTime < timeValue) {
            setType('info');
            setAlertMessage(textData.verification_code___later);
            setOpen(true);
            return;
        }

        setOpenLoading(true);
        setCountdownTime(59);
        handleSendEmailVerifyCode();
    };
    const handleSendEmailVerifyCode = async () => {
        try {
            await walletSendWithdrawPasswordService();
            setOpenLoading(false);
            setType('success');
            setAlertMessage(textData.sent_successfully);
            setOpen(true);

            intervalId.current = setInterval(() => {
                setCountdownTime((prevCounter) => prevCounter - 1);
            }, 1000);
        } catch (error) {
            setOpenLoading(false);
            setCountdownTime(timeValue);

            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };
    const handleResetPassword = async () => {
        try {
            // const saltPWD = CryptoJS.MD5(newPassword + '{PWD_Salt_CC_Poker}').toString();
            const param = {
                password:newPassword,
                key:inputVerifyCode,
            }
            await walletResetWithdrawPasswordService(param);
            setOpenLoading(false);
            resetPasswordSuccessCallback && resetPasswordSuccessCallback(newPassword);
        } catch (error) {
            setOpenLoading(false);
            
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };
    
    useEffect(() => {
        if(countdownTime === 0) {
            clearInterval(intervalId.current);
            setCountdownTime(timeValue);
        }
    }, [countdownTime]);
    useEffect(() => {
        return () => {
            clearInterval(intervalId.current);
        };
    }, []);

    const handleConfirmBtnClick = () => {
        if(newPassword !== confirmPassword) {
            setOpen(true);
            setAlertMessage(textData.thePasswordInconsistent);
            return;
        }

        setOpenLoading(true);
        handleResetPassword();
    };

    //alert 
    const [ openLoading,setOpenLoading ] = useState(false);
    const [ type, setType ] = useState('info');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <Dialog
            open={isOpen}
            // onClose={handleCloseClick}
            sx={{
                [`& .${dialogClasses.paper}`]: {
                    bgcolor:'transparent',
                    borderRadius:'18px',
                    maxWidth:'calc(100% - 36px)',
                    maxHeight:'calc(100% - 36px)',
                    margin:'0px',
                    padding:'0px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:'8px',
                        maxWidth:'calc(100% - 28px)',
                        maxHeight:'calc(100% - 28px)',
                        width:'calc(100% - 28px)',
                    },
                },
            }}
        >
            <DialogContent sx={{
                bgcolor:'transparent',
                borderRadius:'18px',
                width:'500px',
                maxWidth:'100%',
                m:'0px',
                p:'0px',
                overflow:'auto',
                scrollbarWidth: 'none', // Firefox
                msOverflowStyle: 'none', // Internet Explorer
                '&::-webkit-scrollbar': {
                    display: 'none', // Chrome, Safari, Edge
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'100%',
                    borderRadius:'8px',
                },
                transition: theme.transitions.create(['all'], {
                    duration: 300,
                    easing: 'ease-out',
                }),
            }}>
                <TTLoading 
                    open={openLoading}
                    isForce={true}
                />
                <TTAlert 
                    type={type}
                    isOpen={open}
                    message={alertMessage}
                    closeCallback={handleAlertClose}
                />

                <Box sx={{
                    bgcolor:'#1E2736',
                    borderRadius:'18px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    padding:'18px',
                    width:'calc(100% - 36px)',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:'8px',
                    },
                }}>
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        width:'100%',
                    }}>
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'18px',
                            fontWeight:700,
                        }}>
                            {textData.reset_password}
                        </Typography>
                        <IconButton sx={{
                            mt:'-8px',
                            mr:'-8px',
                        }} onClick={handleCloseClick}>
                            <CardMedia
                                component='img'
                                image={closeIcon}
                                sx={{
                                    objectFit:'contain',
                                    width:'18px',
                                    height:'18px',
                                    '&:hover': {
                                        filter: 'brightness(0) invert(1)',
                                    },
                                }}
                            />
                        </IconButton>
                    </Grid>

                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        mt:'28px',
                        width:'100%',
                    }}>
                        <Typography sx={{
                            color:'#8EA3C3',
                            fontSize:'14px',
                            fontWeight:700,
                        }}>
                            {textData.password}
                        </Typography>
                        <IconButton sx={{
                            padding:'0px',
                        }} onClick={handleSeeOrHiddenNewPWD}>
                            <CardMedia
                                component='img'
                                image={seeNewPWD ? hiddenIcon : seeIcon}
                                sx={{
                                    objectFit:'contain',
                                    width:'30px',
                                    height:'30px',
                                    '&:hover': {
                                        filter: 'brightness(0) invert(1)',
                                    },
                                }}
                            />
                        </IconButton>
                    </Grid>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        width:'100%',
                        height:'58px',
                        mt:'2px'
                    }}>
                        <HeaderWalletSetWithdrawPINInput
                            isSee={seeNewPWD}
                            handleOnValueChanged={handleNewPWDValueChanged}
                        />
                    </Box>

                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        mt:'18px',
                        width:'100%',
                    }}>
                        <Typography sx={{
                            color:'#8EA3C3',
                            fontSize:'14px',
                            fontWeight:700,
                        }}>
                            {textData.confirm_password}
                        </Typography>
                        <IconButton sx={{
                            padding:'0px',
                        }} onClick={handleSeeOrHiddenConfirmNewPWD}>
                            <CardMedia
                                component='img'
                                image={seeConfirmNewPWD ? hiddenIcon : seeIcon}
                                sx={{
                                    objectFit:'contain',
                                    width:'30px',
                                    height:'30px',
                                    '&:hover': {
                                        filter: 'brightness(0) invert(1)',
                                    },
                                }}
                            />
                        </IconButton>
                    </Grid>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        width:'100%',
                        height:'58px',
                        mt:'2px'
                    }}>
                        <HeaderWalletSetWithdrawPINInput
                            isSee={seeConfirmNewPWD}
                            isNoAutoFocus={true}
                            handleOnValueChanged={handleConfirmNewPWDValueChanged}
                        />
                    </Box>

                    <Typography sx={{
                        color:'#8EA3C3',
                        fontSize:'14px',
                        fontWeight:700,
                        mt:'18px',
                        width:'100%',
                    }}>
                        {textData.verification}
                    </Typography>
                    <Box sx={{
                        bgcolor:'#000000',
                        borderRadius:'8px',
                        border:'1px solid #55657E',
                        position:'relative',
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        width:'100%',
                        height:'40px',
                        mt:'8px',
                        overflow:'hidden',
                    }}>
                        <FormControl sx={{
                            width:'100%',
                            height:'100%',
                            ml:'8px',
                        }}>
                            <CustomInput 
                                value={inputVerifyCode}
                                inputProps={{ 
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*',
                                }}
                                onChange={handleVerifyValueChanged}
                            />
                        </FormControl>
                        <Button sx={{
                            textTransform:'none',
                            textDecoration:'none',
                            bgcolor:'#55657E',
                            color:'#FFFFFF',
                            fontSize:'14px',
                            fontWeight:700,
                            padding:'0px 17px',
                            height:'100%',
                            position:'absolute',
                            top:'0px',
                            right:'0px',
                            borderRadius:'0px',
                            '&:hover': {
                                bgcolor:'#55657E',
                            },
                        }} onClick={handleSendCodeClick}>
                            {countdownTime < timeValue ? countdownTime : textData.send_code}
                        </Button>
                    </Box>

                    <Box sx={{
                        bgcolor:'#2C394E',
                        width:'100%',
                        height:'2px',
                        mt:'18px',
                    }} />

                    <Button disabled={(newPassword.length === 6 && confirmPassword.length === 6 && inputVerifyCode.length !== 0) ? false : true} sx={{
                        textTransform:'none',
                        textDecoration:'none',
                        bgcolor:'#1184FA',
                        color:'#FFFFFF',
                        fontSize:'18px',
                        fontWeight:700,
                        width:'100%',
                        height:'40px',
                        borderRadius:'8px',
                        margin:'28px 0px',
                        '&:hover': {
                            bgcolor:'#1184FA',
                        },
                        '&.Mui-disabled': {
                            bgcolor: alpha(
                                '#1184FA',
                                0.5,
                            ),
                        }
                    }} onClick={handleConfirmBtnClick}>
                        {textData.confirm}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default HeaderWalletResetPIN;