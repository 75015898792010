import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileBreakpoint from '../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import WalletContent from '../component/wallet/WalletContent';
import TTAppBar from '../component/common/TTAppBar';
import { useNavigate } from 'react-router-dom';

const Wallet = () => {
    const { t } = useTranslation();
    const textData = {
        myWallet: t("my_wallet"),
        transaction: t("transaction"),
    };
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();

    const handleTransactionClick = () => {
        navigate(RoutePath.record,{state: {token:'USDT',category:0}});
    };

    if(screenMD) {
        return (
            <React.Fragment>
                <Customer />
                <TTAppBar  
                    style={2} 
                    title={textData.myWallet} 
                    rightTitle={textData.transaction} 
                    rightOnClick={handleTransactionClick} 
                />
                <WalletContent screenMD={true} />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.wallet} drawerIndex={-1} />
            <WalletContent screenMD={false} />
        </React.Fragment>
    )
}

export default Wallet;