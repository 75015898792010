import React from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import LiveCasinoContent from '../component/liveCasino/LiveCasinoContent';

const LiveCasino = () => {
    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.liveCasino} drawerIndex={4}/>
            <LiveCasinoContent />
        </React.Fragment>
    )
}

export default LiveCasino;