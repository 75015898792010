import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import emailIcon from '../../assets/images/profile/profile-email.png';
import passwordIcon from '../../assets/images/profile/profile-password.png';
import telephoneIcon from '../../assets/images/profile/profile-telephone.png';
import balanceExchangeIcon from '../../assets/images/profile/profile-balance-exchange.png';
import mfaIcon from '../../assets/images/profile/profile-mfa.png';
import googleIcon from '../../assets/images/profile/profile-google.png';
import successIcon from '../../assets/images/profile/profile-success.png';
import privacyIcon from '../../assets/images/login-and-register/login-and-register-password-see.png';
import arrowRightIcon from '../../assets/images/header/header-arrow-right.png';
import ProfileContentBottomItem from './ProfileContentBottomItem';
import ProfileContentBottomReferral from './ProfileContentBottomReferral';
import LoginAndRegisterContentRightForgetAlert from '../login-and-register/LoginAndRegisterContentRightForgetAlert';
import TTAlert from '../common/TTAlert';
import Cookies from 'js-cookie';
import RoutePath from '../../tools/RoutePath';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import ProfileContentPrivacySetting from './ProfileContentPrivacySetting';
import TTLoading from '../common/TTLoading';
import { logoutService } from '../../network/service/user';
import { userLogout } from '../../features/userSlice';
import { clearUserCookies } from '../../tools/CommonTool';
import { walletLogout } from '../../features/walletSlice';

const ProfileContentBottom = (props) => {
    const {
        accountSetting,email,password,telephone,balanceExchange,mfa,
        google,privacySetting,set,reset,bind,enterYourCode,youHaveCode,comingSoon,logout,
        allowOtherTimes,allowOtherProfit,allowOtherWagered
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginEmail = useSelector((state) => state.user.user.email);

    let itemArr = [
        {
            type:1,title:email,image:emailIcon,rightImage:null,
            desc:loginEmail,buttonTitle:null,btnLeftTitle:null
        },
        {
            type:2,title:password,image:passwordIcon,rightImage:null,
            desc:null,buttonTitle:reset,btnLeftTitle:null
        },
        {
            type:2,title:telephone,image:telephoneIcon,rightImage:null,
            desc:null,buttonTitle:set,btnLeftTitle:null
        },
        {
            type:3,title:balanceExchange,image:balanceExchangeIcon,rightImage:null,
            desc:null,buttonTitle:reset,btnLeftTitle:'USD'
        },
        {
            type:2,title:mfa,image:mfaIcon,rightImage:null,
            desc:null,buttonTitle:bind,btnLeftTitle:null
        },
        {
            type:4,title:google,image:googleIcon,rightImage:successIcon,
            desc:'example@google.com',buttonTitle:bind,btnLeftTitle:null
        }
    ];
    if(screenMD) {
        itemArr.push(
            {
                type:5,title:privacySetting,image:privacyIcon,rightImage:arrowRightIcon,
                desc:null,buttonTitle:bind,btnLeftTitle:null
            }
        );
    }

    const [ openLoading,setOpenLoading ] = useState(false);

    const handleRightBtnClick = (index) => {
        if(index === 1) {
            setForgetOpen(true);
        }else if(index === 6) {
            setOpenPrivacySetting(true);
        }else {
            setAlertMessage(comingSoon);
            setAlertType('info');
            setOpenAlert(true);
        }
    };
    const handleLogoutClick = async () => {
        setOpenLoading(true);
        try {
            const response = await logoutService();
            setOpenLoading(false);
            clearUserCookies();
            dispatch(userLogout(response.data));
            dispatch(walletLogout());
            navigate(RoutePath.login,{state: {type:'login',fromPage:RoutePath.home,isLogout:true}});
        } catch (error) {
            setOpenLoading(false);
            
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setAlertType('error');
            setAlertMessage(errorMsg);
            setOpenAlert(true);
        }
    };

    // alert
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ alertType, setAlertType ] = useState('error');
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    // forget alert
    const [ forgetOpen, setForgetOpen ] = useState(false);
    const handleForgetAlertClose = () => {
        setForgetOpen(false);
    };

    // privacy setting
    const [ openPrivacySetting,setOpenPrivacySetting ] = useState(false);
    const handleClosePrivacySetting = () => {
        setOpenPrivacySetting(false);
    };

    return (
        <React.Fragment>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <ProfileContentPrivacySetting 
                privacySetting={privacySetting}
                allowOtherTimes={allowOtherTimes}
                allowOtherProfit={allowOtherProfit}
                allowOtherWagered={allowOtherWagered}
                open={openPrivacySetting}
                onCloseClick={handleClosePrivacySetting}
            />
            <LoginAndRegisterContentRightForgetAlert 
                isOpen={forgetOpen}
                isProfile={true}
                closeCallback={handleForgetAlertClose}
            />
            <TTAlert 
                type={alertType}
                isOpen={openAlert}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />
            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'start',
                mt:'18px',
                gap:'18px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    justifyContent:'flex-start',
                },
            }}>
                {screenMD ? 
                <ProfileContentBottomReferral 
                    enterYourCode={enterYourCode}
                    youHaveCode={youHaveCode}
                    bind={bind}
                /> : ''
                }
                <Box sx={{
                    bgcolor:'#15151B',
                    borderRadius:'18px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    gap:'18px',
                    padding:'16px 18px',
                    width:'575px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:'0px',
                        width:'calc(100% - 36px)',
                    },
                }}>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'24px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'15px',
                        },
                    }}>
                        {accountSetting}
                    </Typography>
                    {itemArr.map((item,index) => (
                        <ProfileContentBottomItem 
                            item={item}
                            btnOnClick={() => handleRightBtnClick(index)}
                            key={index}
                        />
                    ))}
                </Box>
                {screenMD ? 
                '' : 
                <ProfileContentBottomReferral 
                    enterYourCode={enterYourCode}
                    youHaveCode={youHaveCode}
                    bind={bind}
                /> 
                }
                {screenMD ? 
                 <Button variant='text' sx={{
                    bgcolor:'#3A3E53',
                    borderRadius:'27px',
                    height:'54px',
                    textTransform:'none',
                    color:'#FFFFFF',
                    fontSize:'16px',
                    fontStyle:'normal',
                    fontWeight:500,
                    margin:'0px 18px 18px 18px',
                    width:'calc(100% - 36px)',
                    '&:hover': {
                        bgcolor:'#3A3E53',
                    }
                 }} onClick={handleLogoutClick}>
                    {logout}
                 </Button> : ''
                }
            </Grid>
        </React.Fragment>
    );
}

export default ProfileContentBottom; 