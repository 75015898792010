import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import usdtIcon from '../../assets/images/header/header-usdt.png';

const ReferralContentMainContentEarningItem = (props) => {
    const {
        amount,symbol,
    } = props;
    const theme = useTheme();

    return (
        <Box sx={{
            bgcolor:'#1E212A',
            borderRadius:'12px',
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center',
            padding:'16px 30px',
            gap:'6px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                borderRadius:'8px',
                padding:'16px 13px',
                gap:'9px',
            },
        }}>
            <CardMedia
                component="img"
                image={usdtIcon}
                sx={{
                    width:'35px',
                    height:'35px',
                    objectFit:'contain',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'24px',
                        height:'24px',
                    },
                }}
            />
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
            }}>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'18px',
                    fontStyle:'normal',
                    fontWeight:500,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                    },
                }}>
                    {symbol}
                </Typography>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'24px',
                    fontStyle:'normal',
                    fontWeight:500,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                    },
                }}>
                    {amount}
                </Typography>
            </Box>
        </Box>
    );
}

const ReferralContentMainContentEarningItem2 = (props) => {
    const {
        title,amount,
    } = props;

    return (
        <Grid container sx={{
            bgcolor:'#252735',
            borderRadius:'18px',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            padding:'18px 48px',
            gap:'20px',
        }}>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'18px',
                fontStyle:'normal',
                fontWeight:400,
            }}>
                {title}
            </Typography>
            <Typography sx={{
                color:'#FFD233',
                fontSize:'24px',
                fontStyle:'normal',
                fontWeight:400,
                textAlign:'right',
            }}>
                {amount}
            </Typography>
        </Grid>
    );
}

const ReferralContentMainContentEarningBottom = (props) => {
    const {
        title1,amount1,title2,amount2,
    } = props;

    return (
        <Box sx={{
            bgcolor:'#1E212A',
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center',
            width:'100%',
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'flex-start',
                width:'calc((100% - 1px) / 2 - 36px)',
                padding:'4px 18px',
            }}>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                    width:'100%',
                    textAlign:'center',
                }}>
                    {title1}
                </Typography>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'15px',
                    fontStyle:'normal',
                    fontWeight:500,
                    width:'100%',
                    textAlign:'center',
                }}>
                    {amount1}
                </Typography>
            </Box>
            <Box sx={{
                bgcolor:'#3A3E53',
                mt:'6px',
                mb:'6px',
                width:'1px',
                height:'calc(100% - 12px)',
            }} />
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'flex-start',
                width:'calc((100% - 1px) / 2 - 36px)',
                padding:'4px 18px',
            }}>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                    width:'100%',
                    textAlign:'center',
                }}>
                    {title2}
                </Typography>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'15px',
                    fontStyle:'normal',
                    fontWeight:500,
                    width:'100%',
                    textAlign:'center',
                }}>
                    {amount2}
                </Typography>
            </Box>
        </Box>
    );
}

const ReferralContentMainContentEarning = (props) => {
    const {
        tokenArr,amount1,amount2,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        overviewEarning: t("overview_earning"),
        totalNumberAgents: t("total_number___agents"),
        activeUserYesterday: t("active_user_yesterday"),
    };

    return (
        <Box sx={{
            bgcolor:'#15151B',
            borderRadius:'18px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            padding:'16px 18px',
            gap:'18px',
            width:'calc(100% - 36px)',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                borderRadius:'0px',
                width:'100%',
                padding:'28px 0px 0px 0px',
                gap:'20px',
            },
        }}>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'24px',
                fontStyle:'normal',
                fontWeight:500,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'15px',
                    ml:'18px',
                },
            }}>
                {textData.overviewEarning}
            </Typography>
            <Box sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'30px',
                width:'100%',
                overflow:'auto',
                scrollbarWidth: 'none', // Firefox
                msOverflowStyle: 'none', // Internet Explorer
                '&::-webkit-scrollbar': {
                    display: 'none', // Chrome, Safari, Edge
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    gap:'10px',
                    ml:'18px',
                    mr:'18px',
                    width:'calc(100% - 36px)',
                },
            }}>
                {tokenArr.map((item,index) => (
                    <ReferralContentMainContentEarningItem 
                        amount={item.sum_amount}
                        symbol={item.symbol}
                        key={index}
                    />
                ))}
            </Box>
            {!screenMD ? 
                <React.Fragment>
                    <ReferralContentMainContentEarningItem2 
                        title={textData.totalNumberAgents}
                        amount={amount1}
                    />
                    <ReferralContentMainContentEarningItem2 
                        title={textData.activeUserYesterday}
                        amount={amount2}
                    />
                </React.Fragment> :
                <ReferralContentMainContentEarningBottom 
                    title1={textData.totalNumberAgents}
                    amount1={amount1}
                    title2={textData.activeUserYesterday}
                    amount2={amount2}
                />
            }
        </Box>
    );
}

export default ReferralContentMainContentEarning;