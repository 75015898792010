import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import defaultAvatarIcon from '../../assets/images/header/header-default-avatar.png';
import editIcon from '../../assets/images/profile/profile-edit.png';
import editCloseIcon from '../../assets/images/profile/profile-edit-close.png';
import editDoneIcon from '../../assets/images/profile/profile-edit-done.png';
import seeIcon from '../../assets/images/login-and-register/login-and-register-password-see.png';
import hiddenIcon from '../../assets/images/login-and-register/login-and-register-password-hidden.png';
import avatarEditIcon from '../../assets/images/profile/profile-avatar-edit.png';
import { useDispatch, useSelector } from 'react-redux';
import TTLoading from '../common/TTLoading';
import TTAlert from "../common/TTAlert";
import ProfileContentEditAvatarAlert from './ProfileContentEditAvatarAlert';
import { updateUserInfoService } from '../../network/service/user';
import { userRefreshUserInfo } from '../../features/userSlice';

const ProfileContentPersonalInfoInput = styled(InputBase)(({ theme }) => ({
    [`& .${inputBaseClasses.input}`]: {
      position: 'relative',
      border: '0px solid',
      backgroundColor:'transparent',
      borderColor: 'transparent',
      fontSize: '24px',
      color:'#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      caretColor:'#1CA45F',
      '&::placeholder': {
        color: '#3A3E53',
        opacity: 1,
      },
      [theme.breakpoints.down(MobileBreakpoint.match)]: {
        fontSize: '20px',
      },
    },
}));

const ProfileContentPersonalInfo = (props) => {
    const {
        joinedOn,privacySetting,isShowPrivacy,pleaseEnterNickname,privacySettingOnClick
    } = props;
    const theme = useTheme();
    const dispatch = useDispatch();

    const userId = useSelector((state) => state.user.user.pk);
    const username = useSelector((state) => state.user.user.username);
    const profileImageUrl = useSelector((state) => state.user.user.profile_image_url);
    const joinedTime = useSelector((state) => state.user.user.joined_time);

    const [ defaultNickname,setDefaultNickname ] = useState(username);
    const [ nickname, setNickname] = useState(defaultNickname);
    const [ isEditNickname,setIsEditNickname ] = useState(false);
    const [ openLoading,setOpenLoading ] = useState(false);
    const [ showEdit,setShowEdit ] = useState(false);
    const [ showSelectAvatar,setShowSelectAvatar ] = useState(false);

    // const date = '1970/01/01';
    var date = new Date(joinedTime * 1000);
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2); 
    var day = ('0' + date.getDate()).slice(-2);
    var formattedDate = year + '/' + month + '/' + day;

    let defaultAvatarIndex = -1;
    let avatarUrl = '';
    if(profileImageUrl !== undefined && profileImageUrl.length > 0) {
        if(profileImageUrl.startsWith('avatars://')) {
            avatarUrl = profileImageUrl.slice(10);
            defaultAvatarIndex = profileImageUrl.match(/\d+/g) - 1;
        }
    }

    const handleEditNicknameClick = () => {
        setNickname(defaultNickname);
        setIsEditNickname(true);
    };
    const handleInputChanged = (e) => {
        setNickname(e.target.value);
    };
    const handleEditCloseClick = () => {
        setNickname(defaultNickname);
        setIsEditNickname(false);
    };
    const handleEditDoneClick = async () => {
        if(nickname === defaultNickname) {
            setIsEditNickname(false);
            return;
        }
        if(nickname === undefined || nickname.length <= 0) {
            setType('info');
            setAlertMessage(pleaseEnterNickname);
            setOpen(true);
            return;
        }
        setOpenLoading(true);
        try {
            let params = {
                name:nickname,
                userId:userId,
            };
            const response = await updateUserInfoService(params);
            dispatch(userRefreshUserInfo(response.data));
            setOpenLoading(false);
            setDefaultNickname(nickname);
            setIsEditNickname(false);
        } catch (error) {
            setOpenLoading(false);
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };
    const handleEditAvatarClick = () => {
        setShowSelectAvatar(true);
    }
    const handleAvatarMouseEnterClick = () => {
        setShowEdit(true);
    }
    const handleAvatarMouseLeaveClick = () => {
        setShowEdit(false);
    }
    const handleForgetAlertClose = () => {
        setShowSelectAvatar(false);
    }

    //alert 
    const [ type, setType ] = useState('info');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            <ProfileContentEditAvatarAlert 
                isOpen={showSelectAvatar}
                defaultIndex={defaultAvatarIndex}
                closeCallback={handleForgetAlertClose}
            />
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <TTAlert 
                type={type}
                isOpen={open}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />

            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                mt:'35px',
                gap:'18px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    justifyContent:'flex-start',
                    mt:'0px',
                    pl:'18px',
                },
            }}>
                <Box sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'13px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        gap:'8px',
                    },
                }}>
                    <Box sx={{
                        position:'relative',
                        width:'58px',
                        height:'58px',
                        cursor:'pointer',
                        borderRadius:'29px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'42px',
                            height:'42px',
                            borderRadius:'21px',
                        },
                    }}  onClick={handleEditAvatarClick}
                        onMouseEnter={handleAvatarMouseEnterClick}
                        onMouseLeave={handleAvatarMouseLeaveClick}>
                        <CardMedia
                            component="img"
                            image={avatarUrl.length > 0 ? require(`../../assets/images/profile/avatar/${avatarUrl}.png`) : defaultAvatarIcon}
                            sx={{
                                width:'100%',
                                height:'100%',
                                borderRadius:'29px',
                                objectFit:'contain',
                            }}
                        />
                        <Box sx={{
                            display:showEdit ? 'block' : 'none',
                            position:'absolute',
                            top:'0px',
                            left:'0px',
                            width:'58px',
                            height:'58px',
                            borderRadius:'29px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'42px',
                                height:'42px',
                                borderRadius:'21px',
                            },
                        }}>
                            <CardMedia
                                component="img"
                                image={avatarEditIcon}
                                sx={{
                                    width:'100%',
                                    height:'100%',
                                    objectFit:'contain',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                    }}>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'center',
                            gap:'10px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                gap:'5px',
                            },
                        }}>
                            <Typography sx={{
                                display:isEditNickname ? 'none' : 'block',
                                color:'#FFFFFF',
                                fontSize:'24px',
                                fontStyle:'normal',
                                fontWeight:400,
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    fontSize:'20px',
                                },
                            }}>
                                {nickname}
                            </Typography>
                            <Box sx={{
                                bgcolor:'#15151B',
                                borderRadius:'18px',
                                height:'36px',
                                display:isEditNickname ? 'flex' : 'none',
                                justifyContent:'flex-start',
                                alignItems:'center',
                                gap:'10px',
                                padding:'0px 8px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    borderRadius:'13px',
                                    height:'26px',
                                },
                            }}>
                                <FormControl sx={{
                                    width:'240px',
                                    height:'100%',
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        width:'180px',
                                    },
                                }}>
                                    <ProfileContentPersonalInfoInput type="text" 
                                        value={nickname}
                                        onChange={handleInputChanged}
                                        sx={{
                                            width:'100%',
                                            height:'100%',
                                        }}
                                    />
                                </FormControl>
                                <IconButton onClick={handleEditCloseClick}>
                                    <CardMedia
                                        component="img"
                                        image={editCloseIcon}
                                        sx={{
                                            width:'20px',
                                            height:'20px',
                                            objectFit:'contain',
                                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                width:'16px',
                                                height:'16px',
                                            },
                                        }}
                                    />
                                </IconButton>
                                <IconButton onClick={handleEditDoneClick}>
                                    <CardMedia
                                        component="img"
                                        image={editDoneIcon}
                                        sx={{
                                            width:'20px',
                                            height:'20px',
                                            objectFit:'contain',
                                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                                width:'16px',
                                                height:'16px',
                                            },
                                        }}
                                    />
                                </IconButton>
                            </Box>
                            <Button variant='image' disableRipple sx={{
                                display:isEditNickname ? 'none' : 'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                width:'60px',
                                height:'18px',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    filter: 'brightness(0) invert(1)',
                                },
                            }} onClick={handleEditNicknameClick}>
                                <CardMedia
                                    component="img"
                                    image={editIcon}
                                    sx={{
                                        width:'18px',
                                        height:'18px',
                                        objectFit:'contain',
                                    }}
                                />
                            </Button>
                        </Box>
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'15px',
                            fontStyle:'normal',
                            fontWeight:400,
                        }}>
                            {`${joinedOn} ${formattedDate}`}
                        </Typography>

                    </Box>
                </Box>
                <Button variant='contained' sx={{
                    bgcolor:'#1E212A',
                    borderRadius:'22px',
                    textTransform:'none',
                    color:"#ACB3D7",
                    fontSize:'18px',
                    fontStyle:'normal',
                    fontWeight:400,
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'13px',
                    padding:'16px 18px',
                    '&:hover': {
                        backgroundColor: '#1E212A',
                    },
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        display:'none',
                    },
                }} onClick={privacySettingOnClick}>
                    <CardMedia
                        component="img"
                        image={isShowPrivacy ? hiddenIcon : seeIcon}
                        sx={{
                            width:'32px',
                            height:'32px',
                            objectFit:'contain',
                        }}
                    />
                    {privacySetting}
                </Button>
            </Grid>
        </React.Fragment>
    );
}

export default ProfileContentPersonalInfo;