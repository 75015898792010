import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const DepositContentDesc = (props) => {
    const { 
        youCanOnlyDeposit,toThisAmount,theMinimumLeakage,token,network,depositLimit,
    } = props;
    const theme = useTheme();
    
    return (
        <Box sx={{
            bgcolor:'#15151B',
            borderRadius:'18px',
            padding:'32px 60px 45px 60px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            gap:'16px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                borderRadius:'0px',
                padding:'18px 25px',
            },
        }}>
            <Typography sx={{
                color:'#6D718D',
                fontSize:'22px',
                fontStyle:'normal',
                fontWeight:400,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'12px',
                },
            }}>
                {`${youCanOnlyDeposit} ${token} (${network}) ${toThisAmount}: ${depositLimit} ${token} ${theMinimumLeakage}`}
            </Typography>
        </Box>
    )
}

export default DepositContentDesc;