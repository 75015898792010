import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import noticeIcon from '../../assets/images/referral/referral-notice.png';
import logoDarkIcon from '../../assets/images/referral/referral-logo-dark.png';
import logoLightIcon from '../../assets/images/referral/referral-logo-light.png';
import spaceIcon from '../../assets/images/referral/referral-space.png';
import arrowIcon from '../../assets/images/referral/referral-arrow.png';

const ReferralContentMainPlaceholderStructureLogo = (props) => {
    const { image,title } = props;
    const theme = useTheme();

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center', 
            gap:'2px'
        }}>
            <CardMedia
                component="img"
                image={image}
                sx={{
                    width:'48px',
                    height:'48px',
                    objectFit:'contain',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'calc(40 * (100vw / 375))',
                        height:'calc(40 * (100vw / 375))',
                    },
                }}
            />
             <Typography sx={{
                color:'#ACB3D7',
                fontSize:'10px',
                fontStyle:'normal',
                fontWeight:400,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'calc(9 * (100vw / 375))',
                },
            }}>
                {title}
            </Typography>
        </Box>
    );
}

const ReferralContentMainPlaceholderStructure = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        threeTierYou: t("three_tier___you"),
        you: t("you"),
        levelOne: t("level_one"),
        levelTwo: t("level_two"),
        levelThree: t("level_three"),
        experienceTheEveryone: t("experience_the__everyone"),
    };
    
    return (
        <Box sx={{
            bgcolor:'#15151B',
            borderRadius:'18px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            alignSelf:'stretch',
            gap:'18px',
            padding:'28px 18px',
            width:'calc(100% - 36px)',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                borderRadius:'8px',
                padding:'28px 18px',
                width:'calc(100vw - 36px)',
            },
        }}>
            <Box sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'8px',
            }}>
                <CardMedia
                    component="img"
                    image={noticeIcon}
                    sx={{
                        width:'20px',
                        height:'20px',
                        objectFit:'contain',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'16px',
                            height:'16px',
                        },
                    }}
                />
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'18px',
                    fontStyle:'normal',
                    fontWeight:500,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                    },
                }}>
                    {textData.threeTierYou}
                </Typography>
            </Box>
            <Typography sx={{
                color:'#6D728D',
                fontSize:'12px',
                fontStyle:'normal',
                fontWeight:500,
            }}>
                {textData.experienceTheEveryone}
            </Typography>
            <Grid container sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'60px',
            }}>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                    width:'101px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'50px',
                    },
                }}>
                    {textData.you}
                </Typography>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textAlign:'center',
                }}>
                    {textData.levelOne}
                </Typography>
                {/* <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textAlign:'center',
                    width:'129px',
                }}>
                    {textData.levelTwo}
                </Typography>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textAlign:'right',
                }}>
                    {textData.levelThree}
                </Typography> */}
            </Grid>
            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'flex-start',
            }}>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'46px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        gap:'calc(7 * 100vw / 375)',
                    },
                }}>
                    <ReferralContentMainPlaceholderStructureLogo 
                        image={logoLightIcon}
                        title={'Fallindo'}
                    />
                    <CardMedia
                        component="img"
                        image={spaceIcon}
                        sx={{
                            width:'25px',
                            height:'87px',
                            objectFit:'cover',
                            mb:'10px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                gap:'calc(7 * 100vw / 375)',
                                width:'calc(20 * 100vw / 375)',
                                height:'calc(76 * 100vw / 375)',
                                mb:'calc(10 * 100vw / 375)',
                            },
                        }}
                    />
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'58px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'calc(40 * 100vw / 375)',
                        },
                    }}>
                        {Array(2).fill('').map((_,index) => (
                            <ReferralContentMainPlaceholderStructureLogo 
                                image={logoDarkIcon}
                                title={'Fallindo'}
                                key={index}
                            />
                        ))}
                    </Box>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'flex-start',
                        gap:'46px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'calc(35 * 100vw / 375)',
                        },
                    }}>
                        {Array(2).fill('').map((_,index) => (
                            <CardMedia
                                component="img"
                                image={spaceIcon}
                                sx={{
                                    width:'25px',
                                    height:'87px',
                                    objectFit:'cover',
                                    mb:index === 0 ? '15px' : '-2px',
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        gap:'calc(7 * 100vw / 375)',
                                        width:'calc(20 * 100vw / 375)',
                                        height:'calc(76 * 100vw / 375)',
                                        mb:'calc(5 * 100vw / 375)',
                                    },
                                }}
                                key={index}
                            />
                        ))}
                    </Box>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'21px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'calc(16 * 100vw / 375)',
                        },
                    }}>
                        {Array(4).fill('').map((_,index) => (
                            <ReferralContentMainPlaceholderStructureLogo 
                                image={logoDarkIcon}
                                title={'Fallindo'}
                                key={index}
                            />
                        ))}
                    </Box>
                </Box>

                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'flex-start',
                    gap:'82px',
                    mt:'20px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        gap:'calc(66 * 100vw / 375)',
                    },
                }}>
                    {Array(4).fill('').map((_,index) => (
                        <CardMedia
                            component="img"
                            image={arrowIcon}
                            sx={{
                                width:'31px',
                                height:'6px',
                                objectFit:'cover',
                                pb:(index === 3 || index === 4) ? '12px' : '0px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'calc(31 * 100vw / 375)',
                                    height:'calc(6 * 100vw / 375',
                                },
                            }}
                            key={index}
                        />
                    ))}
                </Box>

                <Box sx={{
                    display:'flex',
                    alignItems:'center',
                    gap:'17px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        gap:'calc(5 * 100vw / 375)',
                    },
                }}>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'flex-start',
                        gap:'21px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'calc(16 * 100vw / 375)',
                        },
                    }}>
                        {Array(4).fill('').map((_,index) => (
                            <ReferralContentMainPlaceholderStructureLogo 
                                image={logoDarkIcon}
                                title={'Fallindo'}
                                key={index}
                            />
                        ))}
                    </Box>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'flex-start',
                        gap:'21px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'calc(16 * 100vw / 375)',
                        },
                    }}>
                        {Array(4).fill('').map((_,index) => (
                            <ReferralContentMainPlaceholderStructureLogo 
                                image={logoDarkIcon}
                                title={'Fallindo'}
                                key={index}
                            />
                        ))}
                    </Box>
                </Box>
            </Grid>
        </Box>
    );
}

export default ReferralContentMainPlaceholderStructure;