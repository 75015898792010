import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import usdtIcon from '../../assets/images/header/header-usdt.png';
import defaultAvatarIcon from '../../assets/images/header/header-default-avatar.png';

const GameContentBetHistoryCommonItem = (props) => {
    const {
        name,betTime,betAmount,betProfit,total,isMyBets,maxAmountLength,handleAddResultMaxAmountLength,
    } = props;
    
    const theme = useTheme();

    const profitLength = (betProfit.toString().includes('-') || betProfit.toString().includes('+')) ? betProfit.toString().length : betProfit.toString().length + 1;
    if(profitLength < maxAmountLength) {
        // 位数不足以 添加0 需要将最大长度+1
        if(!betProfit.toString().includes('.') && 
        ((betProfit.toString().includes('-') || betProfit.toString().includes('+')) ? betProfit.toString().length + 2 : betProfit.toString().length + 3) > maxAmountLength) {
            handleAddResultMaxAmountLength && handleAddResultMaxAmountLength(
                (betProfit.toString().includes('-') || betProfit.toString().includes('+')) ? (betProfit.toString().length + 2 - maxAmountLength) : (betProfit.toString().length + 3 - maxAmountLength)
            );
        }
    }

    const date = new Date(parseFloat(betTime) * 1000);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            width:"100%",
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                justifyContent:'space-between',
                mt:'10px',
            },
        }}>
            {/* <Box sx={{
                display:isMyBets ? 'none' : 'flex',
                justifyContent:'start',
                alignItems:'center',
                gap:'8px',
                width:'200px',
            }}>
                <CardMedia
                    component="img"
                    image={(avatar !== undefined && avatar.length > 0 && avatar.startsWith('avatars://')) ? require(`../../assets/images/profile/avatar/${avatar.slice(10)}.png`) : defaultAvatarIcon}
                    sx={{
                        width:'24px',
                        height:'24px',
                        borderRadius:'12px',
                    }}
                />
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'17px',
                    fontStyle:'normal',
                    fontWeight:400,
                }}>
                    {'user'}
                </Typography>
            </Box> */}
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'18px',
                fontStyle:'normal',
                fontWeight:400,
                width:'calc(100% / 4)',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'12px',
                },
            }}>
                {formattedTime}
            </Typography>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'18px',
                fontStyle:'normal',
                fontWeight:400,
                textAlign:'center',
                width:'calc(100% / 4)',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'12px',
                },
            }}>
                {name}
            </Typography>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'18px',
                fontStyle:'normal',
                fontWeight:400,
                textAlign:'center',
                width:'calc(100% / 4)',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'12px',
                },
            }}>
                {betAmount}
            </Typography>
            <Box sx={{
                display:'flex',
                justifyContent:'flex-end',
                alignItems:'center',
                gap:'8px',
                width:'calc(100% / 4)',
            }}>
                <CardMedia
                    component="img"
                    image={ usdtIcon }
                    sx={{
                        width:'24px',
                        height:'24px',
                        borderRadius:'12px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'12px',
                            height:'12px',
                            borderRadius:'6px',
                        },
                    }}
                />
                <Typography sx={{
                    color:!betProfit.toString().includes('-') ? '#1CA45F' : '#ACB3D7',
                    fontSize:'17px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textAlign:'right',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                    },
                }}>
                    {`${betProfit.toString().includes('-') ? '' : '+'}${betProfit}`}   
                    {profitLength >= maxAmountLength ? 
                        '' :
                        <span style={{color:'#6D728D'}}>{(betProfit.toString().includes('.') ? '' : '.').padEnd(maxAmountLength - betProfit.toString().length - (betProfit.toString().includes('-') ? 0 : 1), '0')}</span>
                    }
                </Typography>
            </Box>
        </Grid>
    );
}

export default GameContentBetHistoryCommonItem;