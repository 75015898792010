import React, { useState, useEffect,useContext,useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Decimal } from 'decimal.js';
import Contexts from '../DiceGameContext';
import AudioTick from '../../../../assets/audios/dicetick.mp3';
import AudioRolling from '../../../../assets/audios/dicerolling.mp3';

const { AnimationActive,Multiplier,RollOverOrUnder,RollOver,Requesting,
  RollResult,DiceVolume,BetHistory,IsAutoBetting } = Contexts;

const diceAudioRolling = new Audio(AudioRolling);

export const requestbetresults = (isAnimationActive) => {
  const tipelement = document.getElementById('dicetipResultDiv');
  tipelement.classList.remove('dice-bet-result-scale');
  tipelement.classList.remove('dice-bet-result-noanimation-scale');
  void tipelement.offsetWidth;
  //只保留2位小数
  // setRollResult((rollResult).toFixed(2));
  //往div id 为tipResultDiv 的div里添加 dice-bet-result-scale 类
  if (isAnimationActive){
    tipelement.classList.add('dice-bet-result-scale');
  }else{
    tipelement.classList.add('dice-bet-result-noanimation-scale');
  }
}

export const playRollingAudio = (globalVolume) => {
  // 如果音频正在播放，先停止并重置播放位置
  if (!diceAudioRolling.paused) {
    diceAudioRolling.pause();
    diceAudioRolling.currentTime = 0; // 重置音频文件到开始位置
  }
  diceAudioRolling.volume = globalVolume / 100;
  diceAudioRolling.cloneNode(true).play().catch(error => {
    console.log("Playback was prevented. Error: ", error)
  });
}

//游戏音效---拖动滑块时tick音效
const diceAudioTick = new Audio(AudioTick);
const playTickAudio = (globalVolume) => {
  console.log('playTickAudio')
  pauseAudio();
  playAudio(globalVolume);
}

// 播放音频的函数
const playAudio = (globalVolume) =>{
  console.log('playAudio')
  diceAudioTick.volume = globalVolume / 100;
  const playPromise = diceAudioTick.cloneNode(true).play();
  if (playPromise !== undefined) {
    console.log('playPromise')
    playPromise.then(() => {
      // 成功开始播放
    }).catch(error => {
      // 处理播放失败
      console.error('播放失败:', error);
    });
  }
}

// 暂停音频的函数
const pauseAudio = () => {
  diceAudioTick.pause();
  diceAudioTick.currentTime = 0;
}

//防抖函数
const debounce = (func, delay) => {
  let timerId;
  return function(...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      console.log('debounce func do')
      func.apply(this, args);
    }, delay);
  };
}
// 创建一个防抖版本的 debouncedPauseAudio
const debouncedPauseAudio = debounce(pauseAudio, 200);



const DiceComponetGamePart = (props) => {
  const theme = useTheme();
  React.useEffect(() => {
      window.scrollTo({ 
          left: 0,
          top: 0,
          behavior: 'auto'
    });
  },[]);

  const pastWinBttColor = '#ffffff';
  const pastWinBttNormalColor = '#0e71d4';
  const pastWinBttHoverColor = '#1184FA';

  const pastLossBttColor = '#a9a9a9';
  const pastLostBttNormalColor = '#273854';
  const pastLostBttHoverColor = '#557086';
  
  //是否可以继续点击
  const [canClick, setCanClick] = useState(true);
  //乘数
  const {multiplier, setMultiplier} =  useContext(Multiplier);
  //掷大于 true 或掷小于 false
  const {rollOverOrUnder, setRollOverOrUnder} = useContext(RollOverOrUnder);
  //是否有动画
  const {isAnimationActive} = useContext(AnimationActive);
  //投注数字
  const {rollOver, setRollOver} = useContext(RollOver);
  //是否请求中
  const {isRequesting} = useContext(Requesting);
  //投注结果数字
  const {rollResult} = useContext(RollResult);
  //获胜机率
  const [winChance, setWinChance] = useState('49.50');
  //游戏音量
  const {globalVolume} = useContext(DiceVolume);
  //是否自动投注
  const {isAutoBetActive} = useContext(IsAutoBetting);
  //投注历史
  const {historyBetsArr,setHistoryBetsArr} = useContext(BetHistory);

  //删除一条数据
  const deleteOnePastData = (item) => {
    let newArr = historyBetsArr.map((item,index) => {
      item['moveLeft'] = false;
      return item;
    });
    //从newArr数组里删除item.roundId 这一条数据
    let deleteArr = newArr.filter(
      (tmpitem,index) => {
        return tmpitem.roundId !== item.roundId;
      }
    );
    console.log('afterdelete===='+deleteArr);
    setHistoryBetsArr(deleteArr);
  }

  //重置数据 moveLeft 到 false
  const resetPastData = () => {
    let newArr = historyBetsArr.map((item,index) => {
      item['moveLeft'] = false;
      return item;
    });
    setHistoryBetsArr(newArr);
  }

  // //新增一条数据
  // const addOnePastData = (newData) => {
  //   if (!canClick) {
  //     return
  //   }
  //   setCanClick(false)
  //   if(pastBetsArr.length >= 5) {
  //     pastBetsArr[pastBetsArr.length - 1]['disappear'] = true;
  //   }
  //   let newArr = [newData,...pastBetsArr]
  //   newArr.map((item,index) => {
  //     item['moveLeft'] = true;
  //     return item;
  //   });
  //   console.log('newArr===='+newArr);
  //   setHistoryBetsArr(newArr);
  // }

  const tabSelectedColor = '#304553';
  const textColor = '#8EA3C3';
  const primaryColor = '#000000';
  const hoverSecondaryColor = '#1184FA';
  //进度条文字颜色
  const progressTextColor = '#8EA3C3';

  //x图标
  const XSvgIcon = () => (
    <svg fill={textColor} viewBox="0 0 64 64" width="14px" height="14px"> <title></title> <path d="M55.92 7.227H43.493L32 23.307 20.507 7.227H8.08L25.787 32 8.08 56.773h12.453L32 40.694l11.467 16.08H55.92L38.213 32 55.92 7.227Z"></path>
    </svg>
  );
  //百分符号svg
  const PercentSvgIcon = () => (
    <svg fill="#B0BAD3" width="16" height="16" viewBox="0 0 64 64" >
       <path d="M18.38 29.904c6.364 0 11.524-5.16 11.524-11.524 0-6.364-5.16-11.524-11.524-11.524-6.364 0-11.525 5.16-11.525 11.524 0 6.364 5.16 11.524 11.524 11.524Zm0-14.666a3.142 3.142 0 1 1-.001 6.285 3.142 3.142 0 0 1 0-6.285Zm27.24 18.858c-6.364 0-11.524 5.16-11.524 11.524 0 6.364 5.16 11.524 11.524 11.524 6.364 0 11.524-5.16 11.524-11.524 0-6.364-5.16-11.524-11.524-11.524Zm0 14.666a3.142 3.142 0 1 1 0-6.285 3.142 3.142 0 0 1 0 6.285Zm.585-41.904L6.857 57.144h10.644L56.85 6.858H46.205Z"></path>
    </svg>
  );
  //旋转svg
  const RotateSvgIcon = () => (
    <svg fill="#B0BAD3" viewBox="0 0 64 64" width="16" height="16"> <title></title> <path d="M19.94 21.198a16 16 0 0 0-3.673 7.495h5.066L10.667 43.707 0 28.693h5.467A26.667 26.667 0 0 1 49.653 11.84l-6.986 8a16 16 0 0 0-22.728 1.358Zm33.394-.905L64 35.307h-5.333A26.666 26.666 0 0 1 14.48 52.16l6.987-8a16 16 0 0 0 26.267-8.853h-5.067l10.667-15.014Z"></path>
    </svg>
  );
  //显示结果svg <style>.cls-1{fill:#fff;}.cls-2{fill:#e9f0f5;}.cls-3{fill:#d3dee6;}</style>
  const ResultFuckSvgIcon = () => (
    <svg fill='#ffffff' width="46" height="52"><g id="games">
      <path fill='#ffffff' d="M43.28,11.13,25.05.61a4.56,4.56,0,0,0-4.55,0L2.28,11.13A4.55,4.55,0,0,0,0,15.07v21a4.55,4.55,0,0,0,2.28,3.94L20.5,50.58a4.56,4.56,0,0,0,4.55,0L43.28,40.06a4.56,4.56,0,0,0,2.27-3.94v-21A4.56,4.56,0,0,0,43.28,11.13Z"/>
      <path fill='#e9f0f5' d="M21.13,24.64,1.85,13.51A1.23,1.23,0,0,0,0,14.57v22a3.69,3.69,0,0,0,1.84,3.19l19.09,11a3.69,3.69,0,0,0,1.85.49,34.48,34.48,0,0,0,0-23.82A3.3,3.3,0,0,0,21.13,24.64Z"/>
      <path fill="#d3dee6" d="M43.7,13.51,24.42,24.64a3.3,3.3,0,0,0-1.64,2.86V51.32a3.68,3.68,0,0,0,1.84-.49l19.09-11a3.69,3.69,0,0,0,1.84-3.19v-22A1.23,1.23,0,0,0,43.7,13.51Z"/></g>
    </svg>
  );
  
  const progressDefaultColor = '#1184FA';
  const progressBetColor ='#E61043';
  const minMultiplier = 1.0102; //乘数最小
  const maxMultiplier = 9900; //乘数最大
  const minOddsWin = 0.01; //胜率最小
  const maxOddsWin = 98  //胜率最大
  const dec_99 = new Decimal(String(99))// 计算胜率最大99%的Decimal对象
  const dec_100 = new Decimal(String(100))
  const onChangedRollOver = (e) => {
    let value = e.target.value;
    let num = Number(value);
    let dec_num = new Decimal(String(num))
    if (num < minMultiplier) {
      num = minMultiplier;
    } else if (num > maxMultiplier) {
      num = maxMultiplier;
    }
    setRollOver(num.toFixed(2));
    //计算获胜几率
    let dec_oddsWin = rollOverOrUnder?dec_100.minus(dec_num):dec_num
    setWinChance(dec_oddsWin)
    //计算乘数
    setMultiplier(dec_99.div(dec_oddsWin).toFixed(4))
  }
  //根据乘数，获得几率 其中之一计算其他2个数值,并且下注进度变化
  const countMulOverOdds = (mode,newMultiplier,fixed,newOddsWin) => {
    if(mode === 'mult'){
      //根据乘数，获得几率
      const dec_multiplier = new Decimal(String(newMultiplier))
      // 99 / multiplier 获得固定的胜率
      let dec_oddsWin = dec_99.div(dec_multiplier)
      console.log('dec_oddsWin===='+dec_oddsWin)
      let dec_fix2oddsWin =  rollOverOrUnder?Math.floor(dec_oddsWin*100)/100:Math.ceil(dec_oddsWin*100)/100
      console.log('dec_fix2oddsWin===='+dec_fix2oddsWin)
      setWinChance(dec_fix2oddsWin.toFixed(4));
      // 计算进度条位置
      rollOverOrUnder?setRollOver(dec_100.minus(dec_fix2oddsWin).toFixed(2)):setRollOver((dec_fix2oddsWin).toFixed(2))
      // 根据几率，获得精确乘数---只有当脱离焦点才精确计算
      const dec_fixMultiplier = dec_99.div(dec_fix2oddsWin)
      console.log('dec_fixMultiplier===='+dec_fixMultiplier)
      if(fixed){
        setMultiplier(dec_fixMultiplier.toFixed(4));
      }
    }else if(mode === 'odds'){
      //根据几率，获得乘数
      const dec_winOdds = new Decimal(String(newOddsWin))
      //反算乘数 99 / winOdds
      const dec_Multiplier = dec_99.div(dec_winOdds)
      console.log('dec_Multiplier===='+dec_Multiplier)
      const dec_minOddsWin = new Decimal(String(minOddsWin))
      const dec_maxOddsWin = new Decimal(String(maxOddsWin))
      let showValue = dec_Multiplier;
      if (dec_Multiplier.greaterThan(dec_maxOddsWin)) {
        // 大于最大值
        showValue=maxMultiplier;
      } else if(dec_Multiplier.lessThan(dec_minOddsWin)) {
        // 小于最小值
        showValue=minMultiplier;
      }else{
        showValue=dec_Multiplier;
      }
      //保留showValue小数点后4位
      let tmpValue = showValue.toFixed(4)
      setMultiplier(tmpValue);

      //掷小于或大于计算
      rollOverOrUnder?setRollOver(dec_100.minus(dec_winOdds).toFixed(2)):setRollOver((dec_winOdds).toFixed(2))
    }
  }

  //获得几率输入框--失去焦点事件
  const oddsWinCheckValue = () => {
    const dec_minOddsWin = new Decimal(String(minOddsWin))
    const dec_maxOddsWin = new Decimal(String(maxOddsWin))
    const dec_inputOddsWin = new Decimal(winChance.length?String(winChance):'0')
    let showValue = dec_inputOddsWin;
    if (dec_inputOddsWin.greaterThan(dec_maxOddsWin)) {
      // 大于最大值
      showValue=maxOddsWin;
    } else if(dec_inputOddsWin.lessThan(dec_minOddsWin)) {
      // 小于最小值
      showValue=minOddsWin;
    }else{
      showValue=dec_inputOddsWin;
    }
    //保留showValue小数点后4位
    let tmpValue = showValue.toFixed(4)
    setWinChance(tmpValue);
    countMulOverOdds('odds',tmpValue,true,tmpValue);
  }
  //获得几率输入框--变化事件
  const oddsWinChangeEvent = (event) => {
    let newValue = event.target.value;
    console.log('oddsWinChangeEvent newValue:',newValue)
    //键盘输入
    if (event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType) {
      setWinChance(newValue);
    } else {
      // 没有key属性，上下箭头被按下
      const dec_minOddsWin = new Decimal(String(minOddsWin))
      const dec_maxOddsWin = new Decimal(String(maxOddsWin))
      const dec_inputOdds = new Decimal(String(newValue))
      let showValue = dec_inputOdds;
      if (dec_inputOdds.greaterThan(dec_maxOddsWin)) {
        // 大于最大值
        showValue=maxOddsWin;
      } else if(dec_inputOdds.lessThan(dec_minOddsWin)) {
        // 小于最小值
        showValue=minOddsWin;
      }
      //保留showValue小数点后4位
      let tmpValue = showValue.toFixed(4)
      setWinChance(tmpValue);
      countMulOverOdds('odds',tmpValue,false,tmpValue);
    }
  }

  //乘数输入框--失去焦点事件
  const multiplierCheckValue = () => {
    const dec_minMultiplier = new Decimal(String(minMultiplier))
    const dec_maxMultiplier = new Decimal(String(maxMultiplier))
    const dec_inputMultiplier = new Decimal(String(multiplier.length?String(multiplier):'0'))
    let showValue = dec_inputMultiplier;
    if (dec_inputMultiplier.greaterThan(dec_maxMultiplier)) {
      // 大于最大值
      showValue=maxMultiplier;
    } else if(dec_inputMultiplier.lessThan(dec_minMultiplier)) {
      // 小于最小值
      showValue=minMultiplier;
    }
    //保留showValue小数点后4位
    let tmpValue = showValue.toFixed(4)
    setMultiplier(tmpValue);
    countMulOverOdds('mult',tmpValue,true,null);
  }
  //乘数输入框--变化事件
  const multiplierChangeEvent = (event) => {
    const newValue = event.target.value;
    console.log('whenMultiplierInputEvent newValue:',newValue)
    //键盘输入
    if (event.nativeEvent instanceof InputEvent && event.nativeEvent.inputType) {
      setMultiplier(newValue);
    } else {
      // 没有key属性，上下箭头被按下
      const dec_minMultiplier = new Decimal(String(minMultiplier))
      const dec_maxMultiplier = new Decimal(String(maxMultiplier))
      const dec_inputMultiplier = new Decimal(String(newValue))
      let showValue = newValue;
      if (dec_inputMultiplier.greaterThan(dec_maxMultiplier)) {
        // 大于最大值
        showValue=maxMultiplier;
      } else if(dec_inputMultiplier.lessThan(dec_minMultiplier)) {
        // 小于最小值
        showValue=minMultiplier;
      }else{
        showValue=dec_inputMultiplier;
      }
      //保留showValue小数点后4位
      let tmpValue = showValue.toFixed(4)
      setMultiplier(tmpValue);
      countMulOverOdds('mult',tmpValue,false,null);
    }
  }

  

  return (
    <React.Fragment>
      <Box sx={{
          position:'relative',
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          alignItems:'center',
          backgroundColor:'transparent',
          height:'calc(100% - 1.5rem)',
          padding:'0.75rem',
      }}>
        <div style={{
          position:'absolute',
          right:'1rem',
          top:'1rem',
          width:'100%',
          display:'flex',
          flexDirection:'row-reverse',
          gap:'0.25rem',
        }}>
          {
            historyBetsArr.map((item,index) => {
              return (
                <Button className={
                  [
                    'dice-pastbet',
                    item.disappear ? 'dice-pastbet-disappear' : item.moveLeft ? 'dice-pastbet-moveLeft' : '',
                  ].join(' ')
                } key={item.roundId}
                  sx={{
                    backgroundColor:item.isWin ? pastWinBttNormalColor : pastLostBttNormalColor,
                    color:item.isWin ? pastWinBttColor : pastLossBttColor,
                    '&:hover': {
                      backgroundColor:item.isWin ? pastWinBttHoverColor : pastLostBttHoverColor,
                    },
                  }}
                  onAnimationEnd={(e) => {
                    if (historyBetsArr.length > 5){
                      if (item.disappear){
                        deleteOnePastData(item);
                        setCanClick(true)
                      }
                    }else{
                      if (index === 0) { // 只在最后一个动画结束时重置
                        resetPastData();
                        setCanClick(true)
                      }
                    }
                  }}
                >
                  {item.result}
                </Button>
              );
            })
          }
        </div>
        <div id='diceContainer' style={{
          width:'100%',
          marginTop:'2.25rem',
          paddingBottom:'1rem',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          flexGrow:1,
        }}>
          {/* <button onClick={
            ()=>{
              //随机一个1000-9999的数字
              let randomNum = Math.floor(Math.random() * 9000 + 1000)/100;
              //随机一个10000-99999的数字
              let randomRoundId = Math.floor(Math.random() * 90000 + 10000);
              //随机一个true或false
              let randomIsWin = Math.random() > 0.5 ? true : false;
              addOnePastData({'isWin':randomIsWin,'result':String(randomNum),'roundId':String(randomRoundId),'moveLeft':false,'disappear':false});
            }
            }>
            游戏内容
          </button> */}
          <div className='dice-game-betinput-container'>
            <div id='backgrounddiv' style={{
              width:'100%',
              height:'100%',
              backgroundColor:'#273854',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              borderRadius:'24px',
            }}>
              <div id='container1' style={{
                width:'100%',
                height:'100%',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                paddingLeft:'12px',
                paddingRight:'12px',
              }}>
                <div id='triangleContainer' style={{
                  width:'100%',
                  height:'calc(100% - 24px)',
                  backgroundColor:'#0C131D',
                  borderRadius:'24px',
                  display:'flex',
                  flexDirection:'column',
                  justifyContent:'center',
                  alignItems:'center',
                }}>
                  <div id='container2' style={{
                    width:'calc(100% - 24px)',
                    height:'100%',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    paddingLeft:'24px',
                    paddingRight:'24px',
                  }}>
                  <div id='triangleContainer' style={{
                      width:'100%',
                      height:0
                    }}>
                      <div style={{
                        height:0,
                        left:0,
                        position: 'relative',
                      }} className='dice-game-triangle' >
                        <div style={{
                          color:progressTextColor,
                          marginLeft:'-5px',
                          transform:'translate(0,-270%)',
                          fontSize:'14px',
                          fontWeight:'bold',
                        }}>0</div>
                      </div>
                      <div style={{
                        height:0,
                        left:'25%',
                        position: 'relative',
                      }} className='dice-game-triangle' >
                        <div style={{
                          color:progressTextColor,
                          marginLeft:'-9px',
                          transform:'translate(0,-270%)',
                          fontSize:'14px',
                          fontWeight:'bold',
                        }}>25</div>
                      </div>
                      <div style={{
                        height:0,
                        left:'50%',
                        position: 'relative',
                      }} className='dice-game-triangle' >
                        <div style={{
                          color:progressTextColor,
                          marginLeft:'-9px',
                          transform:'translate(0,-270%)',
                          fontSize:'14px',
                          fontWeight:'bold',
                        }}>50</div>
                      </div>
                      <div style={{
                        height:0,
                        left:'75%',
                        position: 'relative',
                      }} className='dice-game-triangle' >
                        <div style={{
                          color:progressTextColor,
                          marginLeft:'-8px',
                          transform:'translate(0,-270%)',
                          fontSize:'14px',
                          fontWeight:'bold',
                        }}>75</div>
                      </div>
                      <div style={{
                        height:0,
                        left:'100%',
                        position: 'relative',
                      }} className='dice-game-triangle' >
                        <div style={{
                          color:progressTextColor,
                          marginLeft:'-12px',
                          transform:'translate(0,-270%)',
                          fontSize:'14px',
                          fontWeight:'bold',
                        }}>100</div>
                      </div>
                  </div>
                    <div style={{
                      width:'100%',
                      position:'relative',
                      height:'8px',
                    }}>
                      <div id='resultShow' style={{
                        width:'100%',
                        position: 'absolute',
                        bottom: '50%',
                        left: '0',
                        right: '0',
                        zIndex: '5',
                        pointerEvents: 'none',
                        transition: 'transform 300ms',
                        willChange: 'transform',
                      }}>
                        <div id='dicetipResultDiv' style={{
                          left:rollResult+'%',
                          display:'inline-flex',
                          position:'relative',
                          transition: isAnimationActive?'all 500ms':'none',
                          transformOrigin: 'center bottom',
                          transform: 'translate(-50%, 4px)',
                          justifyContent:'center',
                          alignItems:'center',
                          opacity:0,
                        }}
                          onAnimationEnd={(e) => {
                            if(e.animationName === 'dice-bet-tip-scale')
                              e.target.classList.remove('dice-bet-result-scale');
                            if(e.animationName === 'dice-bet-result-noanimation-scale')
                              e.target.classList.remove('dice-bet-result-noanimation-scale');
                          }}>
                          <ResultFuckSvgIcon/>
                          <span style={{
                            position:'absolute',
                            fontSize:'12px',
                            fontWeight:'600',
                            textShadow: '0 1px 0 #fff',
                            transition: '100ms',
                            color: rollOverOrUnder?(rollResult>=rollOver?progressDefaultColor:progressBetColor):(rollResult<rollOver?progressDefaultColor:progressBetColor)
                          }}>{rollResult}</span>
                        </div>
                        
                      </div>
                      <div id='customProgress' style={{
                        width:'100%',
                        height:'100%',
                        backgroundColor:'#ffffff',
                        position:'absolute',
                        borderRadius:'4px',
                        overflow:'hidden',
                      }}>
                        <div id='fullprogress' style={{
                          width:'100%',
                          height:'100%',
                          backgroundColor: rollOverOrUnder? progressDefaultColor:progressBetColor,
                        }}>
                        </div>
                        <div id='valueprogress' style={{
                          width:rollOver+'%',
                          height:'100%',
                          backgroundColor:rollOverOrUnder? progressBetColor:progressDefaultColor,
                          transform:'translate(0,-100%)',
                        }}>
                        </div>
                      </div>
                      <input type="range" min="2" max="98" value={rollOver} step="1"
                        className="dice-game-betinput"
                        onChange={(event)=>{
                          if(!isRequesting){
                            playTickAudio(globalVolume);
                            onChangedRollOver(event);
                          }
                          debouncedPauseAudio();
                        }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <button onClick={requestbetresults}>测试按钮</button> */}
        <div className='dice-game-bottom'>
          <div className='dice-game-label' style={{
          }}>
            <TextField
              id="outlined-number"
              label=""
              type="number"
              placeholder=''
              value={multiplier}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                border:'1px solid',
                borderColor:'#55657E',
                borderRadius:'4px',
                padding:'0px 0px 0px 0px',
                backgroundColor:primaryColor,
                width: 'calc(100%-4px)',
                '& .MuiInputBase-input': {
                    height: '26px',
                    padding: '0px 0px 0px 10px',//上右下左
                    fontSize: '12px', // 调整字体大小
                    color: 'white', // 设置占位符颜色
                },
                '& .MuiInputBase-input::placeholder': { // 特别针对占位符的样式
                  color: 'white', // 设置占位符颜色
                  fontWeight: 'bold', // 设置占位符字体加粗
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                      borderColor: 'transparent', // 设置透明
                  }
                },
                '&:hover': {
                  borderColor: hoverSecondaryColor, // 鼠标悬停时的边框颜色
                },
                flex: '1 1 0%',
                opacity: isRequesting || isAutoBetActive? 0.5 : 1,
                pointerEvents: isRequesting || isAutoBetActive?'none':'default',
              }}
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <XSvgIcon /> 
                    </InputAdornment>
                ),
              }}
              onFocus={e => {
                e.target.select();
              }}
              onBlur={
                e => {
                  multiplierCheckValue();
                }
              }
              onChange={(event)=>{
                multiplierChangeEvent(event)
              }}
              inputProps={{ step: 1 }}
            />
            <span style={{
              fontSize:"12px", 
              fontWeight:'bold', 
              color: textColor, 
              marginBottom:'2px',
            }}>乘数</span>
          </div>
          <div id='tzdayu' className='dice-game-label' >
            <Box 
              className='dice-game-div'
              onClick={rollOverEvent => {
                if (isRequesting || isAutoBetActive) {
                  return
                }
                // 99 - rollOver 并且保留两位小数
                let showRoll = new Decimal(rollOver).toFixed(2)
                showRoll = ((new Decimal(100))).minus(new Decimal(rollOver)).toFixed(2)
                setRollOver(showRoll)
                setRollOverOrUnder(!rollOverOrUnder)
              }}
              style={{
                opacity: isRequesting || isAutoBetActive ? '0.5' : '1'
              }}
              sx={{
                '&:hover': {
                  border: isRequesting||isAutoBetActive?'1px solid #55657E':'1px solid #1184FA'
                },
              }}
            >
              <span style={{ 
                fontSize:"11px", 
                fontWeight:'bold', 
                color:'white', 
                padding:'0px 0px 0px 10px',//上右下左
                }}>{rollOver}</span>
              <div style={{
                display:'flex',
                alignItems:'center',
                marginLeft:'auto',
                marginRight:'10px',
              }}><RotateSvgIcon/></div>
            </Box>
            <span style={{
              fontSize:"12px", 
              fontWeight:'bold', 
              color: textColor, 
              marginBottom:'2px',
            }}>
              {rollOverOrUnder?'掷大于':'掷小于'}
            </span>
          </div>
          <div className='dice-game-label' style={{
          }}>
            <TextField
              id="outlined-number"
              label=""
              type="number"
              placeholder='0'
              
              value={winChance}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                border:'1px solid',
                borderColor:'#55657E',
                borderRadius:'4px',
                padding:'0px 0px 0px 0px',
                backgroundColor:primaryColor,
                width: 'calc(100%-4px)',
                '& .MuiInputBase-input': {
                    height: '26px',
                    padding: '0px 0px 0px 10px',//上右下左
                    fontSize: '12px', // 调整字体大小
                    color: 'white', // 设置占位符颜色
                },
                '& .MuiInputBase-input::placeholder': { // 特别针对占位符的样式
                  color: 'white', // 设置占位符颜色
                  fontWeight: 'bold', // 设置占位符字体加粗
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                      borderColor: 'transparent', // 设置透明
                  }
                },
                '&:hover': {
                  borderColor: hoverSecondaryColor, // 鼠标悬停时的边框颜色
                },
                flex: '1 1 0%',
                opacity: isRequesting || isAutoBetActive ? 0.5 : 1,
                pointerEvents: isRequesting || isAutoBetActive?'none':'default',
              }}
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <PercentSvgIcon /> 
                    </InputAdornment>
                ),
              }}
              onFocus={e => {
                e.target.select();
              }}
              inputProps={{ step: 1 }}
              onBlur={
                e => {
                  oddsWinCheckValue();
                }
              }
              onChange={(event)=>{
                if(!isAutoBetActive){
                  oddsWinChangeEvent(event)
                }
              }}
            />
            <span style={{
              fontSize:"12px", 
              fontWeight:'bold', 
              color: textColor, 
              marginBottom:'2px',
            }}>获胜机率</span>
          </div>
        </div>
      </Box>
    </React.Fragment>
  );
}

export default DiceComponetGamePart;