import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import copy from 'clipboard-copy';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import usdtIcon from '../../assets/images/header/header-usdt.png';
import copyIcon from '../../assets/images/wallet/wallet-copy.png';
import TTAlert from '../common/TTAlert';

const DepositContentToken = (props) => {
    const { token,network,depositAddress,copySuccessfully,copyFailed,address,tokenValue,networkValue } = props;
    const theme = useTheme();

    const [ type, setType ] = useState('success');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');

    const handleCopy = () => {
        copy(address)
        .then(() => {
            setType('success');
            setOpen(true);
            setAlertMessage(copySuccessfully);
        })
        .catch((err) => {
            setType('error');
            setOpen(true);
            setAlertMessage(copyFailed);
        });
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };
    
    return (
        <React.Fragment>
            <TTAlert 
                type={type}
                isOpen={open}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />
             <Grid container sx={{
                bgcolor:'#15151B',
                borderRadius:'18px',
                padding:'45px 60px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'flex-start',
                gap:'0px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    borderRadius:'0px',
                    padding:'18px',
                },
            }}>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'28px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textAlign:'center',
                    whiteSpace: 'normal', // 添加这行以重置white-space属性
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                    },
                }}>
                    {token}
                </Typography>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'44px',
                    mt:'28px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        gap:'18px',
                        mt:'12px',
                    },
                }}>
                    <CardMedia
                        component="img"
                        image={usdtIcon}
                        sx={{
                            width:'36px',
                            height:'36px',
                            objectFit:'contain',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'28px',
                                height:'28px',
                            },
                        }}
                    />
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'30px',
                        fontStyle:'normal',
                        fontWeight:500,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'18px',
                        },
                    }}>
                        {tokenValue}
                    </Typography>
                </Box>
                <Box sx={{
                    bgcolor:'#2B3143',
                    width:'100%',
                    height:'1.7px',
                    mt:'20px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'8px',
                    },
                }}/>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'28px',
                    fontStyle:'normal',
                    fontWeight:400,
                    mt:'20px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'18px',
                        fontSize:'15px',
                    },
                }}>
                    {network}
                </Typography>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'30px',
                    fontStyle:'normal',
                    fontWeight:500,
                    mt:'24px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'8px',
                        fontSize:'18px',
                    },
                }}>
                    {networkValue}
                </Typography>
                <Box sx={{
                    bgcolor:'#2B3143',
                    width:'100%',
                    height:'1.7px',
                    mt:'12px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'8px',
                    },
                }}/>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    mt:'29px',
                    width:'100%',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'18px',
                    },
                }}>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'28px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'15px',
                        },
                    }}>
                        {`${tokenValue} ${depositAddress}`}
                    </Typography>
                    <Button variant="image" disableRipple onClick={handleCopy} sx={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        width:'62px',
                        height:'54px',
                        textDecoration:'none',
                        bgcolor:'transparent',
                        '&:hover': {
                            bgcolor:'transparent',
                            filter: 'brightness(0) invert(1)',
                        },
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'40px',
                            height:'40px',
                        },
                    }}>
                        <CardMedia
                            component='img'
                            image={copyIcon}
                            sx={{
                                width:'22px',
                                height:'22px',
                                objectFit:'contain',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'18px',
                                    height:'18px',
                                    mr:'-20px',
                                },
                            }}
                        />
                    </Button>
                </Grid>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'22px',
                    fontStyle:'normal',
                    fontWeight:400,
                    mt:'14px',
                    wordBreak:'break-all',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'18px',
                        mt:'10px',
                    },
                }}>
                    {address}
                </Typography>
            </Grid>
        </React.Fragment>
    )
}

export default DepositContentToken;