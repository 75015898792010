import React, { useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import seeIcon from '../../assets/images/login-and-register/login-and-register-password-see.png';
import hiddenIcon from '../../assets/images/login-and-register/login-and-register-password-hidden.png';
import HeaderWalletSetWithdrawPINInput from './HeaderWalletSetWithdrawPINInput';
import HeaderWalletResetPIN from './HeaderWalletResetPIN';
import TTAlert from '../common/TTAlert';

const HeaderWalletEnterPIN = (props) => {
    const { 
        isOpen,
        closeCallback,
        confirmPWDSuccessCallback,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        enter_pin: t("enter_pin"),
        withdrawal_password: t("withdrawal_password"),
        for_you__password: t("for_you__password"),
        forgot_password: t("forgot_password"),
        confirm: t("confirm"),
        reset_successfully: t("reset_successfully"),
    }

    const [ defaultValue,setDefaultValue ] = useState('');
    const [ seePWD,setSeePWD ] = useState(false);
    const [ PINPWD,setPINPWD ] = useState('');
    const handlePWDValueChanged = (text) => {
        setPINPWD(text);
    };
    const handleSeeOrHiddenNewPWD = () => {
        setSeePWD(!seePWD);
    };
    const handleCloseClick = () => {
        closeCallback && closeCallback();
    };
    const handleForgotPasswordClick = () => {
        setOpenForgotPWD(true);
    };
    const handleConfirmBtnClick = () => {
        confirmPWDSuccessCallback && confirmPWDSuccessCallback(PINPWD);
    };

    // forgot password
    const [ openForgotPWD,setOpenForgotPWD ] = useState(false);
    const handleCloseForgotPWDClick = () => {
        setOpenForgotPWD(false);
    };
    const handleResetPasswordSuccessCallback = (newPassword) => {
        setOpenForgotPWD(false);

        setDefaultValue(newPassword);
        setPINPWD(newPassword);
        setSeePWD(true);

        setType('success');
        setAlertMessage(textData.reset_successfully);
        setOpen(true);
    };

    // alert
    const [ type, setType ] = useState('info');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            {openForgotPWD ? 
                <HeaderWalletResetPIN 
                    isOpen={openForgotPWD}
                    closeCallback={handleCloseForgotPWDClick}
                    resetPasswordSuccessCallback={handleResetPasswordSuccessCallback}
                /> : ''
            }

            <Dialog
                open={isOpen}
                // onClose={handleCloseClick}
                sx={{
                    [`& .${dialogClasses.paper}`]: {
                        bgcolor:'transparent',
                        borderRadius:'18px',
                        maxWidth:'calc(100% - 36px)',
                        maxHeight:'calc(100% - 36px)',
                        margin:'0px',
                        padding:'0px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'8px',
                            maxWidth:'calc(100% - 28px)',
                            maxHeight:'calc(100% - 28px)',
                            width:'calc(100% - 28px)',
                        },
                    },
                }}
            >
                <DialogContent sx={{
                    bgcolor:'transparent',
                    borderRadius:'18px',
                    width:'500px',
                    maxWidth:'100%',
                    m:'0px',
                    p:'0px',
                    overflow:'auto',
                    scrollbarWidth: 'none', // Firefox
                    msOverflowStyle: 'none', // Internet Explorer
                    '&::-webkit-scrollbar': {
                        display: 'none', // Chrome, Safari, Edge
                    },
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'100%',
                        borderRadius:'8px',
                    },
                    transition: theme.transitions.create(['all'], {
                        duration: 300,
                        easing: 'ease-out',
                    }),
                }}>
                    <TTAlert 
                        type={type}
                        isOpen={open}
                        message={alertMessage}
                        closeCallback={handleAlertClose}
                    />

                    <Box sx={{
                        bgcolor:'#1E2736',
                        borderRadius:'18px',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        padding:'18px',
                        width:'calc(100% - 36px)',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'8px',
                        },
                    }}>
                        <Grid container sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            width:'100%',
                        }}>
                            <Typography sx={{
                                color:'#ACB3D7',
                                fontSize:'18px',
                                fontWeight:700,
                            }}>
                                {textData.enter_pin}
                            </Typography>
                            <IconButton sx={{
                                mt:'-8px',
                                mr:'-8px',
                            }} onClick={handleCloseClick}>
                                <CardMedia
                                    component='img'
                                    image={closeIcon}
                                    sx={{
                                        objectFit:'contain',
                                        width:'18px',
                                        height:'18px',
                                        '&:hover': {
                                            filter: 'brightness(0) invert(1)',
                                        },
                                    }}
                                />
                            </IconButton>
                        </Grid>

                        <Grid container sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            mt:'28px',
                            width:'100%',
                        }}>
                            <Typography sx={{
                                color:'#8EA3C3',
                                fontSize:'14px',
                                fontWeight:700,
                            }}>
                                {textData.withdrawal_password}
                            </Typography>
                            <IconButton sx={{
                                padding:'0px',
                            }} onClick={handleSeeOrHiddenNewPWD}>
                                <CardMedia
                                    component='img'
                                    image={seePWD ? hiddenIcon : seeIcon}
                                    sx={{
                                        objectFit:'contain',
                                        width:'30px',
                                        height:'30px',
                                        '&:hover': {
                                            filter: 'brightness(0) invert(1)',
                                        },
                                    }}
                                />
                            </IconButton>
                        </Grid>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            width:'100%',
                            height:'58px',
                            mt:'2px'
                        }}>
                            <HeaderWalletSetWithdrawPINInput
                                isSee={seePWD}
                                handleOnValueChanged={handlePWDValueChanged}
                                defaultValue={defaultValue}
                            />
                        </Box>

                        <Box sx={{
                            bgcolor:'#2C394E',
                            width:'100%',
                            height:'2px',
                            mt:'28px',
                        }} />

                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'14px',
                            fontWeight:600,
                            width:'100%',
                            textAlign:'center',
                            mt:"28px",
                            textTransform:'capitalize',
                        }}>
                            {textData.for_you__password}
                        </Typography>

                        <Typography sx={{
                            color:'#D0A358',
                            fontSize:'14px',
                            fontWeight:600,
                            width:'100%',
                            textAlign:'center',
                            mt:"18px",
                            textTransform:'capitalize',
                            cursor:'pointer',
                            '&:hover': {
                                color:'#705932',
                            },
                        }} onClick={handleForgotPasswordClick}>
                            {textData.forgot_password}
                        </Typography>

                        <Button disabled={PINPWD.length === 6 ? false : true} sx={{
                            textTransform:'none',
                            textDecoration:'none',
                            bgcolor:'#1184FA',
                            color:'#FFFFFF',
                            fontSize:'18px',
                            fontWeight:700,
                            width:'100%',
                            height:'40px',
                            borderRadius:'8px',
                            margin:'28px 0px 0px 0px',
                            '&:hover': {
                                bgcolor:'#1184FA',
                            },
                            '&.Mui-disabled': {
                                bgcolor: alpha(
                                    '#1184FA',
                                    0.5,
                                ),
                            }
                        }} onClick={handleConfirmBtnClick}>
                            {textData.confirm}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default HeaderWalletEnterPIN;

