import { createSlice } from '@reduxjs/toolkit';

const generateRandomNumber = () => {
    const min = 500;
    const max = 1000;
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNum;
};

const initialState = {
    onlineUserNumber: generateRandomNumber(),
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        onlineUserRefresh(state,action) {
            state.onlineUserNumber = action.payload.number;
            // console.log('onlineUserRefresh state:',JSON.stringify(state));
        },
    }
});

export const { onlineUserRefresh } = appSlice.actions;

export default appSlice.reducer;