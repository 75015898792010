import React, { useEffect, useState } from 'react';
import RoutePath from '../../tools/RoutePath';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import headerUSDT from '../../assets/images/header/header-usdt.png';
import headerArrowRight from '../../assets/images/header/header-arrow-right.png';
import { userWalletInfosService } from '../../network/service/wallet';
import { useDispatch, useSelector } from 'react-redux';
import { walletRefresh } from '../../features/walletSlice';
import HeaderWallet from './HeaderWallet';

const HeaderBalance = (props) => {
    const { userToken,fromPage } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const totalBalance = useSelector((state) => state.wallet.totalBalance);
    const balanceLeft = totalBalance.toString().split('.').length > 1 ? totalBalance.toString().split('.')[0] : totalBalance;
    const balanceRight = totalBalance.toString().split('.').length > 1 ? totalBalance.toString().split('.')[1] : '0000';
    const symbol = useSelector((state) => state.wallet.symbol);

    const handleWallet = () => {
        if(userToken.length > 0) {
            // navigate(RoutePath.wallet);
            setWalletOpen(true);
        }else {
            navigate(RoutePath.login,{state: {type:'login',fromPage:fromPage}});
        }
    }

    const handleUserWalletInfos = async () => {
        try {
            const response = await userWalletInfosService();
            if(response.data.data.length > 0) {
                for (let index = 0; index < response.data.data.length; index++) {
                    const element = response.data.data[index];
                    if(element.symbol.toUpperCase() === 'BRL') {
                        dispatch(walletRefresh(element))
                        break;
                    }
                }
            }
        } catch (error) {
            console.log('userWalletInfos error:'+error);
        }
    };

    useEffect(() => {
        if(userToken.length > 0) {
            handleUserWalletInfos();
        }
    },[userToken]);

    // 钱包
    const [ walletOpen, setWalletOpen ] = useState(false);
    const handleWalletAlertClose = () => {
        setWalletOpen(false);
    };
    
    return (
        <React.Fragment>
            {walletOpen ? 
                <HeaderWallet
                    isOpen={walletOpen}
                    category={0}
                    closeCallback={handleWalletAlertClose}
                /> : ''
            }

            <Button variant="contained" sx={{
                bgcolor:'#0D0F13',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                gap:'13px',
                textTransform: 'none',
                height:'28px',
                borderRadius:'14px',
                padding:'0px 24px 0px 13px',
                '&:hover': {
                    backgroundColor: '#0D0F13',
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'0px 8px 0px 8px',
                    gap:'8px',
                },
            }} onClick={handleWallet}>
                <CardMedia
                    component="img"
                    image={headerUSDT}
                    sx={{
                        width:'20px',
                        height:'20px',
                        objectFit:'contain',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'18px',
                            height:'18px',
                        },
                    }}
                />
                <Typography sx={{
                    display:screenMD ? 'none' : 'block',
                    color:'#FFD233',
                    fontSize:'15px',
                    fontStyle:'normal',
                    fontWeight:500,
                }}>
                    {symbol}
                </Typography>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'15px',
                    fontStyle:'normal',
                    fontWeight:500,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                    },
                }}>
                    {`${balanceLeft}.`}
                    <span style={{color:'#6D728D'}}>{balanceRight}</span>
                </Typography>
                <CardMedia
                    component="img"
                    image={headerArrowRight}
                    sx={{
                        width:'5px',
                        height:'12px',
                        objectFit:'contain',
                    }}
                />
            </Button>
        </React.Fragment>
    );
}

export default HeaderBalance;