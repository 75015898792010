import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useTranslation } from "react-i18next";
import logoIcon from '../../assets/images/wallet/wallet-tt-logo.png';
import gameIcon1 from '../../assets/images/temp/game1.png';
import gameIcon2 from '../../assets/images/temp/game2.png';
import gameIcon3 from '../../assets/images/temp/game3.png';
import RoutePath from '../../tools/RoutePath';
import FavoriteContentItem from './FavoriteContentItem';
import TTGameItemSkeleton from '../common/TTGameItemSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoriteListService } from '../../network/service/game';
import { favoriteDataLoadMore, favoriteDataRefresh } from '../../features/favoriteSlice';
import AppConfig from '../../tools/AppConfig';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 16,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#3A3E53',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 8,
      backgroundColor: '#1CA45F',
    },
}));

const FavoriteContentMain = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const { t,i18n } = useTranslation();
    const textData = {
        favoriteGames: t("favorite_games"),
        clickToHere: t("click_to___here"),
        recommendForYou: t("recommend_for_you"),
        loadMore: t("load_more"),
        noMoreData: t("no_more_data"),
        comingSoon: t("coming_soon"),
    };

    const recommendArr = [
        // {
        //     icon:gameIcon1,
        //     gameUrl:`${AppConfig.webSiteUrl}heads-and-tails/`,
        //     title:'Heads&Tails',
        // },
        {
            icon:gameIcon2,
            gameUrl:`${AppConfig.webSiteUrl}plinko/`,
            title:'Plinko',
        },
    ]

    const [ isLoading,setIsLoading ] = useState(false);
    const [ pageStartIndex,setPageStartIndex ] = useState(0);
    const [ progress, setProgress ] = useState(0);

    const favoriteData = useSelector((state) => state.favorite.favoriteData);

    // 获取游戏列表
    const handleFetchFavoriteList = async (pageSize) => {
        try {
            const param = {
                start:pageStartIndex,
                end:pageStartIndex + pageSize,
            };
            const response = await getFavoriteListService(param);
            setIsLoading(false);
            if(pageStartIndex > 0) {
                dispatch(favoriteDataLoadMore({favoriteData:response.data}));
            }else {
                dispatch(favoriteDataRefresh({favoriteData:response.data}));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleStartGetData = () => {
        if(favoriteData !== null && pageStartIndex === 0 && favoriteData.data.length > 20) {
            handleFetchFavoriteList(favoriteData.data.length);
        }else {
            if(favoriteData !== null && favoriteData.total_count - pageStartIndex < 20) {
                handleFetchFavoriteList(favoriteData.total_count - pageStartIndex);
            }else {
                handleFetchFavoriteList(20);
            }
        }
    };

    useEffect(() => {
        handleStartGetData();
    }, [pageStartIndex,i18n.language]);
    useEffect(() => {
        if(favoriteData !== null) {
            if(favoriteData.data.length < favoriteData.total_count) {
                const pg = favoriteData.data.length / favoriteData.total_count * 100;
                setProgress(Math.round(pg * 10) / 10);
            }else {
                setProgress(100);
            }
        }
    }, [favoriteData]);

    const handleLoadMoreClick = () => {
        if(favoriteData.data.length >= favoriteData.total_count || isLoading) {
            return;
        }
        setIsLoading(true);
        setPageStartIndex((prevPage) => {
            if(pageStartIndex + 20 < favoriteData.data.length) {
                return favoriteData.data.length;
            }
            return prevPage + 20;
        });
    }

    return (
        <Grid container sx={{
            padding:'36px 60px 145px 60px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'8px 0px 8px 0px',
            },
        }}>
            <Container sx={{
                // bgcolor:'#bbbbbb',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                minHeight:'500px',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'28px',
                    fontStyle:'normal',
                    fontWeight:500,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                        ml:'18px',
                        mt:'10px',
                    },
                }}>
                    {textData.favoriteGames}
                </Typography>

                <Grid container sx={{
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'start',
                    mt:'17px',
                    rowGap:'46px',
                    columnGap:'22px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        rowGap:'18px',
                        columnGap:'18px',
                        ml:'18px',
                        mr:'18px',
                    },
                }}>
                    {!favoriteData ?
                    (screenMD ? Array(6).fill('') : Array(12).fill('')).map((_,index) => (
                        <TTGameItemSkeleton 
                            itemWidth={screenMD ? 'calc((100vw - 105px) / 2)' : '148px'}
                            key={index}
                        />
                    )) : 
                    <React.Fragment>
                        { favoriteData.data.length === 0 ?
                        <Box sx={{
                            bgcolor:'#15151B',
                            borderRadius:'18px',
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'center',
                            alignItems:'center',
                            height:'578px',
                            gap:'30px',
                            width:'100%',
                            mt:'42px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                height:'303px',
                                gap:'18px',
                                borderRadius:'0px',
                                mt:'18px'
                            },
                        }}>
                            <CardMedia
                                component="img"
                                image={ logoIcon }
                                sx={{
                                    objectFit:'contain',
                                    width:'219px',
                                    height:'70px',
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        width:'185px',
                                        height:'58px',
                                    },
                                }}
                            />
                            <Typography sx={{
                                color:'#6D728D',
                                fontSize:'18px',
                                fontStyle:'normal',
                                fontWeight:400,
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    fontSize:'12px',
                                },
                            }}>
                                {textData.clickToHere}
                            </Typography>
                        </Box> :
                        favoriteData.data.map((item,index) => (
                            <FavoriteContentItem 
                                image={item.game_image}
                                title={item.game_name}
                                gameUrl={''}
                                gameUid={item.game_uid}
                                isCloseFreePlayMode={(item.game_provider && item.game_provider.name && item.game_provider.name.toLocaleLowerCase().includes('pragmaticplay')) ? true : false}
                                playerNum={888}
                                isFavorite={true}
                                favoriteNum={item.favorite_count}
                                category={textData.favoriteGames}
                                comingSoon={textData.comingSoon}
                                fromPage={RoutePath.favorite}
                                key={index}
                            />
                        ))
                        }
                    </React.Fragment>
                    }
                </Grid>

                {(!favoriteData || favoriteData.data.length === 0) ? '' :
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        width:'100%',
                        gap:'54px',
                        mt:'48px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'20px',
                            mt:'20px',
                        },
                    }}>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            gap:'84px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                gap:'28px',
                            },
                        }}>
                            <Typography sx={{
                                color:'#FFFFFF',
                                fontSize:'18px',
                                fontStyle:'normal',
                                fontWeight:500,
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    fontSize:'16px',
                                },
                            }}>
                                {`${favoriteData.data.length > favoriteData.total_count ? favoriteData.total_count : favoriteData.data.length}/${favoriteData.total_count}`}
                            </Typography>
                            <BorderLinearProgress variant="determinate" value={progress} sx={{
                                width:'174px',
                                height:'16px',
                            }}/>
                            <Typography sx={{
                                color:'#FFFFFF',
                                fontSize:'18px',
                                fontStyle:'normal',
                                fontWeight:500,
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    fontSize:'16px',
                                },
                            }}>
                                {`${progress.toString().replace(/\.0$/, '')}%`}
                            </Typography>
                        </Box>
                        <Button variant='text' disableRipple={isLoading} onClick={handleLoadMoreClick} sx={{
                            bgcolor:'#3A3E53',
                            height:'48px',
                            borderRadius:'24px',
                            padding:'0px 30px',
                            justifyContent:"center",
                            alignItems:'center',
                            color:'#FFFFFF',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:500,
                            textTransform:'none',
                            minWidth:'210px',
                            '&:hover': {
                                background: '#3A3E53',
                            },
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'16px',
                                mb:'18px',
                            },
                        }}>
                            {
                                isLoading ? <CircularProgress sx={{ color:'#FFFFFF', }} size={30}/> :
                                <React.Fragment>
                                    {progress === 100 ? textData.noMoreData : textData.loadMore}
                                </React.Fragment>
                            }
                        </Button>
                    </Box>
                }

                {/* <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'start',
                    alignItems:'start',
                    mt:'48px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'8px',
                        width:'100%',
                        bgcolor:'#15151B',
                        pb:'18px',
                    },
                }}>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'24px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'15px',
                            ml:'18px',
                            mt:'18px',
                        },
                    }}>
                        {textData.recommendForYou}
                    </Typography>
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'start',
                        alignItems:'start',
                        mt:'17px',
                        rowGap:'30px',
                        columnGap:'22px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            rowGap:'18px',
                            columnGap:'18px',
                            padding:'0px 36px',
                        },
                    }}>
                        {recommendArr.map((item,index) => (
                            <FavoriteContentItem 
                                image={item.icon}
                                title={item.title}
                                gameUrl={item.gameUrl}
                                isCloseFreePlayMode={true}
                                category={textData.favoriteGames}
                                comingSoon={textData.comingSoon}
                                fromPage={RoutePath.favorite}
                                key={index}
                            />
                        ))}
                    </Grid>
                </Box> */}
            </Container>
        </Grid>
    );
}

export default FavoriteContentMain;