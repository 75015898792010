import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { menuClasses } from '@mui/material/Menu';
import { menuItemClasses } from '@mui/material/MenuItem';
import { popoverClasses } from '@mui/material/Popover';
import { styled, alpha } from '@mui/material/styles';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import HeaderWalletMenu from './HeaderWalletMenu';
import HeaderWalletDeposit from './HeaderWalletDeposit';
import HeaderWalletWithdraw from './HeaderWalletWithdraw';
import HeaderWalletSetWithdrawPIN from './HeaderWalletSetWithdrawPIN';
import { useDispatch, useSelector } from 'react-redux';
import { walletFiatPreWithdrawService } from '../../network/service/wallet';
import { walletFiatWithdrawPreInfoRefresh } from '../../features/walletSlice';
import TTAlert from '../common/TTAlert';
import TTLoading from '../common/TTLoading';

const HeaderWallet = (props) => {
    const { 
        isOpen,
        category,
        closeCallback,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        wallet: t("wallet"),
        withdraw: t("withdraw"),
        withdraw_records: t("withdraw_records"),
        manage_account: t("manage_account"),
        success: t("success"),
    }

    const fiatWithdrawPreInfo = useSelector((state) => state.wallet.fiatWithdrawPreInfo);

    // 当前的是 0:充值 1:提现
    const [ currentCategory, setCurrentCategory ] = useState(category);
    const [ openWallet,setOpenWallet ] = useState(isOpen);
    const [ openSetPIN,setOpenSetPIN ] = useState(false);

    // 提现配置
    const handleFetchWithdrawInfo = async (completionCallback) => {
        try {
            if(completionCallback) {
                setOpenLoading(true);
            }
            const response = await walletFiatPreWithdrawService();
            setOpenLoading(false);
            dispatch(walletFiatWithdrawPreInfoRefresh(response.data));
            completionCallback && completionCallback(response.data.has_withdraw_password);
        } catch (error) {
            console.log('userWalletInfos error:'+error);
        }
    };

    useEffect(() => {
        setCurrentCategory(category);
        handleFetchWithdrawInfo();
    }, [category]);
    useEffect(() => {
        setOpenWallet(isOpen);
    }, [isOpen]);
    
    const handleCloseClick = () => {
        closeCallback && closeCallback();
    };
    const handleCloseSetPINClick = () => {
        setOpenSetPIN(false);
        setOpenWallet(true);
    };
    // 设置提现密码成功回调
    const handleConfirmCallback = () => {
        setType('success');
        setAlertMessage(textData.success);
        setOpen(true);

        setCurrentCategory(1);
        setOpenSetPIN(false);
        setOpenWallet(true);
    }

    const handleChooseMenuClick = (type) => {
        if(fiatWithdrawPreInfo.hasWithdrawPassword === null && type === 1) {
            handleFetchWithdrawInfo((hasPwd) => {
                if(!hasPwd) {
                    setOpenWallet(false);
                    setOpenSetPIN(true);
                }else {
                    setCurrentCategory(type);
                }
            });
            return;
        }
        if(!fiatWithdrawPreInfo.hasWithdrawPassword && type === 1) {
            setOpenWallet(false);
            setOpenSetPIN(true);
            return;
        }
        setCurrentCategory(type);
    };

    // alert
    const [ openLoading,setOpenLoading ] = useState(false);
    const [ type, setType ] = useState('info');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    // 移动端 提现
    const withdrawMenuTitleArr = [
        textData.withdraw,
        textData.withdraw_records,
        textData.manage_account,
    ];
    const [ withdrawCategory,setWithdrawCategory ] = useState(0);
    const handleWithdrawMenuClick = (event) => {
        setAnchorMenuEl(event.currentTarget);
    };
    const [ anchorMenuEl, setAnchorMenuEl ] = React.useState(null);
    const openMenu = Boolean(anchorMenuEl);
    const handleMenuClose = () => {
        setAnchorMenuEl(null);
    };
    const handleMenuClick = (index) => {
        setWithdrawCategory(index);
        setAnchorMenuEl(null);
    };
    const handleWithdrawCategoryChangedCallback = (index) => {
        setWithdrawCategory(index);
    };

    return (
        <React.Fragment>
            <HeaderWalletSetWithdrawPIN
                isOpen={openSetPIN}
                closeCallback={handleCloseSetPINClick}
                confirmCallback={handleConfirmCallback}
            />

            <Dialog
                open={openWallet}
                // onClose={handleCloseClick}
                sx={{
                    [`& .${dialogClasses.paper}`]: {
                        bgcolor:'transparent',
                        borderRadius:'18px',
                        maxWidth:'calc(100% - 36px)',
                        maxHeight:'calc(100% - 36px)',
                        margin:'0px',
                        padding:'0px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'8px',
                            maxWidth:'calc(100% - 28px)',
                            maxHeight:'calc(100% - 28px)',
                            width:'calc(100% - 28px)',
                        },
                    },
                }}
            >
                <DialogContent sx={{
                    bgcolor:'transparent',
                    borderRadius:'18px',
                    width:currentCategory === 0 ? '500px' : '896px',
                    maxWidth:'100%',
                    m:'0px',
                    p:'0px',
                    overflow:'auto',
                    scrollbarWidth: 'none', // Firefox
                    msOverflowStyle: 'none', // Internet Explorer
                    '&::-webkit-scrollbar': {
                        display: 'none', // Chrome, Safari, Edge
                    },
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'100%',
                        borderRadius:'8px',
                    },
                    transition: theme.transitions.create(['all'], {
                        duration: 300,
                        easing: 'ease-out',
                    }),
                }}>
                    <TTLoading 
                        open={openLoading}
                        isForce={true}
                    />
                    <TTAlert 
                        type={type}
                        isOpen={open}
                        message={alertMessage}
                        closeCallback={handleAlertClose}
                    />

                    <Box sx={{
                        bgcolor:'#1E2736',
                        borderRadius:'18px',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'12px',
                        padding:'18px',
                        width:'calc(100% - 36px)',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'8px',
                        },
                    }}>
                        <Grid container sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            width:'100%',
                        }}>
                            <Typography sx={{
                                color:'#ACB3D7',
                                fontSize:'18px',
                                fontWeight:700,
                            }}>
                                {textData.wallet}
                            </Typography>
                            <IconButton sx={{
                                mt:'-8px',
                                mr:'-8px',
                            }} onClick={handleCloseClick}>
                                <CardMedia
                                    component='img'
                                    image={closeIcon}
                                    sx={{
                                        objectFit:'contain',
                                        width:'18px',
                                        height:'18px',
                                        '&:hover': {
                                            filter: 'brightness(0) invert(1)',
                                        },
                                    }}
                                />
                            </IconButton>
                        </Grid>
                        {(screenMD && currentCategory === 1) ? 
                            <Box sx={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'center',
                            }}>
                                <Box sx={{
                                    display:'flex',
                                    justifyContent:'flex-start',
                                    alignItems:'center',
                                    bgcolor:'#000000',
                                    borderRadius:'8px',
                                    border:'1px solid #55657E',
                                    padding:'0px 9px',
                                    height:'28px',
                                    cursor:'pointer',
                                }} onClick={handleWithdrawMenuClick}>
                                    <Typography sx={{
                                        color:'#8EA3C3',
                                        fontSize:'14px',
                                        fontWeight:400,
                                    }}>
                                        {withdrawMenuTitleArr[withdrawCategory]}
                                    </Typography>
                                    <Box sx={{
                                        ml:'8px',
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                                        <path d="M0.000196238 0.300195L5.6002 8.70019L11.2002 0.300196" fill="#8EA3C3"/>
                                        </svg>
                                    </Box>
                                </Box>
                            </Box> : ''
                        }
                        <Box sx={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            width:'460px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'100%',
                            },
                        }}>
                            <HeaderWalletMenu 
                                defaultIndex={currentCategory}
                                chooseMenuClick={handleChooseMenuClick}
                            />
                        </Box>
                        {currentCategory === 0 ?
                            <HeaderWalletDeposit /> :
                            <HeaderWalletWithdraw 
                                withdrawCategory={withdrawCategory}
                                withdrawCategoryChangedCallback={handleWithdrawCategoryChangedCallback}
                            />
                        }
                    </Box>
                    
                    {screenMD ? 
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorMenuEl}
                            open={openMenu}
                            onClose={handleMenuClose}
                        >
                            {withdrawMenuTitleArr.map((item,index) => (
                                <MenuItem onClick={() => handleMenuClick(index)} disableRipple sx={{
                                    display:'flex',
                                    justifyContent:'flex-start',
                                    alignItems:'center',
                                    bgcolor:index === withdrawCategory ? '#0C131D' : 'transparent',
                                    borderLeft:index === withdrawCategory ? '4px solid #1184FA' : 'none',
                                    width:'100%',
                                    height:'40px',
                                    padding:'0px 18px',
                                }}>
                                    {item}
                                </MenuItem>
                            ))}
                        </StyledMenu> : ''
                    }
                    
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default HeaderWallet;


const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    [`& .${paperClasses.root}`]: {
      minWidth:'180px',
      marginTop:'13px',
      padding:'16px 0px',
      color: '#FFFFFF',
      borderRadius: 8,
      backgroundColor:'#16202C',
      [`& .${menuClasses.list}`]: {
        padding: '0px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'start',
        alignItems:'start',
        gap:'18px',
      },
      [`& .${menuItemClasses.root}`]: {
        '&:active': {
          backgroundColor: alpha(
            '#0C131D',
            0.8,
          ),
        },
        '&:hover': {
            backgroundColor:'#0C131D',
        }
      },
    },
    [`& .${popoverClasses.paper}`]: {
        backgroundColor: '#16202C',
    }
}));