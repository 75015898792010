import React, { useMemo } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useFormControl } from '@mui/material/FormControl';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import referralIcon from '../../assets/images/login-and-register/login-and-register-referral.png';
import referralTipIcon from '../../assets/images/login-and-register/login-and-register-referral-tip.png';
import LoginAndRegisterContentInputTip from "./LoginAndRegisterContentInputTip";

const EmailInput = styled(InputBase)(({ theme }) => ({
    [`& .${inputBaseClasses.input}`]: {
      position: 'relative',
      border: '1px solid',
      borderColor: 'transparent',
      fontSize: '14px',
      width:'350px',
      height:'44px',
      color:'#FFFFFF',
      fullWidth:true,
      caretColor:'#1CA45F',
      backgroundColor: 'transparent',
      '&::placeholder': {
        color: '#6D728D',
        opacity: 1,
      },
      [theme.breakpoints.down(MobileBreakpoint.match)]: {
        width:'calc(100vw - 190px)',
        fontSize:'12px',
      },
    },
}));

const LoginAndRegisterContentReferralCodeInput = (props) => {
    const { placeholder,defaultText,optional,forceInputInviteCode,isInputTip,tipText,handleInputChanged } = props;
    const { focused } = useFormControl() || {};
    const theme = useTheme();

    const borderColor = React.useMemo(() => {
        return focused ? '#1CA45F' : (isInputTip ? '#C25156' : 'transparent');
    }, [focused,isInputTip]);
    const icon = React.useMemo(() => {
        return isInputTip ? (focused ? referralIcon : referralTipIcon) : referralIcon;
    }, [focused,isInputTip]);
    const showInputTip = React.useMemo(() => {
        return isInputTip ? (focused ? false : true) : false;
    }, [focused,isInputTip]);

    const handleInputValueChanged = (e) => {
        const filteredValue = e.target.value.replace(/[\u4E00-\u9FA5]/g, '');
        e.target.value = filteredValue;

        handleInputChanged && handleInputChanged(e);
    }

    return (
        <Box sx={{
            display:'flex',
            position:'relative',
            alignItems:'center',
            width:'429px',
            height:'50px',
            backgroundColor: '#0D0F13',
            border: '1px solid',
            borderColor: borderColor,
            borderRadius:'25px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'100%',
                backgroundColor:'#0C0D11',
            },
        }}>
            <CardMedia
                component='img'
                image={icon}
                sx={{
                    ml:'20px',
                    mr:'12px',
                    width:'21px',
                    height:'20px',
                }}
            />
            <EmailInput type='text' onChange={handleInputValueChanged} placeholder={placeholder} value={defaultText}/>
            <Typography sx={{
                display:forceInputInviteCode ? 'none' : 'block',
                color:'#3A3E53',
                textAlign:'right',
                fontSize:'14px',
                fontStyle:'normal',
                fontWeight:500,
                mr:'20px',
                position:'absolute',
                right:'0px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'12px',
                },
            }}>
                {optional}
            </Typography>
            <Box sx={{
                position:'absolute',
                left:'5px',
                top:'23px',
                zIndex:1,
                pointerEvents: 'none',
            }}>
                <LoginAndRegisterContentInputTip 
                    open={showInputTip}
                    text={tipText}
                />
            </Box>
        </Box>
    );
}

export default LoginAndRegisterContentReferralCodeInput;