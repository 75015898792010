import React, { useEffect, useRef, useState } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import TTAlert from "../common/TTAlert";
import TTLoading from "../common/TTLoading";
import LoginAndRegisterContentPasswordInput from "./LoginAndRegisterContentPasswordInput";
import LoginAndRegisterContentVerifyCodeInput from "./LoginAndRegisterContentVerifyCodeInput";
import { getCookieEmail, setCookieAccessToken, setCookieUserId, setCookieUsername } from "../../tools/CommonTool";
import RoutePath from "../../tools/RoutePath";
import { resetEmailPasswordSendCodeService, resetEmailPasswordService } from "../../network/service/user";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { useDispatch } from "react-redux";
import { userLogin } from "../../features/userSlice";

const LoginAndRegisterContentRightForgetAlert = (props) => {
    const { 
        isOpen,
        isProfile,
        closeCallback,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        password: t("password"),
        resetPassword: t("reset_password"),
        confirmPassword: t("confirm_password"),
        charactersLong: t("characters_long"),
        lowercaseCharacter: t("lowercase_character"),
        uppercaseCharacter: t("uppercase_character"),
        number: t("number"),
        verification: t("verification"),
        sendCode: t("send_code"),
        confirm: t("confirm"),
        verificationCodeLater: t("verification_code___later"),
        pleaseInputPassword: t("please_input___password"),
        theNewInvalid: t("the_new___invalid"),
        pleaseInputAgain: t("please_input___again"),
        thePasswordInconsistent: t("the_password___inconsistent"),
        pleaseInputCode: t("please_input___code"),
        sentSuccessfully: t("sent_successfully"),
        resetSuccessfully: t("reset_successfully"),
    }

    const passwordMatchArr = [`6-12 ${textData.charactersLong}`,textData.lowercaseCharacter,textData.uppercaseCharacter,textData.number];

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ newPassword,setNewPassword ] = useState('');
    const [ confirmNewPassword,setConfirmNewPassword ] = useState('');
    const [ verifyCode,setVerifyCode ] = useState('');
    const [ inputVerify,setInputVerify ] = useState(Array(4).fill(false));
    const [ openLoading,setOpenLoading ] = useState(false);

    const handlePasswordInputChanged = (e) => {
        const newArray = [...inputVerify];

        if(/^.{6,12}$/.test(e.target.value)) {
            newArray[0] = true;
        }else {
            newArray[0] = false;
        }
        if(/[a-z]/.test(e.target.value)) {
            newArray[1] = true;
        }else {
            newArray[1] = false;
        }
        if(/[A-Z]/.test(e.target.value)) {
            newArray[2] = true;
        }else {
            newArray[2] = false;
        }
        if(/\d/.test(e.target.value)) {
            newArray[3] = true;
        }else {
            newArray[3] = false;
        }
        setInputVerify(newArray);

        setNewPassword(e.target.value);
    };
    const handleConfirmPasswordInputChanged = (e) => {
        setConfirmNewPassword(e.target.value);
    };
    const handleVerifyCodeInputChanged = (e) => {
        setVerifyCode(e.target.value);
    };

    const timeValue = 60;
    const [ countdownTime,setCountdownTime ] = useState(timeValue);
    const intervalId = useRef();
    const handleSendCodeClick = () => {
        if(countdownTime < timeValue) {
            setType('info');
            setAlertMessage(textData.verificationCodeLater);
            setOpen(true);
            return;
        }

        setCountdownTime(59);

        handleSendEmailVerifyCode();
    };
    const handleConfirmClick = async () => {
        if(newPassword.trim().length <= 0) {
            setType('error');
            setAlertMessage(textData.pleaseInputPassword);
            setOpen(true);
            return;
        }
        const isVerified = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,12}$/.test(newPassword);
        if(!isVerified) {
            setType('error');
            setAlertMessage(textData.theNewInvalid);
            setOpen(true);
            return;
        }
        if(confirmNewPassword.trim().length <= 0) {
            setType('error');
            setAlertMessage(textData.pleaseInputAgain);
            setOpen(true);
            return;
        }
        if(newPassword !== confirmNewPassword) {
            setType('error');
            setAlertMessage(textData.thePasswordInconsistent);
            setOpen(true);
            return;
        }
        if(verifyCode.trim().length <= 0) {
            setType('error');
            setAlertMessage(textData.pleaseInputCode);
            setOpen(true);
            return;
        }

        const saltPWD = CryptoJS.MD5(newPassword + '{PWD_Salt_CC_Poker}').toString();
        const saltPWD2 = CryptoJS.MD5(confirmNewPassword + '{PWD_Salt_CC_Poker}').toString();
        setOpenLoading(true);
        try {
            let params = {
                key:verifyCode,
                email:getCookieEmail(),
                password1:saltPWD,
                password2:saltPWD2,
            };
            const response = await resetEmailPasswordService(params);
            setOpenLoading(false);
            setCookieAccessToken(response.data.data.access_token);
            setCookieUserId(response.data.data.user.pk);
            setCookieUsername(response.data.data.user.username);
            dispatch(userLogin(response.data.data));
            if(screenMD && isProfile) {
                setType('success');
                setAlertMessage(textData.resetSuccessfully);
                setOpen(true);

                closeCallback && closeCallback();
            }else {
                navigate(RoutePath.home);
            }
        } catch (error) {
            setOpenLoading(false);
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };

    const handleSendEmailVerifyCode = async () => {
        try {
            let params = {
                email:getCookieEmail(),
            };
            await resetEmailPasswordSendCodeService(params);
            setType('success');
            setAlertMessage(textData.sentSuccessfully);
            setOpen(true);

            intervalId.current = setInterval(() => {
                setCountdownTime((prevCounter) => prevCounter - 1);
            }, 1000);
        } catch (error) {
            setCountdownTime(timeValue);

            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };
    
    useEffect(() => {
        if(countdownTime === 0) {
            clearInterval(intervalId.current);
            setCountdownTime(timeValue);
        }
    }, [countdownTime]);
    useEffect(() => {
        return () => {
            clearInterval(intervalId.current);
        };
    }, []);

    const handleCloseClick = () => {
        // setCountdownTime(timeValue);
        // clearInterval(intervalId.current);
        closeCallback && closeCallback();
    };

    //alert 
    const [ type, setType ] = useState('info');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };
    
    return (
        <React.Fragment>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <TTAlert 
                type={type}
                isOpen={open}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />
            
            <Dialog
                open={isOpen}
                onClose={handleCloseClick}
                sx={{
                    [`& .${dialogClasses.paper}`]: {
                        bgcolor:'#15151B',
                        borderRadius:'18px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'18px',
                            width:'calc(100vw - 56px)',
                        },
                    },
                }}
            >
                <DialogContent sx={{
                    bgcolor:'#15151B',
                    width:'591px',
                    m:'0px',
                    p:'0px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:'18px',
                        width:'calc(100vw - 65px)',
                    },
                }}>
                    <Box sx={{
                        bgcolor:'#15151B',
                        borderRadius:'18px',
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        padding:'18px 28px',
                        gap:'36px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            padding:'18px',
                            gap:'18px',
                        },
                    }}>
                        <Grid container sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                        }}>
                            <Typography sx={{
                                color:'#ACB3D7',
                                fontSize:'24px',
                                fontStyle:'normal',
                                fontWeight:400,
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    fontSize:'15px',
                                },
                            }}>
                                {textData.resetPassword}
                            </Typography>
                            <CardMedia
                                component='img'
                                image={closeIcon}
                                sx={{
                                    objectFit:'contain',
                                    width:'32px',
                                    height:'32px',
                                    cursor:'pointer',
                                    '&:hover': {
                                        filter: 'brightness(0) invert(1)',
                                    },
                                }}
                                onClick={closeCallback}
                            />
                        </Grid>
                        
                        <Box sx={{
                            width:'100%',
                        }}>
                            <LoginAndRegisterContentPasswordInput 
                                placeholder={textData.password}
                                isAutoFocus={true}
                                isMobileReset={true}
                                handleInputChanged={handlePasswordInputChanged}
                            />
                            <Box sx={{
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                                gap:'12px',
                                mt:'12px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    gap:'10px',
                                },
                            }}>
                                {passwordMatchArr.map((item,index) => (
                                    <Box sx={{
                                        display:'flex',
                                        justifyContent:'flex-start',
                                        alignItems:'center',
                                        gap:'8px',
                                        padding:'0px 18px',
                                    }} key={index}>
                                        <Box sx={{
                                            bgcolor:inputVerify[index] ? '#1CA45F' : '#3A3E53',
                                            borderRadius:'4px',
                                            width:'8px',
                                            height:'8px'
                                        }}/>
                                        <Typography sx={{
                                            color:inputVerify[index] ? '#1CA45F' : '#6D728D',
                                            fontSize:'15px',
                                            fontStyle:'normal',
                                            fontWeight:400,
                                        }}>
                                            {item}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
    
                        <LoginAndRegisterContentPasswordInput 
                            placeholder={textData.confirmPassword}
                            isAutoFocus={true}
                            isMobileReset={true}
                            handleInputChanged={handleConfirmPasswordInputChanged}
                        />

                        <Grid sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            gap:'34px',
                            width:'100%',
                        }}>
                            <LoginAndRegisterContentVerifyCodeInput 
                                placeholder={textData.verification}
                                handleInputChanged={handleVerifyCodeInputChanged}
                            />
                            <Button variant='text' sx={{
                                bgcolor:'#1CA45F',
                                borderRadius:'27px',
                                height:'54px',
                                padding:'0px 35px',
                                textTransform:'none',
                                color:'#FFFFFF',
                                fontSize:'15px',
                                fontStyle:'normal',
                                fontWeight:400,
                                whiteSpace: 'nowrap',
                                '&:hover': {
                                    bgcolor:'#1CA45F',
                                },
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    height:'36px',
                                    borderRadius:'18px',
                                    fontSize:'12px',
                                },
                            }} onClick={handleSendCodeClick}>
                                {countdownTime < timeValue ? countdownTime : textData.sendCode}
                            </Button>
                        </Grid>

                        <Button variant='text' sx={{
                            bgcolor:'#3A3E53',
                            borderRadius:'27px',
                            width:'100%',
                            height:'54px',
                            color:'#FFFFFF',
                            fontSize:'17px',
                            fontStyle:'normal',
                            fontWeight:500,
                            textTransform: 'none',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            gap:'7px',
                            '&:hover': {
                                backgroundColor: '#3A3E53',
                            },
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                height:'52px',
                                borderRadius:'26px',
                            },
                        }} onClick={handleConfirmClick}>
                            {textData.confirm}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default LoginAndRegisterContentRightForgetAlert;