import React from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import LimboLandingPage from '../component/HtmlGames/limboAbout/LimboLandingPage';

const SDGameDice = () => {
    console.log('SDGameDice')
    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.limboGame} drawerIndex={1}/>
            <LimboLandingPage />
        </React.Fragment>
    )
}

export default SDGameDice;