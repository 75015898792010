import React from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import { useLocation } from 'react-router-dom';
import CasinoListContent from '../component/casinoList/CasinoListContent';

const CasinoList = () => {
    const location = useLocation();
    const category = location.state && location.state.category;
    const fromPage = location.state && location.state.fromPage;

    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={fromPage} drawerIndex={category}/>
            <CasinoListContent category={category} fromPage={fromPage} />
        </React.Fragment>
    )
}

export default CasinoList;