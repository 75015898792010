import React, { useEffect, useState } from 'react';
import RoutePath from '../../tools/RoutePath';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import headerLogo from '../../assets/images/header/header-logo.png';
import mobileTTLogo from '../../assets/images/header/header-mobile-tt-logo.png';
import mobileMenuLogo from '../../assets/images/header/header-mobile-drawer-menu.png';
import headerLogoPlay from '../../assets/images/header/header-logo-play.png';
import HeaderGiftCode from './HeaderGiftCode';
import HeaderBalance from './HeaderBalance';
import HeaderUnLogin from './HeaderUnLogin';
import HeaderLoggedIn from './HeaderLoggedIn';
import TTDrawer from '../drawer/TTDrawer';
import { loginWithTelegramService, userInfoService } from '../../network/service/user';
import { userLogin, userRefreshUserInfo } from '../../features/userSlice';
import { setCookieAccessToken, setCookieEmail, setCookieUserId, setCookieUsername } from '../../tools/CommonTool';

const Header = (props) => {
    const { fromPage,drawerIndex } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t,i18n } = useTranslation();
    const textData = {
        giftCode: t("gift_code"),
        login: t("login"),
        signUp: t("sign_up"),
        deposit: t("deposit"),
        account: t("account"),
        transaction: t("transaction"),
        logout: t("logout"),
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ openDrawer,setOpenDrawer ] = useState(false);
    const handleCloseDrawerClick = () => {
        setOpenDrawer(false);
    };

    // 登录状态
    const userId = useSelector((state) => state.user.user.pk);
    const token = useSelector((state) => state.user.access_token);
    const forceLogin = useSelector((state) => state.user.forceLogin);

    const handleTTBetLogo = () => {
        if(screenMD) {
            setOpenDrawer(true);
            return;
        }
        navigate(RoutePath.home);
    }
    const handleGetUserInfo = async (userId) => {
        try {
            const response = await userInfoService(userId);
            setCookieUsername(response.data.name);
            dispatch(userRefreshUserInfo(response.data));
        } catch (error) {
            console.log('getUserInfo error:'+error);
        }
    };
    useEffect(() => {
        if(userId > 0) {
            handleGetUserInfo(userId);
        }
    },[userId]);
    useEffect(() => {
        if(forceLogin) {
            navigate(RoutePath.login,{state: {type:'login',fromPage:RoutePath.home,isLogout:true}});
        }
    }, [forceLogin]);

    return (
        <React.Fragment>
            <Drawer
                anchor='left'
                open={!screenMD ? false : openDrawer}
                onClose={handleCloseDrawerClick}
                sx={{
                    [`& .${drawerClasses.paper}`]: {
                        bgcolor:'#15151B',
                    }
                }}
            >
                <TTDrawer 
                    defaultSelectIndex={drawerIndex} 
                    isSlider={true} 
                    drawerOnClose={handleCloseDrawerClick}
                />
            </Drawer>
            <TTDrawer defaultSelectIndex={drawerIndex} isSlider ={screenMD ? false : true} />
            <AppBar sx={{
                bgcolor:'#15151B',
                minHeight:'0px',
            }}>
                <Toolbar sx={{
                    display:'flex',
                    padding:'0px 45px 0px 32px',
                    justifyContent:'space-between',
                    alignItems:'center',
                    height:'64px',
                    minHeight:'0px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        padding:'0px 16px 0px 8px',
                    },
                }}>
                    <Button sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'start',
                        gap:'31px',
                        '&:hover': {
                            backgroundColor: '#15151B',
                        },
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'8px',
                        },
                    }} onClick={handleTTBetLogo} disableRipple>
                        <CardMedia
                            component="img"
                            image={ screenMD ? mobileTTLogo : headerLogo}
                            sx={{
                                width:'92px',
                                height:'29px',
                                objectFit:'contain',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'31px',
                                    height:'28px',
                                },
                            }}
                        />
                        <CardMedia
                            component="img"
                            image={ screenMD ? mobileMenuLogo : headerLogoPlay}
                            sx={{
                                display:screenMD ? 'block' : 'none',
                                width:'26px',
                                height:'26px',
                                objectFit:'contain',
                            }}
                        />
                    </Button>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'end',
                        alignItems:'center',
                        gap:'20px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'8px',
                        },
                    }}>
                        {/* <HeaderGiftCode 
                            giftCode={textData.giftCode}
                        /> */}
                        <HeaderBalance 
                            userToken={token}
                            fromPage={fromPage}
                        />
                        <HeaderUnLogin 
                            login={textData.login}
                            signUp={textData.signUp}
                            fromPage={fromPage}
                            isHidden={token.length > 0 ? true : false}
                        />
                        <HeaderLoggedIn 
                            deposit={textData.deposit}
                            account={textData.account}
                            transaction={textData.transaction}
                            logout={textData.logout}
                            isHidden={token.length > 0 ? false : true}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

export default Header;