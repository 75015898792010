const RoutePath = {
    home:'/',
    poker:'/poker',
    favorite:'/favorite',
    recent:'/recent',
    login:'/login',
    register:'/register',
    wallet:'/wallet',
    deposit:'/deposit',
    withdraw:'/withdraw',
    game:'/game',
    gameMobile:'/gameMobile',
    profile:'/profile',
    record:'/record',
    faq:'/faq',
    slots:'/slots',
    inHouseGames:'/inHouseGames',
    helpCenter:'/helpCenter',
    helpCenterDesc:'/helpCenterDesc',
    referral:'/referral',
    liveCasino:'/liveCasino',
    diceGame:'/sdgames/dice',
    popularSlots:'/popularSlots',
    newSlots:'/newSlots',
    hotSlots:'/hotSlots',
    limboGame:'/sdgames/limbo',
    depositResult:'/depositResult',
};

export default RoutePath;
