import React from 'react';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../tools/RoutePath';

const DepositResult = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const textData = {
        deposit_success: t("deposit_success"),
        success: t("success"),
    }

    const handleDoneBtnClick = () => {
        navigate(RoutePath.home,{state:{openDepositRecord:true}});
    };

    return (
        <React.Fragment>
            <Grid sx={{
                width:'100vw',
                height:'100vh',
                bgcolor:'#0C131D',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
            }}>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'100%',
                }}>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'40px',
                        fontWeight:600,
                    }}>
                        {textData.deposit_success}
                    </Typography> 
                    <Box sx={{
                        bgcolor:'#2C394E',
                        width:'100%',
                        height:'2px',
                        margin:'26px 0px',
                    }} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="156" height="128" viewBox="0 0 156 128" fill="none">
                    <path d="M148.673 113.179C148.673 117.247 145.384 120.547 141.33 120.547C137.275 120.547 133.987 117.247 133.987 113.179C133.987 109.111 137.275 105.812 141.33 105.812C145.384 105.812 148.673 109.111 148.673 113.179Z" fill="url(#paint0_linear_8053_10420)"/>
                    <path d="M22.643 22.1276C22.643 28.3382 17.6246 33.3735 11.4348 33.3735C5.24504 33.3735 0.226562 28.3382 0.226562 22.1276C0.226562 15.9171 5.24504 10.8818 11.4348 10.8818C17.6246 10.8818 22.643 15.9171 22.643 22.1276Z" fill="url(#paint1_linear_8053_10420)"/>
                    <path d="M68.6383 17.6528C38.3832 17.6528 13.8494 42.2689 13.8494 72.6255C13.8494 102.982 38.3832 127.598 68.6383 127.598C98.8933 127.598 123.427 102.982 123.427 72.6255C123.427 42.2689 98.8933 17.6528 68.6383 17.6528ZM96.5237 60.1773L72.035 92.9114C70.7556 94.629 68.7464 95.6415 66.6111 95.6506C65.3407 95.6596 64.0883 95.298 63.0071 94.629L43.3116 82.4792C41.1763 81.2226 39.8789 78.9083 39.9329 76.4223C39.978 73.9363 41.3655 71.6763 43.5549 70.5101C45.7443 69.3439 48.3842 69.4615 50.4654 70.8084L64.8812 79.6768L85.6038 51.9418C87.8743 48.9134 92.154 48.3077 95.1722 50.5768C98.1815 52.8549 98.7942 57.1489 96.5237 60.1773Z" fill="url(#paint2_linear_8053_10420)"/>
                    <path d="M107.498 22.5977L116.94 3.18869L117.805 1.89596L118.877 0.955793L120.184 0.313947L121.598 0.015625L123.022 0.0969857L124.499 0.57611L124.941 0.793071L126.22 1.66996L127.166 2.74573L127.806 4.0475L128.103 5.47583L128.013 6.89512L127.536 8.37769L118.093 27.7958L117.228 29.0795L116.156 30.0287L114.85 30.6705L113.435 30.9598L112.012 30.8784L110.534 30.3993L110.093 30.1824L108.813 29.3145L107.867 28.2387L107.227 26.9279L106.93 25.5086L107.02 24.0803L107.498 22.5977Z" fill="url(#paint3_linear_8053_10420)"/>
                    <path d="M127.932 34.6572L147.799 26.3222L149.303 25.9335H150.727L152.123 26.3132L153.385 27.0274L154.403 28.0308L155.196 29.3687L155.385 29.8298L155.772 31.3304V32.7678L155.394 34.169L154.682 35.4346L153.682 36.4471L152.349 37.2426L132.482 45.5686L130.977 45.9663H129.554L128.157 45.5866L126.896 44.8725L125.878 43.86L125.085 42.5311L124.896 42.07L124.508 40.5604V39.132L124.887 37.7308L125.598 36.4652L126.599 35.4437L127.932 34.6572Z" fill="url(#paint4_linear_8053_10420)"/>
                    <defs>
                        <linearGradient id="paint0_linear_8053_10420" x1="77.9995" y1="0.015625" x2="77.9995" y2="127.598" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#30AF87"/>
                        <stop offset="1" stopColor="#0F382B"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_8053_10420" x1="77.9995" y1="0.015625" x2="77.9995" y2="127.598" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#30AF87"/>
                        <stop offset="1" stopColor="#0F382B"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_8053_10420" x1="77.9995" y1="0.015625" x2="77.9995" y2="127.598" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#30AF87"/>
                        <stop offset="1" stopColor="#0F382B"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_8053_10420" x1="77.9995" y1="0.015625" x2="77.9995" y2="127.598" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#30AF87"/>
                        <stop offset="1" stopColor="#0F382B"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_8053_10420" x1="77.9995" y1="0.015625" x2="77.9995" y2="127.598" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#30AF87"/>
                        <stop offset="1" stopColor="#0F382B"/>
                        </linearGradient>
                    </defs>
                    </svg>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        margin:'16px 0px',
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" fill="none">
                            <path d="M13.9991 0.493164C6.79223 0.493164 0.894531 6.3908 0.894531 13.5997C0.894531 20.8067 6.79223 26.7043 13.9991 26.7043C21.208 26.7043 27.1057 20.8067 27.1057 13.5997C27.1057 6.3908 21.208 0.493164 13.9991 0.493164ZM11.3786 20.152L4.82624 13.5998L6.66166 11.7635L11.3786 16.4814L21.3406 6.52246L23.174 8.35677L11.3786 20.152Z" fill="#30AF87"/>
                        </svg>
                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'24px',
                            fontWeight:400,
                            ml:'16px',
                        }}>
                            {textData.success}
                        </Typography> 
                    </Box>
                    <Box sx={{
                        bgcolor:'#2C394E',
                        width:'100%',
                        height:'2px',
                    }} />
                    <Button sx={{
                        textTransform:'none',
                        textDecoration:'none',
                        bgcolor:'#1184FA',
                        color:'#FFFFFF',
                        fontSize:'14px',
                        fontWeight:700,
                        width:'230px',
                        height:'32px',
                        borderRadius:'8px',
                        mt:'88px',
                        '&:hover': {
                            bgcolor:'#1184FA',
                        },
                    }} onClick={handleDoneBtnClick}>
                        {'OK'}
                    </Button>
                </Box>
            </Grid>
        </React.Fragment>
    )
}

export default DepositResult;