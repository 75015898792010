import api from "../api";
import httpManager from "../httpManager";

// 获取钱包余额
export const userWalletInfosService = () => {
    return httpManager({
        url:api.gameGetSimpleTokensInfo,
        method:'post',
        data:{},
    });
}

// 获取重置地址
export const walletDepositInfoService = (params) => {
    return httpManager({
        url:api.gameGetTokensInfo,
        method:'post',
        data:params,
    });
}

// 划转
export const walletTransferService = (params) => {
    return httpManager({
        url:api.walletTransfer,
        method:'post',
        data:params,
    });
}

// 提现
export const walletWithdrawService = (params) => {
    return httpManager({
        url:api.walletWithdrawRequest,
        method:'post',
        data:params,
    });
};

// 充值记录
export const walletDepositRecordService = (params) => {
    return httpManager({
        url:api.walletGetDepositRecord,
        method:'post',
        data:params,
    });
}

// 提现记录
export const walletWithdrawRecordService = (params) => {
    return httpManager({
        url:api.walletGetWithdrawRecord,
        method:'post',
        data:params,
    });
}

// 钱包(法币)可充值配置
export const walletFiatPreDepositService = (params) => {
    return httpManager({
        url:`${api.walletFiatPreDeposit}?symbol=${params.symbol}`,
        method:'get',
        data:params,
    });
}
// 钱包(法币)充值
export const walletFiatDepositService = (params) => {
    return httpManager({
        url:api.walletFiatDeposit,
        method:'post',
        data:params,
    });
}
// 钱包(法币)充值记录
export const walletFiatDepositListService = (params) => {
    return httpManager({
        url:`${api.walletDepositList}?symbol=${params.symbol}&page_num=${params.page}&page_size=${params.pageSize}`,
        method:'get',
        data:{},
    });
}
// 钱包(法币)提现配置
export const walletFiatPreWithdrawService = () => {
    return httpManager({
        url:api.walletFiatPreWithdraw,
        method:'get',
        data:{},
    });
}
// 钱包(法币)添加提现密码
export const walletAddWithdrawPasswordService = (params) => {
    return httpManager({
        url:api.walletAddWithdrawPassword,
        method:'post',
        data:params,
    });
}
// 钱包(法币)修改提现密码 发送验证码
export const walletSendWithdrawPasswordService = () => {
    return httpManager({
        url:api.walletSendWithdrawPasswordCode,
        method:'post',
        data:{},
    });
}
// 钱包(法币)修改提现密码
export const walletResetWithdrawPasswordService = (params) => {
    return httpManager({
        url:api.walletResetWithdrawPassword,
        method:'post',
        data:params,
    });
}
// 钱包(法币)提现
export const walletFiatWithdrawService = (params) => {
    return httpManager({
        url:api.walletFiatWithdraw,
        method:'post',
        data:params,
    });
}
// 钱包(法币)提现记录
export const walletFiatWithdrawListService = (params) => {
    return httpManager({
        url:`${api.walletWithdrawList}?symbol=${params.symbol}&page_num=${params.page}&page_size=${params.pageSize}`,
        method:'get',
        data:{},
    });
}
// 钱包(法币)添加提现账户
export const walletAddBandCardService = (params) => {
    return httpManager({
        url:api.walletAddBankCard,
        method:'post',
        data:params,
    });
} 