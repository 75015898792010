import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import GameContentGamePreview from "./GameContentGamePreview";
import GameContentBetHistory from "./GameContentBetHistory";
import GameContentRecommend from "./GameContentRecommend";
import { useSelector } from "react-redux";

const GameContentMain = (props) => {
    const {
        category,fromPage,gameIcon,gameName,gameUrl,gameUid,isCloseFreePlayMode,isFavorite,favoriteNum,
    } = props;
    const { t } = useTranslation();
    const textData = {
        recommendForYou: t("recommend_for_you"),
        whilePlayingIn: t("while_playing___in"),
        profileSetting: t("profile_setting"),
        freePlay: t("free_play"),
        realPlay: t("real_play"),
        funPlay: t("fun_play"),
        favorites: t("favorites"),
        by: t("by"),
        comingSoon: t("coming_soon"),
        pleaseLoginAccount: t("please_login__account"),
        insufficientBalanceUp: t("insufficient_balance___up"),
        latestBets: t("latest_bets"),
        myBets: t("my_beats"),
        player: t("player"),
        time: t("time"),
        bet: t("bet"),
        betID: t("bet_id"),
        amount: t("amount"),
        payout: t("payout"),
        profit: t("profit"),
        noData: t("no_data"),
        choice: t("choice"),
    };
    const theme = useTheme();

    const lookNewGameUrl = useSelector((state) => state.game.currentGameUrl);
    
    return (
        <Grid container sx={{
            padding:'36px 60px 100px 60px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'8px 0px',
            },
        }}>
            <Container sx={{
                // bgcolor:'#bbbbbb',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                minHeight:'500px',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <GameContentGamePreview 
                    category={category}
                    fromPage={fromPage}
                    gameIcon={gameIcon}
                    gameName={gameName}
                    gameUrl={gameUrl}
                    gameUid={gameUid}
                    isCloseFreePlayMode={isCloseFreePlayMode}
                    isFavorite={isFavorite}
                    favoriteNum={favoriteNum}
                    whilePlayingIn={textData.whilePlayingIn}
                    profileSetting={textData.profileSetting}
                    freePlay={textData.freePlay}
                    realPlay={textData.realPlay}
                    funPlay={textData.funPlay}
                    favorites={textData.favorites}
                    by={textData.by}
                    lookNewGameUrl={lookNewGameUrl}
                    pleaseLoginAccount={textData.pleaseLoginAccount}
                    insufficientBalanceUp={textData.insufficientBalanceUp}
                />
                <GameContentBetHistory 
                    latestBets={textData.latestBets}
                    myBets={textData.myBets}
                    player={textData.player}
                    time={textData.time}
                    bet={textData.bet}
                    choice={textData.choice}
                    betID={textData.betID}
                    amount={textData.amount}
                    payout={textData.payout}
                    profit={textData.profit}
                    noData={textData.noData}
                    gameUrl={gameUrl}
                    gameUid={gameUid}
                />
                {/* <GameContentRecommend 
                    category={category}
                    recommendForYou={textData.recommendForYou}
                    currentGameUrl={gameUrl}
                    comingSoon={textData.comingSoon}
                    fromPage={fromPage}
                /> */}
            </Container>
        </Grid>
    );
}

export default GameContentMain;