import React, { useEffect } from 'react';
import DepositContent from '../component/deposit/DepositContent';
import { useSelector } from 'react-redux';
import { fpDepositEvent } from '../tools/FacebookPixelEvent';

const Deposit = () => {

    const nickname = useSelector((state) => state.user.user.username);
    const userId = useSelector((state) => state.user.user.pk);

    const user = {
        userId:userId,
        nickname:nickname,
    }

    useEffect(() => {
        fpDepositEvent(user);
    }, []);

    return (
        <React.Fragment>
            <DepositContent />
        </React.Fragment>
    )
}

export default Deposit;