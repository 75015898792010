import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';
import LimboHorGame from './LimboHorGame';
import LimboComponetMenuTabs from './limboComponet/LimboComponetMenuTabs';
import LimboComponetFooter from './limboComponet/LimboComponetFooter';
import Contexts from './LimboContext';
import LimboComponetGamePart from './limboComponet/LimboComponetGamePart';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const serverConfig = {
    baseURL: "http://54.233.228.45:8080/", // 请求基础地址,可根据环境自定义
    withCredentials: false, // 跨域请求是否需要携带 cookie
};
const { MaxBetContext, AnimationActive, Multiplier,
    Requesting,RollResult,DiceVolume,BetHistory,IsAutoBetting,AutoBetStart,AutoBetStop } = Contexts;

let layoutDirection = 'hor';

const LimboGameContent = () => {
    const theme = useTheme();
    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);

    //游戏网络---初始化
    const totalBalance = useSelector((state) => state.wallet.totalBalance);
    const accessToken = useSelector((state) => state.user.access_token);
    // console.log('HtmlGamesContent totalBalance:',totalBalance);
    // console.log('HtmlGamesContent accessToken:',accessToken);
    const httpManager = axios.create({
        baseURL: serverConfig.baseURL,
        timeout: 10000,
        headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        },
        withCredentials: serverConfig.withCredentials,
    });
    // 游戏网络---设置请求拦截器以添加Authorization头
    httpManager.interceptors.request.use(
        config => {
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
        },
        error => {
        return Promise.reject(error);
        }
    );

    // 设置状态变量来存储窗口宽度
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        // 定义处理窗口大小变化的函数
        function handleResize() {
            setWindowWidth(window.innerWidth);
            console.log('HtmlGamesContent windowWidth:',window.innerWidth);
            if(window.innerWidth > (700 + 260)){
                layoutDirection = 'hor';
            }else{
                layoutDirection = 'ver';
            }
        }
        // 添加事件监听器
        window.addEventListener('resize', handleResize);
        // 清理函数
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const layoutChangeWidth = 700 + 260;

    //共享状态
    const [isMaxBetActive, setIsMaxBetActive] = useState(false);
    const [isAnimationActive, setIsAnimationActive] = useState(true);
    const [multiplier, setMultiplier] = useState('2.0000');
    const [isRequesting, setIsRequesting] = useState(false);
    const [rollResult, setRollResult] = useState('0');
    const [globalVolume, setGlobalVolume] = useState(100);
    const [historyBetsArr, setHistoryBetsArr] = useState([]);
    const [isAutoBetActive, setIsAutoBetActive] = useState(false);
    const [autoBetStart, setAutoBetStart] = useState(false);
    const [autoBetStop, setAutoBetStop] = useState(false);


    useEffect(() => {
        if (autoBetStart) {
            //延迟2秒后设置为false
            setTimeout(() => {
                setAutoBetStart(false);
            }, 2000);
        }
    },[autoBetStart]);

    useEffect(() => {
        if (autoBetStop) {
            //延迟2秒后设置为false
            setTimeout(() => {
                setAutoBetStop(false);
            }, 2000);
        }
    },[autoBetStop]);

    console.log('HtmlGamesContent layoutDirection:',layoutDirection);
    return (
      <React.Fragment>
        {
            <Box sx={{
                width:'100%',
                height:'0px',
                zIndex:'100',
            }}>
                <Stack sx={{ 
                    width: '260px',  
                    marginTop:'40px',
                    marginLeft:'10px',
                    visibility: autoBetStart || autoBetStop ? 'visible' : 'hidden',
                    pointerEvents: autoBetStart || autoBetStop ? 'auto' : 'none',
                    }} spacing={2}>
                    <Alert onClose={() => {
                        setAutoBetStart(false);
                    }}
                    variant='filled'
                    sx={{
                        visibility: autoBetStart ? 'visible' : 'hidden',
                        pointerEvents: autoBetStart ? 'auto' : 'none',
                        maxHeight: autoBetStart ? '60px' : '0px', // 假设 Alert 高度大约为 60px
                        overflow: 'hidden',
                        backgroundColor:'#0A67A3',
                        transition:'all 0.3s ease-in-out',
                        transform: autoBetStart?'translate(0px)':'translateX(-300px)',
                    }}
                    >开始自动下注</Alert>
                    <Alert onClose={() => {

                    }}
                    variant='filled'
                    sx={{
                        visibility: autoBetStop ? 'visible' : 'hidden',
                        pointerEvents: autoBetStop ? 'auto' : 'none', 
                        backgroundColor:'#D0A358',
                        transition:'all 0.3s ease-in-out',
                        transform: autoBetStop?'translateX(0px)':'translateX(-300px)',
                    }}
                    >停止自动下注</Alert>
                </Stack>
            </Box>
        }
        {
            windowWidth > layoutChangeWidth ? (
             <LimboHorGame 
                isMaxBetActive={isMaxBetActive}
                setIsMaxBetActive={setIsMaxBetActive}
                isAnimationActive={isAnimationActive}
                setIsAnimationActive={setIsAnimationActive}
                multiplier={multiplier}
                setMultiplier={setMultiplier}
                httpManager={httpManager}
                isRequesting={isRequesting}
                setIsRequesting={setIsRequesting}
                rollResult={rollResult}
                setRollResult={setRollResult}
                totalBalance={totalBalance}
                globalVolume={globalVolume}
                setGlobalVolume={setGlobalVolume}
                historyBetsArr={historyBetsArr}
                setHistoryBetsArr={setHistoryBetsArr}
                isAutoBetActive={isAutoBetActive}
                setIsAutoBetActive={setIsAutoBetActive}
                layoutDirection={layoutDirection}
                autoBetStart={autoBetStart}
                setAutoBetStart={setAutoBetStart}
                autoBetStop={autoBetStop}
                setAutoBetStop={setAutoBetStop}
             />
            ):(
              //竖版
              <Box sx={{
                  display:'flex',
                  flexDirection: 'column',
                  justifyContent:'center',
                  alignItems:'center',
                  width:'calc(100% - 10px)',
                  height:'auto',
                  padding:'5px 5px 5px 5px',
                  marginTop:'27px',
              }}>
                  <Box sx={{
                      backgroundColor:'#16202C',
                      maxWidth:'378px',
                      width:'100%',
                      flexShrink: 1,
                      height:'353px',
                      padding:'8px 8px 8px 8px',
                      borderTopLeftRadius:'5px',
                      borderTopRightRadius:'5px',
                  }}>
                        <IsAutoBetting.Provider value={{ isAutoBetActive, setIsAutoBetActive }}>
                        <BetHistory.Provider value={{ historyBetsArr, setHistoryBetsArr }}>
                        <DiceVolume.Provider value={{ globalVolume, setGlobalVolume }}>
                        <RollResult.Provider value={{ rollResult, setRollResult }}>
                        <Requesting.Provider value={{ isRequesting, setIsRequesting }}>
                        <Multiplier.Provider value={{ multiplier, setMultiplier }}>
                        <AnimationActive.Provider value={{ isAnimationActive, setIsAnimationActive }}>
                            <LimboComponetGamePart/>
                        </AnimationActive.Provider>
                        </Multiplier.Provider>
                        </Requesting.Provider>
                        </RollResult.Provider>
                        </DiceVolume.Provider>
                        </BetHistory.Provider>
                        </IsAutoBetting.Provider>
                  </Box>
                  <Box sx={{
                      backgroundColor:'#1E2736',
                      maxWidth:'378px',
                      width:'100%',
                      flexShrink: 1,
                      height:'auto',
                      padding:'8px 8px 8px 8px',
                  }}>
                        <AutoBetStop.Provider value={{ autoBetStop, setAutoBetStop }}>
                        <AutoBetStart.Provider value={{ autoBetStart, setAutoBetStart }}>
                        <IsAutoBetting.Provider value={{ isAutoBetActive, setIsAutoBetActive }}>
                        <BetHistory.Provider value={{ historyBetsArr, setHistoryBetsArr }}>
                        <DiceVolume.Provider value={{ globalVolume, setGlobalVolume }}>
                        <RollResult.Provider value={{ rollResult, setRollResult }}>
                        <Requesting.Provider value={{ isRequesting, setIsRequesting }}>
                        <Multiplier.Provider value={{ multiplier, setMultiplier }}>
                        <AnimationActive.Provider value={{ isAnimationActive, setIsAnimationActive }}>
                        <MaxBetContext.Provider value={{ isMaxBetActive, setIsMaxBetActive,isAnimationActive,setIsAnimationActive}}>
                            <LimboComponetMenuTabs 
                                httpManager={httpManager}
                                totalBalance={totalBalance}
                                layoutDirection={layoutDirection}
                                />
                        </MaxBetContext.Provider>
                        </AnimationActive.Provider>
                        </Multiplier.Provider>
                        </Requesting.Provider>
                        </RollResult.Provider>
                        </DiceVolume.Provider>
                        </BetHistory.Provider>
                        </IsAutoBetting.Provider>
                        </AutoBetStart.Provider>
                        </AutoBetStop.Provider>
                  </Box>
                <Box sx={{
                    backgroundColor:'#1E2736',
                    maxWidth:'384px',
                    width:'calc(100% - 10px)',
                    flexShrink: 1,
                    height:'42px',
                    padding:'5px 5px 5px 5px',
                    borderBottomLeftRadius:'5px',
                    borderBottomRightRadius:'5px',
                }}>
                    <DiceVolume.Provider value={{ globalVolume, setGlobalVolume }}>
                    <AnimationActive.Provider value={{ isAnimationActive, setIsAnimationActive }}>
                    <MaxBetContext.Provider value={{ isMaxBetActive, setIsMaxBetActive,isAnimationActive,setIsAnimationActive}}>
                        <LimboComponetFooter/>
                    </MaxBetContext.Provider>
                    </AnimationActive.Provider>
                    </DiceVolume.Provider>
                    
                </Box> 
              </Box>
            )
        }
      </React.Fragment>
    );
}

export default LimboGameContent;