import React from 'react';

const MaxBetContext = React.createContext({
  isMaxBetActive: false,
  setIsMaxBetActive: () => {},
});

const AnimationActive = React.createContext({
  isAnimationActive: true,
  setIsAnimationActive: () => {},
});

const Multiplier = React.createContext({
  multiplier: '2.0000',
  setMultiplier: () => {},
});

const Requesting = React.createContext({
  isRequesting: false,
  setIsRequesting: () => {},
});

const RollResult = React.createContext({
  rollResult: '0',
  setRollResult: () => {},
});

const DiceVolume = React.createContext({
  globalVolume: 100,
  setGlobalVolume: () => {},
});

const BetHistory = React.createContext({
  historyBetsArr: [],
  setHistoryBetsArr: () => {},
});

const IsAutoBetting = React.createContext({
  isAutoBetActive: false,
  setIsAutoBetActive: () => {},
});

const AutoBetStart = React.createContext({
  autoBetStart: false,
  setAutoBetStart: () => {},
});

const AutoBetStop = React.createContext({
  autoBetStop: false,
  setAutoBetStop: () => {},
});

const Contexts = { 
  MaxBetContext, AnimationActive,Multiplier,
  Requesting,RollResult,DiceVolume,BetHistory,IsAutoBetting,
  AutoBetStart,AutoBetStop
 };

export default Contexts;
