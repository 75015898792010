import React from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';

const WalletContentMainNoticeAlert = (props) => {
    const { isOpen,handleCloseClick } = props;

    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        notice: t("notice"),
        confirm: t("confirm"),
        pleaseNoteWithdrawing: t("please_note___withdrawing"),
    }

    return (
        <Dialog
            open={isOpen}
            sx={{
                [`& .${dialogClasses.paper}`]: {
                    bgcolor:'#15151B',
                    borderRadius:'18px',
                },
            }}
        >
            <DialogContent sx={{
                bgcolor:'#15151B',
                width:'591px',
                margin:'0px',
                padding:'0px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'350px',
                },
            }}>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'flex-start',
                    padding:'18px 28px 67px 28px',
                    width:'calc(100% - 56px)',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        padding:'18px',
                        width:'calc(100% - 36px)',
                    },
                }}>
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        width:'100%',
                    }}>
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'24px',
                            fontStyle:'normal',
                            fontWeight:400,
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'15px',
                            },
                        }}>
                            {textData.notice}
                        </Typography>
                        <CardMedia
                            component='img'
                            image={closeIcon}
                            sx={{
                                objectFit:'contain',
                                width:'32px',
                                height:'32px',
                                cursor:'pointer',
                                '&:hover': {
                                    filter: 'brightness(0) invert(1)',
                                },
                            }}
                            onClick={handleCloseClick}
                        />
                    </Grid>
                    <Typography sx={{
                        bgcolor:'#1E212A',
                        borderRadius:'18px',
                        color:'#ACB3D7',
                        fontSize:'20px',
                        fontStyle:'normal',
                        fontWeight:'400',
                        padding:'27px 18px 70px 18px',
                        width:'calc(100% - 36px)',
                        mt:'36px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'18px',
                            padding:'18px 20px',
                            width:'calc(100% - 36px)',
                            mt:'16px',
                            textAlign:'center',
                        },
                    }}>
                        {textData.pleaseNoteWithdrawing}
                    </Typography>

                    <Button variant='text' sx={{
                        textDecoration:'none',
                        bgcolor:'#1CA45F',
                        borderRadius:'27px',
                        height:'54px',
                        width:'100%',
                        mt:'36px',
                        color:'#FFFFFF',
                        fontSize:'18px',
                        fontStyle:'normal',
                        fontWeight:500,
                        '&:hover': {
                            bgcolor:'#1CA45F',
                        },
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'26px',
                            height:'52px',
                            mt:'28px',
                        },
                    }} onClick={handleCloseClick}>
                        {textData.confirm}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default WalletContentMainNoticeAlert;